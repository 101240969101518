import IBufferUpdate from '@buffer/interfaces/IBufferUpdate';
import CmcdManager from '@cmcd/cmcdManager';
import ConfigManager from '@config/configManager';
import Dispatcher from '@dispatcher/dispatcher';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import ERequestType from '@network/enum/ERequestType';
import IHttpProgress from '@network/interfaces/IHttpProgress';
import IHttpResponse from '@network/interfaces/IHttpResponse';
import IHttpTimeout from '@network/interfaces/IHttpTimeout';
import EContentType from '@parser/manifest/enum/EContentType';

import EWMAAlgorithm from './ewma/algorithm';
import EWMAThrougput from './ewma/throughput';
import IAlgorithm from './interfaces/IAlgorithm';
import QUETRA from './quetra/algorithm';

class AbrManager {
  private _logger: ILogger;
  private _algorithm: IAlgorithm | null = null;
  private _bandwidthEstimator: EWMAThrougput;
  private _ewma: EWMAAlgorithm;

  private _bandwidths: Array<number> = [];
  private _bandwidth: number | null = null;

  private _isRecoveryPhase: boolean = true;

  constructor(
    private _mediaSource: MediaSource,
    private _configManager: ConfigManager,
    private _loggerManager: LoggerManager,
    private _dispatcher: Dispatcher
  ) {
    this._logger = this._loggerManager.registerLogger(ELogType.ABR);
    this._bandwidthEstimator = new EWMAThrougput(this._configManager, this._loggerManager, this._dispatcher);
    this._ewma = new EWMAAlgorithm(this._bandwidthEstimator, this._loggerManager);
  }

  set cmcdManager(cmcdManager: CmcdManager) {
    this._bandwidthEstimator.cmcdManager = cmcdManager;
  }

  public get bandwidth(): number | null {
    return this._bandwidth;
  }

  private canChooseBandwidth(): boolean {
    if (this._mediaSource.readyState === 'ended' || this._isRecoveryPhase) {
      return false;
    }

    return true;
  }

  private getBandwidthFromAbr(): number {
    if (!this._algorithm) return this._bandwidths[0];

    return this._algorithm.chooseBandwidth(this._bandwidths);
  }

  private getBandwidthFromThroughput(): number {
    return this._ewma.chooseBandwidth(this._bandwidths);
  }

  /**
   * Choose a bandwidth using one of the selected algorithm.
   * We avoid to change the decision during loading and seeking (recovery phase) if the algorithm target is not reached
   * @returns the chosen bandwidth
   */
  public chooseBandwidth(): number {
    if (this._bandwidth === null) {
      this._bandwidth = this.getBandwidthFromThroughput();
    } else {
      if (this._isRecoveryPhase && this._algorithm?.isTargetReached()) {
        this._isRecoveryPhase = false;
      }
      if (this.canChooseBandwidth()) {
        const bandwidthFromAbr: number = this.getBandwidthFromAbr();
        const bandwidthFromThroughput: number = this.getBandwidthFromThroughput();
        let bandwidth: number;
        // conservative behaviour:
        // when ABR want to switch up, we limit that decision up to the available throughput
        if (bandwidthFromAbr > this._bandwidth) {
          bandwidth = Math.min(bandwidthFromAbr, bandwidthFromThroughput);
        } else {
          bandwidth = bandwidthFromAbr;
        }
        if (bandwidth !== this._bandwidth) {
          this._logger.debug(`switch request: ${bandwidth}`);
          this._bandwidth = bandwidth;
        }
      }
    }

    return this._bandwidth;
  }

  public init(bandwidths: Array<number>, isLive: boolean): void {
    this._bandwidths = bandwidths.filter(
      (bandwidth: number) =>
        bandwidth >= this._configManager.abr.minBandwidth && bandwidth <= this._configManager.abr.maxBandwidth
    );

    if (isLive) {
      this._algorithm = this._ewma;
    } else {
      this._algorithm = new QUETRA(this._bandwidthEstimator, this._configManager, this._loggerManager);
    }
  }

  public onHttpProgress = (_httpProgress: IHttpProgress): void => {
    return;
  };

  public onHttpResponse = (httpResponse: IHttpResponse): void => {
    this._bandwidthEstimator.onHttpResponse(httpResponse);
    this._algorithm?.onHttpResponse(httpResponse);
  };

  public onHttpTimeout = (httpTimeout: IHttpTimeout): boolean => {
    let abortSignal: boolean = false;
    if (httpTimeout.request.type === ERequestType.VIDEO_SEGMENT) {
      const videoBandwidths: Array<number> = this._bandwidths;
      // we should abort video segment requests if we are not already at the lowest bitrate
      if (this._bandwidth !== videoBandwidths[0]) {
        abortSignal = true;
      }
    }
    this._bandwidthEstimator.onHttpTimeout(httpTimeout);
    this._algorithm?.onHttpTimeout(httpTimeout);

    return abortSignal;
  };

  public onAverageSegmentDuration(averageSegmentDuration: number): void {
    this._algorithm?.onAverageSegmentDuration(averageSegmentDuration);
  }

  public onBufferUpdate(bufferUpdate: IBufferUpdate): void {
    const {contentType, ahead} = bufferUpdate;
    if (contentType === EContentType.VIDEO) {
      this._algorithm?.onBufferUpdate(ahead);
    }
  }

  public onRateChange(playbackRate: number): void {
    this._algorithm?.onRateChange(playbackRate);
  }

  public onSeeking(): void {
    if (!this._isRecoveryPhase && this._configManager.abr.stepDownOnSeeking) {
      const videoBandwidths: Array<number> = this._bandwidths;
      const index: number = videoBandwidths.findIndex((v: number) => v === this._bandwidth);
      if (index > 0) {
        this._logger.debug(`Lowering bitrate to recover faster`);
        this._bandwidth = videoBandwidths[index - 1];
      }
    }
    this._isRecoveryPhase = true;
    this._algorithm?.onSeeking();
  }

  public destroy(): void {
    this._logger.info('Destroying ABR manager');

    if (this._algorithm && this._algorithm !== this._ewma) {
      this._algorithm.destroy();
    }
    this._algorithm = null;
    this._bandwidthEstimator.destroy();
    this._ewma.destroy();
  }
}

export default AbrManager;
