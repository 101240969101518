import AbrManager from '@abr/abrManager';
import CdnManager from '@cdn/cdnManager';
import CmcdManager from '@cmcd/cmcdManager';
import ECmcdIntegrationMethod from '@cmcd/enum/ECmcdIntegrationMethod';
import ConfigManager from '@config/configManager';
import IRetryConfig from '@config/interfaces/IRetryConfig';
import Dispatcher from '@dispatcher/dispatcher';
import EEvent from '@dispatcher/enum/EEvent';
import EErrorCode from '@error/enum/EErrorCode';
import EErrorSeverity from '@error/enum/EErrorSeverity';
import EErrorType from '@error/enum/EErrorType';
import IHttpError from '@error/interfaces/IHttpError';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import getUniqueId from '@utils/getUniqueId';

import ERequestType from './enum/ERequestType';
import IFilterRequestRef from './interfaces/IFilterRequestRef';
import IFilterResponseRef from './interfaces/IFilterResponseRef';
import IHttpProgress from './interfaces/IHttpProgress';
import IHttpRequest from './interfaces/IHttpRequest';
import IHttpResponse from './interfaces/IHttpResponse';
import IHttpTimeout from './interfaces/IHttpTimeout';
import INetworkOptions from './interfaces/INetworkOptions';
import INetworkRequest from './interfaces/INetworkRequest';
import IRequest from './interfaces/IRequest';
import IRequestFilter from './interfaces/IRequestFilter';
import IResponseFilter from './interfaces/IResponseFilter';

class NetworkManager {
  private _logger: ILogger;
  private _requests: Map<string, INetworkRequest> = new Map();
  private _cdnManager: CdnManager | null = null;
  private _cmcdManager: CmcdManager | null = null;
  private _requestFilter: IRequestFilter | null = null;
  private _responseFilter: IResponseFilter | null = null;

  constructor(
    private _abrManager: AbrManager | null,
    private _configManager: ConfigManager,
    loggerManager: LoggerManager,
    private _dispatcher: Dispatcher
  ) {
    this._logger = loggerManager.registerLogger(ELogType.NETWORK);
    this.addListeners();
  }

  set cdnManager(cdnManager: CdnManager) {
    this._cdnManager = cdnManager;
  }

  set cmcdManager(cmcdManager: CmcdManager) {
    this._cmcdManager = cmcdManager;
  }

  private addListeners(): void {
    self.addEventListener('online', this.doPendingRequests);
  }

  private removeListeners(): void {
    self.removeEventListener('online', this.doPendingRequests);
  }

  private getRetryConfig(requestType: ERequestType): IRetryConfig {
    switch (requestType) {
      case ERequestType.AUDIO_SEGMENT:
      case ERequestType.VIDEO_SEGMENT:
      case ERequestType.TEXT_SEGMENT:
        return this._configManager.network.segment;
      case ERequestType.MANIFEST:
        return this._configManager.network.manifest;
      case ERequestType.CERTIFICATE:
      case ERequestType.LICENSE:
        return this._configManager.network.drm;
    }
  }

  private createNetworkError(code: EErrorCode, uri: string, statusCode: number): IHttpError {
    return {
      type: EErrorType.NETWORK,
      code,
      uri,
      statusCode
    };
  }
  private emitNetworkErrorEvent(error: IHttpError, severity: EErrorSeverity, message: string): void {
    if (severity === EErrorSeverity.WARN) {
      this._logger.warn(message, error.uri);
    } else {
      this._logger.error(message, error.uri);
    }
    this._dispatcher.emit({
      name: EEvent.TAPE_ERROR,
      message,
      severity,
      ...error
    });
  }

  private emitHttpRequestEvent(httpRequest: IHttpRequest): void {
    this._dispatcher.emit({
      name: EEvent.HTTP_REQUEST,
      ...httpRequest
    });
  }

  private doPendingRequests = (): void => {
    const pendingRequests: Array<INetworkRequest> = [];
    this._requests.forEach((networkRequest: INetworkRequest, id: string) => {
      pendingRequests.push(networkRequest);
      this._requests.delete(id);
    });
    pendingRequests.forEach((networkRequest: INetworkRequest) => {
      const {url, request, options, resolveRef, rejectRef} = networkRequest;
      this.request(url, request, options, undefined, resolveRef, rejectRef);
    });
  };

  private abortAll(shouldReject: boolean = true): void {
    this._requests.forEach((networkRequest: INetworkRequest) => {
      networkRequest.xhr.abort();
      if (shouldReject) {
        const networkError: IHttpError = this.createNetworkError(
          EErrorCode.HTTP_ABORT,
          networkRequest.url,
          networkRequest.xhr.status
        );
        networkRequest.rejectRef(networkError);
      }
    });
    // we are not clearing the requests set here on purpose
  }

  public get pendingRequests(): Array<INetworkRequest> {
    return Array.from(this._requests.values());
  }

  public registerRequestFilter(requestFilter: IRequestFilter): void {
    this._requestFilter = requestFilter;
  }

  public registerResponseFilter(responseFilter: IResponseFilter): void {
    this._responseFilter = responseFilter;
  }

  public request(
    url: string,
    request: IRequest,
    options: INetworkOptions = {},
    retry?: number,
    resolveRef?: (r: IHttpResponse) => void,
    rejectRef?: (r: IHttpError) => void,
    previousHttpStatus: number = 0
  ): Promise<IHttpResponse> {
    return new Promise((resolve: (r: IHttpResponse) => void, reject: (r: IHttpError) => void) => {
      const xhrId: string = getUniqueId();
      resolve = resolveRef ?? resolve;
      reject = rejectRef ?? reject;
      const retryConfig: IRetryConfig = this.getRetryConfig(request.type);
      const retryLeft: number = retry ?? retryConfig.maxRetry;
      this._requests.delete(xhrId);

      if (retryLeft < 0) {
        const message: string = `Max(${retryConfig.maxRetry}) retry reached | ${request.type}`;
        switch (request.type) {
          case ERequestType.CERTIFICATE:
          case ERequestType.LICENSE:
            {
              const code: EErrorCode =
                request.type === ERequestType.CERTIFICATE
                  ? EErrorCode.CERTIFICATE_REQUEST_FAILED
                  : EErrorCode.LICENSE_REQUEST_FAILED;

              const networkError: IHttpError = this.createNetworkError(code, url, previousHttpStatus);
              this.emitNetworkErrorEvent(networkError, EErrorSeverity.FATAL, message);

              reject(networkError);
            }
            break;
          default:
            {
              if (this._cdnManager) {
                const hasNext: boolean = Boolean(this._cdnManager.next());
                if (hasNext) {
                  const networkError: IHttpError = this.createNetworkError(
                    EErrorCode.HTTP_RETRY,
                    url,
                    previousHttpStatus
                  );
                  this.emitNetworkErrorEvent(networkError, EErrorSeverity.ERROR, message);

                  this.abortAll(false);
                  this.doPendingRequests();
                } else {
                  const networkError: IHttpError = this.createNetworkError(
                    EErrorCode.CDN_EXHAUSTED,
                    url,
                    previousHttpStatus
                  );
                  this.emitNetworkErrorEvent(networkError, EErrorSeverity.FATAL, message);

                  reject(networkError);
                }
              } else {
                const networkError: IHttpError = this.createNetworkError(
                  EErrorCode.HTTP_RETRY,
                  url,
                  previousHttpStatus
                );
                this.emitNetworkErrorEvent(networkError, EErrorSeverity.FATAL, message);

                reject(networkError);
              }
            }
            break;
        }

        return;
      }

      const xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.responseType = options.responseType || 'arraybuffer';
      const timeout: number = retryConfig.timeout;
      xhr.timeout = timeout;
      if (!options.headers) {
        options.headers = {};
      }

      if (this._cmcdManager) {
        switch (this._configManager.cmcd.method) {
          case ECmcdIntegrationMethod.QUERYSTRING:
            {
              const extendedUrl: URL = new URL(url);
              const [paramName, paramValue] = this._cmcdManager.getDataAsQueryParameter({
                requestType: request.type
              });
              extendedUrl.searchParams.set(paramName, paramValue);
              url = extendedUrl.toString();
            }
            break;
          case ECmcdIntegrationMethod.HEADERS:
            {
              for (const [name, value] of Object.entries(
                this._cmcdManager.getDataAsHeaders({
                  requestType: request.type
                })
              )) {
                options.headers[name] = value as string;
              }
            }
            break;
        }
      }

      this._requests.set(xhrId, {
        url,
        request,
        xhr,
        options,
        resolveRef: resolve,
        rejectRef: reject
      });

      const before: number = performance.now();

      const requestRef: IFilterRequestRef = {
        requestType: request.type,
        url: this._cdnManager?.getUrl(url) ?? url,
        method: options.method || 'GET',
        headersRef: options.headers ?? {},
        bodyRef: options.body
      };

      this._requestFilter?.(requestRef);

      const method: string = requestRef.method;
      let actualUrl: string = requestRef.url;
      options.body = requestRef.bodyRef;

      xhr.open(method, actualUrl, true);

      if (requestRef.headersRef) {
        Object.keys(requestRef.headersRef).forEach((name: string) => {
          const value: string | undefined = requestRef.headersRef?.[name];
          if (value) {
            xhr.setRequestHeader(name, value);
          }
        });
      }

      if (options.byteRange) {
        const [start, end] = options.byteRange;
        const value: string = `bytes=${start}-${end}`;
        xhr.setRequestHeader('Range', value);
        actualUrl += `@${value}`;
      }

      const message: string = `${method} | ${xhr.responseType} | ${actualUrl}`;
      this._logger.debug(`Request: ${message}`);

      xhr.onload = (): void => {
        const timeMs: number = performance.now() - before;
        if (xhr.status >= 200 && xhr.status < 300) {
          this._requests.delete(xhrId);
          const message: string = `${xhr.status} | ${timeMs.toFixed(2)}ms | ${actualUrl}`;
          this._logger.debug(`Response: ${message}`);

          let contentLength: string | null = null;
          if (xhr.getAllResponseHeaders().toLowerCase().indexOf('content-length') >= 0) {
            contentLength = xhr.getResponseHeader('content-length');
          }

          let bytes: number = contentLength ? +contentLength : 0;
          if (!bytes && request.type === ERequestType.MANIFEST) {
            bytes = xhr.response.length;
          }

          const requestResponserRef: IFilterResponseRef = {
            requestType: request.type,
            url: xhr.responseURL,
            method,
            headers: xhr.getAllResponseHeaders(),
            responseRef: xhr.response
          };
          this._responseFilter?.(requestResponserRef);

          const httpResponse: IHttpResponse = {
            url: actualUrl,
            request,
            data: requestResponserRef.responseRef,
            timeMs,
            bytes
          };
          this._abrManager?.onHttpResponse(httpResponse);
          resolve(httpResponse);
        } else {
          const networkError: IHttpError = this.createNetworkError(
            EErrorCode.HTTP_LOAD,
            actualUrl,
            xhr.status
          );
          this.emitNetworkErrorEvent(
            networkError,
            EErrorSeverity.ERROR,
            `Generic error ${xhr.status} | ${request.type} | Retry left ${retryLeft}`
          );

          this.request(url, request, options, retryLeft - 1, resolve, reject, xhr.status);
        }
      };

      xhr.onerror = (): void => {
        if (!navigator.onLine) {
          const networkError: IHttpError = this.createNetworkError(
            EErrorCode.HTTP_NETWORK,
            actualUrl,
            xhr.status
          );
          this.emitNetworkErrorEvent(networkError, EErrorSeverity.ERROR, 'Network error');
          // do nothing, keep the request pending
        } else {
          const networkError: IHttpError = this.createNetworkError(
            EErrorCode.HTTP_UNKNOWN,
            actualUrl,
            xhr.status
          );
          this.emitNetworkErrorEvent(
            networkError,
            EErrorSeverity.ERROR,
            `Unknown error ${xhr.status} | ${request.type} | Retry left ${retryLeft}`
          );

          this.request(url, request, options, retryLeft - 1, resolve, reject, xhr.status);
        }
      };

      xhr.onabort = (): void => {
        this._logger.debug(`Abort ${url}`);
      };

      let loaded: number = 0;
      xhr.onprogress = (e: ProgressEvent<EventTarget>): void => {
        loaded = e.loaded;
        const timeMs: number = performance.now() - before;
        const httpProgress: IHttpProgress = {
          url: actualUrl,
          request,
          data: xhr.response,
          timeMs,
          loaded,
          bytes: e.total
        };
        this._abrManager?.onHttpProgress(httpProgress);
      };

      xhr.ontimeout = (): void => {
        const networkError: IHttpError = this.createNetworkError(
          EErrorCode.HTTP_TIMEOUT,
          actualUrl,
          xhr.status
        );
        this.emitNetworkErrorEvent(
          networkError,
          EErrorSeverity.WARN,
          `Timeout after ${retryConfig.timeout} | ${request.type} | Retry left ${retryLeft}`
        );

        const httpTimeout: IHttpTimeout = {
          url: actualUrl,
          request,
          timeout,
          loaded
        };

        const shouldAbort: boolean = Boolean(this._abrManager?.onHttpTimeout(httpTimeout));
        if (shouldAbort) {
          reject(networkError);
        } else {
          this.request(url, request, options, retryLeft - 1, resolve, reject, xhr.status);
        }
      };

      xhr.onloadend = (): void => {
        xhr.onload = null;
        xhr.onerror = null;
        xhr.onabort = null;
        xhr.onprogress = null;
        xhr.ontimeout = null;
        xhr.onloadend = null;
      };

      xhr.send(options.body);

      const httpRequest: IHttpRequest = {
        url: actualUrl,
        request
      };
      this._cmcdManager?.onHttpRequest(httpRequest);
      this.emitHttpRequestEvent(httpRequest);
    });
  }

  public abort(requestType: ERequestType, shouldReject: boolean = true): void {
    this._requests.forEach((networkRequest: INetworkRequest, id: string) => {
      if (networkRequest.request.type === requestType) {
        if (navigator.onLine) {
          networkRequest.xhr.abort();
          if (shouldReject) {
            const networkError: IHttpError = this.createNetworkError(
              EErrorCode.HTTP_ABORT,
              networkRequest.url,
              networkRequest.xhr.status
            );
            networkRequest.rejectRef(networkError);
          }
        }
        this._requests.delete(id);
      }
    });
  }

  public destroy(): void {
    this._logger.info('Destroying Network Manager');
    this.removeListeners();

    this.abortAll();
    this._requests.clear();
    this._requestFilter = null;
    this._responseFilter = null;
  }
}

export default NetworkManager;
