const getTimeRange = (timeRanges: TimeRanges, time?: number): [number, number] => {
  if (timeRanges.length === 0) return [0, 0];

  if (time !== undefined) {
    for (let i: number = 0; i < timeRanges.length; i++) {
      const start: number = timeRanges.start(i);
      const end: number = timeRanges.end(i);
      if (start <= time && time <= end) {
        return [start, end];
      }
    }

    return [0, 0];
  } else {
    let start: number = Infinity;
    let end: number = 0;
    for (let i: number = 0; i < timeRanges.length; i++) {
      const timeRangeStart: number = timeRanges.start(i);
      const timeRangeEnd: number = timeRanges.end(i);
      if (timeRangeStart < start) {
        start = timeRangeStart;
      }
      if (timeRangeEnd > end) {
        end = timeRangeEnd;
      }
    }

    return [start, end];
  }
};

export default getTimeRange;
