import Dispatcher from '@dispatcher/dispatcher';
import EEvent from '@dispatcher/enum/EEvent';
import IDataSegment from '@downloader/segment/interfaces/IDataSegment';
import EErrorCode from '@error/enum/EErrorCode';
import EErrorSeverity from '@error/enum/EErrorSeverity';
import EErrorType from '@error/enum/EErrorType';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import buildRawText from '@utils/buildRawText';
import createEmptyRegion from '@utils/createEmptyRegion';
import getPercentageValue from '@utils/getPercentageValue';
import getTimeFromAttr from '@utils/getTimeFromAttr';
import DataViewReader from '@utils/mp4/dataViewReader';
import EEndian from '@utils/mp4/enum/EEndian';
import ESize from '@utils/mp4/enum/ESize';
import IIden from '@utils/mp4/interfaces/IIden';
import IMdat from '@utils/mp4/interfaces/IMdat';
import IMdhd from '@utils/mp4/interfaces/IMdhd';
import IParsedBox from '@utils/mp4/interfaces/IParsedBox';
import IPayl from '@utils/mp4/interfaces/IPayl';
import ITfdt from '@utils/mp4/interfaces/ITfdt';
import ITfhd from '@utils/mp4/interfaces/ITfhd';
import ITrun from '@utils/mp4/interfaces/ITrun';
import Mp4Parser from '@utils/mp4/parser';
import {LINE_HEIGHT_MULTIPLIER} from '@utils/textConstants';

import ICue from '../interfaces/ICue';
import IParser from '../interfaces/IParser';
import IRegion from '../interfaces/IRegion';
import IRegionAlignment from '../interfaces/IRegionAlignment';
import IRegionScroll from '../interfaces/IRegionScroll';
import IStyle from '../interfaces/IStyle';
import IText from '../interfaces/IText';

class WebVTTParser implements IParser {
  private _logger: ILogger;
  private _timescale: number = 0;

  private _DEFAULT_COLOR_CLASS: Record<string, string> = {
    white: 'rgba(255,255,255,1)',
    lime: 'rgba(0,255,0,1)',
    cyan: 'rgba(0,255,255,1)',
    red: 'rgba(255,0,0,1)',
    yellow: 'rgba(255,255,0,1)',
    magenta: 'rgba(255,0,255,1)',
    blue: 'rgba(0,0,255,1)',
    black: 'rgba(0,0,0,1)'
  };
  //  default text background color is equivalent to text color with bg_ prefix
  private _DEFAULT_BG_COLOR_CLASS: Record<string, string> = {};

  // MPEG-TS clock frequency in Hz
  private _MPEG_TIMESCALE = 90000;
  // PTS maximum value: 2^33 (8589934592)
  private _TS_ROLLOVER = 0x200000000;

  constructor(loggerManager: LoggerManager, private _dispatcher: Dispatcher) {
    this._logger = loggerManager.registerLogger(ELogType.PARSER);
    Object.keys(this._DEFAULT_COLOR_CLASS).forEach((key: string) => {
      this._DEFAULT_BG_COLOR_CLASS[`bg_${key}`] = this._DEFAULT_COLOR_CLASS[key];
    });
  }

  private buildSubtitle(segment: IDataSegment): Array<ICue> {
    const cues: Array<ICue> = [];

    const str: string = segment.data as string;

    const lines: Array<string> = str.split(/\r?\n/);
    let styles: Record<string, Record<string, string>> = {};
    const regions: Record<string, IRegion> = {};
    let nth: number = 0;
    let offset: number = 0;
    let isStyle: boolean = false;
    let styleBlock: string = '';
    let isRegion: boolean = false;
    const regionBlock: Array<string> = [];
    for (let i: number = 0; i < lines.length; i++) {
      const line: string = lines[i];

      // Look at X-TIMESTAMP-MAP metadata header in order to synchronize timestamps between audio/video and subtitles
      // https://datatracker.ietf.org/doc/html/draft-pantos-http-live-streaming-20#section-3.5
      if (line.includes('X-TIMESTAMP-MAP')) {
        const cueTimeMatch: RegExpMatchArray | null = line.match(/LOCAL:([^,]+)/);
        const mpegTimeMatch: RegExpMatchArray | null = line.match(/MPEGTS:(\d+)/);
        if (cueTimeMatch && mpegTimeMatch) {
          const cueTime: number = getTimeFromAttr(cueTimeMatch[1]);
          let mpegTime: number = +mpegTimeMatch[1];
          // Handle timestamp rollovers around PTS maximum value
          const rolloverSeconds: number = this._TS_ROLLOVER / this._MPEG_TIMESCALE;
          let segmentStart: number = segment.time;
          while (segmentStart >= rolloverSeconds) {
            segmentStart -= rolloverSeconds;
            mpegTime += this._TS_ROLLOVER;
          }
          offset = mpegTime / this._MPEG_TIMESCALE - cueTime;
        }
        continue;
      }

      if (line === 'STYLE') {
        isStyle = true;
        continue;
      }

      if (isStyle) {
        if (line === '') {
          isStyle = false;
          styles = this.buildStyles(styleBlock);
        } else {
          styleBlock += line + '\n';
          continue;
        }
      }

      if (line === 'REGION') {
        isRegion = true;
        continue;
      }

      if (isRegion) {
        if (line === '') {
          isRegion = false;
          this.buildRegions(regions, regionBlock);
        } else {
          regionBlock.push(line);
          continue;
        }
      }

      if (line.indexOf('-->') <= 0) continue;

      const tokens: Array<string> = line.split('-->');
      const begin: number = getTimeFromAttr(tokens[0].trim());
      const [endStr, ...inlineRegionTokens] = tokens[1].trim().split(' ');
      const end: number = getTimeFromAttr(endStr);
      // following lines are text
      i++;
      const texts: Array<IText> = [];
      let textGroup: string = '';
      while (i < lines.length && lines[i] !== '') {
        textGroup += lines[i] + '\n';
        i++;
      }
      if (textGroup === '') continue;

      const text: Array<IText> = this.buildText(textGroup, styles);
      texts.push(...text);

      texts[texts.length - 1].text = texts[texts.length - 1].text.replace(/\n$/, '');

      const attributes: Record<string, string> = this.getAttributes(inlineRegionTokens);
      const region: IRegion | null = this.buildRegion(regions, attributes);

      let position: number = 0;
      if (attributes.position !== undefined) {
        position = getPercentageValue(attributes.position);
      }

      cues.push({
        id: `${begin}_${end}_${nth}`,
        begin,
        end,
        position,
        offset,
        texts,
        rawText: buildRawText(texts),
        lang: '',
        region
      });

      nth++;
    }

    return cues;
  }

  private buildRegions(regions: Record<string, IRegion>, regionBlock: Array<string>): void {
    const attributes: Record<string, string> = this.getAttributes(regionBlock);

    if (attributes.id !== undefined) {
      regions[attributes.id] = createEmptyRegion();
      this.processRegionAttributes(regions[attributes.id], attributes);
    }
  }

  private getAttributes(tokens: Array<string>): Record<string, string> {
    const attributes: Record<string, string> = {};
    tokens.forEach((line: string) => {
      const [key, value] = line.split(':');
      attributes[key] = value;
    });

    return attributes;
  }

  private buildRegion(regions: Record<string, IRegion>, attributes: Record<string, string>): IRegion | null {
    if (Object.keys(attributes).length <= 0) return null;
    let region: IRegion = createEmptyRegion();

    if (attributes.region !== undefined) {
      region = {...regions[attributes.region]};
    }

    this.processRegionAttributes(region, attributes);

    return region;
  }

  private processRegionAttributes(region: IRegion, attributes: Record<string, string>): void {
    if (attributes.width !== undefined) {
      region.width = getPercentageValue(attributes.width);
    }

    if (attributes.lines !== undefined) {
      region.height = Math.round(+attributes.lines * LINE_HEIGHT_MULTIPLIER);
    }

    if (attributes.regionanchor !== undefined) {
      const [x, y]: Array<string> = attributes.regionanchor.split(',');
      region.regionAnchorX = getPercentageValue(x);
      region.regionAnchorY = getPercentageValue(y);
    }

    if (attributes.viewportanchor !== undefined) {
      const [x, y]: Array<string> = attributes.viewportanchor.split(',');
      region.viewportanchorX = getPercentageValue(x);
      region.viewportanchorY = getPercentageValue(y);
    }

    if (attributes.align !== undefined) {
      region.align = attributes.align as IRegionAlignment;
    }

    if (attributes.scroll !== undefined) {
      region.scroll = attributes.scroll as IRegionScroll;
    }
  }

  private buildStyles(styleBlock: string): Record<string, Record<string, string>> {
    const styleRegex: RegExp = /::cue(?:\(([^)]+)\))? {([^}]+)}/g;
    const propertyRegex: RegExp = /([^\s]+)\s*:\s*([^;]+);/g;
    const styles: Record<string, Record<string, string>> = {};

    let match: RegExpExecArray | null;
    while ((match = styleRegex.exec(styleBlock)) !== null) {
      const className: string = match[1] ? match[1].replace(/^\./, '') : '0';
      const classStyles: Record<string, string> = {};
      let propertyMatch: RegExpExecArray | null;
      while ((propertyMatch = propertyRegex.exec(match[2])) !== null) {
        const camelCaseProp: string = propertyMatch[1].replace(/-([a-z])/g, (_: string, letter: string) =>
          letter.toUpperCase()
        );
        classStyles[camelCaseProp] = propertyMatch[2];
      }
      styles[className] = classStyles;
    }

    return styles;
  }

  private buildText(line: string, styles: Record<string, Record<string, string>>): Array<IText> {
    const regex: RegExp = /<(\w+)((?:\.[\w-#%]+)*)>(.*?)<\/\1>/gs;
    let match: RegExpExecArray | null;
    let lastIndex: number = 0;
    let textWithClasses: Array<{text: string; classes: Array<string>}> = [];

    while ((match = regex.exec(line)) !== null) {
      const [, tag, classesStr, text] = match;
      const classes: Array<string> = classesStr ? classesStr.slice(1).split('.') : [];
      const startIndex: number = match.index;
      const endIndex: number = regex.lastIndex;
      if (startIndex > lastIndex) {
        textWithClasses.push({
          text: line.substring(lastIndex, startIndex),
          classes: []
        });
      }
      textWithClasses.push({
        text,
        classes: [tag, ...classes]
      });
      lastIndex = endIndex;
    }

    if (lastIndex < line.length) {
      textWithClasses.push({
        text: line.substring(lastIndex),
        classes: []
      });
    }

    if (styles['0']) {
      textWithClasses = textWithClasses.map(
        (twc: {text: string; classes: Array<string>}): {text: string; classes: Array<string>} => {
          const {text, classes} = twc;

          return {
            text,
            classes: ['0', ...classes]
          };
        }
      );
    }

    return textWithClasses.map((twc: {text: string; classes: Array<string>}): IText => {
      const {text, classes} = twc;

      if (classes.length === 0) {
        return {
          style: null,
          text
        };
      }

      const style: IStyle = {};

      for (let i: number = 0; i < classes.length; i++) {
        const cl: string = classes[i];
        if (cl === 'b') {
          style.fontWeight = 'bold';
        } else if (cl === 'i') {
          style.fontStyle = 'italic';
        } else if (cl === 'u') {
          style.textDecoration = 'underline';
        } else if (styles[cl]) {
          Object.keys(styles[cl]).forEach((key: string) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            style[key as keyof IStyle] = styles[cl][key as keyof IStyle] as any;
          });
        } else if (this._DEFAULT_COLOR_CLASS[cl]) {
          style.color = this._DEFAULT_COLOR_CLASS[cl];
        } else if (this._DEFAULT_BG_COLOR_CLASS[cl]) {
          style.backgroundColor = this._DEFAULT_BG_COLOR_CLASS[cl];
        }
      }

      return {
        style,
        text
      };
    });
  }

  private parseInit(data: ArrayBuffer): void {
    new Mp4Parser(this._dispatcher)
      .box('moov', Mp4Parser.children)
      .box('trak', Mp4Parser.children)
      .box('mdia', Mp4Parser.children)
      .fullBox('mdhd', (box: IParsedBox) => {
        const parsedMdhdBox: IMdhd = Mp4Parser.parseMdhd(box);
        this._timescale = parsedMdhdBox.timescale;
      })
      .parse(data);
  }

  public parseMp4(segment: IDataSegment): Array<ICue> {
    const data: ArrayBuffer = segment.data as ArrayBuffer;

    if (segment.id === 0) {
      this.parseInit(data);

      return [];
    }

    const cues: Array<ICue> = [];
    let defaultDuration: number = 0;
    let baseMediaDecodeTime: number = 0;
    let presentations: ITrun['sampleData'] = [];
    let rawPayload: Uint8Array | undefined;

    new Mp4Parser(this._dispatcher)
      .box('moof', Mp4Parser.children)
      .box('traf', Mp4Parser.children)
      .fullBox('tfhd', (box: IParsedBox) => {
        const parsedTfhdBox: ITfhd = Mp4Parser.parseTfhd(box);
        defaultDuration = parsedTfhdBox.defaultSampleDuration ?? 0;
      })
      .fullBox('tfdt', (box: IParsedBox) => {
        const parsedTfdtBox: ITfdt = Mp4Parser.parseTfdt(box);
        baseMediaDecodeTime = parsedTfdtBox.baseMediaDecodeTime;
      })
      .fullBox('trun', (box: IParsedBox) => {
        const parsedTrunBox: ITrun = Mp4Parser.parseTrun(box);
        presentations = parsedTrunBox.sampleData;
      })
      .box('mdat', (box: IParsedBox) => {
        const parsedMdatBox: IMdat = Mp4Parser.parseMdat(box);
        rawPayload = parsedMdatBox.data;
      })
      .parse(data);

    if (rawPayload) {
      let currentTime: number = baseMediaDecodeTime;

      const reader: DataViewReader = new DataViewReader(rawPayload, EEndian.BIG, this._dispatcher);

      let i: number = 0;
      for (const presentation of presentations) {
        // If one presentation corresponds to multiple payloads, it is assumed
        // that all of those payloads have the same start time and duration.
        const duration: number = presentation.sampleDuration || defaultDuration;
        const startTime: number = presentation.sampleCompositionTimeOffset
          ? baseMediaDecodeTime + presentation.sampleCompositionTimeOffset
          : currentTime;
        currentTime = startTime + (duration || 0);

        // Read samples until it adds up to the given size.
        let totalSize: number = 0;

        do {
          // Read the payload size.
          const payloadSize: number = reader.readUint32();
          totalSize += payloadSize;

          // Skip the type.
          const payloadType: number = reader.readUint32();
          const payloadName: string = reader.typeToString(payloadType);

          let payload: Uint8Array | null = null;
          if (payloadName === 'vttc') {
            if (payloadSize > ESize.UINT64) {
              payload = reader.readBytes(payloadSize - ESize.UINT64);
            }
          } else if (payloadName === 'vtte') {
            // It's a vtte, which is a vtt cue that is empty. Ignore any data that
            // does exist.
            reader.skip(payloadSize - ESize.UINT64);
          } else {
            reader.skip(payloadSize - ESize.UINT64);
          }

          if (duration) {
            if (payload) {
              let text: string = '';
              let id: string = '';
              // let settings: string = '';
              const begin: number = startTime / this._timescale;
              const end: number = currentTime / this._timescale;

              new Mp4Parser(this._dispatcher)
                .box('payl', (box: IParsedBox) => {
                  const parsedPaylBox: IPayl = Mp4Parser.parsePayl(box);
                  text = parsedPaylBox.text;
                })
                .box('iden', (box: IParsedBox) => {
                  const parsedIdenBox: IIden = Mp4Parser.parseIden(box);
                  id = parsedIdenBox.id;
                })
                // .box('sttg', (box: IParsedBox) => {
                //   const parsedSttgBox: ISttg = Mp4Parser.parseSttg(box);
                //   settings = parsedSttgBox.settings;
                // })
                .parse(payload);

              const texts: Array<IText> = [
                {
                  text,
                  style: null
                }
              ];

              cues.push({
                id: `${begin}_${end}_${i}_${id}`,
                begin,
                end,
                position: 0,
                offset: 0,
                texts,
                rawText: buildRawText(texts),
                lang: '',
                region: null
              });

              i++;
            }
          } else {
            const message: string = 'WebVTT sample duration unknown, and no default found!';
            this._logger.warn(message);
            this._dispatcher.emit({
              name: EEvent.TAPE_ERROR,
              type: EErrorType.INTERNAL,
              code: EErrorCode.PARSER,
              message,
              severity: EErrorSeverity.WARN
            });
          }
          // If no sampleSize was specified, it's assumed that this presentation
          // corresponds to only a single cue.
        } while (presentation.sampleSize && totalSize < presentation.sampleSize);
      }
    }

    return cues;
  }

  public parseText(segment: IDataSegment): Array<ICue> {
    const text: string = segment.data as string;
    if (!text) return [];

    return this.buildSubtitle(segment);
  }

  public destroy(): void {
    this._logger.info('Destroying WebVTT parser');
  }
}

export default WebVTTParser;
