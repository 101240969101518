import IStyledChar from '@parser/text/cea/interfaces/IStyledChar';
import ICue from '@parser/text/interfaces/ICue';
import IStyle from '@parser/text/interfaces/IStyle';
import IText from '@parser/text/interfaces/IText';
import buildRawText from '@utils/buildRawText';

import {DEFAULT_BG_COLOR, DEFAULT_TXT_COLOR} from '../textConstants';

const createTextCue = (underline: boolean, italics: boolean, txtColor: string, bgColor: string): IText => {
  const style: IStyle = {};
  if (underline) {
    style.textDecoration = 'underline';
  }
  if (italics) {
    style.fontStyle = 'italic';
  }
  style.color = txtColor;
  style.backgroundColor = bgColor;

  return {
    text: '',
    style
  };
};

const getParsedCaption = (cue: ICue, memory: Array<Array<IStyledChar | null>>): ICue | null => {
  if (cue.begin >= cue.end) {
    return null;
  }

  // Find the first and last row that contains characters
  let firstNonEmptyRow: number = -1;
  let lastNonEmptyRow: number = -1;

  for (let i: number = 0; i < memory.length; i++) {
    if (memory[i].some((e: IStyledChar | null) => e !== null && e.character.trim() !== '')) {
      firstNonEmptyRow = i;
      break;
    }
  }

  for (let i: number = memory.length - 1; i >= 0; i--) {
    if (memory[i].some((e: IStyledChar | null) => e !== null && e.character.trim() !== '')) {
      lastNonEmptyRow = i;
      break;
    }
  }

  // Exit early if no non-empty row was found
  if (firstNonEmptyRow === -1 || lastNonEmptyRow === -1) {
    return null;
  }

  // Keeps track of the current styles for a cue being emitted
  let currentUnderline: boolean = false;
  let currentItalics: boolean = false;
  let currentTextColor: string = DEFAULT_TXT_COLOR;
  let currentBackgroundColor: string = DEFAULT_BG_COLOR;

  for (let i: number = firstNonEmptyRow; i <= lastNonEmptyRow; i++) {
    // Create first text cue. Default styles
    let currentText: IText = createTextCue(
      currentUnderline,
      currentItalics,
      currentTextColor,
      currentBackgroundColor
    );

    // Find the first and last non-empty characters in this row. We do this so
    // no styles creep in before/after the first and last non-empty chars
    const row: Array<IStyledChar | null> = memory[i];
    let firstNonEmptyCol: number = -1;
    let lastNonEmptyCol: number = -1;

    for (let j: number = 0; j < row.length; j++) {
      if (row[j] !== null && row[j]?.character.trim() !== '') {
        firstNonEmptyCol = j;
        break;
      }
    }

    for (let j: number = row.length - 1; j >= 0; j--) {
      if (row[j] !== null && row[j]?.character.trim() !== '') {
        lastNonEmptyCol = j;
        break;
      }
    }

    // If no non-empty char. was found in this row, it must be a linebreak
    if (firstNonEmptyCol === -1 || lastNonEmptyCol === -1) {
      continue;
    }

    for (let j: number = firstNonEmptyCol; j <= lastNonEmptyCol; j++) {
      const styledChar: IStyledChar | null = row[j];

      // A null between non-empty cells in a row is handled as a space
      if (!styledChar) {
        currentText.text += ' ';
        continue;
      }
      const underline: boolean = styledChar.underline;
      const italics: boolean = styledChar.italics;
      const textColor: string = styledChar.textColor;
      const backgroundColor: string = styledChar.backgroundColor;

      // If any style properties have changed, we need to open a new cue
      if (
        underline !== currentUnderline ||
        italics !== currentItalics ||
        textColor !== currentTextColor ||
        backgroundColor !== currentBackgroundColor
      ) {
        // Push the currently built cue and start a new cue, with new styles
        if (currentText.text) {
          cue.texts.push(currentText);
        }
        currentText = createTextCue(underline, italics, textColor, backgroundColor);

        currentUnderline = underline;
        currentItalics = italics;
        currentTextColor = textColor;
        currentBackgroundColor = backgroundColor;
      }

      currentText.text += styledChar.character;
    }
    if (currentText.text) {
      cue.texts.push(currentText);
    }
  }

  if (cue.texts.length > 0) {
    cue.rawText = buildRawText(cue.texts);

    return cue;
  }

  return null;
};

export default getParsedCaption;
