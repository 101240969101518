const MIN_UPDATE_PERIOD_MS: number = 1000;

const scheduleLiveUpdate = (
  minimumUpdatePeriod: number,
  averageSegmentDuration: number,
  configUpdatePeriod: number | null,
  requestDelayMs: number,
  isSameManifest: boolean
): number => {
  let value: number = minimumUpdatePeriod;

  if (configUpdatePeriod) {
    value = configUpdatePeriod;
  }
  if (isSameManifest) {
    value = averageSegmentDuration / 2;
  }

  const timeout: number = value * 1000 - requestDelayMs;

  return Math.max(timeout, MIN_UPDATE_PERIOD_MS);
};

export default scheduleLiveUpdate;
