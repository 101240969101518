import ESwitchDirection from '@abr/enum/ESwitchDirection';
import ConfigManager from '@config/configManager';
import EErrorCode from '@error/enum/EErrorCode';
import IHttpError from '@error/interfaces/IHttpError';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import IGetSegments from '@manifest/interfaces/IGetSegments';
import ISegmentFilters from '@manifest/interfaces/ISegmentFilters';
import ManifestManager from '@manifest/manifestManager';
import ERequestType from '@network/enum/ERequestType';
import IHttpResponse from '@network/interfaces/IHttpResponse';
import INetworkOptions from '@network/interfaces/INetworkOptions';
import INetworkRequest from '@network/interfaces/INetworkRequest';
import NetworkManager from '@network/networkManager';
import EContentType from '@parser/manifest/enum/EContentType';
import EMimeType from '@parser/manifest/enum/EMimeType';
import ISegment from '@parser/manifest/interfaces/ISegment';

import EBatchStatus from '../enum/EBatchStatus';
import EDownloaderDirection from '../enum/EDownloaderDirection';
import IBatch from '../interfaces/IBatch';
import IDataSegment from '../interfaces/IDataSegment';

class SingleSegmentDownloader {
  protected _batches: Array<IBatch> = [];
  protected _logger: ILogger;
  private _lastSegmentInDownload: ISegment | null = null;
  private _lastSegmentInBuffer: ISegment | null = null;
  private _useRealEncryptedInitSegmentPatchApplied: boolean = false;
  private _direction: EDownloaderDirection = EDownloaderDirection.FORWARD;
  private _bufferEndPosition: number | null = null;

  constructor(
    private _videoElement: HTMLVideoElement,
    private _mediaSource: MediaSource,
    private _manifestManager: ManifestManager,
    private _networkManager: NetworkManager,
    protected _configManager: ConfigManager,
    loggerManager: LoggerManager,
    protected _contentType: Exclude<EContentType, EContentType.IMAGE>,
    private _onBatchReady: () => void,
    protected _label?: string
  ) {
    this._logger = loggerManager.registerLogger(ELogType.DOWNLOADER);
  }

  protected get requestType(): ERequestType {
    switch (this._contentType) {
      case EContentType.VIDEO:
        return ERequestType.VIDEO_SEGMENT;
      case EContentType.AUDIO:
        return ERequestType.AUDIO_SEGMENT;
      case EContentType.TEXT:
        return ERequestType.TEXT_SEGMENT;
    }
  }

  /**
   * Batch is ready when all the segments that are part of that batch are downloaded
   * @param httpResponse
   * @returns
   */
  private onHttpResponse = (httpResponse: IHttpResponse, segmentRef: ISegment): void => {
    const batch: IBatch | undefined = this._batches.find(
      (b: IBatch) => b.status === EBatchStatus.DOWNLOADING
    );
    if (!batch) return;

    let segmentsWithData: number = 0;
    for (let i: number = 0; i < batch.segments.length; i++) {
      const segment: IDataSegment = batch.segments[i];
      if (segment.data) {
        segmentsWithData++;
        continue;
      }

      let matchFound: boolean = false;
      if (segment.url === segmentRef.url) {
        matchFound = true;
        if (segment.byteRange && segmentRef.byteRange) {
          const [s1, s2] = segment.byteRange;
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const [r1, r2] = segmentRef.byteRange!;
          matchFound = s1 === r1 && s2 === r2;
        }
      }
      if (matchFound) {
        this._logger.debug(`${this._contentType} segment ready ${this.composeDebugMessage(segment)}`, {
          ...segment
        });
        segment.data = httpResponse.data as ArrayBuffer | string;
        segmentsWithData++;
        continue;
      }
    }

    if (segmentsWithData === batch.segments.length) {
      batch.status = EBatchStatus.DOWNLOADED;
      this._logger.debug(`${this._contentType} batch ready`, [
        ...batch.segments.map((segment: ISegment) => this.composeDebugMessage(segment))
      ]);

      this._onBatchReady();
    }
  };

  private createBatch(segments: Array<ISegment>): IBatch {
    const batch: IBatch = {
      segments: [],
      status: EBatchStatus.DOWNLOADING
    };

    for (let i: number = 0; i < segments.length; i++) {
      batch.segments.push({
        ...segments[i],
        data: null,
        appended: false
      });
    }

    this._logger.debug(`Create ${this._contentType} batch`, [
      ...segments.map((segment: ISegment) => this.composeDebugMessage(segment))
    ]);

    return batch;
  }

  private isLatestBatchDownloading(): boolean {
    return this._batches[this._batches.length - 1]?.status === EBatchStatus.DOWNLOADING;
  }

  private shouldWaitOtherDownload(): boolean {
    if (!this._lastSegmentInDownload) return false;
    let targetRequestType: ERequestType | null = null;
    switch (this._contentType) {
      case EContentType.VIDEO:
        targetRequestType = ERequestType.AUDIO_SEGMENT;
        break;
      case EContentType.AUDIO:
        targetRequestType = ERequestType.VIDEO_SEGMENT;
        break;
    }

    if (!targetRequestType) return false;

    const times: Array<number> = this._networkManager.pendingRequests
      .filter((r: INetworkRequest) => r.request.type === targetRequestType)
      .map((r: INetworkRequest) => (r.request.ref as ISegment).time);

    if (times.length <= 0) return false;

    const maxTime: number = Math.max(...times);

    return this._lastSegmentInDownload.time > maxTime;
  }

  private canDownload(): boolean {
    if (this._mediaSource.readyState === 'ended') return false;
    if (this.isLatestBatchDownloading()) return false;
    if (this.hasReachedBatchLimits()) return false;
    if (this.shouldWaitOtherDownload()) return false;

    return true;
  }

  private getSegmentsToDownload = (targetTime: number | null): Array<ISegment> => {
    let getSegments: IGetSegments | null;
    const segmentFilters: ISegmentFilters = {
      audioLabel: this._label,
      videoBandwidth: undefined
    };
    switch (this._direction) {
      case EDownloaderDirection.FORWARD:
        getSegments = this._manifestManager.getNextSegments(
          targetTime,
          this._lastSegmentInDownload,
          this._lastSegmentInBuffer,
          this._contentType,
          segmentFilters
        );

        break;
      case EDownloaderDirection.BACKWARD:
        {
          if (this._bufferEndPosition === null) {
            this._logger.warn('buffer end position is not set');
            this.setDirectionForward();

            return [];
          }

          let time: number;
          let threshold: number;
          if (this._lastSegmentInDownload) {
            time = this._lastSegmentInDownload.time - this._lastSegmentInDownload.duration;
            threshold = this._lastSegmentInDownload.duration / 2;
          } else {
            time = this._bufferEndPosition;
            threshold = this._lastSegmentInBuffer?.duration ?? this._manifestManager.averageSegmentDuration;
          }

          if (time - threshold <= this._videoElement.currentTime) {
            this.setDirectionForward();

            getSegments = this._manifestManager.getNextSegments(
              this._bufferEndPosition,
              this._lastSegmentInDownload,
              this._lastSegmentInBuffer,
              this._contentType,
              segmentFilters
            );
          } else {
            getSegments = this._manifestManager.getPrevSegments(
              time,
              this._lastSegmentInDownload,
              this._lastSegmentInBuffer,
              this._contentType,
              segmentFilters
            );
          }
        }
        break;
    }

    if (!getSegments || getSegments.segments.length <= 0) return [];

    if (getSegments.switchInfo) {
      this.abort();
      if (!this._manifestManager.isLive()) {
        switch (getSegments.switchInfo.direction) {
          case ESwitchDirection.DOWN:
            {
              if (this._direction !== EDownloaderDirection.FORWARD) {
                this.setDirectionForward();
              }
            }
            break;
          case ESwitchDirection.UP:
            {
              if (this._direction === EDownloaderDirection.BACKWARD) {
                // We have to discard the segment and download the first next segment
                segmentFilters.videoBandwidth = getSegments.switchInfo.bandwidth;
                getSegments = this._manifestManager.getNextSegments(
                  this._bufferEndPosition,
                  this._lastSegmentInDownload,
                  this._lastSegmentInBuffer,
                  this._contentType,
                  segmentFilters
                );
                if (!getSegments) return [];
              } else {
                this.setDirectionBackward();
              }
            }
            break;
        }
      }
    }

    if (
      this._configManager.patch.useRealEncryptedInitSegment &&
      !this._useRealEncryptedInitSegmentPatchApplied &&
      this._direction === EDownloaderDirection.FORWARD
    ) {
      this.useRealEncryptedInitSegment(getSegments.segments);
      this._useRealEncryptedInitSegmentPatchApplied = true;
    }

    return getSegments.segments;
  };

  private setDirectionBackward(): void {
    this._logger.debug(`Setting ${this._contentType} downloader direction to backward`);
    this._direction = EDownloaderDirection.BACKWARD;
  }

  private setDirectionForward(): void {
    this._logger.debug(`Setting ${this._contentType} downloader direction to forward`);
    this._direction = EDownloaderDirection.FORWARD;
  }

  private useRealEncryptedInitSegment(segmentsToDownload: Array<ISegment>): void {
    if (segmentsToDownload[0].id === 0) {
      const encryptedInitSegment: ISegment | null = this._manifestManager.getNextEncryptedInitSegment(
        segmentsToDownload[0]
      );
      if (encryptedInitSegment) {
        this._logger.debug(`Patch: need encrypted init ${this._contentType} segment`, encryptedInitSegment);
        segmentsToDownload.unshift(encryptedInitSegment);
      }
    }
  }

  protected composeDebugMessage(segment: ISegment): string {
    const prefix: string = this._label ? `${this._label}:` : '';

    return `${prefix}${segment.id} (${segment.representation.adaptation.period.id}:${segment.representation.bandwidth})`;
  }

  protected hasReachedBatchLimits(): boolean {
    if (this._batches.length >= this._configManager.downloader.maxBatchesLength) return true;

    let dataSize: number = 0;
    for (let x: number = 0; x < this._batches.length; x++) {
      const batch: IBatch = this._batches[x];
      for (let y: number = 0; y < batch.segments.length; y++) {
        dataSize += (batch.segments[y].data as ArrayBuffer).byteLength;
      }
    }
    const bytesLimit: number = this._configManager.downloader.maxBatchesSizeMb * 1000000;
    if (dataSize >= bytesLimit) return true;

    return false;
  }

  protected onHttpTimeout = (segment: ISegment): void => {
    this._logger.debug(`${this._contentType} segment timeout ${this.composeDebugMessage(segment)}`, {
      ...segment
    });

    const latestBatch: IBatch = this._batches[this._batches.length - 1];
    if (latestBatch.status !== EBatchStatus.DOWNLOADING) {
      this._logger.warn('Timeout occurred without any downloading segment');

      return;
    }

    const targetTime: number = segment.time - segment.duration;

    this.abort();
    this.downloadSegments(targetTime);
  };

  public set lastSegmentInBuffer(lastSegmentAppendedToBuffer: ISegment) {
    this._lastSegmentInBuffer = lastSegmentAppendedToBuffer;
  }

  public set bufferEndPosition(bufferEndPosition: number) {
    this._bufferEndPosition = bufferEndPosition;
  }

  protected abort(): void {
    this._logger.debug(`Abort ${this._contentType} segment downloader`);
    this._networkManager.abort(this.requestType);
    this._lastSegmentInDownload = null;

    while (this._batches[this._batches.length - 1]?.status === EBatchStatus.DOWNLOADING) {
      this._logger.debug(`Remove ${this._contentType} batch, because it's in downloading status`);
      this._batches.pop();
    }
  }

  public downloadSegments(targetTime: number | null = null): void {
    if (this.canDownload()) {
      const segmentsToDownload: Array<ISegment> = this.getSegmentsToDownload(targetTime);

      if (segmentsToDownload.length <= 0) return;

      const batch: IBatch = this.createBatch(segmentsToDownload);
      this._batches.push(batch);

      this._lastSegmentInDownload = Object.assign({}, segmentsToDownload[segmentsToDownload.length - 1]);
      for (let i: number = 0; i < batch.segments.length; i++) {
        const segment: ISegment = batch.segments[i];

        const networkOptions: INetworkOptions = {};
        const {
          byteRange,
          representation: {
            adaptation: {mimeType}
          }
        } = segment;

        this._logger.debug(
          `Downloading next ${this._contentType} segment ${this.composeDebugMessage(segment)}`,
          {...segment}
        );

        if (this._contentType === EContentType.TEXT && mimeType !== EMimeType.MP4) {
          networkOptions.responseType = 'text';
        }
        if (byteRange) {
          networkOptions.byteRange = byteRange;
        }

        this._networkManager
          .request(segment.url, {type: this.requestType, ref: segment}, networkOptions)
          .then(
            (r: IHttpResponse) => this.onHttpResponse(r, segment),
            (e: IHttpError) => {
              if (e.code === EErrorCode.HTTP_TIMEOUT) {
                this.onHttpTimeout(segment);
              }
            }
          );
      }
    }
  }

  public getReadyDataSegment(): IDataSegment | null {
    const batch: IBatch | undefined = this._batches[0];
    if (!batch || batch.status !== EBatchStatus.DOWNLOADED) return null;

    return batch.segments[0] ?? null;
  }

  public deleteReadyDataSegment(): void {
    const batch: IBatch | undefined = this._batches[0];
    if (!batch || batch.status !== EBatchStatus.DOWNLOADED) return;

    if (batch.segments[0]?.data) {
      batch.segments.shift();
    }

    if (batch.segments.length === 0) {
      this._batches.shift();
    }
  }

  public init(time: number): void {
    this.downloadSegments(time);
  }

  public reset(shouldDownload: boolean = true): void {
    this._lastSegmentInBuffer = null;
    this._bufferEndPosition = null;
    this._batches.length = 0;
    this.setDirectionForward();
    this.abort();
    if (shouldDownload) {
      this.downloadSegments(this._videoElement.currentTime);
    }
  }

  public destroy(): void {
    this._logger.info(`Destroying ${this._contentType} Single Segment Downloader`);

    this._lastSegmentInBuffer = null;
    this._bufferEndPosition = null;
    this.abort();
  }
}

export default SingleSegmentDownloader;
