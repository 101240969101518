import ConfigManager from '@config/configManager';
import Dispatcher from '@dispatcher/dispatcher';
import EEvent from '@dispatcher/enum/EEvent';
import EErrorCode from '@error/enum/EErrorCode';
import EErrorSeverity from '@error/enum/EErrorSeverity';
import EErrorType from '@error/enum/EErrorType';
import IHttpError from '@error/interfaces/IHttpError';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import IRepresentationChange from '@manifest/interfaces/IRepresentationChange';
import ERequestType from '@network/enum/ERequestType';
import IHttpResponse from '@network/interfaces/IHttpResponse';
import NetworkManager from '@network/networkManager';
import DashParser from '@parser/manifest/dash/parser';
import IManifest from '@parser/manifest/interfaces/IManifest';
import IRepresentation from '@parser/manifest/interfaces/IRepresentation';
import ProbeCapabilities from '@utils/capabilities/probe';
import isLiveManifest from '@utils/isLiveManifest';
import scheduleLiveUpdate from '@utils/manifest/scheduleLiveUpdate';

import IDownloader from '../interfaces/IDownloader';

class DashDownloader implements IDownloader {
  private _logger: ILogger;
  private _parser: DashParser | null = null;
  private _fetchTimeout: number | null = null;
  private _lastPublishTime: number = 0;
  private _lastManifestRequestTimeMs: number = 0;
  private _averageSegmentDuration: number = 0;

  constructor(
    private _url: URL,
    private _networkManager: NetworkManager,
    private _configManager: ConfigManager,
    probeCapabilities: ProbeCapabilities,
    loggerManager: LoggerManager,
    private _dispatcher: Dispatcher,
    private _onManifestDownloaded: (m: IManifest) => void
  ) {
    this._logger = loggerManager.registerLogger(ELogType.DOWNLOADER);
    this._parser = new DashParser(
      this._url,
      this._networkManager,
      _configManager,
      probeCapabilities,
      loggerManager,
      this._dispatcher
    );
  }

  private onHttpResponse = async (httpResponse: IHttpResponse, representationId?: string): Promise<void> => {
    let manifest: IManifest | null | undefined = null;
    const before: number = performance.now();
    try {
      if (representationId === undefined) {
        manifest = await this._parser?.parseManifest(httpResponse.data as string);
      } else {
        // manifest with SegmentBase
        manifest = this._parser?.parseSegment(httpResponse.data as ArrayBuffer, representationId);
      }
    } catch (e) {
      const message: string = 'Error parsing manifest';
      this._logger.error(message, httpResponse.url, e);
      this._dispatcher.emit({
        name: EEvent.TAPE_ERROR,
        type: EErrorType.INTERNAL,
        code: EErrorCode.PARSER,
        severity: EErrorSeverity.FATAL,
        message
      });
    }

    if (manifest) {
      const parseTime: number = performance.now() - before;
      this._logger.log(`Manifest parsed in ${parseTime.toFixed(2)}ms`);
      this._logger.debug('Parsed manifest', manifest);

      if (isLiveManifest(manifest)) {
        const isSameManifest: boolean = manifest.publishTime === this._lastPublishTime;
        if (isSameManifest) {
          this._logger.log(`Just fetched the same manifest, do nothing`);
        } else {
          this.emitManifestDownloadedEvent(manifest, parseTime);
          this._onManifestDownloaded(manifest);
        }

        this._lastPublishTime = manifest.publishTime;
        const requestDelayMs: number = performance.now() - this._lastManifestRequestTimeMs;
        const timeout: number = scheduleLiveUpdate(
          manifest.minimumUpdatePeriod,
          this._averageSegmentDuration,
          this._configManager.manifest.updatePeriod,
          requestDelayMs,
          isSameManifest
        );
        this._logger.log(`Fetch the next manifest in ${timeout}ms`);
        const manifestUrl: string = manifest.patchLocation ?? this._url.href;
        this._fetchTimeout = self.setTimeout(() => {
          if (!this._parser) return;
          this.fetch(manifestUrl);
        }, timeout);
      } else {
        this.emitManifestDownloadedEvent(manifest, parseTime);
        this._onManifestDownloaded(manifest);
      }
    }
  };

  private emitManifestDownloadedEvent(manifest: IManifest, timeMs: number): void {
    this._dispatcher.emit({
      name: EEvent.MANIFEST_DOWNLOADED,
      url: manifest.url,
      type: manifest.type,
      timeMs
    });
  }

  private fetch(manifestUrl: string): void {
    this._lastManifestRequestTimeMs = performance.now();
    this._networkManager
      .request(
        manifestUrl,
        {type: ERequestType.MANIFEST},
        {
          responseType: 'text'
        }
      )
      .then(this.onHttpResponse, (_: IHttpError) => {});
  }

  private clearTimeout(): void {
    if (this._fetchTimeout !== null) {
      self.clearTimeout(this._fetchTimeout);
    }
  }

  public init(): void {
    this.fetch(this._url.href);
  }

  public onAverageSegmentDuration(averageSegmentDuration: number): void {
    this._averageSegmentDuration = averageSegmentDuration;
  }

  /**
   * We have an empty representation when we have a manifest with SegmentBase
   */
  public onRepresentationEmpty(representation: IRepresentation): void {
    const {id} = representation;
    this._parser?.fetchSegment(id).then((r: IHttpResponse) => this.onHttpResponse(r, id));
  }

  public onRepresentationChange(_representationChange: IRepresentationChange): void {
    return void 0;
  }

  public destroy(): void {
    this._logger.info('Destroying DASH downloader');

    this._parser?.destroy();
    this._parser = null;
    this.clearTimeout();
  }
}

export default DashDownloader;
