import Dispatcher from '@dispatcher/dispatcher';
import EEvent from '@dispatcher/enum/EEvent';
import EErrorCode from '@error/enum/EErrorCode';
import EErrorSeverity from '@error/enum/EErrorSeverity';
import EErrorType from '@error/enum/EErrorType';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';

import ICea708ClosedCaptionByte from './interfaces/ICea708ClosedCaptionByte';

class DtvccPacket {
  private _logger: ILogger;
  private _pos: number = 0;

  constructor(
    private _packetData: Array<ICea708ClosedCaptionByte>,
    loggerManager: LoggerManager,
    private _dispatcher: Dispatcher
  ) {
    this._logger = loggerManager.registerLogger(ELogType.PARSER);
  }

  public getPosition(): number {
    return this._pos;
  }

  public hasMoreData(): boolean {
    return this._pos < this._packetData.length;
  }

  // Reads a byte from the packet
  public readByte(): ICea708ClosedCaptionByte {
    return this._packetData[this._pos++];
  }

  // Skips the provided number of blocks in the buffer
  public skip(numBlocks: number): void {
    if (this._pos + numBlocks > this._packetData.length) {
      const message: string = 'Buffer position out of bounds';
      this._logger.warn(message);
      this._dispatcher.emit({
        name: EEvent.TAPE_ERROR,
        type: EErrorType.INTERNAL,
        code: EErrorCode.READER_OUT_OF_BOUNDS,
        severity: EErrorSeverity.WARN,
        message
      });
    }
    this._pos += numBlocks;
  }
}

export default DtvccPacket;
