import Dispatcher from '@dispatcher/dispatcher';
import IDataSegment from '@downloader/segment/interfaces/IDataSegment';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import buildRawText from '@utils/buildRawText';
import createEmptyRegion from '@utils/createEmptyRegion';
import getPercentageValue from '@utils/getPercentageValue';
import getTimeFromAttr from '@utils/getTimeFromAttr';
import htmlUnescape from '@utils/htmlUnescape';
import IMDat from '@utils/mp4/interfaces/IMdat';
import IParsedBox from '@utils/mp4/interfaces/IParsedBox';
import Mp4Parser from '@utils/mp4/parser';
import uint8ToString from '@utils/uint8ToString';
import {GenericParserContext, GenericXmlNode, getNodeText, XmlParser} from '@utils/xml-parser-ts/parser';

import ICue from '../interfaces/ICue';
import IParser from '../interfaces/IParser';
import IRegion from '../interfaces/IRegion';
import IRegionAlignment from '../interfaces/IRegionAlignment';
import IStyle from '../interfaces/IStyle';
import IText from '../interfaces/IText';

type NodeOrString = GenericXmlNode | string;
class TtmlParser implements IParser {
  private _logger: ILogger;

  constructor(loggerManager: LoggerManager, private _dispatcher: Dispatcher) {
    this._logger = loggerManager.registerLogger(ELogType.PARSER);
  }

  private buildCue(
    paragraphNode: GenericXmlNode,
    styleMap: Record<string, IStyle>,
    regionMap: Record<string, IRegion>,
    lang: string,
    index: number
  ): ICue {
    const beginAttr: string = paragraphNode.attrs['begin'] || '';
    const endAttr: string = paragraphNode.attrs['end'] || '';
    const regionAttr: string = paragraphNode.attrs['region'] || '';
    const styleAttr: string = paragraphNode.attrs['style'] || '';
    const paragraphStyle: IStyle = this.getStyle(paragraphNode);
    const children: Array<NodeOrString> = paragraphNode.orderedChildren || [];
    const texts: Array<IText> = this.buildTextItems(children, styleMap, null);
    const begin: number = getTimeFromAttr(beginAttr);
    const end: number = getTimeFromAttr(endAttr);
    const region: IRegion | null = this.buildRegion(
      regionAttr,
      regionMap,
      styleAttr,
      styleMap,
      paragraphStyle
    );

    return {
      id: `${begin}_${end}_${index}`,
      begin,
      end,
      position: 0,
      offset: 0,
      texts,
      lang,
      rawText: buildRawText(texts),
      region
    };
  }

  private buildRegion(
    regionId: string,
    regionMap: Record<string, IRegion>,
    styleId: string,
    styleMap: Record<string, IStyle>,
    localStyle: IStyle
  ): IRegion {
    const region: IRegion = regionMap?.[regionId] || createEmptyRegion();
    const style: IStyle = styleMap?.[styleId];

    return {
      ...region,
      style: {
        ...style,
        ...localStyle,
        ...region.style
      }
    };
  }

  private convertAlignment(alignment: string): IRegionAlignment {
    switch (alignment) {
      case 'before':
        return 'start';
      case 'center':
        return 'center';
      case 'after':
        return 'end';
      case 'justify':
        return 'auto';
      default:
        return 'center';
    }
  }

  private convertAttributesToRegion(node: GenericXmlNode): Partial<IRegion> {
    const region: Partial<IRegion> = {};
    const origin: string | null = node.attrs['tts:origin'] || null;
    const extent: string | null = node.attrs['tts:extent'] || null;
    const displayAlign: string | null = node.attrs['tts:displayAlign'] || null;

    if (origin) {
      const [originX, originY] = origin.split(' ');
      const originPercX: number = getPercentageValue(originX);
      const originPercY: number = getPercentageValue(originY);

      region.regionAnchorX = 0;
      region.regionAnchorY = 0;

      region.viewportanchorX = originPercX;
      region.viewportanchorY = originPercY;
    }

    if (extent) {
      const [extentX, extentY] = extent.split(' ');
      const originExtentX: number = getPercentageValue(extentX);
      const originExtentY: number = getPercentageValue(extentY);

      region.width = originExtentX;
      region.height = originExtentY;
    }

    if (displayAlign) {
      region.align = this.convertAlignment(displayAlign);
    }

    return region;
  }

  private getFontSizeAttr(node: GenericXmlNode): string | null {
    const fontSizeAttr: string | null = node.attrs['tts:fontSize'] || null;
    if (fontSizeAttr && fontSizeAttr.slice(-1) === 'c') {
      return null;
    }

    return fontSizeAttr;
  }

  private getStyle(node: GenericXmlNode): IStyle {
    const colorAttr: string | null = node.attrs['tts:color'];
    const backgroundColorAttr: string | null = node.attrs['tts:backgroundColor'] || null;
    const fontFamilyAttr: string | null = node.attrs['tts:fontFamily'] || null;
    const fontSizeAttr: string | null = this.getFontSizeAttr(node);
    const textAlignAttr: string | null = node.attrs['tts:textAlign'] || null;
    const textDecorationAttr: string | null = node.attrs['tts:textDecoration'] || null;
    const fontWeightAttr: string | null = node.attrs['tts:fontWeight'] || null;
    const fontStyleAttr: string | null = node.attrs['tts:fontStyle'] || null;
    const zIndexAttr: string | null = node.attrs['tts:zIndex'] || null;

    return {
      ...(colorAttr && {color: colorAttr}),
      ...(backgroundColorAttr && {backgroundColor: backgroundColorAttr}),
      ...(fontFamilyAttr && {fontFamily: fontFamilyAttr}),
      ...(fontSizeAttr && {fontSize: fontSizeAttr}),
      ...(textAlignAttr && {textAlign: textAlignAttr}),
      ...(textDecorationAttr && {textDecoration: textDecorationAttr}),
      ...(fontWeightAttr && {fontWeight: fontWeightAttr}),
      ...(fontStyleAttr && {fontStyle: fontStyleAttr}),
      ...(zIndexAttr && {zIndex: +zIndexAttr})
    };
  }

  private buildStyleMap(styleNodes: Array<GenericXmlNode | string>): Record<string, IStyle> {
    const styleMap: Record<string, IStyle> = {};

    for (let i: number = 0; i < styleNodes.length; i++) {
      const styleNode: GenericXmlNode | string = styleNodes[i];
      if (typeof styleNode === 'string') continue;
      const idAttr: string | null = styleNode.attrs['xml:id'] || null;
      const styleAttr: string | null = styleNode.attrs['style'] || null;

      if (!idAttr) continue;
      if (styleMap[idAttr]) continue;

      let inheritStyle: IStyle = {};
      if (styleAttr && styleMap[styleAttr]) {
        inheritStyle = {...styleMap[styleAttr]};
      }

      styleMap[idAttr] = {
        ...inheritStyle,
        ...this.getStyle(styleNode)
      };
    }

    return styleMap;
  }

  private buildRegionMap(
    regionNodes: Array<GenericXmlNode | string>,
    styleMap: Record<string, IStyle>
  ): Record<string, IRegion> {
    return (
      regionNodes.filter((node: GenericXmlNode | string) => typeof node !== 'string') as Array<GenericXmlNode>
    ).reduce((acc: Record<string, IRegion>, cur: GenericXmlNode) => {
      const idAttr: string | null = cur.attrs['xml:id'] || null;
      const styleAttr: string | null = cur.attrs['style'] || null;
      const defaultRegion: IRegion = createEmptyRegion();

      const style: IStyle = {
        ...this.getStyle(cur),
        ...(styleAttr && styleMap?.[styleAttr])
      };

      return {
        ...acc,
        ...(idAttr && {
          [idAttr]: {
            ...defaultRegion,
            ...this.convertAttributesToRegion(cur),
            ...(Object.keys(style).length > 0 && {style: {...style}})
          }
        })
      };
    }, {});
  }

  private buildTextItems(
    nodes: Array<NodeOrString>,
    styleMap: Record<string, IStyle>,
    containerStyle: IStyle | null
  ): Array<IText> {
    const texts: Array<IText> = [];

    for (let i: number = 0; i < nodes.length; i++) {
      const node: NodeOrString = nodes[i];
      if (typeof node === 'string') {
        const text: string = node.trim();
        if (text) {
          texts.push({
            style: containerStyle,
            text: htmlUnescape(text)
          });
        }
      } else {
        const nodeChildren: Array<NodeOrString> = node.orderedChildren || [];

        if (nodeChildren.length > 1) {
          let style: IStyle | null = null;
          const styleName: string | null = node.attrs['style'] || null;
          if (styleName) {
            style = styleMap[styleName] ?? null;
          }
          texts.push(...this.buildTextItems(nodeChildren, styleMap, style));
        } else if (node.tagName === 'br') {
          // do nothing
        } else {
          let style: IStyle | null = null;
          const styleName: string | null = node.attrs['style'] || null;

          style = {
            ...(containerStyle && containerStyle),
            ...(styleName && styleMap?.[styleName]),
            ...this.getStyle(node)
          };

          if (Object.keys(style).length === 0) {
            style = null;
          }

          const text: string = htmlUnescape(getNodeText(node)).trim();

          texts.push({
            text,
            style
          });
        }
      }
    }

    return texts;
  }

  public parseMp4(segment: IDataSegment): Array<ICue> {
    if (segment.id === 0) return [];

    const segmentText: IDataSegment = {...segment};
    const data: ArrayBuffer = segment.data as ArrayBuffer;

    new Mp4Parser(this._dispatcher)
      .box('mdat', (box: IParsedBox) => {
        const parsedMdatBox: IMDat = Mp4Parser.parseMdat(box);
        segmentText.data = uint8ToString(parsedMdatBox.data);
      })
      .parse(data);

    return this.parseText(segmentText);
  }

  public parseText(segment: IDataSegment): Array<ICue> {
    const text: string = segment.data as string;
    if (!text) return [];

    const ttNode: GenericXmlNode = {
      tagName: 'tt',
      children: {},
      attrs: {}
    };

    new XmlParser(
      new GenericParserContext(ttNode, {saveMultipleText: true, needsOrderedChildren: true})
    ).write(text);

    const langAttr: string = ttNode.attrs['xml:lang'] || '';

    const head: GenericXmlNode | null = ttNode.children['head']?.[0] || null;
    const body: GenericXmlNode | null = ttNode.children['body']?.[0] || null;

    let styles: Array<NodeOrString> = [];
    let regions: Array<NodeOrString> = [];
    let paragraphs: Array<NodeOrString> = [];

    if (head) {
      const children: Array<NodeOrString> = head.orderedChildren || [];

      children.forEach((c: NodeOrString) => {
        if (typeof c === 'string') return;
        if (c.tagName === 'styling') {
          styles = [...(c.orderedChildren || [])];
        } else if (c.tagName === 'layout') {
          regions = [...(c.orderedChildren || [])];
        }
      });
    }

    if (body) {
      const children: Array<NodeOrString> = body.orderedChildren || [];

      children.forEach((c: NodeOrString) => {
        if (typeof c === 'string') return;
        if (c.tagName === 'div') {
          paragraphs = c.orderedChildren || [];
        }
      });
    }

    const styleMap: Record<string, IStyle> = this.buildStyleMap(styles);
    const regionMap: Record<string, IRegion> = this.buildRegionMap(regions, styleMap);

    return (paragraphs.filter((node: NodeOrString) => typeof node !== 'string') as Array<GenericXmlNode>).map(
      (node: GenericXmlNode, i: number) => this.buildCue(node, styleMap, regionMap, langAttr, i)
    );
  }

  public destroy(): void {
    this._logger.info('Destroying TTML parser');
  }
}

export default TtmlParser;
