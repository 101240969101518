import AbrManager from '@abr/abrManager';
import ESwitchDirection from '@abr/enum/ESwitchDirection';
import ISwitchInfo from '@abr/interfaces/ISwitchInfo';
import ConfigManager from '@config/configManager';
import Dispatcher from '@dispatcher/dispatcher';
import EEvent from '@dispatcher/enum/EEvent';
import ManifestDownloader from '@downloader/manifest/manifestDownloader';
import {RESTRICTED_EME_STATUSES} from '@eme/emeConstants';
import EMediaKeyStatus from '@eme/enum/EMediaKeyStatus';
import EErrorCode from '@error/enum/EErrorCode';
import EErrorSeverity from '@error/enum/EErrorSeverity';
import EErrorType from '@error/enum/EErrorType';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import IRepresentationChange from '@manifest/interfaces/IRepresentationChange';
import ERequestType from '@network/enum/ERequestType';
import INetworkRequest from '@network/interfaces/INetworkRequest';
import NetworkManager from '@network/networkManager';
import EContentType from '@parser/manifest/enum/EContentType';
import EPlayable from '@parser/manifest/enum/EPlayable';
import IAdaptationSet from '@parser/manifest/interfaces/IAdaptationSet';
import IContentProtection from '@parser/manifest/interfaces/IContentProtection';
import IManifest from '@parser/manifest/interfaces/IManifest';
import IOutbandEvent from '@parser/manifest/interfaces/IOutbandEvent';
import IOutbandEventStream from '@parser/manifest/interfaces/IOutbandEventStream';
import IPeriod from '@parser/manifest/interfaces/IPeriod';
import IRepresentation from '@parser/manifest/interfaces/IRepresentation';
import IRole from '@parser/manifest/interfaces/IRole';
import ISegment from '@parser/manifest/interfaces/ISegment';
import ELiveEdgeStatus from '@presentation/enum/ELiveEdgeStatus';
import ISeekableRange from '@presentation/interfaces/ISeekableRange';
import PresentationManager from '@presentation/presentationManager';
import ThumbnailManager from '@thumbnail/thumbnailManager';
import binarySearchByTime from '@utils/binarySearch';
import ProbeCapabilities from '@utils/capabilities/probe';
import getCeaAdaptations from '@utils/cea/getCeaAdaptations';
import isAdaptationPlayable from '@utils/isAdaptationPlayable';
import isKindaEqual from '@utils/isKindaEqual';

import IAbrRepresentationChange from './interfaces/IAbrRepresentationChange';
import IAudioDedupFilter from './interfaces/IAudioDedupFilter';
import IAvailableRepresentations from './interfaces/IAvailableRepresentations';
import IGetSegments from './interfaces/IGetSegments';
import ISegmentFilters from './interfaces/ISegmentFilters';

class ManifestManager {
  private _logger: ILogger;
  private _manifestDownloader: ManifestDownloader;
  private _probeCapabilities: ProbeCapabilities;
  private _presentationManager: PresentationManager;
  private _thumbnailManager: ThumbnailManager;

  private _manifestRef: IManifest | null = null;
  private _contentProtections: Array<IContentProtection> = [];
  private _contentProtectionKeyStatusesMap: Map<string, string> = new Map();
  private _periodPresentationTimeOffsetMap: Map<string, number> = new Map();

  private _selectedVideoTrack: number | 'auto' | null = 'auto';
  private _selectedAudioTrack: string | null = null;
  private _selectedTextTrack: Omit<string, 'off'> | 'off' | null = null;
  private _selectedImageTrack: Omit<string, 'off'> | 'off' = 'off';

  private _averageSegmentDuration: number = 0;
  private _adjustedStartingPosition: number | null = null;
  private _audioVideoStartingPositionMap: Map<EContentType, number> = new Map();

  constructor(
    url: string,
    private _videoElement: HTMLVideoElement,
    private _mediaSource: MediaSource,
    private _abrManager: AbrManager,
    private _networkManager: NetworkManager,
    private _configManager: ConfigManager,
    loggerManager: LoggerManager,
    private _dispatcher: Dispatcher,
    private _audioDedupFilter: IAudioDedupFilter | null,
    private _onManifestUpdate: (isFirstUpdate: boolean) => void,
    private _onAvailableRepresentations: (ar: IAvailableRepresentations) => void,
    private _onRepresentationChange: (r: IRepresentationChange | IAbrRepresentationChange) => void,
    private _onStartingPositionAdjusted: (p: number) => void,
    onSeekableRangeChange: (r: ISeekableRange) => void,
    onContentProtectionEncountered: (cp: IContentProtection) => void
  ) {
    this._logger = loggerManager.registerLogger(ELogType.MANIFEST);
    this._probeCapabilities = new ProbeCapabilities(
      this._configManager,
      loggerManager,
      onContentProtectionEncountered
    );

    this._manifestDownloader = new ManifestDownloader(
      url,
      this._networkManager,
      this._configManager,
      this._probeCapabilities,
      loggerManager,
      this._dispatcher,
      this.onSetManifest
    );
    this._presentationManager = new PresentationManager(
      this._configManager,
      loggerManager,
      this._dispatcher,
      onSeekableRangeChange
    );
    this._thumbnailManager = new ThumbnailManager(loggerManager, this._dispatcher);
  }

  public get contentProtections(): Array<IContentProtection> {
    return this._contentProtections;
  }

  public get averageSegmentDuration(): number {
    return this._averageSegmentDuration;
  }

  public get manifest(): IManifest {
    if (!this._manifestRef) {
      throw new Error('manifest not ready yet');
    }

    return this._manifestRef;
  }

  private addNewPeriods(newPeriods: Array<IPeriod>): void {
    for (let nP: number = newPeriods.length - 1; nP >= 0; nP--) {
      this._logger.debug(`Adding new period: ${newPeriods[nP].id}`);
      this._manifestRef?.periods.push(newPeriods[nP]);
    }
  }

  private removeOldPeriods(): void {
    if (!this._manifestRef) return;

    const startSeekableRange: number = this._presentationManager.getSeekableRange().start;
    let count: number = 0;
    for (let i: number = 0; i < this._manifestRef.periods.length; i++) {
      const {start, duration, id} = this._manifestRef.periods[i];
      if (start + duration < startSeekableRange) {
        this._logger.debug(`Removing period: ${id}`);
        count++;
      } else {
        break;
      }
    }
    if (count > 0) {
      this._manifestRef.periods.splice(0, count);
    }
  }

  private chooseClosestRepresentation(
    representations: Array<IRepresentation>,
    bandwidth: number
  ): IRepresentation {
    return representations
      .sort((r1: IRepresentation, r2: IRepresentation) => r1.bandwidth - r2.bandwidth)
      .reduce((r1: IRepresentation, r2: IRepresentation) => {
        const aDiff: number = Math.abs(r1.bandwidth - bandwidth);
        const bDiff: number = Math.abs(r2.bandwidth - bandwidth);

        if (aDiff === bDiff) {
          return r1 > r2 ? r1 : r2;
        } else {
          return bDiff < aDiff ? r2 : r1;
        }
      });
  }

  private chooseVideoRepresentation(
    representations: Array<IRepresentation>
  ): [IRepresentation, ISwitchInfo | null] {
    let switchInfo: ISwitchInfo | null = null;

    const currentBandwidth: number | null = this._abrManager.bandwidth;
    const chosenBandwidth: number | null = this._abrManager.chooseBandwidth();

    const chosenRepresentation: IRepresentation = this.chooseClosestRepresentation(
      representations,
      chosenBandwidth
    );

    let direction: ESwitchDirection | null = null;
    if (chosenBandwidth !== currentBandwidth) {
      direction = ESwitchDirection.DOWN;
      if (currentBandwidth === null || chosenBandwidth > currentBandwidth) {
        direction = ESwitchDirection.UP;
      }
    }

    if (direction) {
      switchInfo = {direction, bandwidth: chosenBandwidth};
    }

    return [chosenRepresentation, switchInfo];
  }

  private emitAvailableRepresentations(period: IPeriod): void {
    this.emitAvailableRepresentation(period, EContentType.VIDEO);
    this.emitAvailableRepresentation(period, EContentType.AUDIO);
    this.emitAvailableRepresentation(period, EContentType.TEXT);
    this.emitAvailableRepresentation(period, EContentType.IMAGE);
  }

  private emitAvailableRepresentation(period: IPeriod, contentType: EContentType): void {
    const representations: Array<IRepresentation> = [];
    const adaptations: Array<IAdaptationSet> = period[contentType].filter(isAdaptationPlayable);
    representations.push(...this.getRepresentations(adaptations));
    if (contentType === EContentType.TEXT) {
      const videoAdaptation: IAdaptationSet | undefined =
        period[EContentType.VIDEO].find(isAdaptationPlayable);
      if (videoAdaptation) {
        representations.push(...this.getRepresentations(getCeaAdaptations(videoAdaptation)));
      }
    }

    this._logger.info(`Available ${contentType} representations`, representations);
    this._onAvailableRepresentations({contentType, representations});

    this._dispatcher.emit({
      name: EEvent.AVAILABLE_REPRESENTATIONS,
      contentType,
      representations
    });
  }

  private emitInitRepresentations(period: IPeriod): void {
    const [videoRepresentation /*, switchInfo */] = this.getRepresentation(period, EContentType.VIDEO);
    this._selectedVideoTrack = videoRepresentation ? 'auto' : null;

    const [audioRepresentation /*, switchInfo */] = this.getRepresentation(period, EContentType.AUDIO);
    this._selectedAudioTrack = audioRepresentation?.id ?? null;

    const [textRepresentation /*, switchInfo */] = this.getRepresentation(period, EContentType.TEXT);
    this._selectedTextTrack = textRepresentation?.id ?? 'off';

    const [imageRepresentation /*, switchInfo */] = this.getRepresentation(period, EContentType.IMAGE);
    this._selectedImageTrack = imageRepresentation?.id ?? 'off';

    if (this._manifestRef) {
      this.forceBuildSegments(this._manifestRef);
      this._presentationManager.updateMinMaxKnownTime(this._manifestRef);
    }

    this.emitRepresentationChange(videoRepresentation, EContentType.VIDEO);
    this.emitRepresentationChange(audioRepresentation, EContentType.AUDIO);
    this.emitRepresentationChange(textRepresentation, EContentType.TEXT);
    this.emitRepresentationChange(imageRepresentation, EContentType.IMAGE);
  }

  private emitRepresentationChange(
    representation: IRepresentation | null,
    contentType: EContentType,
    switchInfo: ISwitchInfo | null = null
  ): void {
    this._logger.debug(`${contentType} representation changed to:`, representation);
    this._onRepresentationChange({contentType, representation, ...(switchInfo && {switchInfo})});
    this._manifestDownloader.onRepresentationChange({contentType, representation});

    this._dispatcher.emit({
      name: EEvent.REPRESENTATION_CHANGE,
      contentType,
      representation
    });
  }

  private updateOutbandStreamEvent(periods: Array<IPeriod>): void {
    for (let i: number = 0; i < periods.length; i++) {
      const period: IPeriod = periods[i];
      if (period.eventStream) {
        this.emitOutbandEventStream(period.eventStream);
      }
    }
  }

  private emitOutbandEventStream(eventStream: IOutbandEventStream): void {
    for (let i: number = 0; i < eventStream.events.length; i++) {
      const outbandEvent: IOutbandEvent = eventStream.events[i];
      this._dispatcher.emit({
        name: EEvent.OUTBAND_STREAM,
        schemeIdUri: eventStream.schemeIdUri,
        ...outbandEvent
      });
    }
  }

  private calculateAverageSegmentDuration(representationRef?: IRepresentation): void {
    if (!representationRef) return;

    const segmentsLength: number = representationRef.segments.length;
    if (segmentsLength <= 0) return;

    let totalSegmentDuration: number = 0;

    for (let i: number = 0; i < segmentsLength; i++) {
      totalSegmentDuration += representationRef.segments[i].duration;
    }

    const averageSegmentDuration: number = totalSegmentDuration / segmentsLength;

    this._averageSegmentDuration = averageSegmentDuration;
    this._abrManager.onAverageSegmentDuration(averageSegmentDuration);
    this._manifestDownloader.onAverageSegmentDuration(averageSegmentDuration);
    this._presentationManager.onAverageSegmentDuration(averageSegmentDuration);
  }

  private forceBuildSegments(manifestRef: Readonly<IManifest>): void {
    const periods: Array<IPeriod> = [];
    periods.push(manifestRef.periods[0]);
    if (manifestRef.periods.length > 1) {
      periods.push(manifestRef.periods[manifestRef.periods.length - 1]);
    }
    const videoRepresentations: Array<IRepresentation> = this.forceBuildSegmentsForType(
      periods,
      EContentType.VIDEO
    );
    this.forceBuildSegmentsForType(periods, EContentType.AUDIO);
    this.forceBuildSegmentsForType(periods, EContentType.TEXT);

    this.calculateAverageSegmentDuration(videoRepresentations[0]);
  }

  private forceBuildSegmentsForType(
    periods: Array<IPeriod>,
    contentType: EContentType
  ): Array<IRepresentation> {
    const representations: Array<IRepresentation> = [];
    for (let i: number = 0, perLen: number = periods.length; i < perLen; i++) {
      const offset: number = this.getPeriodPresentationTimeOffset(periods[i].id);
      const [rep] = this.getRepresentation(periods[i], contentType);
      if (rep) {
        rep.buildSegments?.(offset);
        representations.push(rep);
      }
    }

    return representations;
  }

  /**
   * Select audio representation by preference
   * preferences have different weight: lang -> role -> label
   * @param representations
   * @returns
   */
  private getAudioRepresentationByPreferences(
    representations: Array<IRepresentation>
  ): IRepresentation | undefined {
    let representation: IRepresentation | undefined;
    let highestScore: number = 0;

    for (let i: number = 0; i < representations.length; i++) {
      let score: number = 0;
      const r: IRepresentation = representations[i];
      if (this._configManager.playback.preferredAudioLanguage) {
        if (r.adaptation.lang === this._configManager.playback.preferredAudioLanguage) {
          score += 20;
        }
      }
      if (this._configManager.playback.preferredAudioCodecs) {
        if (r.codecs === this._configManager.playback.preferredAudioCodecs) {
          score += 7;
        }
      }
      if (this._configManager.playback.preferredAudioRole) {
        const roles: Array<string> = r.adaptation.roles.map((r: IRole) => r.value);
        if (roles.includes(this._configManager.playback.preferredAudioRole!)) {
          score += 5;
        }
      }
      if (this._configManager.playback.preferredAudioLabel) {
        if (r.adaptation.label === this._configManager.playback.preferredAudioLabel) {
          score += 1;
        }
      }

      if (score > highestScore) {
        highestScore = score;
        representation = r;
      }
    }

    return representation;
  }

  private getAvailableBandwidths(period: IPeriod): Array<number> {
    const adaptations: Array<IAdaptationSet> | null = this.getAdaptationsSet(period, EContentType.VIDEO);
    if (adaptations.length <= 0) return [];
    const representations: Array<IRepresentation> = this.getRepresentations(adaptations);

    return representations
      .sort((r1: IRepresentation, r2: IRepresentation) => r1.bandwidth - r2.bandwidth)
      .map((r: IRepresentation) => r.bandwidth);
  }

  private getFirstEncryptedPeriodOrFallback(): IPeriod | null {
    if (!this._manifestRef) return null;

    let period: IPeriod | null = null;
    for (let i: number = 0; i < this._manifestRef.periods.length; i++) {
      if (
        this._manifestRef.periods[i][EContentType.VIDEO][0]?.contentProtections.length > 0 ||
        this._manifestRef.periods[i][EContentType.AUDIO][0]?.contentProtections.length > 0
      ) {
        period = this._manifestRef.periods[i];
        break;
      }
    }

    if (!period) {
      period = this._manifestRef.periods[0];
    }

    return period;
  }

  private getPeriod(time: number): IPeriod | null {
    if (!this._manifestRef) return null;

    for (let i: number = 0; i < this._manifestRef.periods.length; i++) {
      const period: IPeriod = this._manifestRef.periods[i];
      const periodStart: number = period.start;
      const periodEnd: number = period.start + period.duration;
      if ((isKindaEqual(periodStart, time) || periodStart < time) && time < periodEnd) {
        return period;
      }
    }

    return null;
  }

  private getAdaptationsSet(period: IPeriod, contentType: EContentType): Array<IAdaptationSet> {
    const adaptations: Array<IAdaptationSet> = period[contentType].filter(isAdaptationPlayable);

    if (contentType === EContentType.TEXT) {
      const videoAdaptation: IAdaptationSet | undefined =
        period[EContentType.VIDEO].find(isAdaptationPlayable);
      if (videoAdaptation) {
        // we create it, so we know that is already playable
        const ceaAdptations: Array<IAdaptationSet> = getCeaAdaptations(videoAdaptation);
        adaptations.push(...ceaAdptations);
      }
    }

    return adaptations;
  }

  private getRepresentation(
    period: IPeriod,
    contentType: EContentType,
    segmentFilters?: ISegmentFilters
  ): [IRepresentation | null, ISwitchInfo | null] {
    const adaptations: Array<IAdaptationSet> | null = this.getAdaptationsSet(period, contentType);
    if (adaptations.length <= 0) return [null, null];
    const representations: Array<IRepresentation> = this.getRepresentations(adaptations);

    switch (contentType) {
      case EContentType.VIDEO: {
        if (this._selectedVideoTrack === null) return [null, null];
        if (this._selectedVideoTrack === 'auto') {
          if (segmentFilters?.videoBandwidth !== undefined) {
            return [this.chooseClosestRepresentation(representations, segmentFilters.videoBandwidth), null];
          } else {
            return this.chooseVideoRepresentation(representations);
          }
        } else {
          return [this.chooseClosestRepresentation(representations, this._selectedVideoTrack), null];
        }
      }
      case EContentType.AUDIO: {
        let representation: IRepresentation | undefined;
        if (this._configManager.manifest.isMultiview && segmentFilters?.audioLabel) {
          representation = representations.find(
            (r: IRepresentation) => r.adaptation.label === segmentFilters.audioLabel
          );
        } else if (this._selectedAudioTrack === null) {
          representation = this.getAudioRepresentationByPreferences(representations);
        } else {
          representation = representations.find((r: IRepresentation) => r.id === this._selectedAudioTrack);
        }

        if (!representation) {
          representation = representations[0];
        }

        return [representation, null];
      }
      case EContentType.TEXT: {
        let representation: IRepresentation | undefined;
        if (this._selectedTextTrack === 'off') {
          return [null, null];
        } else if (this._selectedTextTrack === null) {
          if (this._configManager.playback.preferredTextLanguage) {
            representation = representations.find(
              (r: IRepresentation) => r.adaptation.lang === this._configManager.playback.preferredTextLanguage
            );
          }
        } else {
          representation = representations.find((r: IRepresentation) => r.id === this._selectedTextTrack);
        }

        return [representation ?? null, null];
      }
      case EContentType.IMAGE: {
        if (this._selectedImageTrack === 'off') return [null, null];

        const representation: IRepresentation | undefined = representations.find(
          (representation: IRepresentation) => representation.id === this._selectedImageTrack
        );

        return [representation ?? null, null];
      }
    }
  }

  private getRepresentations(adaptations: Array<IAdaptationSet>): Array<IRepresentation> {
    const representations: Array<IRepresentation> = [];
    adaptations.forEach((a: IAdaptationSet) => {
      representations.push(...a.representations);
    });

    return representations;
  }

  private getInitSegment(representation: IRepresentation): ISegment | null {
    const firstSegment: ISegment = representation.segments[0];
    if (firstSegment.id === 0) {
      return firstSegment;
    }

    return null;
  }

  private getMediaSegment(representation: IRepresentation, time: number): ISegment | null {
    let mediaSegmentIndex: number = 0;
    const firstSegment: ISegment = representation.segments[0];
    if (firstSegment.id === 0) {
      mediaSegmentIndex = 1;
    }

    const index: number = this.searchClosestSegmentIndex(representation.segments, time, mediaSegmentIndex);

    if (index < 0) {
      return null;
    }

    const targetSegment: ISegment = representation.segments[index];
    if (targetSegment.time < time) {
      return null;
    }

    return representation.segments[index];
  }

  private getNextPeriod(periodId: string): IPeriod | null {
    if (!this._manifestRef) return null;

    for (let x: number = 0; x < this._manifestRef.periods.length; x++) {
      if (this._manifestRef.periods[x].id === periodId) {
        return this._manifestRef.periods[x + 1] ?? null;
      }
    }

    return null;
  }

  private handleEmptyRepresentation(representation: IRepresentation): boolean {
    let isWaiting: boolean = false;
    if (representation.buildSegments) {
      this._logger.debug(
        `Build ${representation.adaptation.contentType} segments for representation ${representation.id} (${representation.adaptation.period.id}:${representation.bandwidth})`
      );
      const offset: number = this.getPeriodPresentationTimeOffset(representation.adaptation.period.id);
      representation.buildSegments(offset);

      const isAudioOrVideo: boolean = [EContentType.AUDIO, EContentType.VIDEO].includes(
        representation.adaptation.contentType
      );
      if (isAudioOrVideo && this._manifestRef) {
        this._presentationManager.updateMinMaxKnownTime(this._manifestRef);
      }
    } else if (!this.isWaitingManifestResponse(representation.id)) {
      this._logger.debug(
        `No segments found for ${representation.adaptation.contentType} representation: ${representation.id}, waiting for segments`
      );
      this._manifestDownloader.onRepresentationEmpty(representation);
      isWaiting = true;
    } else {
      this._logger.debug(
        `Still waiting ${representation.adaptation.contentType} representation: ${representation.id} from network`
      );
      isWaiting = true;
    }

    return isWaiting;
  }

  private isManifestPlayable = (period: IPeriod): boolean => {
    const hasVideoTrack: boolean = period[EContentType.VIDEO].filter(isAdaptationPlayable).length > 0;
    const hasAudioTrack: boolean = period[EContentType.AUDIO].filter(isAdaptationPlayable).length > 0;

    if (!hasVideoTrack || !hasAudioTrack) {
      const message: string = 'Video or Audio adaptations are not playable';
      this._logger.error(message);
      this._dispatcher.emit({
        name: EEvent.TAPE_ERROR,
        type: EErrorType.INTERNAL,
        code: EErrorCode.MANIFEST_NOT_PLAYABLE,
        severity: EErrorSeverity.FATAL,
        message
      });

      return false;
    }

    return true;
  };

  private setContentProtections = (): void => {
    if (!this._manifestRef) return;

    for (let x: number = 0; x < this._manifestRef.periods.length; x++) {
      const period: IPeriod = this._manifestRef.periods[x];
      [EContentType.VIDEO, EContentType.AUDIO].forEach((key: string) => {
        for (let y: number = 0; y < period[key as EContentType.VIDEO].length; y++) {
          const adaptation: IAdaptationSet = period[key as EContentType.VIDEO][y];
          for (let z: number = 0; z < adaptation.contentProtections.length; z++) {
            const contentProtection: IContentProtection = adaptation.contentProtections[z];
            const {keyId, keySystem, schemeIdUri} = contentProtection;
            const exists: boolean = Boolean(
              this._contentProtections.find((cp: IContentProtection) => {
                return cp.keyId === keyId && cp.keySystem === keySystem && cp.schemeIdUri === schemeIdUri;
              })
            );
            if (!exists) {
              this._contentProtections.push(contentProtection);
            }
          }
        }
      });
    }
  };

  private searchClosestSegmentIndex(segments: Array<ISegment>, time: number, startIndex: number): number {
    return binarySearchByTime<ISegment>(
      segments,
      time,
      (segment: ISegment) => segment.time - segment.duration,
      startIndex
    );
  }

  private selectTrack(
    id: number | 'auto' | Omit<string, 'off'> | 'off' | null,
    contentType: EContentType
  ): void {
    this._logger.debug(`Select ${contentType} track: ${id}`);
    const period: IPeriod | null = this.getFirstEncryptedPeriodOrFallback();
    if (!period) {
      this._logger.warn(`Can't select the ${contentType} track. Period not found`);

      return;
    }
    const [representation /*, switchInfo */] = this.getRepresentation(period, contentType);

    this.emitRepresentationChange(representation, contentType);
  }

  private updateThumbnails(): void {
    if (!this._manifestRef) return;

    if (this._selectedImageTrack === 'off') {
      this._thumbnailManager.reset();
    } else {
      const representations: Array<IRepresentation> = [];
      for (let i: number = 0; i < this._manifestRef.periods.length; i++) {
        const period: IPeriod = this._manifestRef.periods[i];
        const [representation /*, switchInfo */] = this.getRepresentation(period, EContentType.IMAGE);
        if (representation) {
          if (representation.segments.length <= 0) {
            this.handleEmptyRepresentation(representation);
          }
          representations.push(representation);
        }
      }
      this._thumbnailManager.emitThumbnails(representations);
    }
  }

  private isWaitingManifestResponse(representationId: string): boolean {
    let isPending: boolean = false;
    this._networkManager.pendingRequests.forEach((networkRequest: INetworkRequest) => {
      if (
        networkRequest.request.type === ERequestType.MANIFEST &&
        networkRequest.request.ref === representationId
      ) {
        isPending = true;

        return;
      }
    });

    return isPending;
  }

  private onSetManifest = (manifest: IManifest): void => {
    if (this._mediaSource?.readyState === 'open') {
      this.setManifest(manifest);
    } else {
      const setManifestCallback: () => void = () => {
        if (this._mediaSource) {
          this._mediaSource.removeEventListener('sourceopen', setManifestCallback);
          this.setManifest(manifest);
        }
      };
      this._mediaSource.addEventListener('sourceopen', setManifestCallback);
    }
  };

  private setManifest = (manifest: IManifest): void => {
    if (!this._manifestRef) {
      this._manifestRef = {...manifest};

      const period: IPeriod | null = this.getFirstEncryptedPeriodOrFallback();
      if (!period || !this.isManifestPlayable(period)) {
        this._logger.warn(`Can't find a playable period`);

        return;
      }

      this.setContentProtections();
      this.dedupAudioAdaptations();

      const bandwidths: Array<number> = this.getAvailableBandwidths(period);
      this._abrManager.init(bandwidths, this.isLive());

      this._logger.debug('Manifest set');
      this._dispatcher.emit({
        name: EEvent.MANIFEST_READY
      });
      this._onManifestUpdate(true);

      this.emitAvailableRepresentations(period);
      this.emitInitRepresentations(period);
      this.updateThumbnails();
      this.updateOutbandStreamEvent(this._manifestRef.periods);
    } else {
      const {mediaPresentationDuration, publishTime, type} = manifest;
      // update manifest
      // we don't want update timeShiftBufferDepth
      this._manifestRef.type = type;
      this._manifestRef.publishTime = publishTime;
      this._manifestRef.mediaPresentationDuration = mediaPresentationDuration;

      // update periods (in reverse order)
      const newPeriods: Array<IPeriod> = [];
      for (
        let oP: number = this._manifestRef.periods.length - 1, nP: number = manifest.periods.length - 1;
        oP >= 0 && nP >= 0;
        nP--
      ) {
        if (this._manifestRef.periods[oP].id !== manifest.periods[nP].id) {
          // this is necessary to avoid memory leak
          manifest.periods[nP].manifest = this._manifestRef;
          this.dedupAudioAdaptationsForPeriod(manifest.periods[nP]);
          newPeriods.push(manifest.periods[nP]);
        } else {
          const startSeekableRange: number = this._presentationManager.getSeekableRange().start;
          this._manifestRef.periods[oP].duration = manifest.periods[nP].duration;
          this._manifestRef.periods[oP].eventStream = manifest.periods[nP].eventStream;
          [EContentType.VIDEO, EContentType.AUDIO, EContentType.TEXT, EContentType.IMAGE].forEach(
            (contentType: string) => {
              if (!this._manifestRef) return;
              // update adaptations
              for (
                let a: number = 0;
                a < this._manifestRef.periods[oP][contentType as EContentType.VIDEO].length;
                a++
              ) {
                this._manifestRef.periods[oP][contentType as EContentType.VIDEO][a].inbandEventStreams =
                  manifest.periods[nP][contentType as EContentType.VIDEO][a].inbandEventStreams;
                // update representations
                for (
                  let r: number = 0;
                  r <
                  this._manifestRef.periods[oP][contentType as EContentType.VIDEO][a].representations.length;
                  r++
                ) {
                  // update segments
                  const newSegments: Array<ISegment> = [];
                  const oRepresentationRef: IRepresentation =
                    this._manifestRef.periods[oP][contentType as EContentType.VIDEO][a].representations[r];
                  const nRepresentationRef: IRepresentation =
                    manifest.periods[nP][contentType as EContentType.VIDEO][a].representations[r];

                  const offset: number = this.getPeriodPresentationTimeOffset(
                    this._manifestRef.periods[oP].id
                  );
                  oRepresentationRef.buildSegments?.(offset);
                  nRepresentationRef.buildSegments?.(offset);

                  for (
                    let oS: number = oRepresentationRef.segments.length - 1,
                      nS: number = nRepresentationRef.segments.length - 1;
                    nS >= 0;
                    nS--
                  ) {
                    if (
                      oRepresentationRef.segments[oS].time - oRepresentationRef.segments[oS].duration <
                      nRepresentationRef.segments[nS].time - nRepresentationRef.segments[nS].duration
                    ) {
                      newSegments.push(nRepresentationRef.segments[nS]);
                    } else {
                      break;
                    }
                  }

                  const startIndex: number = 1;
                  let count: number = 0;
                  for (let oS: number = startIndex; oS < oRepresentationRef.segments.length; oS++) {
                    if (
                      oRepresentationRef.segments[oS].time - oRepresentationRef.segments[oS].duration <
                      startSeekableRange
                    ) {
                      count++;
                    } else {
                      break;
                    }
                  }
                  if (count) {
                    this._logger.debug(
                      `Removing ${count} ${contentType} segments (${oRepresentationRef.bandwidth})`
                    );
                    this._manifestRef.periods[oP][contentType as EContentType.VIDEO][a].representations[
                      r
                    ].segments.splice(startIndex, count);
                  }

                  for (let nS: number = newSegments.length - 1; nS >= 0; nS--) {
                    newSegments[nS].id =
                      oRepresentationRef.segments[oRepresentationRef.segments.length - 1].id + 1;

                    this._logger.debug(
                      `Adding new ${contentType} segment: ${newSegments[nS].id} (${newSegments[nS].representation.bandwidth}) to period ${this._manifestRef.periods[oP].id}`
                    );
                    const representation: IRepresentation =
                      this._manifestRef.periods[oP][contentType as EContentType.VIDEO][a].representations[r];
                    // this is necessary to avoid memory leak
                    newSegments[nS].representation = representation;
                    this._manifestRef.periods[oP][contentType as EContentType.VIDEO][a].representations[
                      r
                    ].segments.push(newSegments[nS]);
                  }

                  const periodEnd: number =
                    this._manifestRef.periods[oP].start + this._manifestRef.periods[oP].duration;
                  const lastSegment: ISegment =
                    oRepresentationRef.segments[oRepresentationRef.segments.length - 1];
                  if (periodEnd !== Infinity && lastSegment && lastSegment.time !== periodEnd) {
                    this._logger.debug(
                      `Adjusting ${oRepresentationRef.adaptation.contentType} segment ${lastSegment.id} (${oRepresentationRef.adaptation.period.id}:${oRepresentationRef.bandwidth}) duration from ${lastSegment.time} to ${periodEnd}`
                    );
                    const start: number = lastSegment.time - lastSegment.duration;
                    lastSegment.time = periodEnd;
                    lastSegment.duration = periodEnd - start;
                  }
                }
              }
            }
          );

          oP--;
        }
      }

      this.addNewPeriods(newPeriods);
      this.checkPlayableStatusBasedOnProtectionKeyStatus(newPeriods);
      this._presentationManager.updateMinMaxKnownTime(this._manifestRef);
      this.updateThumbnails();
      this.updateOutbandStreamEvent(newPeriods);
      this.removeOldPeriods();

      this._logger.debug('Manifest updated');
      this._onManifestUpdate(false);
    }
  };

  private adjustStartingPosition(
    targetTime: number,
    mediaSegment: ISegment,
    contentType: EContentType
  ): number {
    if (
      this._audioVideoStartingPositionMap.get(contentType) === undefined &&
      [EContentType.VIDEO, EContentType.AUDIO].includes(contentType) &&
      !this.isLive()
    ) {
      const targetSegmentStartTime: number = mediaSegment.time - mediaSegment.duration;
      this._audioVideoStartingPositionMap.set(
        contentType,
        Math.max(targetSegmentStartTime, this._presentationManager.getSeekableRange().start)
      );

      if (this._audioVideoStartingPositionMap.size === 2) {
        this._adjustedStartingPosition = Math.max(
          this._audioVideoStartingPositionMap.get(EContentType.AUDIO)!,
          this._audioVideoStartingPositionMap.get(EContentType.VIDEO)!
        );
        this._logger.debug(
          `Adjusting starting position to start of segment time (max value from video/audio): ${this._adjustedStartingPosition}`
        );
        this._onStartingPositionAdjusted(this._adjustedStartingPosition);
      }

      return this._audioVideoStartingPositionMap.get(contentType) ?? targetTime;
    }

    return targetTime;
  }

  private checkPlayableStatusBasedOnProtectionKeyStatus(periods: Array<IPeriod> | undefined): void {
    if (!periods?.length || this._contentProtectionKeyStatusesMap.size === 0) return;
    let hasDetectedAnyUnplayableAdaptation: boolean = false;
    for (let pIndex: number = periods.length - 1; pIndex >= 0; pIndex--) {
      [EContentType.VIDEO, EContentType.AUDIO].forEach((contentType: string) => {
        for (
          let aIndex: number = 0;
          aIndex < periods[pIndex][contentType as EContentType.VIDEO].length;
          aIndex++
        ) {
          const adpSet: IAdaptationSet = periods[pIndex][contentType as EContentType.VIDEO][aIndex];
          const isPlayable: boolean = this.detectMediaKeysAdaptationPlayability(adpSet);
          if (!isPlayable) hasDetectedAnyUnplayableAdaptation = true;
        }
      });
    }

    if (hasDetectedAnyUnplayableAdaptation) {
      const period: IPeriod | null = this.getFirstEncryptedPeriodOrFallback();
      if (period) {
        this.isManifestPlayable(period);
        this.emitAvailableRepresentations(period);
      }
    }
  }

  private dedupAudioAdaptations(): void {
    if (!this._manifestRef) return;

    for (let i: number = 0; i < this._manifestRef.periods.length; i++) {
      this.dedupAudioAdaptationsForPeriod(this._manifestRef.periods[i]);
    }
  }

  private dedupAudioAdaptationsForPeriod(period: IPeriod): void {
    if (this._configManager.manifest.isMultiview || !this._audioDedupFilter) {
      return;
    }

    const getMapKey = (a: IAdaptationSet): string => {
      const role: string = a.roles[0]?.value || 'no-role';

      return `${a.lang}:${role}`;
    };

    const bestAdaptationPerLanguageMap: Map<string, IAdaptationSet> = period.audio
      .filter(isAdaptationPlayable)
      .reduce((bestAdaptationPerLanguage: Map<string, IAdaptationSet>, adaptation: IAdaptationSet) => {
        const key: string = getMapKey(adaptation);
        const previousMatchAdaptation: IAdaptationSet | undefined = bestAdaptationPerLanguage.get(key);

        if (!previousMatchAdaptation) {
          bestAdaptationPerLanguage.set(key, adaptation);
        } else if (
          this._audioDedupFilter?.(adaptation.representations[0], previousMatchAdaptation.representations[0])
        ) {
          this._logger.debug(
            `Found a best candidate for: "${key}":`,
            adaptation.representations[0],
            'previous candidate was:',
            previousMatchAdaptation.representations[0]
          );
          bestAdaptationPerLanguage.set(key, adaptation);
        }

        return bestAdaptationPerLanguage;
      }, new Map<string, IAdaptationSet>());

    for (let i: number = 0; i < period.audio.length; i++) {
      const key: string = getMapKey(period.audio[i]);
      if (period.audio[i].id !== bestAdaptationPerLanguageMap.get(key)?.id) {
        this._logger.debug(`Adaptation "${period.audio[i].id}" is not playable due to audio dedup logic`);
        period.audio[i].playable.set(EPlayable.DEDUP, false);
      }
    }
  }

  private detectMediaKeysAdaptationPlayability(a: IAdaptationSet): boolean {
    const contentProtection: IContentProtection | undefined = a.contentProtections.filter(
      (cp: IContentProtection) => cp.keySystem === this._configManager.eme.keySystem
    )[0];

    if (!contentProtection) return true;

    let isAdaptationPlayable: boolean = true;
    const contentProtectionKeyStatus: string | undefined = this._contentProtectionKeyStatusesMap.get(
      contentProtection.keyId
    );
    if (contentProtectionKeyStatus) {
      const isAdaptationRestricted: boolean = RESTRICTED_EME_STATUSES.includes(
        contentProtectionKeyStatus as EMediaKeyStatus
      );
      if (isAdaptationRestricted) {
        this._logger.debug(
          `Adaptation "${a.id}" is not playable due to media key status ${contentProtectionKeyStatus}`
        );
        a.playable.set(EPlayable.MEDIA_KEYS, false);
        isAdaptationPlayable = false;
      } else {
        if (a.playable.get(EPlayable.MEDIA_KEYS) === false) {
          this._logger.debug(
            `Adaptation "${a.id}" is now playable again due to media key status ${contentProtectionKeyStatus}`
          );
          a.playable.set(EPlayable.MEDIA_KEYS, true);
          isAdaptationPlayable = true;
        }
      }
    } else {
      this._logger.debug(
        `Adaptation "${a.id}" is not playable due unrecognizable content protection keyId ${contentProtection.keyId}`
      );
      a.playable.set(EPlayable.MEDIA_KEYS, false);
      isAdaptationPlayable = false;
    }

    return isAdaptationPlayable;
  }

  /**
   * this function can return media segments (plus the init segment if needed) for the requested time
   * or it can return a switch info object in case an abr switch occurs
   *
   * @param {number | null} time
   * @param {ISegment | null} downloadingSegmentRef reference to the previous segment in download
   * @param {ISegment | null} bufferSegmentRef reference to the previous segment in the buffer
   * @param {string} contentType video, audio or text
   * @param {ISegmentFilters?} segmentFilters  can be used to select the right representation using additional filters
   * @param {boolean?} needPrevious if true, it means that I'm looking for the previous segment instead of the next segment
   * @return {Array<ISegment> | ISwitchInfo}
   */
  private getSegments(
    time: number | null,
    downloadingSegmentRef: ISegment | null,
    bufferSegmentRef: ISegment | null,
    contentType: Exclude<EContentType, EContentType.IMAGE>,
    segmentFilters?: ISegmentFilters,
    needPrevious: boolean = false
  ): IGetSegments | null {
    if (downloadingSegmentRef !== null) {
      if (this.isLastSegment(downloadingSegmentRef)) {
        return null;
      }
    }

    let targetTime: number | null = null;
    if (time !== null) {
      targetTime = time;
    } else if (downloadingSegmentRef) {
      targetTime = downloadingSegmentRef.time;
    } else {
      targetTime = this._videoElement.currentTime;
    }

    const segmentsToDownload: Array<ISegment> = [];
    const period: IPeriod | null = this.getPeriod(targetTime);
    if (!period) return null;
    const [representation, switchInfo] = this.getRepresentation(period, contentType, segmentFilters);
    if (!representation) return null;

    if (representation.segments.length <= 0) {
      const isWaiting: boolean = this.handleEmptyRepresentation(representation);
      if (isWaiting) return null;
    }
    if (representation.segments.length <= 0) return null;

    if (switchInfo) {
      this._logger.debug(`${contentType} switch info:`, switchInfo);
      this.emitRepresentationChange(representation, contentType, switchInfo);
    }

    this._logger.debug(
      `Getting ${needPrevious ? 'prev' : 'next'} ${contentType} segments for time: ${targetTime}`
    );
    const segmentRef: ISegment | null = downloadingSegmentRef ?? bufferSegmentRef;
    const needInitSegment: boolean =
      !segmentRef ||
      segmentRef.representation.adaptation.period.id !== representation.adaptation.period.id ||
      segmentRef.representation.id !== representation.id ||
      segmentRef.representation.bandwidth !== representation.bandwidth ||
      segmentRef.representation.codecs !== representation.codecs;

    if (needInitSegment) {
      const initSegment: ISegment | null = this.getInitSegment(representation);
      if (initSegment) {
        segmentsToDownload.push(initSegment);
      }
    }

    const mediaSegment: ISegment | null = this.getMediaSegment(representation, targetTime);
    if (!mediaSegment) return null;

    targetTime = this.adjustStartingPosition(targetTime, mediaSegment, contentType);

    segmentsToDownload.push(mediaSegment);
    if (!needPrevious) {
      const delta: number = mediaSegment.time - targetTime;
      const minPlayableTime: number =
        mediaSegment.duration * this._configManager.downloader.playableTimeFactor;
      if (delta !== 0 && delta < minPlayableTime) {
        const additionalMediaSegment: ISegment | null = this.getMediaSegment(
          representation,
          mediaSegment.time
        );
        if (additionalMediaSegment && additionalMediaSegment.id !== mediaSegment?.id) {
          this._logger.log(`Getting an additional ${contentType} segments for time: ${mediaSegment.time}`);
          segmentsToDownload.push(additionalMediaSegment);
        }
      }
    }

    return {segments: segmentsToDownload, switchInfo};
  }

  public onKeyStatusesChanged(keyStatusesMap: Map<string, string>): void {
    this._contentProtectionKeyStatusesMap = keyStatusesMap;
    this.checkPlayableStatusBasedOnProtectionKeyStatus(this._manifestRef?.periods);
  }

  public getNextEncryptedInitSegment(segment: ISegment): ISegment | null {
    const {
      representation: {
        adaptation: {
          period: {id},
          contentType
        }
      }
    } = segment;

    let representation: IRepresentation | null = null;
    let periodId: string = id;
    while (representation === null) {
      const nextPeriod: IPeriod | null = this.getNextPeriod(periodId);
      if (!nextPeriod || !nextPeriod[contentType]) break;
      if (nextPeriod[contentType][0].contentProtections.length <= 0) {
        periodId = nextPeriod.id;
        continue;
      }
      representation = this.getRepresentation(nextPeriod, contentType)[0];
    }

    if (!representation) return null;

    return representation.segments[0];
  }

  public getPeriodPresentationTimeOffset(periodId: string): number {
    const presentationTimeOffset: number | undefined = this._periodPresentationTimeOffsetMap.get(periodId);
    if (presentationTimeOffset === undefined) {
      // we need to calculate it
      // we should pick the lowest value between Audio and Video here, the greatest value is causing a small delta between the presentation time and the base media decode time
      const period: IPeriod | undefined = this._manifestRef?.periods.find((p: IPeriod) => p.id === periodId);
      if (!period) return 0;
      const audioOffset: number =
        period.audio.find(isAdaptationPlayable)?.representations[0]?.presentationTimeOffset ?? 0;
      const videoOffset: number =
        period.video.find(isAdaptationPlayable)?.representations[0]?.presentationTimeOffset ?? 0;
      const offset: number = Math.min(audioOffset, videoOffset);
      this._periodPresentationTimeOffsetMap.set(periodId, offset);

      return offset;
    } else {
      return presentationTimeOffset;
    }
  }

  public getPrevSegments(
    time: number,
    downloadingSegmentRef: ISegment | null,
    bufferSegmentRef: ISegment | null,
    contentType: Exclude<EContentType, EContentType.IMAGE>,
    segmentFilters?: ISegmentFilters
  ): IGetSegments | null {
    const targetTime: number = time - 0.1; // we need the previous segment

    return this.getSegments(
      targetTime,
      downloadingSegmentRef,
      bufferSegmentRef,
      contentType,
      segmentFilters,
      true
    );
  }

  public getNextSegments(
    time: number | null,
    downloadingSegmentRef: ISegment | null,
    bufferSegmentRef: ISegment | null,
    contentType: Exclude<EContentType, EContentType.IMAGE>,
    segmentFilters?: ISegmentFilters
  ): IGetSegments | null {
    return this.getSegments(time, downloadingSegmentRef, bufferSegmentRef, contentType, segmentFilters);
  }

  public getStartingPosition = (): number => {
    if (!this._manifestRef) return 0;

    if (this._adjustedStartingPosition !== null) {
      return this._adjustedStartingPosition;
    }

    let position: number | null =
      this._videoElement.currentTime || this._configManager.playback.startingPosition;

    const {start, end} = this._presentationManager.getSeekableRange();
    if (position === null || position < start || position > end) {
      position = this._presentationManager.getDefaultStartingPosition(this._manifestRef);
    }

    return position;
  };

  public isLastSegment(segment: ISegment): boolean {
    const periodId: string = segment.representation.adaptation.period.id;
    const isLastPeriod: boolean =
      periodId === this._manifestRef?.periods[this._manifestRef.periods.length - 1].id;

    const lastSegmentRef: ISegment =
      segment.representation.segments[segment.representation.segments.length - 1];
    const isLastSegmentWithinPeriod: boolean = segment.time === lastSegmentRef?.time;

    return isLastPeriod && isLastSegmentWithinPeriod;
  }

  public onTimeUpdate = (currentTime: number): void => {
    if (!this._manifestRef) return;

    this._presentationManager.onTimeUpdate(this._manifestRef, currentTime);
  };

  public shouldUseEME(): boolean {
    return this.contentProtections.length > 0;
  }

  public selectVideoTrack(bandwidth: number | 'auto'): void {
    if (this._selectedVideoTrack === bandwidth) return;

    if (this._selectedVideoTrack === 'auto') {
      this._selectedVideoTrack = bandwidth;
      if (this._abrManager.bandwidth === bandwidth) {
        return;
      }
    }

    this._selectedVideoTrack = bandwidth;
    this.selectTrack(bandwidth, EContentType.VIDEO);
  }

  public selectAudioTrack(id: string): void {
    if (this._selectedAudioTrack === id) return;
    this._selectedAudioTrack = id;
    this.selectTrack(id, EContentType.AUDIO);
  }

  public selectTextTrack(id: Omit<string, 'off'> | 'off'): void {
    if (this._selectedTextTrack === id) return;
    this._selectedTextTrack = id;
    this.selectTrack(id, EContentType.TEXT);
  }

  public selectImageTrack(id: Omit<string, 'off'> | 'off'): void {
    if (this._selectedImageTrack === id) return;
    this._selectedImageTrack = id;
    this.updateThumbnails();
    this.selectTrack(id, EContentType.IMAGE);
  }

  public getLiveEdgeStatus(): ELiveEdgeStatus | null {
    return this._presentationManager.getLiveEdgeStatus();
  }

  public getSeekableRange(): ISeekableRange | null {
    if (!this._manifestRef) return null;

    return this._presentationManager.getSeekableRange();
  }

  public setProducerReferenceStartTime(producerReferenceStartTime: number): void {
    if (!this._manifestRef) {
      this._logger.warn('setProducerReferenceStartTime: unexpected undefined manifest');

      return;
    }

    this._manifestRef.producerReferenceStartTime = producerReferenceStartTime;
    this._presentationManager.setSeekableRange(this._manifestRef);
  }

  public init(): void {
    this._manifestDownloader.init();
  }

  public isLive(): boolean {
    if (!this._manifestRef) return false;

    return this._presentationManager.isLive(this._manifestRef);
  }

  public destroy(): void {
    this._logger.info('Destroying Manifest manager');

    this._manifestDownloader.destroy();
    this._probeCapabilities.destroy();
    this._presentationManager.destroy();
    this._thumbnailManager.destroy();

    this._contentProtections.length = 0;
    this._contentProtectionKeyStatusesMap.clear();
    this._periodPresentationTimeOffsetMap.clear();
    this._audioVideoStartingPositionMap.clear();

    Promise.resolve().then(() => (this._manifestRef = null));
  }
}

export default ManifestManager;
