import IAdaptationSet from '@parser/manifest/interfaces/IAdaptationSet';

const isAdaptationPlayable = (adaptation: IAdaptationSet): boolean => {
  let isAdaptationPlayable: boolean = true;
  for (const [, isPlayable] of adaptation.playable) {
    if (!isPlayable) {
      isAdaptationPlayable = false;
      break;
    }
  }

  return isAdaptationPlayable;
};

export default isAdaptationPlayable;
