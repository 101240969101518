enum ERequestType {
  LICENSE = 'LICENSE',
  CERTIFICATE = 'CERTIFICATE',
  MANIFEST = 'MANIFEST',
  AUDIO_SEGMENT = 'AUDIO_SEGMENT',
  VIDEO_SEGMENT = 'VIDEO_SEGMENT',
  TEXT_SEGMENT = 'TEXT_SEGMENT'
}

export default ERequestType;
