import ConfigManager from '@config/configManager';
import Dispatcher from '@dispatcher/dispatcher';
import EEvent from '@dispatcher/enum/EEvent';
import EErrorCode from '@error/enum/EErrorCode';
import EErrorSeverity from '@error/enum/EErrorSeverity';
import EErrorType from '@error/enum/EErrorType';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import IRepresentationChange from '@manifest/interfaces/IRepresentationChange';
import NetworkManager from '@network/networkManager';
import EProtocol from '@parser/manifest/enum/EProtocol';
import IManifest from '@parser/manifest/interfaces/IManifest';
import IRepresentation from '@parser/manifest/interfaces/IRepresentation';
import ProbeCapabilities from '@utils/capabilities/probe';
import getFileExtension from '@utils/getFileExtension';

import DashDownloader from './dash/downloader';
import HlsDownloader from './hls/downloader';
import IDownloader from './interfaces/IDownloader';

class ManifestDownloader {
  private _logger: ILogger;
  private _downloader: IDownloader | null = null;

  constructor(
    url: string,
    networkManager: NetworkManager,
    configManager: ConfigManager,
    probeCapabilities: ProbeCapabilities,
    loggerManager: LoggerManager,
    dispatcher: Dispatcher,
    onManifestDownloaded: (m: IManifest) => void
  ) {
    this._logger = loggerManager.registerLogger(ELogType.DOWNLOADER);
    const urlObj: URL = new URL(url);
    const protocol: EProtocol | null = this.getProtocol(url);
    switch (protocol) {
      case EProtocol.DASH:
        this._downloader = new DashDownloader(
          urlObj,
          networkManager,
          configManager,
          probeCapabilities,
          loggerManager,
          dispatcher,
          onManifestDownloaded
        );
        break;
      case EProtocol.HLS:
        this._downloader = new HlsDownloader(
          urlObj,
          networkManager,
          configManager,
          probeCapabilities,
          loggerManager,
          dispatcher,
          onManifestDownloaded
        );
        break;
      default: {
        let message: string = '';
        if (protocol === EProtocol.SMOOTH) {
          message = 'Smooth streaming is not supported';
        } else {
          message = 'Unknown protocol';
        }
        this._logger.error(message);
        dispatcher.emit({
          name: EEvent.TAPE_ERROR,
          type: EErrorType.INTERNAL,
          code: EErrorCode.PROTOCOL_NOT_SUPPORTED,
          severity: EErrorSeverity.FATAL,
          message
        });
      }
    }
  }

  private getProtocol(url: string): EProtocol | null {
    const urlObj: URL = new URL(url);
    const filename: string = urlObj.pathname.substring(urlObj.pathname.lastIndexOf('/') + 1);
    const fileExtension: string = getFileExtension(filename);
    switch (fileExtension) {
      case 'mpd':
        return EProtocol.DASH;
      case 'm3u8':
        return EProtocol.HLS;
      case 'ism':
        return EProtocol.SMOOTH;
    }

    return null;
  }

  public onAverageSegmentDuration(averageSegmentDuration: number): void {
    this._downloader?.onAverageSegmentDuration(averageSegmentDuration);
  }

  public onRepresentationEmpty(representation: IRepresentation): void {
    this._downloader?.onRepresentationEmpty(representation);
  }

  public onRepresentationChange(representationChange: IRepresentationChange): void {
    this._downloader?.onRepresentationChange(representationChange);
  }

  public init(): void {
    this._downloader?.init();
  }

  public destroy(): void {
    this._logger.info('Destroying Manifest downloader');

    this._downloader?.destroy();
    this._downloader = null;
  }
}

export default ManifestDownloader;
