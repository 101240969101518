enum ECmcdObjectType {
  Text = 'm',
  AudioOnly = 'a',
  VideoOnly = 'v',
  MuxedAudioVideo = 'av',
  InitSegment = 'i',
  CaptionOrSubtitle = 'c',
  TimedTextTrack = 'tt',
  // cryptographic material is key, license or certificate
  CryptographicMaterial = 'k',
  Other = 'o'
}

export default ECmcdObjectType;
