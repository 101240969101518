enum EAudioChannelSchemeUri {
  URN_MPEG = 'urn:mpeg:dash:outputChannelPositionList:2012',
  URN_MPEG_23003 = 'urn:mpeg:dash:23003:3:audio_channel_configuration:2011',
  URN_DTS = 'urn:dts:dash:audio_channel_configuration:2012',
  TAG_DOLBY = 'tag:dolby.com,2014:dash:audio_channel_configuration:2011',
  URN_DOLBY = 'urn:dolby:dash:audio_channel_configuration:2011',
  URN_MPEG_MPEGB = 'urn:mpeg:mpegB:cicp:ChannelConfiguration'
}

export default EAudioChannelSchemeUri;
