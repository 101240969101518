function binarySearchByTime<T>(
  targetArray: Array<T>,
  currentTime: number,
  getTime: (currentElement: T) => number,
  startIndexOverride?: number
): number {
  const startIndex: number = startIndexOverride ?? 0;
  let leftIndex: number = startIndex;
  let rightIndex: number = targetArray.length - 1;

  while (leftIndex <= rightIndex) {
    const midIndex: number = leftIndex + ((rightIndex - leftIndex) >> 1);
    const startTime: number = getTime(targetArray[midIndex]);
    const diff: number = startTime - currentTime;
    if (diff < 0) {
      leftIndex = midIndex + 1;
    } else if (diff > 0.001) {
      rightIndex = midIndex - 1;
    } else {
      return midIndex;
    }
  }

  if (rightIndex < startIndex) {
    return startIndex;
  }

  return rightIndex;
}

export default binarySearchByTime;
