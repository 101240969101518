enum EErrorCode {
  CDN_EXHAUSTED = 'CDN_EXHAUSTED',
  CERTIFICATE_ERROR = 'CERTIFICATE_ERROR',
  CERTIFICATE_REQUEST_FAILED = 'CERTIFICATE_REQUEST_FAILED',
  CONTENT_PROTECTION_NOT_FOUND = 'CONTENT_PROTECTION_NOT_FOUND',
  CLOSE_MEDIA_KEY_SESSION = 'CLOSE_MEDIA_KEY_SESSION',
  CREATE_MEDIA_KEYS = 'CREATE_MEDIA_KEYS',
  CREATE_MEDIA_KEY_SESSION = 'CREATE_MEDIA_KEY_SESSION',
  EME_NOT_SUPPORTED = 'EME_NOT_SUPPORTED',
  HTTP_ABORT = 'HTTP_ABORT',
  HTTP_LOAD = 'HTTP_LOAD',
  HTTP_NETWORK = 'HTTP_NETWORK',
  HTTP_RETRY = 'HTTP_RETRY',
  HTTP_TIMEOUT = 'HTTP_TIMEOUT',
  HTTP_UNKNOWN = 'HTTP_UNKNOWN',
  JS_OVERFLOW = 'JS_OVERFLOW',
  JUMP_OVER_GAP = 'JUMP_OVER_GAP',
  INITIALIZE_KEY_SESSION = 'INITIALIZE_KEY_SESSION',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  INVALID_KEY_MESSAGE = 'INVALID_KEY_MESSAGE',
  LICENSE_REQUEST_FAILED = 'LICENSE_REQUEST_FAILED',
  MANIFEST_NOT_PLAYABLE = 'MANIFEST_NOT_PLAYABLE',
  MEDIA_ERROR_ABORTED = 'MEDIA_ERROR_ABORTED',
  MEDIA_ERROR_DECODE = 'MEDIA_ERROR_DECODE',
  MEDIA_ERROR_NETWORK = 'MEDIA_ERROR_NETWORK',
  MEDIA_ERROR_SRC_NOT_SUPPORTED = 'MEDIA_ERROR_SRC_NOT_SUPPORTED',
  MEDIA_ERROR_UNKNOWN = 'MEDIA_ERROR_UNKNOWN',
  MEDIA_KEY_ERROR = 'MEDIA_KEY_ERROR',
  MEDIA_KEY_SESSION_UPDATE = 'MEDIA_KEY_SESSION_UPDATE',
  MEDIA_SOURCE_EXTENSIONS_NOT_SUPPORTED = 'MEDIA_SOURCE_EXTENSIONS_NOT_SUPPORTED',
  OUTSIDE_SEEKABLE_RANGE = 'OUTSIDE_SEEKABLE_RANGE',
  PARSER = 'PARSER',
  PROTOCOL_NOT_SUPPORTED = 'PROTOCOL_NOT_SUPPORTED',
  QUOTA_EXCEEDED_ERROR = 'QUOTA_EXCEEDED_ERROR',
  READER_OUT_OF_BOUNDS = 'READER_OUT_OF_BOUNDS',
  REMOVE_MEDIA_KEY_SESSION = 'REMOVE_MEDIA_KEY_SESSION',
  REQUEST_MEDIA_KEY_ACCESS = 'REQUEST_MEDIA_KEY_ACCESS',
  TEXT_TRACK_NOT_SUPPORTED = 'TEXT_TRACK_NOT_SUPPORTED'
}

export default EErrorCode;
