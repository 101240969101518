import ConfigManager from '@config/configManager';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import ManifestManager from '@manifest/manifestManager';
import NetworkManager from '@network/networkManager';
import EContentType from '@parser/manifest/enum/EContentType';
import IRepresentation from '@parser/manifest/interfaces/IRepresentation';
import ISegment from '@parser/manifest/interfaces/ISegment';

import IDataSegment from './interfaces/IDataSegment';
import MultiSegmentDownloader from './multi/downloader';
import SingleSegmentDownloader from './single/downloader';

class SegmentDownloaderManager {
  private _logger: ILogger;
  private _downloaders: Array<SingleSegmentDownloader | MultiSegmentDownloader> = [];

  constructor(
    private _videoElement: HTMLVideoElement,
    private _mediaSource: MediaSource,
    private _manifestManager: ManifestManager,
    private _networkManager: NetworkManager,
    private _configManager: ConfigManager,
    loggerManager: LoggerManager,
    private _contentType: Exclude<EContentType, EContentType.IMAGE>,
    private _onBatchReady: () => void,
    representations: Array<IRepresentation> = []
  ) {
    this._logger = loggerManager.registerLogger(ELogType.DOWNLOADER);
    if (this.isMultiview) {
      const languages: Set<string> = new Set();
      representations.forEach((r: IRepresentation) => {
        languages.add(r.adaptation.lang);

        if (!r.adaptation.label) {
          this._logger.warn(
            `adaptation label is missing for ${r.adaptation.contentType} representation: "${r.id}"`
          );
        }
        this._downloaders.push(
          new MultiSegmentDownloader(
            this._videoElement,
            this._mediaSource,
            this._manifestManager,
            this._networkManager,
            this._configManager,
            loggerManager,
            this._contentType,
            this._onBatchReady,
            r.adaptation.label
          )
        );
      });
      if (languages.size > 1) {
        // TODO: handle multiview with multiple language: not in scope for MVP
        this._logger.warn(`multiview with multiple language is not fully supported`);
      }
    } else {
      this._downloaders.push(
        new SingleSegmentDownloader(
          this._videoElement,
          this._mediaSource,
          this._manifestManager,
          this._networkManager,
          this._configManager,
          loggerManager,
          this._contentType,
          this._onBatchReady
        )
      );
    }
  }

  private getActiveDownloader(): MultiSegmentDownloader | SingleSegmentDownloader | undefined {
    if (this.isMultiview) {
      return (this._downloaders as Array<MultiSegmentDownloader>).filter(
        (sd: MultiSegmentDownloader) => sd.isActive
      )[0];
    } else {
      return this._downloaders[0];
    }
  }

  private get isMultiview(): boolean {
    return Boolean(this._configManager.manifest.isMultiview && this._contentType === EContentType.AUDIO);
  }

  public set lastSegmentInBuffer(lastSegmentAppendedToBuffer: ISegment) {
    this._downloaders.forEach((s: SingleSegmentDownloader | MultiSegmentDownloader) => {
      s.lastSegmentInBuffer = lastSegmentAppendedToBuffer;
    });
  }

  public set bufferEndPosition(position: number) {
    this._downloaders.forEach((s: SingleSegmentDownloader | MultiSegmentDownloader) => {
      s.bufferEndPosition = position;
    });
  }

  public init(time: number): void {
    this._downloaders.forEach((s: SingleSegmentDownloader | MultiSegmentDownloader) => s.init(time));
  }

  public getReadyDataSegment(): IDataSegment | null {
    return this.getActiveDownloader()?.getReadyDataSegment() ?? null;
  }

  public deleteReadyDataSegment(): void {
    this.getActiveDownloader()?.deleteReadyDataSegment();
  }

  public downloadSegments(time?: number): void {
    this._downloaders.forEach((s: SingleSegmentDownloader | MultiSegmentDownloader) =>
      s.downloadSegments(time)
    );
  }

  public onRepresentationChange(representation: IRepresentation): void {
    if (this.isMultiview) {
      (this._downloaders as Array<MultiSegmentDownloader>).forEach((s: MultiSegmentDownloader) => {
        s.updateStatus(representation.adaptation.label);
      });
    }
  }

  public onTimeUpdate(): void {
    if (this.isMultiview) {
      const {currentTime} = this._videoElement;
      (this._downloaders as Array<MultiSegmentDownloader>).forEach((s: MultiSegmentDownloader) =>
        s.onTimeUpdate(currentTime)
      );
    }
  }

  public reset(shouldDownload: boolean = true): void {
    this._downloaders.forEach((s: SingleSegmentDownloader | MultiSegmentDownloader) =>
      s.reset(shouldDownload)
    );
  }

  public destroy(): void {
    this._logger.info(`Destroying ${this._contentType} Segment Downloader Manager`);

    this._downloaders.forEach((s: SingleSegmentDownloader | MultiSegmentDownloader) => s.destroy());
  }
}

export default SegmentDownloaderManager;
