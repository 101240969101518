import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';

// https://www.mathworks.com/help/dsp/ug/sliding-window-method-and-exponential-weighting-method.html
class EWMA {
  private _logger: ILogger;
  private _alpha: number;
  private _lastEstimate: number = 0;
  private _totalWeight: number = 0;

  constructor(private _halfLife: number, loggerManager: LoggerManager) {
    this._logger = loggerManager.registerLogger(ELogType.ABR);
    this._alpha = Math.exp(Math.log(0.5) / this._halfLife);
  }

  public addSample(weight: number, value: number): void {
    const adjAlpha: number = Math.pow(this._alpha, weight);
    const newEstimate: number = value * (1 - adjAlpha) + adjAlpha * this._lastEstimate;
    if (!isNaN(newEstimate)) {
      this._lastEstimate = newEstimate;
      this._totalWeight += weight;
    }
  }

  public getEstimate(): number {
    const zeroFactor: number = 1 - Math.pow(this._alpha, this._totalWeight);

    return this._lastEstimate / zeroFactor;
  }

  public destroy(): void {
    this._logger.info(`Destroying EWMA: ${this._halfLife}`);
  }
}

export default EWMA;
