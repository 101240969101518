const intToUint8 = (value: number): Uint8Array => {
  const data: Uint8Array = new Uint8Array(4);
  data[0] = (value >> 24) & 0xff;
  data[1] = (value >> 16) & 0xff;
  data[2] = (value >> 8) & 0xff;
  data[3] = value & 0xff;

  return data;
};

export default intToUint8;
