import ConfigManager from '@config/configManager';
import Dispatcher from '@dispatcher/dispatcher';
import EEvent from '@dispatcher/enum/EEvent';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import EContentType from '@parser/manifest/enum/EContentType';
import IAdaptationSet from '@parser/manifest/interfaces/IAdaptationSet';
import IManifest from '@parser/manifest/interfaces/IManifest';
import IPeriod from '@parser/manifest/interfaces/IPeriod';
import IRepresentation from '@parser/manifest/interfaces/IRepresentation';
import ISegment from '@parser/manifest/interfaces/ISegment';
import isAdaptationPlayable from '@utils/isAdaptationPlayable';
import isLiveManifest from '@utils/isLiveManifest';

import ELiveEdgeStatus from './enum/ELiveEdgeStatus';
import ISeekableRange from './interfaces/ISeekableRange';

class PresentationManager {
  private _logger: ILogger;
  private _liveEdgeStatus: ELiveEdgeStatus | null = null;
  private _maxKnownTimeMap: Map<EContentType, number> = new Map();
  private _minKnownTimeMap: Map<EContentType, number> = new Map();
  private _lastSeekableRange: ISeekableRange = {end: 0, start: 0};
  private _averageSegmentDuration: number = 0;

  constructor(
    private _configManager: ConfigManager,
    loggerManager: LoggerManager,
    private _dispatcher: Dispatcher,
    private _onSeekableRangeChange: (r: ISeekableRange) => void
  ) {
    this._logger = loggerManager.registerLogger(ELogType.PRESENTATION);
  }

  private emitSeekableRange(seekableRange: ISeekableRange): void {
    this._logger.debug(`Seekable range: [${seekableRange.start}:${seekableRange.end}]`, seekableRange);
    this._onSeekableRangeChange(seekableRange);
    this._dispatcher.emit({
      name: EEvent.SEEKABLE_RANGE_CHANGE,
      seekableRange
    });
  }

  /**
   * Keeps reference of the max Audio and Video known time separately
   * @param manifest
   */
  private updateMaxKnownTime = (manifest: Readonly<IManifest>): void => {
    const lastPeriod: IPeriod = manifest.periods[manifest.periods.length - 1];
    [EContentType.VIDEO, EContentType.AUDIO, EContentType.TEXT].forEach((contentType: EContentType) => {
      const adaptations: Array<IAdaptationSet> = lastPeriod[contentType as EContentType.VIDEO];
      adaptations.filter(isAdaptationPlayable).forEach((a: IAdaptationSet) => {
        a.representations.forEach((r: IRepresentation) => {
          const lastSegment: ISegment | null = r.segments[r.segments.length - 1];
          if (lastSegment && this.getMaxKnownTime(contentType) < lastSegment.time) {
            this._logger.debug(`update ${contentType} MaxKnownTime`, lastSegment.time);
            this._maxKnownTimeMap.set(contentType, lastSegment.time);
          }
        });
      });
    });
  };

  /**
   * Keeps reference of the min Audio and Video known time separately
   * @param manifest
   */
  private updateMinKnownTime = (manifest: Readonly<IManifest>): void => {
    const firstPeriod: IPeriod = manifest.periods[0];
    [EContentType.VIDEO, EContentType.AUDIO, EContentType.TEXT].forEach((contentType: EContentType) => {
      const adaptations: Array<IAdaptationSet> = firstPeriod[contentType];
      adaptations.filter(isAdaptationPlayable).forEach((a: IAdaptationSet) => {
        a.representations.forEach((r: IRepresentation) => {
          const firstSegment: ISegment | null = r.segments[1];
          if (firstSegment) {
            const segmentStartTime: number = firstSegment.time - firstSegment.duration;
            if (this.getMinKnownTime(contentType) < segmentStartTime) {
              this._logger.debug(`update ${contentType} MinKnownTime`, segmentStartTime);
              this._minKnownTimeMap.set(contentType, segmentStartTime);
            }
          }
        });
      });
    });
  };

  public setSeekableRange(manifest: Readonly<IManifest>): void {
    const seekableRange: ISeekableRange = {
      start: 0,
      end: 0
    };

    const minKnownTime: number = Math.max(
      this.getMinKnownTime(EContentType.VIDEO),
      this.getMinKnownTime(EContentType.AUDIO)
    );
    const maxKnownTime: number = Math.min(
      this.getMaxKnownTime(EContentType.VIDEO),
      this.getMaxKnownTime(EContentType.AUDIO)
    );

    if (this.isLive(manifest)) {
      const availabilityStartTime: number = manifest.availabilityStartTime;
      const producerReferenceStartTime: number = manifest.producerReferenceStartTime;

      const end: number = maxKnownTime || manifest.publishTime - availabilityStartTime;

      let safeMargin: number = this._averageSegmentDuration * 2 + manifest.minimumUpdatePeriod;
      if (this._configManager.manifest.presentationDelay) {
        safeMargin = this._configManager.manifest.presentationDelay;
      } else {
        safeMargin = Math.max(manifest.suggestedPresentationDelay, safeMargin);
      }

      seekableRange.presentationDelay = safeMargin;
      seekableRange.start = Math.max(minKnownTime, end - manifest.timeShiftBufferDepth);
      if (seekableRange.start < 0) {
        seekableRange.start = 0;
      }
      seekableRange.end = end - safeMargin;
      seekableRange.availabilityStartTime = availabilityStartTime;
      seekableRange.producerReferenceStartTime = producerReferenceStartTime;
    } else {
      seekableRange.start = minKnownTime;
      seekableRange.end = maxKnownTime || manifest.mediaPresentationDuration;
    }

    if (seekableRange.end === 0) return;

    if (
      this._lastSeekableRange.start !== seekableRange.start ||
      this._lastSeekableRange.end !== seekableRange.end ||
      this._lastSeekableRange.availabilityStartTime !== seekableRange.availabilityStartTime ||
      this._lastSeekableRange.producerReferenceStartTime !== seekableRange.producerReferenceStartTime
    ) {
      this._lastSeekableRange = {...seekableRange};
      this.emitSeekableRange(this._lastSeekableRange);
    }
  }

  public getDefaultStartingPosition(manifest: Readonly<IManifest>): number {
    if (this.isLive(manifest)) {
      const seekableRange: ISeekableRange = this.getSeekableRange();

      return seekableRange.end;
    } else {
      return this.getSeekableRange().start;
    }
  }

  public getLiveEdgeStatus(): ELiveEdgeStatus | null {
    return this._liveEdgeStatus;
  }

  public getMaxKnownTime(contentType: EContentType): number {
    return this._maxKnownTimeMap.get(contentType) ?? 0;
  }

  public getMinKnownTime(contentType: EContentType): number {
    return this._minKnownTimeMap.get(contentType) ?? 0;
  }

  public getSeekableRange(): ISeekableRange {
    return this._lastSeekableRange;
  }

  public isLive(manifest: Readonly<IManifest>): boolean {
    return isLiveManifest(manifest);
  }

  public onTimeUpdate = (manifest: Readonly<IManifest>, currentTime: number): void => {
    if (this.isLive(manifest)) {
      const {start, end} = this.getSeekableRange();
      const awayPosition: number = end - (end - start) / 2;
      const liveEdgeFudge: number = this._configManager.manifest.liveEdgeFudge ?? 0;
      if (currentTime >= end - liveEdgeFudge) {
        this._liveEdgeStatus = ELiveEdgeStatus.AT;
      } else if (currentTime <= awayPosition) {
        this._liveEdgeStatus = ELiveEdgeStatus.AWAY;
      } else {
        this._liveEdgeStatus = ELiveEdgeStatus.CLOSE;
      }
    }
  };

  public onAverageSegmentDuration(averageSegmentDuration: number): void {
    this._averageSegmentDuration = averageSegmentDuration;
  }

  public updateMinMaxKnownTime = (manifest: Readonly<IManifest>): void => {
    this.updateMinKnownTime(manifest);
    this.updateMaxKnownTime(manifest);
    this.setSeekableRange(manifest);
  };

  public destroy(): void {
    this._liveEdgeStatus = null;
    this._maxKnownTimeMap.clear();
    this._minKnownTimeMap.clear();
    this._lastSeekableRange = {start: 0, end: 0};
  }
}

export default PresentationManager;
