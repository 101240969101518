import ConfigManager from '@config/configManager';
import Dispatcher from '@dispatcher/dispatcher';
import EEvent from '@dispatcher/enum/EEvent';
import EErrorCode from '@error/enum/EErrorCode';
import EErrorSeverity from '@error/enum/EErrorSeverity';
import EErrorType from '@error/enum/EErrorType';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import NetworkManager from '@network/networkManager';
import EKeySystem from '@parser/manifest/enum/EKeySystem';
import IContentProtection from '@parser/manifest/interfaces/IContentProtection';
import StateManager from '@state/stateManager';

import Fairplay from './fairplay/eme';
import IEme from './interfaces/IEme';
import EME_2012 from './legacy/eme_2012';
import Playready from './playready/eme';
import Widevine from './widevine/eme';

class EmeManager {
  private _logger: ILogger;
  private _eme: IEme | null = null;

  constructor(
    private _videoElement: HTMLVideoElement,
    private _networkManager: NetworkManager,
    private _stateManager: StateManager,
    private _configManager: ConfigManager,
    private _loggerManager: LoggerManager,
    private _dispatcher: Dispatcher,
    private _onEmeReady: () => void,
    private _onEmeKeyStatusesChanged: (keyStatuses: Map<string, string>) => void
  ) {
    this._logger = this._loggerManager.registerLogger(ELogType.EME);
    this._logger.info('Creating EME Manager');
  }

  private emitContentProtectionError(keySystem: EKeySystem | null): void {
    const message: string = `No content protection found for '${keySystem}' key system`;
    this._logger.error(message);

    this._dispatcher.emit({
      name: EEvent.TAPE_ERROR,
      type: EErrorType.INTERNAL,
      code: EErrorCode.CONTENT_PROTECTION_NOT_FOUND,
      severity: EErrorSeverity.FATAL,
      message
    });
  }

  public init(): void {
    if (!this._configManager.eme.keySystem) return;

    if (this._configManager.patch.useLegacyEme) {
      this._eme = new EME_2012(
        this._videoElement,
        this._networkManager,
        this._configManager,
        this._logger,
        this._dispatcher,
        this._onEmeReady
      );
    } else if ('requestMediaKeySystemAccess' in navigator) {
      switch (this._configManager.eme.keySystem) {
        case EKeySystem.FAIRPLAY:
          this._eme = new Fairplay(
            this._videoElement,
            this._networkManager,
            this._stateManager,
            this._configManager,
            this._logger,
            this._dispatcher,
            this._onEmeReady,
            this._onEmeKeyStatusesChanged
          );
          break;
        case EKeySystem.WIDEVINE:
          this._eme = new Widevine(
            this._videoElement,
            this._networkManager,
            this._stateManager,
            this._configManager,
            this._logger,
            this._dispatcher,
            this._onEmeReady,
            this._onEmeKeyStatusesChanged
          );
          break;
        case EKeySystem.PLAYREADY:
          this._eme = new Playready(
            this._videoElement,
            this._networkManager,
            this._stateManager,
            this._configManager,
            this._logger,
            this._dispatcher,
            this._onEmeReady,
            this._onEmeKeyStatusesChanged
          );
          break;
      }
    } else {
      const message: string = `Can't select any EME available implementation`;
      this._logger.error(message);

      this._dispatcher.emit({
        name: EEvent.TAPE_ERROR,
        type: EErrorType.INTERNAL,
        code: EErrorCode.EME_NOT_SUPPORTED,
        severity: EErrorSeverity.FATAL,
        message
      });
    }

    this._eme?.init();
  }

  public onManifestParsed(cps: Array<IContentProtection>): void {
    this._logger.info('Initializing EME with content protections', cps);

    const keySystem: EKeySystem | null = this._configManager.eme.keySystem;

    const contentProtections: Array<IContentProtection> = cps.filter(
      (cp: IContentProtection) => cp.keySystem === keySystem
    );

    if (contentProtections.length === 0) {
      this.emitContentProtectionError(keySystem);

      return;
    }

    this._eme?.onManifestParsed(contentProtections);
  }

  public onContentProtectionEncountered(cp: IContentProtection): void {
    this._logger.info('Initializing EME with content protection', cp);

    const keySystem: EKeySystem | null = this._configManager.eme.keySystem;
    if (cp.keySystem !== keySystem) {
      this.emitContentProtectionError(keySystem);

      return;
    }

    this._eme?.onContentProtectionEncountered?.(cp);
  }

  public destroy(): void {
    this._logger.info('Destroying EME manager');

    this._eme?.destroy();
    this._eme = null;
  }
}

export default EmeManager;
