import ECmcdIntegrationMethod from '@cmcd/enum/ECmcdIntegrationMethod';
import ELogLevel from '@logger/enum/ELogLevel';

import IAbrConfig from './interfaces/IAbrConfig';
import IBufferConfig from './interfaces/IBufferConfig';
import ICdnConfig from './interfaces/ICdnConfig';
import ICmcdConfig from './interfaces/ICmcdConfig';
import IDownlaoderConfig from './interfaces/IDownloaderConfig';
import IEmeConfig from './interfaces/IEmeConfig';
import ILoggerConfig from './interfaces/ILoggerConfig';
import IManifestConfig from './interfaces/IManifestConfig';
import INetworkConfig from './interfaces/INetworkConfig';
import IPatchConfig from './interfaces/IPatchConfig';
import IPlaybackConfig from './interfaces/IPlaybackConfig';
import ITapeConfig from './interfaces/ITapeConfig';

class ConfigManager {
  private _config: Required<ITapeConfig> = {
    abr: {
      maxBandwidth: Infinity,
      minBandwidth: 0,
      defaultBandwidthEstimate: 600e3,
      stepDownOnSeeking: false
    },
    buffer: {
      bufferAhead: 30,
      bufferBehind: 10
    },
    cdn: {
      enabled: true,
      cdns: []
    },
    cmcd: {
      enabled: true,
      method: ECmcdIntegrationMethod.QUERYSTRING,
      contentId: '',
      sessionId: ''
    },
    downloader: {
      maxBatchesLength: 4,
      maxBatchesSizeMb: 20,
      playableTimeFactor: 0.5
    },
    eme: {
      keySystem: null,
      videoRobustness: [],
      audioRobustness: [],
      licenseServer: '',
      certificateServer: ''
    },
    logger: {
      enabled: false,
      logThroughEvent: false,
      level: ELogLevel.WARN,
      include: [],
      exclude: []
    },
    manifest: {
      dashPatchLocation: null,
      dashParsePrftFromMpd: false,
      presentationDelay: null,
      liveEdgeFudge: 5,
      updatePeriod: null,
      isMultiview: false
    },
    network: {
      manifest: {timeout: 10000, maxRetry: 2},
      segment: {timeout: 15000, maxRetry: 2},
      drm: {timeout: 15000, maxRetry: 1}
    },
    patch: {
      encryptFirstClearInitSegment: false,
      encryptClearContent: false,
      normaliseTimescale: false,
      useRealEncryptedInitSegment: false,
      removeGapFromFirstInitSegment: false,
      useExtendedType: false,
      useLegacyEme: false,
      convertLittleEndianKeyId: false,
      avoidAudioFrameSplicing: false,
      removeTextTrack: false
    },
    playback: {
      autoplay: false,
      preferredAudioLanguage: null,
      preferredAudioCodecs: null,
      preferredAudioRole: null,
      preferredAudioLabel: null,
      preferredTextLanguage: null,
      startingPosition: null
    }
  };

  constructor(config?: ITapeConfig) {
    if (config) {
      this.update(config);
    }
  }

  public get config(): Required<ITapeConfig> {
    return this._config;
  }

  public get abr(): Required<IAbrConfig> {
    return this._config.abr as Required<IAbrConfig>;
  }

  public get buffer(): Required<IBufferConfig> {
    return this._config.buffer as Required<IBufferConfig>;
  }

  public get cdn(): Required<ICdnConfig> {
    return this._config.cdn as Required<ICdnConfig>;
  }

  public get cmcd(): Required<ICmcdConfig> {
    return this._config.cmcd as Required<ICmcdConfig>;
  }

  public get downloader(): Required<IDownlaoderConfig> {
    return this._config.downloader as Required<IDownlaoderConfig>;
  }

  public get eme(): Required<IEmeConfig> {
    return this._config.eme as Required<IEmeConfig>;
  }

  public get logger(): Required<ILoggerConfig> {
    return this._config.logger as Required<ILoggerConfig>;
  }

  public get manifest(): Required<IManifestConfig> {
    return this._config.manifest as Required<IManifestConfig>;
  }

  public get network(): Required<INetworkConfig> {
    return this._config.network as Required<INetworkConfig>;
  }

  public get patch(): Required<IPatchConfig> {
    return this._config.patch as Required<IPatchConfig>;
  }

  public get playback(): Required<IPlaybackConfig> {
    return this._config.playback as Required<IPlaybackConfig>;
  }

  public update(config: ITapeConfig): void {
    // we remove undefined values here
    const sanitisedConfig: ITapeConfig = JSON.parse(JSON.stringify(config));

    if (sanitisedConfig.abr) {
      this._config.abr = Object.assign(this._config.abr, sanitisedConfig.abr);
    }

    if (sanitisedConfig.buffer) {
      this._config.buffer = Object.assign(this._config.buffer, sanitisedConfig.buffer);
    }

    if (sanitisedConfig.cdn) {
      this._config.cdn = Object.assign(this._config.cdn, sanitisedConfig.cdn);
    }

    if (sanitisedConfig.cmcd) {
      this._config.cmcd = Object.assign(this._config.cmcd, sanitisedConfig.cmcd);
    }

    if (sanitisedConfig.downloader) {
      this._config.downloader = Object.assign(this._config.downloader, sanitisedConfig.downloader);
    }

    if (sanitisedConfig.eme) {
      this._config.eme = Object.assign(this._config.eme, sanitisedConfig.eme);
    }

    if (sanitisedConfig.logger) {
      this._config.logger = Object.assign(this._config.logger, sanitisedConfig.logger);
    }

    if (sanitisedConfig.manifest) {
      this._config.manifest = Object.assign(this._config.manifest, sanitisedConfig.manifest);
    }

    if (sanitisedConfig.network) {
      this._config.network = Object.assign(this._config.network, sanitisedConfig.network);
    }

    if (sanitisedConfig.patch) {
      this._config.patch = Object.assign(this._config.patch, sanitisedConfig.patch);
    }

    if (sanitisedConfig.playback) {
      const normalizedPlaybackConfig: IPlaybackConfig = {
        ...sanitisedConfig.playback
      };

      if (sanitisedConfig.playback.preferredTextLanguage) {
        normalizedPlaybackConfig.preferredTextLanguage =
          sanitisedConfig.playback.preferredTextLanguage.toLowerCase();
      }

      if (sanitisedConfig.playback.preferredAudioLanguage) {
        normalizedPlaybackConfig.preferredAudioLanguage =
          sanitisedConfig.playback.preferredAudioLanguage.toLowerCase();
      }

      this._config.playback = Object.assign(this._config.playback, normalizedPlaybackConfig);
    }
  }
}

export default ConfigManager;
