import IDataSegment from '@downloader/segment/interfaces/IDataSegment';
import ManifestManager from '@manifest/manifestManager';
import ICue from '@parser/text/interfaces/ICue';

function adjustCueTimings(
  cues: Array<ICue>,
  currentDataSegment: IDataSegment,
  manifestManager: ManifestManager
): Array<ICue> {
  const {
    representation: {
      adaptation: {
        period: {start: periodStart, id: periodId}
      }
    }
  } = currentDataSegment;
  const presentationTimeOffset: number = manifestManager.getPeriodPresentationTimeOffset(periodId) ?? 0;
  for (let i: number = 0; i < cues.length; i++) {
    const {begin: relativeBegin, end: relativeEnd, offset} = cues[i];

    const begin: number = periodStart - presentationTimeOffset + relativeBegin + offset;
    const end: number = periodStart - presentationTimeOffset + relativeEnd + offset;
    cues[i].begin = begin;
    cues[i].end = end;
  }

  return cues;
}

export default adjustCueTimings;
