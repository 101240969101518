import Dispatcher from '@dispatcher/dispatcher';
import EEvent from '@dispatcher/enum/EEvent';
import IDataSegment from '@downloader/segment/interfaces/IDataSegment';
import EErrorCode from '@error/enum/EErrorCode';
import EErrorSeverity from '@error/enum/EErrorSeverity';
import EErrorType from '@error/enum/EErrorType';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import IFrma from '@utils/mp4/interfaces/IFrma';
import IMdhd from '@utils/mp4/interfaces/IMdhd';
import IParsedBox from '@utils/mp4/interfaces/IParsedBox';
import ITfdt from '@utils/mp4/interfaces/ITfdt';
import ITfhd from '@utils/mp4/interfaces/ITfhd';
import ITkhd from '@utils/mp4/interfaces/ITkhd';
import ITrex from '@utils/mp4/interfaces/ITrex';
import ITrun from '@utils/mp4/interfaces/ITrun';
import Mp4Parser from '@utils/mp4/parser';

import EBitstreamFormat from '../enum/EBitstreamFormat';
import ICue from '../interfaces/ICue';
import CeaDecoder from './ceaDecoder';

class CeaParser {
  private _logger: ILogger;
  private _ceaDecoder: CeaDecoder;

  private _defaultSampleDuration: number = 0;
  private _defaultSampleSize: number = 0;
  private _trackIdToTimescale = new Map<number, number>();
  private _bitstreamFormat: EBitstreamFormat = EBitstreamFormat.UNKNOWN;

  private _CODEC_BITSTREAM_MAP: Record<string, EBitstreamFormat> = {
    avc1: EBitstreamFormat.H264,
    avc3: EBitstreamFormat.H264,
    hev1: EBitstreamFormat.H265,
    hvc1: EBitstreamFormat.H265,
    // Dolby vision is also H265.
    dvh1: EBitstreamFormat.H265,
    dvhe: EBitstreamFormat.H265
  };

  private _DEFAULT_TIMESCALE: number = 90000;
  private _H264_NALU_TYPE_SEI: number = 0x06;
  private _H265_PREFIX_NALU_TYPE_SEI: number = 0x27;
  private _H265_SUFFIX_NALU_TYPE_SEI: number = 0x28;

  private _parsedIdsMap: Map<string, Set<number>> = new Map();
  private _currentPeriodId: string | null = null;

  constructor(loggerManager: LoggerManager, private _dispatcher: Dispatcher) {
    this._logger = loggerManager.registerLogger(ELogType.PARSER);
    this._ceaDecoder = new CeaDecoder(loggerManager, this._dispatcher);
  }

  private parseInit(data: ArrayBuffer): void {
    const trackIds: Array<number> = [];
    const timescales: Array<number> = [];

    const skipToEnd = (box: IParsedBox): void => {
      const {reader} = box;
      const end: number = box.start + box.size - reader.getPosition();
      reader.skip(end);
    };

    const codecBoxParser = (box: IParsedBox): void => {
      this.setBitstreamFormat(box.name);
      skipToEnd(box);
    };

    new Mp4Parser(this._dispatcher)
      .box('moov', Mp4Parser.children)
      .box('mvex', Mp4Parser.children)
      .fullBox('trex', (box: IParsedBox) => {
        const parsedTrexBox: ITrex = Mp4Parser.parseTrex(box);
        this._defaultSampleDuration = parsedTrexBox.defaultSampleDuration;
        this._defaultSampleSize = parsedTrexBox.defaultSampleSize;
      })
      .box('trak', Mp4Parser.children)
      .fullBox('tkhd', (box: IParsedBox) => {
        const parsedTkhdBox: ITkhd = Mp4Parser.parseTkhd(box);
        trackIds.push(parsedTkhdBox.trackId);
      })
      .box('mdia', Mp4Parser.children)
      .fullBox('mdhd', (box: IParsedBox) => {
        const parsedMdhdBox: IMdhd = Mp4Parser.parseMdhd(box);
        timescales.push(parsedMdhdBox.timescale);
      })
      .box('minf', Mp4Parser.children)
      .box('stbl', Mp4Parser.children)
      .fullBox('stsd', Mp4Parser.sampleDescription)

      // These are the various boxes that signal a codec.
      .box('avc1', codecBoxParser)
      .box('avc3', codecBoxParser)
      .box('hev1', codecBoxParser)
      .box('hvc1', codecBoxParser)
      .box('dvav', codecBoxParser)
      .box('dva1', codecBoxParser)
      .box('dvh1', codecBoxParser)
      .box('dvhe', codecBoxParser)

      // This signals an encrypted sample, which we can go inside of to find
      // the codec used.
      .box('encv', Mp4Parser.visualSampleEntry)
      .box('sinf', Mp4Parser.children)
      .box('frma', (box: IParsedBox) => {
        const parsedFrmaBox: IFrma = Mp4Parser.parseFrma(box);
        this.setBitstreamFormat(parsedFrmaBox.codec);
      })
      .parse(data);

    if (this._bitstreamFormat === EBitstreamFormat.UNKNOWN) {
      const message: string = 'Unable to determine bitstream format for CEA parsing';
      this._logger.warn(message);
      this._dispatcher.emit({
        name: EEvent.TAPE_ERROR,
        type: EErrorType.INTERNAL,
        code: EErrorCode.PARSER,
        severity: EErrorSeverity.WARN,
        message
      });
    }

    // Populate the map from track Id to timescale
    trackIds.forEach((trackId: number, idx: number) => {
      this._trackIdToTimescale.set(trackId, timescales[idx]);
    });
  }

  private process(naluData: Uint8Array): Array<Uint8Array> {
    const seiPayloads: Array<Uint8Array> = [];
    const naluClone: Uint8Array = this.removeEmu(naluData);

    // The following is an implementation of section 7.3.2.3.1
    // in Rec. ITU-T H.264 (06/2019), the H.264 spec.
    let offset: number = 0;

    while (offset < naluClone.length) {
      let payloadType: number = 0; // SEI payload type as defined by H.264 spec
      while (naluClone[offset] === 0xff) {
        payloadType += 255;
        offset++;
      }
      payloadType += naluClone[offset++];

      let payloadSize: number = 0; // SEI payload size as defined by H.264 spec
      while (naluClone[offset] === 0xff) {
        payloadSize += 255;
        offset++;
      }
      payloadSize += naluClone[offset++];

      // Payload type 4 is user_data_registered_itu_t_t35, as per the H.264
      // spec. This payload type contains caption data.
      if (payloadType === 0x04) {
        seiPayloads.push(naluClone.subarray(offset, offset + payloadSize));
      }
      offset += payloadSize;
    }

    return seiPayloads;
  }

  private removeEmu(naluData: Uint8Array): Uint8Array {
    let naluClone: Uint8Array = naluData;
    let zeroCount: number = 0;
    let src: number = 0;
    while (src < naluClone.length) {
      if (zeroCount === 2 && naluClone[src] === 0x03) {
        // 0x00, 0x00, 0x03 pattern detected
        zeroCount = 0;

        const newArr: Array<number> = Array.from(naluClone);
        newArr.splice(src, 1);
        naluClone = new Uint8Array(newArr);
      } else {
        if (naluClone[src] === 0x00) {
          zeroCount++;
        } else {
          zeroCount = 0;
        }
      }
      src++;
    }

    return naluClone;
  }

  private setBitstreamFormat(codec: string): void {
    const bitstreamFormat: EBitstreamFormat | undefined = this._CODEC_BITSTREAM_MAP[codec];
    if (bitstreamFormat) {
      this._bitstreamFormat = bitstreamFormat;
    }
  }

  public clear(): void {
    this._currentPeriodId = null;
    this._parsedIdsMap.clear();
    this._ceaDecoder.clear();
  }

  public parse(segment: IDataSegment, streamAndLanguages: Array<[string, string]>): Array<ICue> {
    const periodId: string = segment.representation.adaptation.period.id;
    const isNewPeriod: boolean = this._currentPeriodId !== periodId;
    this._currentPeriodId = periodId;
    let parsedSegmentSet: Set<number> | undefined = this._parsedIdsMap.get(periodId);
    if (parsedSegmentSet && parsedSegmentSet.has(segment.id)) return [];
    if (!parsedSegmentSet) {
      parsedSegmentSet = new Set();
      this._parsedIdsMap.set(periodId, parsedSegmentSet);
    }
    parsedSegmentSet.add(segment.id);

    const data: ArrayBuffer = segment.data as ArrayBuffer;
    const captionPackets: Array<{pts: number; packet: Uint8Array}> = [];

    if (segment.id === 0) {
      this._logger.debug('Parsing new init segment for CEA');
      if (isNewPeriod) {
        this._ceaDecoder.clear();
      }
      this.parseInit(data);

      return [];
    }

    if (this._bitstreamFormat === EBitstreamFormat.UNKNOWN) {
      // We don't know how to extract SEI from this.
      return [];
    }

    // Fields that are found in MOOF boxes
    let defaultSampleDuration: number = this._defaultSampleDuration;
    let defaultSampleSize: number = this._defaultSampleSize;
    let moofOffset: number = 0;
    const parsedTRUNs: Array<ITrun> = [];
    let baseMediaDecodeTime: number = 0;
    let timescale: number = this._DEFAULT_TIMESCALE;

    new Mp4Parser(this._dispatcher)
      .box('moof', (box: IParsedBox) => {
        moofOffset = box.start;
        // trun box parsing is reset on each moof.
        parsedTRUNs.length = 0;
        Mp4Parser.children(box);
      })
      .box('traf', Mp4Parser.children)
      .fullBox('trun', (box: IParsedBox) => {
        const parsedTrunBox: ITrun = Mp4Parser.parseTrun(box);
        parsedTRUNs.push(parsedTrunBox);
      })
      .fullBox('tfhd', (box: IParsedBox) => {
        const parsedTfhdBox: ITfhd = Mp4Parser.parseTfhd(box);
        defaultSampleDuration = parsedTfhdBox.defaultSampleDuration || this._defaultSampleDuration;
        defaultSampleSize = parsedTfhdBox.defaultSampleSize || this._defaultSampleSize;
        const trackTimescale: number | undefined = this._trackIdToTimescale.get(parsedTfhdBox.trackId);
        if (trackTimescale !== undefined) {
          timescale = trackTimescale;
        }
      })
      .fullBox('tfdt', (box: IParsedBox) => {
        const parsedTfdtBox: ITfdt = Mp4Parser.parseTfdt(box);
        baseMediaDecodeTime = parsedTfdtBox.baseMediaDecodeTime;
      })
      .box('mdat', (box: IParsedBox) => {
        const {reader} = box;

        let sampleIndex: number = 0;
        let sampleSize: number = defaultSampleSize;

        // Combine all sample data.  This assumes that the samples described across
        // multiple trun boxes are still continuous in the mdat box.
        const sampleData: ITrun['sampleData'] = [];
        parsedTRUNs.forEach((t: ITrun) => sampleData.push(...t.sampleData));

        if (sampleData.length) {
          sampleSize = sampleData[0].sampleSize || defaultSampleSize;
        }

        const parsedTrunOffset: number = parsedTRUNs[0]?.dataOffset || 0;
        const offset: number = moofOffset + parsedTrunOffset - box.start - 8;

        reader.skip(offset);

        while (reader.hasMoreData()) {
          const naluSize: number = reader.readUint32();
          const naluHeader: number = reader.readUint8();
          let naluType: number | null = null;
          let isSeiMessage: boolean = false;
          let naluHeaderSize: number = 1;

          switch (this._bitstreamFormat) {
            case EBitstreamFormat.H264:
              naluType = naluHeader & 0x1f;
              isSeiMessage = naluType === this._H264_NALU_TYPE_SEI;
              break;

            case EBitstreamFormat.H265:
              naluHeaderSize = 2;
              reader.skip(1);
              naluType = (naluHeader >> 1) & 0x3f;
              isSeiMessage =
                naluType === this._H265_PREFIX_NALU_TYPE_SEI || naluType === this._H265_SUFFIX_NALU_TYPE_SEI;
              break;

            default:
              return;
          }

          if (isSeiMessage) {
            let timeOffset: number = 0;

            if (sampleIndex < sampleData.length) {
              timeOffset = sampleData[sampleIndex].sampleCompositionTimeOffset || 0;
            }

            const pts: number = (baseMediaDecodeTime + timeOffset) / timescale;

            for (const packet of this.process(reader.readBytes(naluSize - naluHeaderSize))) {
              captionPackets.push({
                packet,
                pts
              });
            }
          } else {
            try {
              reader.skip(naluSize - naluHeaderSize);
            } catch (e) {
              // It is necessary to ignore this error because it can break the start
              // of playback even if the user does not want to see the subtitles.
              break;
            }
          }
          sampleSize -= naluSize + 4;
          if (sampleSize === 0) {
            if (sampleIndex < sampleData.length) {
              baseMediaDecodeTime += sampleData[sampleIndex].sampleDuration || defaultSampleDuration;
            } else {
              baseMediaDecodeTime += defaultSampleDuration;
            }

            sampleIndex++;

            if (sampleIndex < sampleData.length) {
              sampleSize = sampleData[sampleIndex].sampleSize || defaultSampleSize;
            } else {
              sampleSize = defaultSampleSize;
            }
          }
        }
      })
      .parse(data);

    for (const captionPacket of captionPackets) {
      if (captionPacket.packet.length > 0) {
        this._ceaDecoder.extract(captionPacket.packet, captionPacket.pts);
      }
    }

    const cues: Array<ICue> = this._ceaDecoder.decode();

    const validCues: Array<ICue> = [];
    for (let i: number = 0; i < cues.length; i++) {
      const service: string = cues[i].id.split('_')[2];
      const streamAndLanguage: [string, string] | undefined = streamAndLanguages.find(
        (s: [string, string]) => s[0] === service
      );
      if (streamAndLanguage) {
        cues[i].lang = streamAndLanguage[1];
        validCues.push(cues[i]);
      }
    }

    return validCues;
  }

  public destroy(): void {
    this._logger.info('Destroying CEA parser');

    this._parsedIdsMap.clear();

    this.clear();
  }
}

export default CeaParser;
