import EWMAThrougput from '@abr/ewma/throughput';
import ConfigManager from '@config/configManager';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import ERequestType from '@network/enum/ERequestType';
import IHttpResponse from '@network/interfaces/IHttpResponse';
import IHttpTimeout from '@network/interfaces/IHttpTimeout';
import round from '@utils/round';

import IAlgorithm from '../interfaces/IAlgorithm';

// https://www.comp.nus.edu.sg/~ooiwt/papers/mm17-quetra.pdf
// https://github.com/nus-mmsys/QUETRA/blob/ACM-MM/code/quetra.js
class QUETRA implements IAlgorithm {
  private _logger: ILogger;
  private _count: number = -1; // For counting number of segments arrived to take decision on every nth arrival
  private _bufferLevel: number = 0;
  private _bufferThreshold: number = 0;
  private _COUNT_GOAL: number = 5;
  private _bufferSlack: Array<number> = []; // Array storing Buffer slack according to different buffer capacity for rho in range of 0.5 to 1.2 at the interval of 0.01
  private _lastQualityIndex: number = 0;

  constructor(
    private _bandwidthEstimator: EWMAThrougput,
    private _configManager: ConfigManager,
    loggerManager: LoggerManager
  ) {
    this._logger = loggerManager.registerLogger(ELogType.ABR);
    this._logger.info('Using QUETRA');

    this.updateBufferSlack(this._configManager.buffer.bufferAhead);
  }

  private updateBufferSlack(K: number): void {
    this._bufferSlack.length = 0;
    for (let rho: number = 0.5; rho < 1.21; rho += 0.01) {
      const slack: number = round(this.getBufferSlack(K, rho), 4);
      this._bufferSlack.push(slack);
    }
  }

  private getBufferSlack(K: number, rho: number): number {
    let sumxi: number = 0;
    for (let i: number = 0; i <= K - 1; i++) {
      sumxi += this.calculateXi(i, rho);
    }

    return sumxi / (1 + rho * this.calculateXi(K - 1, rho));
  }

  private factorial(n: number): number {
    let result: number = 1;
    for (let i: number = 2; i <= n; i++) {
      result = result * i;
    }

    return result;
  }

  private calculateXi(i: number, rho: number): number {
    let xi: number = 0;
    for (let j: number = 0; j <= i; j++) {
      xi +=
        (Math.pow(-1, j) / this.factorial(j)) *
        Math.pow(i - j, j) *
        Math.pow(rho, j) *
        Math.pow(Math.E, (i - j) * rho); // Math.exp((i - j) * rho)
    }

    return xi;
  }

  private getQualityIndex(bandwidths: Array<number>): number | null {
    this._count++;
    const bufferMax: number = this._configManager.buffer.bufferAhead;
    const rhos: Array<number> = []; // Array to store RHO value corresponds to available bitrates
    const slacks: Array<number> = []; // Array to store buffer slack value corresponds to available bitrates

    const estimatedBandwidth: number = this._bandwidthEstimator.getEstimatedBandwidth();
    // rho for each available bitrate
    for (let i: number = 0; i < bandwidths.length; i++) {
      const ab: number = bandwidths[i];
      rhos[i] = estimatedBandwidth / ab;
    }
    // slack for each bitrate
    for (let i: number = 0; i < bandwidths.length; i++) {
      const rho: number = rhos[i];
      let slackIndex: number = 0;
      if (rho < 0.5) {
        slacks[i] = bufferMax;
      } else if (rho >= 1.2) {
        slacks[i] = 0;
      } else {
        slackIndex = Math.floor((rho - 0.5) / 0.01);
        slacks[i] = this._bufferSlack[slackIndex];
      }
    }

    // selecting Bitrate whose slack is nearest to current buffer occupancy
    let minIndex: number = 0;
    let minDiff: number = Math.abs(this._bufferLevel - slacks[minIndex]);
    for (let i: number = 1; i < bandwidths.length; i++) {
      if (Math.abs(this._bufferLevel - slacks[i]) <= minDiff) {
        minIndex = i;
        minDiff = Math.abs(this._bufferLevel - slacks[minIndex]);
      }
    }

    if (slacks[minIndex] === bufferMax && slacks[bandwidths.length - 1] === slacks[0]) {
      //If slack for all bitrate is equal to max buffer capacity, choose minimum bitrate as it means buffer is empty
      minIndex = 0;
    }

    if (this._bufferLevel < this._bufferThreshold) {
      //Same lower reservoir maintenance as BBA
      minIndex = 0;
    }

    // decision at nth segments arrival or if lower reservoir is needed to be maintained
    if (Boolean(this._count % this._COUNT_GOAL === 0) || this._bufferLevel < this._bufferThreshold) {
      return minIndex;
    }

    return null;
  }

  public chooseBandwidth(bandwidths: Array<number>): number {
    const qualityIndex: number | null = this.getQualityIndex(bandwidths);

    if (qualityIndex !== null) {
      this._lastQualityIndex = qualityIndex;
    }

    return bandwidths[this._lastQualityIndex];
  }

  public onAverageSegmentDuration = (averageSegmentDuration: number): void => {
    this._bufferThreshold = averageSegmentDuration * 1.5;
  };

  public onBufferUpdate = (bufferAhead: number): void => {
    this._bufferLevel = bufferAhead;
  };

  public onHttpResponse = (_httpResponse: IHttpResponse): void => {
    return;
  };

  public onHttpTimeout = (httpTimeout: IHttpTimeout): void => {
    const {request} = httpTimeout;
    if (request.type === ERequestType.VIDEO_SEGMENT) {
      this._bufferLevel = 0;
      // reset the counter and force the next decision
      this._count = -1;
    }
  };

  public onRateChange(_playbackRate: number): void {
    return;
  }

  public onSeeking(): void {
    this._bufferLevel = 0;
    this._count = -1;
  }

  public isTargetReached(): boolean {
    return this._bufferLevel >= this._bufferThreshold;
  }

  public destroy(): void {
    this._logger.info('Destroying QUETRA');

    this._bufferSlack.length = 0;
  }
}

export default QUETRA;
