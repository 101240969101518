import ConfigManager from '@config/configManager';
import Dispatcher from '@dispatcher/dispatcher';
import EEvent from '@dispatcher/enum/EEvent';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';

class CdnManager {
  private _logger: ILogger;
  private _cdns: Array<string> = [];
  private _currentCdn: number = 0;

  constructor(
    manifestUrl: string,
    private _configManager: ConfigManager,
    loggerManager: LoggerManager,
    private _dispatcher: Dispatcher
  ) {
    this._logger = loggerManager.registerLogger(ELogType.CDN);

    this._configManager.cdn.cdns.forEach((cdn: string) => {
      const cdnOrigin: string = this.getOrigin(cdn);
      this._cdns.push(cdnOrigin);
    });

    if (this._cdns.length === 0) {
      const manifestOrigin: string = this.getOrigin(manifestUrl);
      this._cdns.push(manifestOrigin);
    }
  }

  private getCurrent(): string | null {
    return this._cdns[this._currentCdn] ?? null;
  }

  private getOrigin = (url: string): string => {
    const {origin} = new URL(url);

    return origin;
  };

  public next = (): boolean => {
    let hasNext: boolean = false;
    this._currentCdn++;

    const cdn: string | null = this.getCurrent();
    if (cdn) {
      this._logger.info('CDN change', cdn);
      this._dispatcher.emit({
        name: EEvent.CDN_CHANGE,
        cdn
      });
      hasNext = true;
    }

    return hasNext;
  };

  public getUrl(url: string): string {
    const currentCdn: string | null = this.getCurrent();
    if (!currentCdn) return '';

    const origin: string = this.getOrigin(url);
    if (this._cdns.indexOf(origin) < 0) {
      return url;
    } else {
      const {pathname, search} = new URL(url);

      return `${currentCdn}${pathname}${search}`;
    }
  }

  public destroy(): void {
    this._logger.info('Destroying CDN manager');
    this._cdns.length = 0;
  }
}

export default CdnManager;
