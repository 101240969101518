const removeSrcAttribute = (videoElement: HTMLVideoElement): void => {
  if ('removeAttribute' in videoElement) {
    videoElement.removeAttribute('src');
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    videoElement.src = null;
  }
};

export default removeSrcAttribute;
