enum EMediaKeyStatus {
  USABLE = 'usable',
  EXPIRED = 'expired',
  RELEASED = 'released',
  OUTPUT_RESTRICTED = 'output-restricted',
  OUTPUT_DOWNSCALED = 'output-downscaled',
  STATUS_PENDING = 'status-pending',
  INTERNAL_ERROR = 'internal-error'
}

export default EMediaKeyStatus;
