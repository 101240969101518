enum ETagName {
  ACCESSIBILITY = 'Accessibility',
  AUDIO_CHANNEL_CONFIGURATION = 'AudioChannelConfiguration',
  ADAPTATION_SET = 'AdaptationSet',
  BASE_URL = 'BaseURL',
  CENC_PSSH = 'cenc:pssh',
  CONTENT_PROTECTION = 'ContentProtection',
  ESSENTIAL_PROPERTY = 'EssentialProperty',
  EVENT = 'Event',
  EVENT_STREAM = 'EventStream',
  INBAND_EVENT_STREAM = 'InbandEventStream',
  INITIALIZATION = 'Initialization',
  LABEL = 'Label',
  PATCH_LOCATION = 'PatchLocation',
  PERIOD = 'Period',
  REPRESENTATION = 'Representation',
  ROLE = 'Role',
  S = 'S',
  SEGMENT_BASE = 'SegmentBase',
  SEGMENT_TEMPLATE = 'SegmentTemplate',
  SEGMENT_TIMELINE = 'SegmentTimeline',
  UTC_TIMING = 'UTCTiming',
  PRODUCER_REFERENCE_TIME = 'ProducerReferenceTime'
}

export default ETagName;
