/**
 * This method converts the HTML entities &amp;, &lt;, &gt;, &quot;, &#39;,
 * &nbsp;, &lrm; and &rlm; in string to their corresponding characters.
 *
 * @param {!string} input
 * @return {string}
 */
const htmlUnescape = (input: string): string => {
  // Used to map HTML entities to characters.
  const htmlUnescapes: Record<string, string> = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#39;': "'",
    '&apos;': "'",
    '&nbsp;': String.fromCharCode(160),
    '&lrm;': String.fromCharCode(8206),
    '&rlm;': String.fromCharCode(8207)
  };

  // Used to match HTML entities and HTML characters.
  const reEscapedHtml: RegExp = /&(?:amp|lt|gt|quot|apos|#(0+)?39|nbsp|lrm|rlm);/g;
  const reHasEscapedHtml: RegExp = RegExp(reEscapedHtml.source);
  // This check is an optimization, since replace always makes a copy
  if (input && reHasEscapedHtml.test(input)) {
    return input.replace(reEscapedHtml, (entity: string) => {
      // The only thing that might not match the dictionary above is the
      // single quote, which can be matched by many strings in the regex, but
      // only has a single entry in the dictionary.
      return htmlUnescapes[entity] || "'";
    });
  }

  return input || '';
};

export default htmlUnescape;
