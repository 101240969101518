import ConfigManager from '@config/configManager';
import Dispatcher from '@dispatcher/dispatcher';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import ERequestType from '@network/enum/ERequestType';
import IHttpResponse from '@network/interfaces/IHttpResponse';
import NetworkManager from '@network/networkManager';
import ETextCodecs from '@parser/text/enum/ETextCodecs';
import base64ToUint8 from '@utils/base64ToUint8';
import ProbeCapabilities from '@utils/capabilities/probe';
import getAudioChannels from '@utils/getAudioChannels';
import getBaseUrl from '@utils/getBaseUrl';
import getTimeFromDate from '@utils/getTimeFromDate';
import isAbsoluteUrl from '@utils/isAbsoluteUrl';
import IParsedBox from '@utils/mp4/interfaces/IParsedBox';
import ISidx from '@utils/mp4/interfaces/ISidx';
import ISidxReference from '@utils/mp4/interfaces/ISidxReference';
import Mp4Parser from '@utils/mp4/parser';
import round from '@utils/round';
import {GenericParserContext, GenericXmlNode, getNodeText, XmlParser} from '@utils/xml-parser-ts/parser';

import EAudioChannelSchemeUri from '../enum/EAudioChannelSchemeUri';
import EContentType from '../enum/EContentType';
import EKeySystem from '../enum/EKeySystem';
import EManifestType from '../enum/EManifestType';
import EMimeType from '../enum/EMimeType';
import EPlayable from '../enum/EPlayable';
import EProtocol from '../enum/EProtocol';
import ESchemeUri from '../enum/ESchemeUri';
import IAccessibility from '../interfaces/IAccessibility';
import IAdaptationSet from '../interfaces/IAdaptationSet';
import IAudioChannelConfiguration from '../interfaces/IAudioChannelConfiguration';
import IContentProtection from '../interfaces/IContentProtection';
import IEssentialProperty from '../interfaces/IEssentialProperty';
import IInbandEventStream from '../interfaces/IInbandEventStream';
import IManifest from '../interfaces/IManifest';
import IOutbandEventStream from '../interfaces/IOutbandEventStream';
import IPeriod from '../interfaces/IPeriod';
import IRepresentation from '../interfaces/IRepresentation';
import IRole from '../interfaces/IRole';
import ISegment from '../interfaces/ISegment';
import ETagName from './enum/ETagName';

/**
 * Defines the list of known tag names of nodes that the parser accesses during the parsing call.
 *
 * ! Unknown tags are completly skipped during parsing (e.g. the parser jumps immediately to the close tag) !
 *
 * Lazy nodes (lazy: true) define that all children of this node should be parsed from the original xml string
 * only when the node.children is accessed.
 *
 * ! Lazy nodes currently don't support text or cdata attributes (but children of lazy nodes will) !
 * ! Parsing of lazy nodes children doesn't rely on known tags, so we don't need to define them !
 */
const DASH_TAGS: Array<{name: string; lazy?: boolean}> = [
  {name: 'MPD'},
  {name: 'Accessibility'},
  {name: 'AudioChannelConfiguration'},
  {name: 'AdaptationSet'},
  {name: 'BaseURL'},
  {name: 'cenc:pssh'},
  {name: 'ContentProtection'},
  {name: 'EssentialProperty'},
  {name: 'InbandEventStream'},
  {name: 'Initialization'},
  {name: 'Label'},
  {name: 'PatchLocation'},
  {name: 'Period'},
  {name: 'Representation'},
  {name: 'Role'},
  {name: 'SegmentBase'},
  {name: 'SegmentTemplate', lazy: true}, // to defer parsing the segment timeline until needed
  {name: 'UTCTiming'},
  {name: 'ProducerReferenceTime'},
  {name: 'EventStream'},
  {name: 'Event', lazy: true} // must be defined as lazy as we don't known about it's contents
];

const durationRegEx: RegExp =
  /^P(?:([0-9]*)Y)?(?:([0-9]*)M)?(?:([0-9]*)D)?(?:T(?:([0-9]*)H)?(?:([0-9]*)M)?(?:([0-9.]*)S)?)?$/;

class DashParser {
  private _logger: ILogger;
  private _manifest: IManifest | null = null;
  private _representationMap: Map<string, [IRepresentation, [ISegment, ISegment]]> = new Map();
  private _parsedPrft: boolean = false;

  private _KEY_SYSTEM_MAP: Record<ESchemeUri, EKeySystem> = {
    [ESchemeUri.WIDEVINE]: EKeySystem.WIDEVINE,
    [ESchemeUri.PLAYREADY]: EKeySystem.PLAYREADY
  };

  constructor(
    private _url: URL,
    private _networkManager: NetworkManager,
    private _configManager: ConfigManager,
    private _probeCapabilities: ProbeCapabilities,
    loggerManager: LoggerManager,
    private _dispatcher: Dispatcher
  ) {
    this._logger = loggerManager.registerLogger(ELogType.PARSER);
  }

  private getAttributeFromNodes(
    nodeA: GenericXmlNode | undefined,
    nodeB: GenericXmlNode | undefined,
    attribute: string
  ): string {
    let value: string | null = null;
    if (nodeA) {
      value = nodeA.attrs[attribute];
    }

    if (!value) {
      if (nodeB) {
        value = nodeB.attrs[attribute];
      }
    }

    return value ?? '';
  }

  private getDuration(duration: string): number {
    const matches: RegExpExecArray | null = durationRegEx.exec(duration);

    if (!matches) {
      return 0;
    }

    const years: number = Number(matches[1] ?? null);
    const months: number = Number(matches[2] ?? null);
    const days: number = Number(matches[3] ?? null);
    const hours: number = Number(matches[4] ?? null);
    const minutes: number = Number(matches[5] ?? null);
    const seconds: number = Number(matches[6] ?? null);

    const time: number =
      60 * 60 * 24 * 365 * years +
      60 * 60 * 24 * 30 * months +
      60 * 60 * 24 * days +
      60 * 60 * hours +
      60 * minutes +
      seconds;

    return time;
  }

  private getContentType(mimeType: EMimeType): EContentType {
    switch (mimeType) {
      case EMimeType.MP4:
      case EMimeType.TTML:
      case EMimeType.WEBVTT:
        return EContentType.TEXT;
      case EMimeType.AUDIO:
        return EContentType.AUDIO;
      case EMimeType.VIDEO:
        return EContentType.VIDEO;
      case EMimeType.IMAGE:
        return EContentType.IMAGE;
    }
  }

  private getByteRange(range: string): [number, number] {
    const [startStr, endStr]: Array<string> = range.split('-');

    return [+startStr, +endStr];
  }

  private getCodecs(mimeType: EMimeType): string {
    switch (mimeType) {
      case EMimeType.WEBVTT:
        return ETextCodecs.WEBVTT;
    }

    return '';
  }

  private getFrameRate(frameRateAttr: string): number {
    let frameRate: number;
    const tokens: Array<string> = frameRateAttr.split('/');
    if (tokens[1] === undefined) {
      frameRate = +tokens[0];
    } else {
      frameRate = +tokens[0] / +tokens[1];
      frameRate = round(frameRate, 3);
    }

    return frameRate;
  }

  private async buildManifest(mpdNode: GenericXmlNode): Promise<IManifest | null> {
    const type: EManifestType = (mpdNode.attrs['type'] as EManifestType) || EManifestType.STATIC;

    const availabilityStartTimeAttr: string = mpdNode.attrs['availabilityStartTime'] || '';
    const availabilityStartTime: number = getTimeFromDate(availabilityStartTimeAttr);
    const maxSegmentDurationAttr: string = mpdNode.attrs['maxSegmentDuration'] || '';
    const maxSegmentDuration: number = this.getDuration(maxSegmentDurationAttr);
    const mediaPresentationDurationAttr: string = mpdNode.attrs['mediaPresentationDuration'] || '';
    const mediaPresentationDuration: number = this.getDuration(mediaPresentationDurationAttr);
    const minBufferTimeAttr: string = mpdNode.attrs['minBufferTime'] || '';
    const minBufferTime: number = this.getDuration(minBufferTimeAttr);
    const minimumUpdatePeriodAttr: string = mpdNode.attrs['minimumUpdatePeriod'] || '';
    let minimumUpdatePeriod: number = this.getDuration(minimumUpdatePeriodAttr);
    const publishTimeAttr: string = mpdNode.attrs['publishTime'] || '';
    const publishTime: number = getTimeFromDate(publishTimeAttr);
    const timeShiftBufferDepthAttr: string = mpdNode.attrs['timeShiftBufferDepth'] || '';
    const timeShiftBufferDepth: number = this.getDuration(timeShiftBufferDepthAttr);
    if (minimumUpdatePeriod > timeShiftBufferDepth) {
      minimumUpdatePeriod = minBufferTime;
    }
    const suggestedPresentationDelayAttr: string = mpdNode.attrs['suggestedPresentationDelay'] || '';
    const suggestedPresentationDelay: number = this.getDuration(suggestedPresentationDelayAttr);

    const tags: Record<string, GenericXmlNode[] | undefined> = mpdNode.children;

    if (tags[ETagName.UTC_TIMING]?.[0]) {
      const message: string = `${ETagName.UTC_TIMING} tag is not supported`;
      this._logger.debug(message);
    }

    const patchLocation: string | null = this.buildPatchLocation(tags[ETagName.PATCH_LOCATION]?.[0]);

    const {origin, pathname} = this._url;
    const manifestBaseUrl: string = getBaseUrl(origin, pathname);
    let baseUrl: string = '';
    if (tags[ETagName.BASE_URL]?.[0]) {
      baseUrl = this.buildBaseUrl(tags[ETagName.BASE_URL][0], manifestBaseUrl);
    } else {
      baseUrl = manifestBaseUrl;
    }

    const manifest: IManifest = {
      url: this._url.href,
      type,
      protocol: EProtocol.DASH,
      availabilityStartTime,
      producerReferenceStartTime: 0,
      maxSegmentDuration,
      mediaPresentationDuration,
      minBufferTime,
      minimumUpdatePeriod,
      publishTime,
      timeShiftBufferDepth,
      suggestedPresentationDelay,
      patchLocation,
      periods: []
    };

    manifest.periods = await this.buildPeriods(manifest, tags[ETagName.PERIOD] || [], baseUrl);

    return manifest;
  }

  private async buildPeriods(
    manifestRef: Readonly<IManifest>,
    periodNodes: Array<GenericXmlNode>,
    partialBaseUrl: string
  ): Promise<Array<IPeriod>> {
    const periods: Array<IPeriod> = [];

    for (let i: number = 0, periodsLen: number = periodNodes.length; i < periodsLen; i++) {
      const periodNode: GenericXmlNode = periodNodes[i];
      const idAttr: string = periodNode.attrs['id'] || i.toString();
      const durationAttr: string = periodNode.attrs['duration'] || '';
      const duration: number =
        this.getDuration(durationAttr) || manifestRef.mediaPresentationDuration || Infinity;
      const startAttr: string = periodNode.attrs['start'] || '';
      const start: number = this.getDuration(startAttr);

      const tags: Record<string, GenericXmlNode[] | undefined> = periodNode.children;

      let baseUrl: string = partialBaseUrl;
      if (tags[ETagName.BASE_URL]?.[0]) {
        baseUrl = this.buildBaseUrl(tags[ETagName.BASE_URL][0], partialBaseUrl);
      }

      let eventStream: IOutbandEventStream | null = null;
      if (tags[ETagName.EVENT_STREAM]?.[0]) {
        eventStream = this.buildOutbandEventStream(tags[ETagName.EVENT_STREAM]?.[0], start);
      }

      const period: IPeriod = {
        manifest: manifestRef,
        id: idAttr,
        start,
        duration,
        eventStream,
        video: [],
        audio: [],
        text: [],
        image: []
      };

      // eslint-disable-next-line no-await-in-loop
      const adaptationSets: Array<IAdaptationSet> = await this.buildAdaptationSets(
        period,
        tags[ETagName.ADAPTATION_SET] || [],
        baseUrl
      );

      for (let x: number = 0, adaptationSetsLen: number = adaptationSets.length; x < adaptationSetsLen; x++) {
        switch (adaptationSets[x].contentType) {
          case EContentType.VIDEO:
            period.video.push(adaptationSets[x]);
            break;
          case EContentType.AUDIO:
            period.audio.push(adaptationSets[x]);
            break;
          case EContentType.TEXT:
            period.text.push(adaptationSets[x]);
            break;
          case EContentType.IMAGE:
            period.image.push(adaptationSets[x]);
            break;
        }
      }

      periods.push(period);

      // adjust period duration
      const lastPeriod: IPeriod = periods[i - 1];
      if (lastPeriod?.duration === Infinity) {
        lastPeriod.duration = period.start - lastPeriod.start;
      }
    }

    return periods;
  }

  private buildBaseUrl(baseUrlNode: GenericXmlNode, partialUrl: string): string {
    let baseUrl: string = getNodeText(baseUrlNode);
    if (!isAbsoluteUrl(baseUrl)) {
      if (baseUrl[0] === '/') {
        const origin: string = new URL(partialUrl).origin;
        baseUrl = `${origin}${baseUrl}`;
      } else {
        baseUrl = `${partialUrl}${baseUrl}`;
      }
    }

    return baseUrl;
  }

  private async buildAdaptationSets(
    periodRef: Readonly<IPeriod>,
    adaptationSetNodes: Array<GenericXmlNode>,
    partialBaseUrl: string
  ): Promise<Array<IAdaptationSet>> {
    const adaptations: Array<IAdaptationSet> = [];

    for (let i: number = 0, adaptationSetLen: number = adaptationSetNodes.length; i < adaptationSetLen; i++) {
      const adaptationSetNode: GenericXmlNode = adaptationSetNodes[i];
      const idAttr: string = adaptationSetNode.attrs['id'] || i.toString();
      const codecsAttr: string = (adaptationSetNode.attrs['codecs'] || '').toLowerCase();
      const langAttr: string = adaptationSetNode.attrs['lang'] || '';
      const contentTypeAttr: string | null = adaptationSetNode.attrs['contentType'] || '';
      const minBandwidthAttr: string = adaptationSetNode.attrs['minBandwidth'] || '';
      const maxBandwidthAttr: string = adaptationSetNode.attrs['maxBandwidth'] || '';
      const maxWidthAttr: string =
        adaptationSetNode.attrs['maxWidth'] || adaptationSetNode.attrs['width'] || '';
      const maxHeightAttr: string =
        adaptationSetNode.attrs['maxHeight'] || adaptationSetNode.attrs['height'] || '';
      const frameRateAttr: string = adaptationSetNode.attrs['frameRate'] || '';

      const tags: Record<string, GenericXmlNode[] | undefined> = adaptationSetNode.children;

      const firstRep: GenericXmlNode | null = tags[ETagName.REPRESENTATION]?.[0] || null;
      const mimeTypeAttr: EMimeType =
        (adaptationSetNode.attrs['mimeType'] as EMimeType) || (firstRep?.attrs['mimeType'] as EMimeType);
      const contentType: EContentType = (contentTypeAttr ||
        this.getContentType(mimeTypeAttr)) as EContentType;

      const accessibilityNodes: Array<GenericXmlNode> = tags[ETagName.ACCESSIBILITY] || [];
      const audioChannelConfigurationNodes: Array<GenericXmlNode> =
        tags[ETagName.AUDIO_CHANNEL_CONFIGURATION] || [];
      const contentProtectionNodes: Array<GenericXmlNode> = tags[ETagName.CONTENT_PROTECTION] || [];
      const inbandEventStreamNodes: Array<GenericXmlNode> = tags[ETagName.INBAND_EVENT_STREAM] || [];
      const labelNode: GenericXmlNode | undefined = tags[ETagName.LABEL]?.[0];
      const roleNodes: Array<GenericXmlNode> = tags[ETagName.ROLE] || [];
      const segmentTemplateNode: GenericXmlNode | undefined = tags[ETagName.SEGMENT_TEMPLATE]?.[0];
      const producerReferenceTimeNode: GenericXmlNode | undefined =
        tags[ETagName.PRODUCER_REFERENCE_TIME]?.[0];

      const adaptation: IAdaptationSet = {
        period: periodRef,
        id: idAttr,
        playable: new Map(),
        contentType,
        mimeType: mimeTypeAttr,
        label: this.buildLabel(labelNode),
        lang: langAttr.toLowerCase(),
        maxWidth: +maxWidthAttr,
        maxHeight: +maxHeightAttr,
        minBandwidth: +minBandwidthAttr,
        maxBandwidth: +maxBandwidthAttr,
        accessibilities: this.buildAccessibilities(accessibilityNodes),
        roles: this.buildRoles(roleNodes),
        inbandEventStreams: this.buildInbandEventStreams(inbandEventStreamNodes),
        contentProtections: this.buildContentProtections(contentProtectionNodes),
        representations: []
      };

      if (
        this._configManager.manifest.dashParsePrftFromMpd &&
        contentType === EContentType.VIDEO &&
        producerReferenceTimeNode &&
        segmentTemplateNode &&
        !this._parsedPrft
      ) {
        this.parsePrft(periodRef.manifest, producerReferenceTimeNode, segmentTemplateNode);
      }

      // eslint-disable-next-line no-await-in-loop
      adaptation.representations = await this.buildRepresentations(
        adaptation,
        tags[ETagName.REPRESENTATION] || [],
        segmentTemplateNode,
        audioChannelConfigurationNodes,
        partialBaseUrl,
        codecsAttr,
        frameRateAttr
      );

      if (adaptation.representations.length === 0) {
        this._logger.debug(`Adaptation "${adaptation.id}" is not playable due to media capabilities`);
        adaptation.playable.set(EPlayable.MEDIA_CAPABILITIES, false);
      }

      if (!adaptation.maxBandwidth || !adaptation.minBandwidth) {
        const bandwidths: Array<number> = adaptation.representations
          .map((representation: IRepresentation) => representation.bandwidth)
          .sort((b1: number, b2: number) => b1 - b2);

        adaptation.minBandwidth = bandwidths[0];
        adaptation.maxBandwidth = bandwidths[bandwidths.length - 1];
      }

      adaptations.push(adaptation);
    }

    return adaptations;
  }

  private parsePrft(
    manifest: IManifest,
    prftNode: GenericXmlNode,
    segmentTemplateNode: GenericXmlNode
  ): void {
    // we only care about encode time for now
    if (prftNode.attrs['type'] === 'encoder') {
      const utcTimingNode: GenericXmlNode | undefined = prftNode.children?.[ETagName.UTC_TIMING]?.[0];

      // Peacock manifests only have UTC timing not NTP
      if (utcTimingNode && utcTimingNode.attrs['schemeIdUri'] === 'urn:mpeg:dash:utc:http-iso:2014') {
        const timescaleAttr: string = segmentTemplateNode.attrs['timescale'] || '1';
        const wallClockTimeAttr: string = prftNode.attrs['wallClockTime'] || '';
        const wallClockTimeSecs: number = getTimeFromDate(wallClockTimeAttr);
        const presentationTimeAttr: string = prftNode.attrs['presentationTime'] || '';
        const presentationTimeSecs: number = +presentationTimeAttr / +timescaleAttr;

        manifest.producerReferenceStartTime = wallClockTimeSecs - presentationTimeSecs;
        this._parsedPrft = true;
        this._logger.info(
          `Using producerReferenceStartTime from MPD: ${manifest.producerReferenceStartTime}`
        );
      } else {
        this._logger.warn('MPD::ProducerReferenceTime element specifies an unsupported wall clock format');
      }
    }
  }

  private buildInbandEventStreams(inbandEventStreamNodes: Array<GenericXmlNode>): Array<IInbandEventStream> {
    if (inbandEventStreamNodes.length === 0) return [];
    const inbandEventStreams: Array<IInbandEventStream> = [];

    for (
      let i: number = 0, eventStreamNodesLen: number = inbandEventStreamNodes.length;
      i < eventStreamNodesLen;
      i++
    ) {
      const inbandEventStreamNode: GenericXmlNode = inbandEventStreamNodes[i];
      const schemeIdUriAttr: string = inbandEventStreamNode.attrs['schemeIdUri'] || '';
      inbandEventStreams.push({
        schemeIdUri: schemeIdUriAttr
      });
    }

    return inbandEventStreams;
  }

  private buildOutbandEventStream(
    outbandEventStreamNode: GenericXmlNode,
    periodStart: number
  ): IOutbandEventStream {
    const schemeIdUriAttr: string = outbandEventStreamNode.attrs['schemeIdUri'] || '';
    const timescaleAttr: string = outbandEventStreamNode.attrs['timescale'] || '';
    const timescale: number = timescaleAttr ? +timescaleAttr : 1;

    const outbandEventStream: IOutbandEventStream = {
      schemeIdUri: schemeIdUriAttr,
      events: []
    };

    const eventNodes: Array<GenericXmlNode> = outbandEventStreamNode.children?.[ETagName.EVENT] || [];

    for (let y: number = 0, eventNodesLen: number = eventNodes.length; y < eventNodesLen; y++) {
      const eventNode: GenericXmlNode = eventNodes[y];
      const idAttr: string = eventNode.attrs['id'] || '';
      const durationAttr: string = eventNode.attrs['duration'] || '';
      const duration: number = durationAttr ? +durationAttr / timescale : 0;
      const presentationTimeAttr: string = eventNode.attrs['presentationTime'] || '';
      let presentationTime: number = 0;
      if (presentationTimeAttr) {
        presentationTime = +presentationTimeAttr / timescale;
      }
      presentationTime += periodStart;

      outbandEventStream.events.push({
        id: idAttr,
        eventDuration: duration,
        presentationTime,
        value: eventNode
      });
    }

    return outbandEventStream;
  }

  private async buildRepresentations(
    adaptationSetRef: Readonly<IAdaptationSet>,
    representationNodes: Array<GenericXmlNode>,
    adaptationSegmentTemplateNode: GenericXmlNode | undefined,
    adaptationAudioChannelConfigurationNodes: Array<GenericXmlNode>,
    partialBaseUrl: string,
    adaptationCodecs: string,
    adaptationFrameRate: string
  ): Promise<Array<IRepresentation>> {
    const representations: Array<IRepresentation> = [];

    if ([EContentType.AUDIO, EContentType.VIDEO].includes(adaptationSetRef.contentType)) {
      representationNodes.sort(this.sortAudioVideoRepresentations);
    }

    for (
      let i: number = 0, representationsLen: number = representationNodes.length;
      i < representationsLen;
      i++
    ) {
      const representationNode: GenericXmlNode = representationNodes[i];
      const idAttr: string = representationNode.attrs['id'] || '';
      const bandwidthAttr: string = representationNode.attrs['bandwidth'] || '';
      let codecsAttr: string = representationNode.attrs['codecs']?.toLowerCase() || adaptationCodecs;
      const widthAttr: string = representationNode.attrs['width'] || '';
      const heightAttr: string = representationNode.attrs['height'] || '';
      const frameRateAttr: string = representationNode.attrs['frameRate'] || '';
      const frameRate: number = this.getFrameRate(frameRateAttr) || this.getFrameRate(adaptationFrameRate);

      const tags: Record<string, GenericXmlNode[] | undefined> = representationNode.children;
      const audioChannelConfigurationNodes: Array<GenericXmlNode> =
        tags[ETagName.AUDIO_CHANNEL_CONFIGURATION] || adaptationAudioChannelConfigurationNodes || [];
      const audioChannelConfigurations: Array<IAudioChannelConfiguration> =
        this.buildAudioChannelConfigurations(audioChannelConfigurationNodes);

      const bandwidth: number = +bandwidthAttr;
      const width: number = +widthAttr || adaptationSetRef.maxWidth;
      const height: number = +heightAttr || adaptationSetRef.maxHeight;
      if (!codecsAttr) {
        codecsAttr = this.getCodecs(adaptationSetRef.mimeType);
      }
      const cp: IContentProtection | null =
        adaptationSetRef.contentProtections.filter(
          (cp: IContentProtection) => cp.keySystem === this._configManager.eme.keySystem
        )[0] ?? null;
      const channels: number =
        audioChannelConfigurations.filter((ac: IAudioChannelConfiguration) => ac.channels !== 0)[0]
          ?.channels ?? 0;
      // eslint-disable-next-line no-await-in-loop
      const isSupported: boolean = await this._probeCapabilities.isSupported(
        adaptationSetRef.contentType,
        cp,
        adaptationSetRef.mimeType,
        codecsAttr,
        width,
        height,
        frameRate,
        bandwidth,
        channels
      );

      if (!isSupported) {
        this._logger.debug(
          `Unsupported ${adaptationSetRef.contentType} representation: mimeType=${adaptationSetRef.mimeType} codecs=${codecsAttr} bandwidth=${bandwidth} width=${width} height=${height} keySystem=${cp?.keySystem}`
        );
        continue;
      }

      const representationSegmentTemplateNode: GenericXmlNode | undefined =
        tags[ETagName.SEGMENT_TEMPLATE]?.[0];
      const representationSegmentBaseNode: GenericXmlNode | undefined = tags[ETagName.SEGMENT_BASE]?.[0];

      const baseUrlNode: GenericXmlNode | undefined = tags[ETagName.BASE_URL]?.[0];
      let baseUrl: string = partialBaseUrl;
      if (baseUrlNode) {
        baseUrl = this.buildBaseUrl(baseUrlNode, partialBaseUrl);
      }

      const essentialPropertyNodes: Array<GenericXmlNode> = tags[ETagName.ESSENTIAL_PROPERTY] || [];
      const essentialProperties: Array<IEssentialProperty> =
        this.buildEssentialProperties(essentialPropertyNodes);
      const id: string = idAttr || i.toString();

      const timescaleAttr: string = this.getAttributeFromNodes(
        adaptationSegmentTemplateNode,
        representationSegmentTemplateNode,
        'timescale'
      );
      const timescale: number = timescaleAttr ? +timescaleAttr : 1;

      const presentationTimeOffsetAttr: string = this.getAttributeFromNodes(
        adaptationSegmentTemplateNode,
        representationSegmentTemplateNode,
        'presentationTimeOffset'
      );
      const presentationTimeOffset: number = presentationTimeOffsetAttr
        ? +presentationTimeOffsetAttr / timescale
        : 0;

      const representation: IRepresentation = {
        adaptation: adaptationSetRef,
        id,
        bandwidth,
        width,
        height,
        frameRate,
        presentationTimeOffset,
        codecs: codecsAttr,
        essentialProperties,
        audioChannelConfigurations,
        segments: [],
        buildSegments: null,
        timescale
      };

      if (representationSegmentBaseNode) {
        const baseSegments: [ISegment, ISegment] | null = this.buildBaseSegments(
          representation,
          representationSegmentBaseNode,
          baseUrl
        );
        if (baseSegments) {
          this._representationMap.set(id, [representation, [baseSegments[0], baseSegments[1]]]);
        }
      } else {
        representation.buildSegments = (offset: number): void => {
          representation.segments = this.buildSegments(
            representation,
            adaptationSegmentTemplateNode,
            representationSegmentTemplateNode,
            timescale,
            baseUrl,
            offset
          );
          representation.buildSegments = null;
        };
      }

      representations.push(representation);
    }

    return representations;
  }

  private buildAudioChannelConfigurations(
    audioChannelConfigurationNodes: Array<GenericXmlNode>
  ): Array<IAudioChannelConfiguration> {
    if (audioChannelConfigurationNodes.length <= 0) return [];

    const audioChannelConfigurations: Array<IAudioChannelConfiguration> = [];

    for (
      let i: number = 0, audioCfgLen: number = audioChannelConfigurationNodes.length;
      i < audioCfgLen;
      i++
    ) {
      const audioChannelConfigurationNode: GenericXmlNode = audioChannelConfigurationNodes[i];
      const schemeIdUriAttr: string = audioChannelConfigurationNode.attrs['schemeIdUri'] || '';
      const schemeIdUri: EAudioChannelSchemeUri = schemeIdUriAttr.toLowerCase() as EAudioChannelSchemeUri;
      const value: string = audioChannelConfigurationNode.attrs['value'] || '';

      audioChannelConfigurations.push({
        schemeIdUri,
        value,
        channels: getAudioChannels(schemeIdUri, value)
      });
    }

    return audioChannelConfigurations;
  }

  private buildAccessibilities(accessibilityNodes: Array<GenericXmlNode>): Array<IAccessibility> {
    if (accessibilityNodes.length <= 0) return [];

    const accessibilities: Array<IAccessibility> = [];

    for (
      let i: number = 0, accessibilitiesLen: number = accessibilityNodes.length;
      i < accessibilitiesLen;
      i++
    ) {
      const accessibilityNode: GenericXmlNode = accessibilityNodes[i];
      const schemeIdUriAttr: string = accessibilityNode.attrs['schemeIdUri'] || '';
      const valueAttr: string = accessibilityNode.attrs['value'] || '';

      accessibilities.push({
        schemeIdUri: schemeIdUriAttr.toLowerCase(),
        value: valueAttr
      });
    }

    return accessibilities;
  }

  private buildContentProtections(contentProtectionNodes: Array<GenericXmlNode>): Array<IContentProtection> {
    if (contentProtectionNodes.length <= 0) return [];

    const cencNode: GenericXmlNode = contentProtectionNodes[0];
    const defaultKeyId: string = cencNode.attrs['cenc:default_KID'] || '';
    const keyId: string = defaultKeyId.replace(/-/g, '').toLowerCase();

    const contentProtections: Array<IContentProtection> = [];

    for (
      let i: number = 1, contentProtectionsLen: number = contentProtectionNodes.length;
      i < contentProtectionsLen;
      i++
    ) {
      const contentProtectionNode: GenericXmlNode = contentProtectionNodes[i];
      const schemeIdUriAttr: string = contentProtectionNode.attrs['schemeIdUri'] || '';
      const schemeIdUri: string = schemeIdUriAttr.toLowerCase();

      const keySystem: EKeySystem | undefined = this._KEY_SYSTEM_MAP[schemeIdUri as ESchemeUri];
      if (!keySystem) continue;

      let initData: Uint8Array | null = null;
      const cencPsshNode: GenericXmlNode | undefined =
        contentProtectionNode.children?.[ETagName.CENC_PSSH]?.[0];

      if (cencPsshNode) {
        const pssh: string = getNodeText(cencPsshNode);
        if (pssh) {
          initData = base64ToUint8(pssh);
        }
      }

      const contentProtection: IContentProtection = {
        initData,
        keyId,
        keySystem,
        schemeIdUri
      };

      contentProtections.push(contentProtection);
    }

    return contentProtections;
  }

  private buildEssentialProperties(essentialPropertyNodes: Array<GenericXmlNode>): Array<IEssentialProperty> {
    if (essentialPropertyNodes.length <= 0) return [];

    const essentialProperties: Array<IEssentialProperty> = [];

    for (
      let i: number = 0, essentialPropertiesLen: number = essentialPropertyNodes.length;
      i < essentialPropertiesLen;
      i++
    ) {
      const essentialPropertyNode: GenericXmlNode = essentialPropertyNodes[i];
      const schemeIdUriAttr: string = essentialPropertyNode.attrs['schemeIdUri'] || '';
      const valueAttr: string = essentialPropertyNode.attrs['value'] || '';

      essentialProperties.push({
        schemeIdUri: schemeIdUriAttr.toLowerCase(),
        value: valueAttr
      });
    }

    return essentialProperties;
  }

  private buildLabel(labelNode: GenericXmlNode | undefined): string {
    if (!labelNode) return '';

    return getNodeText(labelNode);
  }

  private buildRoles(roleNodes: Array<GenericXmlNode>): Array<IRole> {
    if (roleNodes.length <= 0) return [];

    const roles: Array<IEssentialProperty> = [];

    for (let i: number = 0, rolesLen: number = roleNodes.length; i < rolesLen; i++) {
      const roleNode: GenericXmlNode = roleNodes[i];
      const schemeIdUriAttr: string = roleNode.attrs['schemeIdUri'] || '';
      const valueAttr: string = roleNode.attrs['value'] || '';

      roles.push({
        schemeIdUri: schemeIdUriAttr.toLowerCase(),
        value: valueAttr
      });
    }

    return roles;
  }

  private buildPatchLocation(patchLocationNode: GenericXmlNode | undefined): string | null {
    let patchLocation: string | null = null;
    if (patchLocationNode) {
      patchLocation = getNodeText(patchLocationNode);
    } else {
      patchLocation = this._configManager.manifest.dashPatchLocation;
    }

    return patchLocation;
  }

  private buildBaseSegments(
    representationRef: Readonly<IRepresentation>,
    representationSegmentBaseNode: GenericXmlNode,
    baseUrl: string
  ): [ISegment, ISegment] | null {
    const initializationNode: GenericXmlNode | undefined =
      representationSegmentBaseNode.children?.[ETagName.INITIALIZATION]?.[0];
    const initRangeAttr: string | null = initializationNode ? initializationNode.attrs['range'] : null;
    const baseRangeAttr: string | null = representationSegmentBaseNode.attrs['indexRange'];

    if (!initRangeAttr || !baseRangeAttr) return null;
    const initRange: [number, number] = this.getByteRange(initRangeAttr);
    const baseRange: [number, number] = this.getByteRange(baseRangeAttr);

    const baseSegment: ISegment = {
      representation: representationRef,
      id: 0,
      url: '',
      duration: 0,
      time: 0,
      byteRange: null
    };

    const initSegment: ISegment = {
      ...baseSegment,
      url: baseUrl,
      byteRange: initRange
    };

    const infoSegment: ISegment = {
      ...baseSegment,
      url: baseUrl,
      byteRange: baseRange
    };

    return [initSegment, infoSegment];
  }

  private buildSegments(
    representationRef: Readonly<IRepresentation>,
    adaptationSegmentTemplateNode: GenericXmlNode | undefined,
    representationSegmentTemplateNode: GenericXmlNode | undefined,
    timescale: number,
    baseUrl: string,
    offset: number
  ): Array<ISegment> {
    const segments: Array<ISegment> = [];
    const initializationAttr: string = this.getAttributeFromNodes(
      adaptationSegmentTemplateNode,
      representationSegmentTemplateNode,
      'initialization'
    );
    const mediaAttr: string = this.getAttributeFromNodes(
      adaptationSegmentTemplateNode,
      representationSegmentTemplateNode,
      'media'
    );
    const startNumberAttr: string = this.getAttributeFromNodes(
      adaptationSegmentTemplateNode,
      representationSegmentTemplateNode,
      'startNumber'
    );
    const startNumber: number = startNumberAttr ? +startNumberAttr : 0;
    const endNumberAttr: string = this.getAttributeFromNodes(
      adaptationSegmentTemplateNode,
      representationSegmentTemplateNode,
      'endNumber'
    );
    const endNumber: number = endNumberAttr ? +endNumberAttr : 0;
    const durationAttr: string = this.getAttributeFromNodes(
      adaptationSegmentTemplateNode,
      representationSegmentTemplateNode,
      'duration'
    );
    const duration: number = durationAttr ? +durationAttr : 0;

    let periodStart: number = representationRef.adaptation.period.start;
    const periodDuration: number = representationRef.adaptation.period.duration;
    const periodEnd: number = periodStart + periodDuration;

    const baseSegment: ISegment = {
      representation: representationRef,
      id: 0,
      url: '',
      duration: 0,
      time: 0,
      byteRange: null
    };

    if (initializationAttr) {
      const initSegment: ISegment = {
        ...baseSegment,
        url: this.createUrl(
          baseUrl,
          initializationAttr,
          representationRef.id,
          representationRef.bandwidth.toString(),
          undefined,
          undefined
        ),
        time: periodStart
      };
      segments.push(initSegment);
    }

    let segmentTimelineNodes: Array<GenericXmlNode> = [];
    if (adaptationSegmentTemplateNode || representationSegmentTemplateNode) {
      if (adaptationSegmentTemplateNode) {
        segmentTimelineNodes = adaptationSegmentTemplateNode.children?.[ETagName.SEGMENT_TIMELINE] || [];
      }

      if (representationSegmentTemplateNode && segmentTimelineNodes.length === 0) {
        segmentTimelineNodes = representationSegmentTemplateNode.children?.[ETagName.SEGMENT_TIMELINE] || [];
      }
    } else {
      // representation contains only baseUrl element and it's a media segment
      return [
        {
          ...baseSegment,
          id: 1,
          url: baseUrl,
          duration: periodDuration,
          time: periodEnd
        }
      ];
    }

    const segmentTimelineNode: GenericXmlNode = segmentTimelineNodes[0];

    let id: number = 0;

    if (segmentTimelineNode) {
      const segmentNodes: Array<GenericXmlNode> = segmentTimelineNode.children?.[ETagName.S] || [];
      const tAttr: string = segmentNodes[0].attrs['t'] || '';
      let t: number = tAttr ? +tAttr : 0;
      let n: number = startNumber;

      for (let i: number = 0, segmentNodesLen: number = segmentNodes.length; i < segmentNodesLen; i++) {
        const segNode: GenericXmlNode = segmentNodes[i];
        const rAttr: string = segNode.attrs['r'] || '';
        if (rAttr) {
          for (let x: number = 0, r: number = +rAttr; x <= r; x++) {
            id++;

            const dAttr: string = segNode.attrs['d'] || '';
            const d: number = dAttr ? +dAttr : 0;
            const duration: number = d / timescale;
            const startTime: number = t / timescale;
            const time: number = startTime - offset + duration + periodStart;

            const segment: ISegment = {
              ...baseSegment,
              id,
              url: this.createUrl(
                baseUrl,
                mediaAttr,
                representationRef.id,
                representationRef.bandwidth.toString(),
                t.toString(),
                n.toString()
              ),
              duration,
              time
            };

            t = t + d;

            segments.push(segment);
            n++;
          }
        } else {
          id++;

          const dAttr: string = segNode.attrs['d'] || '';
          const d: number = dAttr ? +dAttr : 0;
          const duration: number = d / timescale;
          const startTime: number = t / timescale;
          const time: number = startTime - offset + duration + periodStart;

          const segment: ISegment = {
            ...baseSegment,
            id,
            url: this.createUrl(
              baseUrl,
              mediaAttr,
              representationRef.id,
              representationRef.bandwidth.toString(),
              t.toString(),
              n.toString()
            ),
            duration,
            time
          };

          if (segmentNodes[i + 1]) {
            t = t + d;
          }

          segments.push(segment);
          n++;
        }
      }
    } else {
      const d: number = duration / timescale;
      let t: number = d;

      let start: number = startNumberAttr === '' ? 1 : startNumber;
      let end: number = endNumberAttr === '' ? Math.floor(periodDuration / d) : endNumber;
      if (startNumberAttr === '' && endNumberAttr === '') {
        end = end + 1;
      }

      if (end === Infinity) {
        const {publishTime, timeShiftBufferDepth} = representationRef.adaptation.period.manifest;
        if (publishTime && timeShiftBufferDepth) {
          end = Math.floor(publishTime / duration) - duration;
          start = end - timeShiftBufferDepth / duration + duration;
          periodStart = publishTime - timeShiftBufferDepth;
        }
      }

      if (end === Infinity) {
        return [];
      }

      for (let i: number = start; i <= end; i++) {
        id++;

        const segment: ISegment = {
          ...baseSegment,
          id,
          url: this.createUrl(
            baseUrl,
            mediaAttr,
            representationRef.id,
            representationRef.bandwidth.toString(),
            undefined,
            i.toString()
          ),
          duration: d,
          time: t + periodStart
        };

        if (i + 1 <= end) {
          t = t + d;
        }

        segments.push(segment);
      }
    }

    if (segments.length > 0) {
      const s: ISegment = segments[segments.length - 1];
      if (periodEnd !== Infinity && s.time !== periodEnd) {
        this._logger.debug(
          `Adjusting ${representationRef.adaptation.contentType} segment ${id} (${representationRef.adaptation.period.id}:${representationRef.bandwidth}) duration from ${s.time} to ${periodEnd}`
        );
        const start: number = s.time - s.duration;
        s.time = periodEnd;
        s.duration = periodEnd - start;
      }
    }

    return segments;
  }

  private createUrl(
    baseUrl: string,
    media: string,
    representationId: string,
    bandwidth: string = '',
    time: string = '',
    number: string = ''
  ): string {
    let url: string = media
      .replace('$RepresentationID$', representationId)
      .replace('$Bandwidth$', bandwidth)
      .replace('$Time$', time)
      .replace('$Number$', number);

    url = this.replacePadding(url, number);

    if (isAbsoluteUrl(url)) {
      return url;
    } else {
      return `${baseUrl}${url}`;
    }
  }

  private replacePadding(url: string, numStr: string): string {
    const paddingMatch: RegExpMatchArray | null = url.match(/\$Number%(\d+)d/);
    if (paddingMatch) {
      const padding: number = parseInt(paddingMatch[1], 10);
      const value: string = this.zeroPadToLength(numStr, padding);

      return url.replace(/\$Number.*\$/, value);
    }

    return url;
  }

  private zeroPadToLength(numStr: string, minStrLength: number): string {
    while (numStr.length < minStrLength) {
      numStr = '0' + numStr;
    }

    return numStr;
  }

  // sort representations by bitrate (highest to lowest)
  private sortAudioVideoRepresentations(rN1: GenericXmlNode, rN2: GenericXmlNode): number {
    const b1: number = +(rN1.attrs['bandwidth'] || '');
    const b2: number = +(rN2.attrs['bandwidth'] || '');

    return b2 - b1;
  }

  public fetchSegment(representationId: string): Promise<IHttpResponse> {
    const representationAndSegments: [IRepresentation, [ISegment, ISegment]] | undefined =
      this._representationMap.get(representationId);
    if (!representationAndSegments) return Promise.reject();

    const [representation, segments] = representationAndSegments;
    const mediaSegment: ISegment = segments[1];
    if (!mediaSegment.byteRange) return Promise.reject();

    return this._networkManager.request(
      mediaSegment.url,
      {type: ERequestType.MANIFEST, ref: representation.id},
      {
        byteRange: mediaSegment.byteRange
      }
    );
  }

  public parseSegment(data: ArrayBuffer, representationId: string): IManifest | null {
    if (!this._manifest) return null;

    const representationAndSegments: [IRepresentation, [ISegment, ISegment]] | undefined =
      this._representationMap.get(representationId);
    if (!representationAndSegments) return null;

    const [representation, segments] = representationAndSegments;

    if (!segments[0].byteRange) return null;

    representation.segments.push(segments[0]);

    const sidxOffset: number = segments[0].byteRange[1];
    new Mp4Parser(this._dispatcher)
      .fullBox('sidx', (box: IParsedBox) => {
        const parsedSidxBox: ISidx = Mp4Parser.parseSidx(box);
        const {earliestPresentationTime, firstOffset, timescale, references} = parsedSidxBox;

        let time: number = 0;
        let id: number = 1;
        let startByte: number = sidxOffset + box.size + firstOffset + 1;
        references.forEach((reference: ISidxReference) => {
          const {subsegmentDuration, referenceSize} = reference;
          const duration: number = (earliestPresentationTime + subsegmentDuration) / timescale;
          time += duration;
          const end: number = startByte + referenceSize - 1;
          const byteRange: [number, number] = [startByte, end];

          const segment: ISegment = {
            representation,
            id,
            url: segments[0].url,
            duration,
            time,
            byteRange
          };

          representation.segments.push(segment);

          id++;
          startByte = end + 1;
        });
      })
      .parse(data);

    return this._manifest;
  }

  /**
   * The goal is to traverse the xml just once
   * @param data
   * @returns
   */
  public async parseManifest(data: string): Promise<IManifest | null> {
    const ts: number = performance.now();
    const mpdNode: GenericXmlNode = {
      tagName: 'MPD',
      attrs: {},
      children: {}
    };
    const parser: XmlParser = new XmlParser(
      new GenericParserContext(mpdNode, {
        knownTags: DASH_TAGS
      })
    );

    parser.write(data);

    if (parser.stats.entered !== parser.stats.exited) {
      throw new Error('xml parser invalid state: entered!=exited');
    }

    this._logger.info('string parsing time (ms)', performance.now() - ts);

    const manifest: IManifest | null = await this.buildManifest(mpdNode);

    if (this._representationMap.size > 0) {
      this._manifest = manifest;
    }

    return manifest;
  }

  public destroy(): void {
    this._logger.info('Destroying DASH parser');

    this._manifest = null;
    this._representationMap.clear();
  }
}

export default DashParser;
