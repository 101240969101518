/* eslint-disable @typescript-eslint/no-explicit-any */
import ConfigManager from '@config/configManager';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import EContentType from '@parser/manifest/enum/EContentType';
import EKeySystem from '@parser/manifest/enum/EKeySystem';
import EMimeType from '@parser/manifest/enum/EMimeType';
import IContentProtection from '@parser/manifest/interfaces/IContentProtection';
import getMimeCodec from '@utils/getMimeCodec';

class ProbeCapabilities {
  private _logger: ILogger;
  private _probeCache: Map<string, {isSupported: boolean; bitrate: number}> = new Map();
  private _probedContentProtections: Array<string> = [];

  constructor(
    private _configManager: ConfigManager,
    loggerManager: LoggerManager,
    private _onContentProtectionEncountered: (cp: IContentProtection) => void
  ) {
    this._logger = loggerManager.registerLogger(ELogType.PROBE);
  }

  private createProbeCacheId(
    contentType: EContentType,
    codecs: string,
    frameRate: number,
    channels: number,
    keySystem?: EKeySystem
  ): string {
    const mainCodecs: string = codecs.split('.')[0];
    let configId: string = `${mainCodecs}|`;

    if (contentType === EContentType.VIDEO) {
      configId += `${frameRate}`;
    } else if (contentType === EContentType.AUDIO) {
      configId += `${channels}`;
    }

    if (keySystem) {
      configId += `|${keySystem}`;
    }

    return configId;
  }

  /**
   * We don't need to check every single representation:
   * we aim to check only the representation with the highest bitrate if the other representations have the same codecs and framerate/channels
   * @param {string} probeCacheId
   * @param {number} currentBitrate
   * @return {boolean | null} return the result from the cache, or null if there is nothing stored in the cache for the gived probeCacheId
   */
  private getProbeResultFromCache(probeCacheId: string, currentBitrate: number): boolean | null {
    const probeResultFromCache: {isSupported: boolean; bitrate: number} | undefined =
      this._probeCache.get(probeCacheId);

    if (probeResultFromCache === undefined) {
      return null;
    }

    if (probeResultFromCache.isSupported) {
      if (currentBitrate > probeResultFromCache.bitrate) {
        return null;
      } else {
        return true;
      }
    } else {
      if (currentBitrate >= probeResultFromCache.bitrate) {
        return false;
      } else {
        return null;
      }
    }
  }

  /**
   * note: audio and video representations are evaluated in order: from the highest to the lowest bitrate to minimize the number of calls.
   */
  public async isSupported(
    contentType: EContentType,
    contentProtection: IContentProtection | null,
    mimeType: EMimeType,
    codecs: string,
    width: number,
    height: number,
    frameRate: number,
    bitrate: number,
    channels: number
  ): Promise<boolean> {
    let isSupported: boolean = true;
    if (contentType === EContentType.AUDIO || contentType === EContentType.VIDEO) {
      const probeCacheId: string = this.createProbeCacheId(
        contentType,
        codecs,
        frameRate,
        channels,
        contentProtection?.keySystem
      );
      const mimeCodec: string = getMimeCodec(mimeType, codecs);
      const needProbeForContentProtection: boolean = Boolean(
        contentProtection?.keyId && !this._probedContentProtections.includes(contentProtection.keyId)
      );
      if (navigator.mediaCapabilities && 'decodingInfo' in navigator.mediaCapabilities) {
        const probeFromCache: boolean | null = this.getProbeResultFromCache(probeCacheId, bitrate);

        if (needProbeForContentProtection) {
          if (probeFromCache === false) {
            return Promise.resolve(probeFromCache);
          }
        } else {
          if (probeFromCache !== null) {
            return Promise.resolve(probeFromCache);
          }
        }

        const config: MediaDecodingConfiguration = {
          type: 'media-source'
        };

        switch (contentType) {
          case EContentType.VIDEO:
            config.video = {
              contentType: mimeCodec,
              width: width || 64,
              height: height || 64,
              bitrate,
              framerate: frameRate || 1
            };
            break;
          case EContentType.AUDIO:
            config.audio = {
              contentType: mimeCodec,
              bitrate,
              channels: (channels || 2) as unknown as string
            };
            break;
        }

        if (contentProtection?.keySystem) {
          (config as any).keySystemConfiguration = {
            keySystem: contentProtection.keySystem
          };

          const videoRobustness: string = this._configManager.eme.videoRobustness[0] ?? '';
          const audioRobustness: string = this._configManager.eme.audioRobustness[0] ?? '';

          switch (contentType) {
            case EContentType.VIDEO:
              (config as any).keySystemConfiguration.video = {robustness: videoRobustness};
              break;
            case EContentType.AUDIO:
              (config as any).keySystemConfiguration.audio = {robustness: audioRobustness};
              break;
          }
        }

        try {
          const decodingInfo: MediaCapabilitiesDecodingInfo = await navigator.mediaCapabilities.decodingInfo(
            config
          );

          isSupported = decodingInfo.supported;
          if (needProbeForContentProtection && isSupported && contentProtection) {
            this._probedContentProtections.push(contentProtection.keyId);
            this._logger.debug(
              `${contentType} representation is supported emitting content protection for: ${contentProtection.keyId}`
            );
            this._onContentProtectionEncountered(contentProtection);
          }
        } catch (e) {
          isSupported = false;
        }

        this._logger.debug(
          `${contentType} representation ${isSupported ? 'is' : 'is not'} supported (decodingInfo)`,
          config
        );

        this._probeCache.set(probeCacheId, {isSupported, bitrate});

        return isSupported;
      } else if (typeof MediaSource.isTypeSupported === 'function') {
        const probeFromCache: boolean | null = this.getProbeResultFromCache(probeCacheId, bitrate);
        if (probeFromCache !== null) {
          return Promise.resolve(isSupported);
        }

        let type: string = mimeCodec;
        if (contentType === EContentType.VIDEO && width && this._configManager.patch.useExtendedType) {
          type += `;width=${width}`;
        }

        isSupported = MediaSource.isTypeSupported(type);

        this._logger.debug(
          `${contentType} representation ${isSupported ? 'is' : 'is not'} supported (isTypeSupported)`,
          type
        );
        if (needProbeForContentProtection && isSupported && contentProtection) {
          this._logger.debug(
            `${contentType} representation is supported emitting content protection for: ${contentProtection.keyId}`
          );
          this._probedContentProtections.push(contentProtection.keyId);
          this._onContentProtectionEncountered(contentProtection);
        }

        this._probeCache.set(probeCacheId, {isSupported, bitrate});

        return Promise.resolve(isSupported);
      }
    }

    return Promise.resolve(isSupported);
  }

  public destroy(): void {
    this._logger.info('Destroying Probe Capabilities');

    this._probeCache.clear();
    this._probedContentProtections.length = 0;
  }
}

export default ProbeCapabilities;
