/**
 * Convert little-endian GUID into big-endian UUID.
 * @param {Uint8Array}
 * @returns {Uint8Array}
 */
function keyIdToUuid(keyId: Uint8Array): Uint8Array {
  const p1A: number = keyId[0];
  const p1B: number = keyId[1];
  const p1C: number = keyId[2];
  const p1D: number = keyId[3];

  const p2A: number = keyId[4];
  const p2B: number = keyId[5];

  const p3A: number = keyId[6];
  const p3B: number = keyId[7];

  const uuid: Uint8Array = new Uint8Array(16);
  // swapping byte endian on 4 bytes
  // [1, 2, 3, 4] => [4, 3, 2, 1]
  uuid[0] = p1D;
  uuid[1] = p1C;
  uuid[2] = p1B;
  uuid[3] = p1A;
  // swapping byte endian on 2 bytes
  // [5, 6] => [6, 5]
  uuid[4] = p2B;
  uuid[5] = p2A;
  // swapping byte endian on 2 bytes
  // [7, 8] => [8, 7]
  uuid[6] = p3B;
  uuid[7] = p3A;
  uuid.set(keyId.subarray(8, 16), 8);

  return uuid;
}

// Some platform implementation of EME returns key IDs as
// little-endian GUIDs, rather than big-endian or just plain array of
// bytes.
const getUuidFromKeyId = (keyId: Uint8Array): Uint8Array => {
  if (keyId.byteLength === 16) {
    return keyIdToUuid(keyId);
  }

  return keyId;
};

export default getUuidFromKeyId;
