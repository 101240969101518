/* eslint-disable @typescript-eslint/ban-ts-comment */
import ConfigManager from '@config/configManager';
import Dispatcher from '@dispatcher/dispatcher';
import EEvent from '@dispatcher/enum/EEvent';
import IDataSegment from '@downloader/segment/interfaces/IDataSegment';
import SegmentDownloaderManager from '@downloader/segment/segmentDownloaderManager';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import ManifestManager from '@manifest/manifestManager';
import NetworkManager from '@network/networkManager';
import EContentType from '@parser/manifest/enum/EContentType';
import EMimeType from '@parser/manifest/enum/EMimeType';
import IRepresentation from '@parser/manifest/interfaces/IRepresentation';
import ICue from '@parser/text/interfaces/ICue';
import TextParser from '@parser/text/textParser';
import binarySearchByTime from '@utils/binarySearch';
import adjustCueTimings from '@utils/cue/adjustCueTimings';
import getSafeMarginBehind from '@utils/getSafeMarginBehind';

import IBuffer from '../interfaces/IBuffer';
import IBufferUpdate from '../interfaces/IBufferUpdate';
import CueHandler from './cueHandler';

class TextBuffer implements IBuffer {
  private _logger: ILogger;
  private _segmentDownloaderManager: SegmentDownloaderManager;
  private _cueHandler: CueHandler;
  private _textParser: TextParser | null = null;
  private _textTrack: TextTrack | null = null;
  private _shouldRemoveBuffer: boolean = false;
  private _downloadedCuesCache: {segmentStart: number; segmentEnd: number; cues: Array<ICue>} | null = null;
  private _requiredBuffer: {behind: number; ahead: number} = {behind: 0, ahead: 0};

  private _tickInterval: number | null = null;
  private _TICK_INTERVAL: number = 500;

  constructor(
    private _videoElement: HTMLVideoElement,
    private _mediaSource: MediaSource,
    private _manifestManager: ManifestManager,
    private _networkManager: NetworkManager,
    private _configManager: ConfigManager,
    private _loggerManager: LoggerManager,
    private _dispatcher: Dispatcher
  ) {
    this._logger = this._loggerManager.registerLogger(ELogType.BUFFER);
    this._segmentDownloaderManager = new SegmentDownloaderManager(
      this._videoElement,
      this._mediaSource,
      this._manifestManager,
      this._networkManager,
      this._configManager,
      this._loggerManager,
      EContentType.TEXT,
      this.feedBuffer
    );
    this._cueHandler = new CueHandler(this._videoElement, this._logger, this._dispatcher);
    this._requiredBuffer.behind = getSafeMarginBehind(
      this._configManager.buffer,
      this._manifestManager.averageSegmentDuration
    );
    this._requiredBuffer.ahead = Math.max(
      this._configManager.buffer.bufferAhead,
      this._manifestManager.averageSegmentDuration
    );
  }

  private onTick = (): void => {
    if (!this._textTrack) return;
    this.feedBuffer();
    if (!this.isInCueCacheRange(this._videoElement.currentTime)) {
      this._segmentDownloaderManager.downloadSegments();
    }
  };

  private addCue(cue: ICue): void {
    if (!this._textTrack || this.getCueById(cue.id)) return;

    this._cueHandler.addCue(this._textTrack, cue);

    this.emitBufferUpdate();
  }

  private getCueById(cueId: string): TextTrackCue | null {
    if (!this._textTrack || !this._textTrack.cues) return null;
    if ('getCueById' in this._textTrack.cues) {
      return this._textTrack.cues.getCueById(cueId);
    } else {
      for (let i: number = 0; i < (this._textTrack.cues as TextTrackCueList).length; i++) {
        if ((this._textTrack.cues as TextTrackCueList)[i].id === cueId) {
          return this._textTrack.cues[i];
        }
      }
    }

    return null;
  }

  private canFeedBuffer(currentTime: number): boolean {
    const timeRange: [number, number] | null = this.getTextTrackRange(currentTime);
    if (!timeRange) {
      return true;
    }

    const endTime: number = timeRange[1];

    return (
      endTime - currentTime <
      Math.max(this._configManager.buffer.bufferAhead, this._manifestManager.averageSegmentDuration)
    );
  }

  private clearBuffer(startTime?: number, endTime?: number): boolean {
    if (!this._textTrack?.cues?.length) return false;

    const start: number = startTime ?? 0;

    let end: number | undefined = endTime;
    if (end === undefined) {
      end = this._textTrack.cues[this._textTrack.cues.length - 1].endTime;
    }

    if (start > end) return false;
    this._logger.debug(`Clear ${EContentType.TEXT} buffer from ${start.toFixed(2)}s to ${end.toFixed(2)}s`);

    const cuesToRemove: Array<TextTrackCue> = [];
    for (let i: number = 0; i < this._textTrack.cues.length; i++) {
      const cue: TextTrackCue = this._textTrack.cues[i];
      if ((start <= cue.startTime && cue.startTime <= end) || (start <= cue.endTime && cue.endTime <= end)) {
        cuesToRemove.push(cue);
      }
    }
    for (let y: number = 0; y < cuesToRemove.length; y++) {
      this._cueHandler.removeCue(this._textTrack, cuesToRemove[y]);
    }

    this._cueHandler.dispatchCuesChange();

    return true;
  }

  private feedBuffer = (): void => {
    const {currentTime} = this._videoElement;

    if (this.canFeedBuffer(currentTime)) {
      let cues: Array<ICue> = [];
      if (this._downloadedCuesCache && this.isInCueCacheRange(currentTime)) {
        cues = this._downloadedCuesCache.cues;
      } else {
        const currentDataSegment: IDataSegment | null = this._segmentDownloaderManager.getReadyDataSegment();
        if (currentDataSegment) {
          this._shouldRemoveBuffer = true;
          const textParser: TextParser = this.getTextParser(currentDataSegment.representation.codecs);
          if (!textParser.parser) return;

          this._segmentDownloaderManager.deleteReadyDataSegment();
          if (currentDataSegment.representation.adaptation.mimeType !== EMimeType.MP4) {
            cues = textParser.parser.parseText(currentDataSegment);
          } else {
            cues = textParser.parser.parseMp4(currentDataSegment);
          }
          cues = adjustCueTimings(cues, currentDataSegment, this._manifestManager);
          if (currentDataSegment.duration >= this._requiredBuffer.behind + this._requiredBuffer.ahead) {
            this._downloadedCuesCache = {
              segmentStart: currentDataSegment.time - currentDataSegment.duration,
              segmentEnd: currentDataSegment.time,
              cues
            };
          } else {
            this._downloadedCuesCache = null;
          }
        }
      }

      const indexToContinuePushFrom: number = this.getAnchorCueIndexToPushFrom(cues, currentTime);
      for (let i: number = indexToContinuePushFrom; i < cues.length; i++) {
        const textTrackRange: [number, number] | null = this.getTextTrackRange();
        if (textTrackRange && textTrackRange[1] - currentTime >= this._requiredBuffer.ahead) {
          break;
        }
        this.addCue(cues[i]);
      }
    }
    if (this._shouldRemoveBuffer) {
      const removed: boolean = this.removeBufferBehind(currentTime);
      this._shouldRemoveBuffer = !removed;
    }
  };

  private getAnchorCueIndexToPushFrom(cues: Array<ICue>, currentTime: number): number {
    if (this._textTrack && this._textTrack.cues?.length) {
      return (
        cues.findIndex(
          (cue: ICue) => cue.id === this._textTrack!.cues?.[this._textTrack!.cues.length - 1]?.id
        ) + 1
      );
    } else {
      return binarySearchByTime<ICue>(cues, currentTime, (cue: ICue) => cue.begin);
    }
  }

  private emitBufferUpdate(): void {
    const bufferRange: [number, number] | null = this.getTextTrackRange();
    if (!bufferRange) return;

    const [behind, ahead]: [number, number] = bufferRange;
    const bufferUpdate: IBufferUpdate = {
      contentType: EContentType.TEXT,
      buffered: null,
      behind,
      ahead
    };
    this._dispatcher.emit({
      name: EEvent.BUFFER_UPDATE,
      ...bufferUpdate
    });
  }

  private getTextParser(codecs: string): TextParser {
    if (!this._textParser) {
      this._textParser = new TextParser(codecs, this._loggerManager, this._dispatcher);
    }

    return this._textParser;
  }

  private init(): void {
    this._logger.info('Add text track');
    this._textTrack = this._videoElement.addTextTrack('subtitles', 'TAPE Text Track', 'generic');
    this._textTrack.mode = 'hidden';
    const currentPosition: number = this._videoElement.currentTime;
    this._segmentDownloaderManager.init(currentPosition);
    this._tickInterval = self.setInterval(this.onTick, this._TICK_INTERVAL);
  }

  private isInCueCacheRange(time: number): boolean {
    const range: [number, number] = this.getCueCacheRange(time);
    if (range[0] === 0 && range[1] === 0) {
      return false;
    }

    return true;
  }

  private getCueCacheRange(time?: number): [number, number] {
    if (!this._downloadedCuesCache || this._downloadedCuesCache.cues.length === 0) return [0, 0];

    return this.getRange(this._downloadedCuesCache.segmentStart, this._downloadedCuesCache.segmentEnd, time);
  }

  private getTextTrackRange(time?: number): [number, number] | null {
    if (!this._textTrack?.cues || this._textTrack.cues.length === 0) return null;
    const start: number = this._textTrack.cues[0].startTime;
    const end: number = this._textTrack.cues[this._textTrack.cues.length - 1].endTime;

    return this.getRange(start, end, time);
  }

  private getRange(start: number, end: number, time?: number): [number, number] {
    if (time) {
      if (start <= time && time <= end) {
        return [start, end];
      }
    } else {
      return [start, end];
    }

    return [0, 0];
  }

  private removeBufferBehind(currentTime: number): boolean {
    const timeRange: [number, number] | null = this.getTextTrackRange();
    if (!timeRange) return false;

    const startTime: number = timeRange[0];
    const endTime: number = currentTime - this._requiredBuffer.behind;

    if (currentTime - startTime >= this._requiredBuffer.behind) {
      return this.clearBuffer(startTime, endTime);
    }

    return false;
  }

  public onRepresentationChange(representation: IRepresentation | null): void {
    if (!representation) {
      if (this._textTrack) {
        this.destroy();
      }

      return;
    }

    if (!this._textTrack) {
      this.init();
    } else {
      this.reset();
      this._downloadedCuesCache = null;
    }
  }

  public getBufferRange(time?: number): [number, number] | null {
    return this.getTextTrackRange(time);
  }

  public reset(): void {
    if (!this._textTrack) return;
    this._segmentDownloaderManager.reset(!this.isInCueCacheRange(this._videoElement.currentTime));
    this.clearBuffer();
  }

  public destroy(): void {
    this._logger.info(`Destroying ${EContentType.TEXT} Buffer`);
    this.clearBuffer();
    this._downloadedCuesCache = null;

    this._textParser?.destroy();
    this._textParser = null;

    if (this._textTrack && this._configManager.patch.removeTextTrack) {
      // @ts-ignore
      this._videoElement.removeTrackById?.(this._textTrack.id);
    }
    this._textTrack = null;

    this._segmentDownloaderManager.destroy();
    this._cueHandler.destroy();

    if (this._tickInterval !== null) {
      self.clearInterval(this._tickInterval);
      this._tickInterval = null;
    }
  }
}

export default TextBuffer;
