import ECeaSchemeUri from '@parser/manifest/enum/ECeaSchemeUri';
import IAccessibility from '@parser/manifest/interfaces/IAccessibility';

const getCea = (accessibilities: Array<IAccessibility>): IAccessibility | null => {
  let accessibility: IAccessibility | null = null;
  for (let i: number = 0; i < accessibilities.length; i++) {
    if (
      [ECeaSchemeUri.CEA608, ECeaSchemeUri.CEA708].includes(accessibilities[i].schemeIdUri as ECeaSchemeUri)
    ) {
      accessibility = accessibilities[i];

      break;
    }
  }

  return accessibility;
};

export default getCea;
