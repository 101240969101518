const stringToUint8 = (text: string): Uint8Array => {
  const result: Uint8Array = new Uint8Array(text.length);
  for (let i: number = 0; i < text.length; i++) {
    result[i] = text.charCodeAt(i);
  }

  return result;
};

export default stringToUint8;
