const DEFAULT_BG_COLOR: string = 'black';
const DEFAULT_TXT_COLOR: string = 'white';
// Maximum number of rows in the buffer.
const CC_ROWS: number = 15;

const LINE_HEIGHT_MULTIPLIER: number = 5.33;
const LINE_WIDTH_MULTIPLIER_4_3: number = 2.5;
const LINE_WIDTH_MULTIPLIER_16_9: number = 1.9;

export {
  CC_ROWS,
  DEFAULT_BG_COLOR,
  DEFAULT_TXT_COLOR,
  LINE_HEIGHT_MULTIPLIER,
  LINE_WIDTH_MULTIPLIER_4_3,
  LINE_WIDTH_MULTIPLIER_16_9
};
