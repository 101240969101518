import ITapeConfig from '@config/interfaces/ITapeConfig';
import ICustomEvents from '@dispatcher/interfaces/ICustomEvents';
import IAudioDedupFilter from '@manifest/interfaces/IAudioDedupFilter';
import MseManager from '@mse/mseManager';
import IRequestFilter from '@network/interfaces/IRequestFilter';
import IResponseFilter from '@network/interfaces/IResponseFilter';

class Tape {
  private _mseManager: MseManager | null = null;

  constructor(videoElement: HTMLVideoElement, config?: ITapeConfig) {
    this._mseManager = new MseManager(videoElement, config);
  }

  public addEventListener<K extends keyof ICustomEvents>(
    eventName: K,
    callback: (event: ICustomEvents[K]) => void
  ): void {
    this._mseManager?.addEventListener(eventName, callback);
  }

  public removeEventListener<K extends keyof ICustomEvents>(
    eventName: K,
    callback: (event: ICustomEvents[K]) => void
  ): void {
    this._mseManager?.removeEventListener(eventName, callback);
  }

  public selectVideoTrack(bandwidth: number | null): void {
    this._mseManager?.selectVideoTrack(bandwidth);
  }

  public selectAudioTrack(id: string): void {
    this._mseManager?.selectAudioTrack(id);
  }

  public selectTextTrack(id: string | null): void {
    this._mseManager?.selectTextTrack(id);
  }

  public selectImageTrack(id: string | null): void {
    this._mseManager?.selectImageTrack(id);
  }

  public setAbrLimits({minBandwidth, maxBandwidth}: {minBandwidth: number; maxBandwidth: number}): void {
    this._mseManager?.setAbrLimits({minBandwidth, maxBandwidth});
  }

  public load(manifestUrl: string): void {
    this._mseManager?.load(manifestUrl);
  }

  public mute(): void {
    this._mseManager?.mute();
  }

  public unmute(): void {
    this._mseManager?.unmute();
  }

  public volume(volume: number): void {
    this._mseManager?.volume(volume);
  }

  public pause(): void {
    this._mseManager?.pause();
  }

  public play(): void {
    this._mseManager?.play();
  }

  public playbackRate(rate: number): void {
    this._mseManager?.playbackRate(rate);
  }

  public registerAudioDedupFilter(audioDedupFilter: IAudioDedupFilter): void {
    this._mseManager?.registerAudioDedupFilter(audioDedupFilter);
  }

  public registerRequestFilter(requestFilter: IRequestFilter): void {
    this._mseManager?.registerRequestFilter(requestFilter);
  }

  public registerResponseFilter(responseFilter: IResponseFilter): void {
    this._mseManager?.registerResponseFilter(responseFilter);
  }

  public seek(relativeTime: number): void {
    this._mseManager?.seek(relativeTime);
  }

  public seekTo(absoluteTime: number): void {
    this._mseManager?.seekTo(absoluteTime);
  }

  public async destroy(): Promise<void> {
    await this._mseManager?.destroy();
    this._mseManager = null;
  }
}

export default Tape;
