enum ECommandCode {
  // "RCL - Resume Caption Loading"
  RCL = 0x20,
  // "BS  - BackSpace"
  BS = 0x21,
  // "AOD - Unused (alarm off)"
  AOD = 0x22,
  // "AON - Unused (alarm on)"
  AON = 0x23,
  // "DER - Delete to End of Row"
  DER = 0x24,
  // "RU2 - Roll-Up, 2 rows"
  RU2 = 0x25,
  // "RU3 - Roll-Up, 3 rows"
  RU3 = 0x26,
  // "RU4 - Roll-Up, 4 rows"
  RU4 = 0x27,
  // "FON - Flash On"
  FON = 0x28,
  // "RDC - Resume Direct Captions"
  RDC = 0x29,
  // "TR - Text Restart"
  TR = 0x2a,
  // "RTD - Resume Text Display"
  RTD = 0x2b,
  // "EDM - Erase Displayed Mem"
  EDM = 0x2c,
  // "CR  - Carriage return"
  CR = 0x2d,
  // "ENM - Erase Nondisplayed Mem"
  ENM = 0x2e,
  // "EOC - End Of Caption (flip mem)"
  EOC = 0x2f
}

export default ECommandCode;
