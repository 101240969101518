import ICustomEvents from './interfaces/ICustomEvents';
import IEvents from './interfaces/IEvents';

class Dispatcher {
  constructor(private _videoElement: HTMLVideoElement) {}

  /**
   * Bus system is working emitting custom events from the video element itself
   */
  public emit<K extends keyof IEvents>(event: IEvents[K]): void {
    const customEvent: CustomEvent = new CustomEvent(event.name, {
      detail: {
        ...event
      }
    });
    this._videoElement?.dispatchEvent(customEvent);
  }

  public on<K extends keyof ICustomEvents>(
    eventName: K,
    callback: (customEvent: ICustomEvents[K]) => void,
    options?: AddEventListenerOptions
  ): void {
    this._videoElement?.addEventListener(eventName, callback as EventListener, options);
  }

  public off<K extends keyof ICustomEvents>(eventName: K, callback: (event: ICustomEvents[K]) => void): void {
    this._videoElement?.removeEventListener(eventName, callback as EventListener);
  }
}

export default Dispatcher;
