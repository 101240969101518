import ISegment from '@parser/manifest/interfaces/ISegment';

import EBatchStatus from '../enum/EBatchStatus';
import IBatch from '../interfaces/IBatch';
import IDataSegment from '../interfaces/IDataSegment';
import SingleSegmentDownloader from '../single/downloader';

class MultiSegmentDownloader extends SingleSegmentDownloader {
  private _isActive: boolean = false;

  private clearDataSegmentStatus(): void {
    for (let x: number = 0; x < this._batches.length; x++) {
      const batch: IBatch = this._batches[x];
      for (let y: number = 0; y < batch.segments.length; y++) {
        if (batch.status === EBatchStatus.DOWNLOADED) {
          const segment: IDataSegment = batch.segments[y];
          segment.appended = false;
        } else {
          return;
        }
      }
    }
  }

  protected hasReachedBatchLimits(): boolean {
    let time: number = 0;
    for (let x: number = 0; x < this._batches.length; x++) {
      const batch: IBatch = this._batches[x];
      for (let y: number = 0; y < batch.segments.length; y++) {
        const segment: IDataSegment = batch.segments[y];
        time += segment.duration;
      }
    }

    return time >= this._configManager.buffer.bufferAhead;
  }

  public get isActive(): boolean {
    return this._isActive;
  }

  protected onHttpTimeout = (segmentRef: ISegment): void => {
    this._logger.debug(`${this._contentType} segment timeout ${this.composeDebugMessage(segmentRef)}`, {
      ...segmentRef
    });

    const latestBatch: IBatch = this._batches[this._batches.length - 1];
    if (latestBatch.status !== EBatchStatus.DOWNLOADING) {
      this._logger.warn('Timeout occurred without any downloading segment');

      return;
    }

    const targetTime: number = latestBatch.segments[0].time - latestBatch.segments[0].duration;

    // remove only the latest batch
    this._batches.pop();

    this.downloadSegments(targetTime);
  };

  public override getReadyDataSegment(): IDataSegment | null {
    for (let x: number = 0; x < this._batches.length; x++) {
      const batch: IBatch = this._batches[x];
      if (batch.status !== EBatchStatus.DOWNLOADED) {
        return null;
      }
      for (let y: number = 0; y < batch.segments.length; y++) {
        const segment: IDataSegment = batch.segments[y];
        if (!segment.appended) {
          return segment;
        }
      }
    }

    return null;
  }

  public override deleteReadyDataSegment(): void {
    for (let x: number = 0; x < this._batches.length; x++) {
      const batch: IBatch = this._batches[x];
      if (batch.status !== EBatchStatus.DOWNLOADED) {
        return;
      }
      for (let y: number = 0; y < batch.segments.length; y++) {
        const segment: IDataSegment = batch.segments[y];
        if (!segment.appended) {
          segment.appended = true;

          return;
        }
      }
    }
  }

  public updateStatus(label: string): void {
    const isActive: boolean = label === this._label;
    // If it goes from active to inactive I have to clear the appended segment flag
    if (this._isActive && !isActive) {
      this.clearDataSegmentStatus();
    }
    this._isActive = isActive;
  }

  /**
   * Handle the eviction of the segments behind the playhead
   * @param time
   */
  public onTimeUpdate(time: number): void {
    let batchDeleteCount: number = 0;
    let segmentDeleteCount: number = 0;
    // the first batch contains the initialisation segment
    // if it's the first batch, we are going to remove segments
    // if it's NOT the first batch, we are going to remove the entire batch
    for (let x: number = 0; x < this._batches.length; x++) {
      const batch: IBatch = this._batches[x];
      if (batch.status !== EBatchStatus.DOWNLOADED) {
        break;
      }
      if (x === 0) {
        // first batch, evict segments behind play head
        for (let y: number = 0; y < this._batches[x].segments.length; y++) {
          const segment: IDataSegment = this._batches[x].segments[y];
          if (segment.id === 0) {
            continue;
          } else if (segment.time < time) {
            segmentDeleteCount++;
          }
        }
      } else {
        // NOT first batch, evict batches behind play head
        const lastSegmentInBatch: IDataSegment = batch.segments[batch.segments.length - 1];
        if (lastSegmentInBatch.time < time) {
          batchDeleteCount++;
          continue;
        } else {
          break;
        }
      }
    }

    if (segmentDeleteCount !== 0) {
      let startIndex: number = 1;
      if (this._batches[0].segments[0].id !== 0) {
        startIndex = 0;
      }
      this._batches[0].segments.splice(startIndex, segmentDeleteCount);
    }

    if (batchDeleteCount !== 0) {
      this._batches.splice(1, batchDeleteCount);
    }
  }

  public override reset(): void {
    // do nothing
  }

  public override destroy(): void {
    this._logger.info(`Destroying ${this._contentType} "${this._label}" Multi Segment Downloader`);
    this._isActive = false;

    this.abort();
  }
}
export default MultiSegmentDownloader;
