const getFileExtension = (uri: string): string => {
  let extension: string = '';
  const tokens: Array<string> = uri.split('?')[0].split('.');
  if (tokens.length > 1) {
    extension = tokens.pop() ?? '';
  }

  return extension;
};

export default getFileExtension;
