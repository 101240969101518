import EContentType from '@parser/manifest/enum/EContentType';
import EMimeType from '@parser/manifest/enum/EMimeType';
import IAccessibility from '@parser/manifest/interfaces/IAccessibility';
import IAdaptationSet from '@parser/manifest/interfaces/IAdaptationSet';
import IPeriod from '@parser/manifest/interfaces/IPeriod';
import IRepresentation from '@parser/manifest/interfaces/IRepresentation';
import IRole from '@parser/manifest/interfaces/IRole';

import getCea from './getCea';
import getStreamAndLanguages from './getStreamAndLanguages';

/**
 * Creates fake text (cea) adaptations from the video adaptation
 * @param adaptation
 * @returns
 */
const getCeaAdaptations = (videoAdaptation: IAdaptationSet): Array<IAdaptationSet> => {
  const adaptations: Array<IAdaptationSet> = [];
  const accessibility: IAccessibility | null = getCea(videoAdaptation.accessibilities);
  if (accessibility) {
    const streamAndLanguages: Array<[string, string]> = getStreamAndLanguages(accessibility);
    const role: IRole = {
      schemeIdUri: 'urn:mpeg:dash:role:2011',
      value: 'caption'
    };
    for (let i: number = 0; i < streamAndLanguages.length; i++) {
      const [id, lang] = streamAndLanguages[i];

      const adaptation: IAdaptationSet = {
        period: {} as Readonly<IPeriod>,
        id,
        playable: new Map(),
        contentType: EContentType.TEXT,
        mimeType: '' as EMimeType,
        label: '',
        lang,
        maxWidth: 0,
        maxHeight: 0,
        minBandwidth: 0,
        maxBandwidth: 0,
        accessibilities: [accessibility],
        roles: [role],
        inbandEventStreams: [],
        contentProtections: [],
        representations: []
      };
      const representation: IRepresentation = {
        adaptation,
        bandwidth: 0,
        codecs: '',
        height: 0,
        width: 0,
        frameRate: 0,
        timescale: 0,
        presentationTimeOffset: 0,
        id: `cea-representation-${id}-${lang}`,
        essentialProperties: [],
        audioChannelConfigurations: [],
        segments: [],
        buildSegments: null
      } as IRepresentation;
      adaptation.representations = [representation];

      adaptations.push(adaptation);
    }
  }

  return adaptations;
};

export default getCeaAdaptations;
