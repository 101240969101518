import IBufferUpdate from '@buffer/interfaces/IBufferUpdate';
import ConfigManager from '@config/configManager';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import IActiveRepresentationChange from '@manifest/interfaces/IActiveRepresentationChange';
import ManifestManager from '@manifest/manifestManager';
import ERequestType from '@network/enum/ERequestType';
import IHttpRequest from '@network/interfaces/IHttpRequest';
import EContentType from '@parser/manifest/enum/EContentType';
import EProtocol from '@parser/manifest/enum/EProtocol';
import ISegment from '@parser/manifest/interfaces/ISegment';
import EPlayerState from '@state/enum/EPlayerState';
import StateManager from '@state/stateManager';
import round from '@utils/round';

import ECmcdObjectType from './enum/ECmcdObjectType';
import ECmcdStreamingFormat from './enum/ECmcdStreamingFormat';
import ECmcdStreamType from './enum/ECmcdStreamType';
import ICmcdData from './interfaces/ICmcdData';
import ICmcdEncoder from './interfaces/ICmcdEncoder';
import ICmcdHeader from './interfaces/ICmcdHeader';

const encodeToken: ICmcdEncoder = (k: string, v: string | number | boolean) => `${k}=${v}`;
const encodeNumber: ICmcdEncoder = (k: string, v: string | number | boolean) => `${k}=${v}`;
const encodeString: ICmcdEncoder = (k: string, v: string | number | boolean) => `${k}="${v}"`;
const encodeBoolean: ICmcdEncoder = (k: string, v: string | number | boolean) => (v ? `${k}` : '');

class CmcdManager {
  private _logger: ILogger;

  private _VERSION: number = 1;
  private _HEADER_LOOKUP: {[key in keyof ICmcdData]: ICmcdHeader} = {
    cid: 'CMCD-Session',
    sid: 'CMCD-Session',
    v: 'CMCD-Session',
    st: 'CMCD-Session',
    sf: 'CMCD-Session',
    br: 'CMCD-Object',
    tb: 'CMCD-Object',
    bl: 'CMCD-Request',
    bs: 'CMCD-Status',
    d: 'CMCD-Object',
    mtp: 'CMCD-Request',
    rtp: 'CMCD-Status',
    ot: 'CMCD-Object',
    pr: 'CMCD-Session',
    su: 'CMCD-Request',
    dl: 'CMCD-Request'
  };
  private _ENCODER_LOOKUP: {
    [key in keyof ICmcdData]: ICmcdEncoder;
  } = {
    cid: encodeString,
    sid: encodeString,
    v: encodeToken,
    st: encodeToken,
    sf: encodeToken,
    br: encodeNumber,
    tb: encodeNumber,
    bl: encodeNumber,
    bs: encodeBoolean,
    d: encodeNumber,
    mtp: encodeNumber,
    rtp: encodeNumber,
    ot: encodeToken,
    pr: encodeNumber,
    dl: encodeNumber
  };

  private _streamFormat: ECmcdStreamingFormat | null = null;
  private _estimatedBandwidth: number | null = null;
  private _topEstimatedBandwidth: number | null = null;
  private _audioBuffered: TimeRanges | null = null;
  private _videoBuffered: TimeRanges | null = null;
  private _starved: boolean = true;
  private _streamType: ECmcdStreamType | null = null;
  private _videoIsPaused: boolean = true;
  private _videoPlaybackRate: number = 1;
  private _videoCurrentTime: number = 0;
  private _activeRepresentationBitrates: Record<EContentType, number | null> = {
    [EContentType.AUDIO]: null,
    [EContentType.VIDEO]: null,
    [EContentType.TEXT]: null,
    [EContentType.IMAGE]: null
  };
  private _lastRequestedDurations: Record<EContentType, number | null> = {
    [EContentType.AUDIO]: null,
    [EContentType.VIDEO]: null,
    [EContentType.TEXT]: null,
    [EContentType.IMAGE]: null
  };
  private _topBitrates: Record<EContentType, number | null> = {
    [EContentType.AUDIO]: null,
    [EContentType.VIDEO]: null,
    [EContentType.TEXT]: null,
    [EContentType.IMAGE]: null
  };

  constructor(
    private _manifestManager: ManifestManager,
    private _stateManager: StateManager,
    private _configManager: ConfigManager,
    loggerManager: LoggerManager
  ) {
    this._logger = loggerManager.registerLogger(ELogType.CMCD);
  }

  /**
   * This is here to enforce some logic specific to the spec that cannot
   * be easily expressed otherwise, i.e
   * - if version is 1 field shouldn't be included
   * - if playback rate is 1 fields shouldn't be included etc.
   */
  private getSanitizedData(options: {requestType: ERequestType}): Partial<ICmcdData> {
    const data: Partial<ICmcdData> = this.getRawData(options);

    if (data.v === 1) {
      delete data.v;
    }

    if (data.pr === 1) {
      delete data.pr;
    }

    const cmcdData: Partial<ICmcdData> = {};

    Object.keys(data).forEach((key: string) => {
      if (data[key as keyof ICmcdData] !== undefined && data[key as keyof ICmcdData] !== null) {
        cmcdData[key as 'cid'] = data[key as 'cid'];
      }
    });

    return cmcdData;
  }

  private getRawData({requestType}: {requestType: ERequestType}): ICmcdData {
    let ot: ECmcdObjectType | null = null;

    switch (requestType) {
      case ERequestType.AUDIO_SEGMENT:
        ot = ECmcdObjectType.AudioOnly;
        break;
      case ERequestType.VIDEO_SEGMENT:
        ot = ECmcdObjectType.VideoOnly;
        break;
      case ERequestType.CERTIFICATE:
        ot = ECmcdObjectType.CryptographicMaterial;
        break;
      case ERequestType.LICENSE:
        ot = ECmcdObjectType.CryptographicMaterial;
        break;
      case ERequestType.MANIFEST:
        ot = ECmcdObjectType.Text;
        break;
      case ERequestType.TEXT_SEGMENT:
        // maybe this is ECmcdObjectType.TimedTextTrack ??
        ot = ECmcdObjectType.CaptionOrSubtitle;
        break;
    }

    let mtp: number | undefined = undefined;
    if (this._estimatedBandwidth !== null) {
      mtp = this._estimatedBandwidth;
    }

    let rtp: number | undefined = undefined;
    if (this._topEstimatedBandwidth !== null) {
      rtp = this._topEstimatedBandwidth;
    }

    let pr: 0 | 1 | 2 | undefined = undefined;
    const videoElementPlaybackRate: number = this._videoPlaybackRate;
    const videoElementIsPaused: boolean = this._videoIsPaused;
    if (videoElementIsPaused) {
      pr = 0;
    } else if (videoElementPlaybackRate > 0 && videoElementPlaybackRate <= 1) {
      pr = 1;
    } else {
      pr = 2;
    }

    let bl: number | undefined = undefined;
    let dl: number | undefined = undefined;
    switch (requestType) {
      case ERequestType.AUDIO_SEGMENT:
        if (!this._audioBuffered || this._audioBuffered.length === 0) break;
        bl = Math.max(0, this._audioBuffered.end(this._audioBuffered.length - 1) - this._videoCurrentTime);
        break;
      case ERequestType.VIDEO_SEGMENT:
        if (!this._videoBuffered || this._videoBuffered.length === 0) break;
        bl = Math.max(0, this._videoBuffered.end(this._videoBuffered.length - 1) - this._videoCurrentTime);
        break;
    }
    if (bl) {
      dl = bl / this._videoPlaybackRate;
    }
    // round to 100milliseconds
    bl = bl === undefined ? bl : round(bl * 1000, 2);
    dl = dl === undefined ? dl : round(dl * 1000, 2);

    const bs: boolean = this._starved;

    let br: number | undefined = undefined;
    switch (requestType) {
      case ERequestType.AUDIO_SEGMENT:
        br = this._activeRepresentationBitrates[EContentType.AUDIO] || undefined;
        break;
      case ERequestType.VIDEO_SEGMENT:
        br = this._activeRepresentationBitrates[EContentType.VIDEO] || undefined;
        break;
    }
    // round to 100kbps
    br = br === undefined ? bl : round(br, 2);

    let dSec: number | undefined = undefined;
    switch (requestType) {
      case ERequestType.AUDIO_SEGMENT:
        dSec = this._lastRequestedDurations[EContentType.AUDIO] || undefined;
        break;
      case ERequestType.VIDEO_SEGMENT:
        dSec = this._lastRequestedDurations[EContentType.VIDEO] || undefined;
        break;
    }
    // convert to milliseconds
    const d: number | undefined = dSec ? dSec * 1000 : undefined;

    let tb: number | undefined = undefined;
    switch (requestType) {
      case ERequestType.AUDIO_SEGMENT:
        tb = this._topBitrates[EContentType.AUDIO] || undefined;
        break;
      case ERequestType.VIDEO_SEGMENT:
        tb = this._topBitrates[EContentType.VIDEO] || undefined;
        break;
    }

    // TODO: Figure out how to add 'nor' and 'nrr' fields in combination with batching
    return {
      cid: this._configManager.cmcd.contentId,
      sid: this._configManager.cmcd.sessionId,
      v: this._VERSION,
      st: this._streamType || undefined,
      sf: this._streamFormat || undefined,
      ot,
      mtp,
      rtp,
      pr,
      bl,
      bs,
      br,
      d,
      tb,
      dl
    };
  }

  public onActiveRepresentationChange = (activeRepresentationChange: IActiveRepresentationChange): void => {
    const {representation, contentType} = activeRepresentationChange;
    this._activeRepresentationBitrates[contentType] = representation?.bandwidth ?? null;
  };

  public getDataAsHeaders(options: {requestType: ERequestType}): Partial<Record<ICmcdHeader, string>> {
    const data: Partial<ICmcdData> = this.getSanitizedData(options);
    const headerMapping: Record<ICmcdHeader, Set<string>> = {
      'CMCD-Object': new Set(),
      'CMCD-Request': new Set(),
      'CMCD-Session': new Set(),
      'CMCD-Status': new Set()
    };
    for (const [k, v] of Object.entries(data)) {
      const targetHeader: ICmcdHeader = this._HEADER_LOOKUP[k as keyof ICmcdData] as ICmcdHeader;
      const encodedValue: string = (this._ENCODER_LOOKUP[k as keyof ICmcdData] as ICmcdEncoder)(k, v);
      if (!encodedValue) continue;
      headerMapping[targetHeader].add(encodedValue);
    }
    const result: Partial<Record<ICmcdHeader, string>> = {};
    for (const [header, valueSet] of Object.entries(headerMapping)) {
      const headerValue: string = Array.from(valueSet).join(',');
      if (headerValue) {
        result[header as ICmcdHeader] = headerValue;
      }
    }

    return result;
  }

  public getDataAsQueryParameter(options: {requestType: ERequestType}): [string, string] {
    const data: Partial<ICmcdData> = this.getSanitizedData(options);
    const chunks: string[] = [];
    for (const [k, v] of Object.entries(data)) {
      const encodedValue: string = (this._ENCODER_LOOKUP[k as keyof ICmcdData] as ICmcdEncoder)(k, v);
      if (!encodedValue) continue;
      chunks.push(encodedValue);
    }

    return ['CMCD', encodeURIComponent(chunks.join(','))];
  }

  public onEstimatedBandwidth = (estimatedBandwidth: number): void => {
    // estimatedBandwidth is in kbps, we just perform rounding to
    // nearest 100kbps value as in the CMCD spec
    this._estimatedBandwidth = round(estimatedBandwidth, 2);
    this._topEstimatedBandwidth = Math.max(this._topEstimatedBandwidth || 0, this._estimatedBandwidth);
  };

  public onBufferUpdate = (bufferUpdate: IBufferUpdate): void => {
    const {contentType, buffered} = bufferUpdate;
    switch (contentType) {
      case EContentType.AUDIO:
        this._audioBuffered = buffered;
        break;
      case EContentType.VIDEO:
        this._videoBuffered = buffered;
        break;
    }
  };

  public onHttpRequest = (httpRequestEvent: IHttpRequest): void => {
    const {request} = httpRequestEvent;
    if ([ERequestType.MANIFEST, ERequestType.CERTIFICATE, ERequestType.LICENSE].includes(request.type))
      return;

    this._starved = this._stateManager.playerState === EPlayerState.BUFFERING;
    this._videoIsPaused = this._stateManager.playerState === EPlayerState.PAUSED;

    const {
      representation: {
        adaptation: {contentType}
      },
      duration
    } = request.ref as ISegment;

    this._lastRequestedDurations[contentType] = duration;
  };

  public onManifestUpdate = (): void => {
    this._streamType = this._manifestManager.isLive() ? ECmcdStreamType.Live : ECmcdStreamType.VOD;

    switch (this._manifestManager.manifest.protocol) {
      case EProtocol.DASH:
        this._streamFormat = ECmcdStreamingFormat.DASH;
        break;
      case EProtocol.HLS:
        this._streamFormat = ECmcdStreamingFormat.HLS;
        break;
      case EProtocol.SMOOTH:
        this._streamFormat = ECmcdStreamingFormat.SMOOTH;
        break;
    }

    for (const period of this._manifestManager.manifest.periods) {
      for (const videoAdaptation of period.video) {
        this._topBitrates[EContentType.VIDEO] = Math.max(
          this._topBitrates[EContentType.VIDEO] || 0,
          videoAdaptation.maxBandwidth
        );
      }
      for (const audioAdaptation of period.audio) {
        this._topBitrates[EContentType.AUDIO] = Math.max(
          this._topBitrates[EContentType.AUDIO] || 0,
          audioAdaptation.maxBandwidth
        );
      }
    }
  };

  public onRateChange = (playbackRate: number): void => {
    this._videoPlaybackRate = playbackRate;
  };

  public onTimeUpdate = (currentTime: number): void => {
    this._videoCurrentTime = currentTime;
  };

  public destroy(): void {
    this._logger.info('Destroying CMCD manager');
  }
}

export default CmcdManager;
