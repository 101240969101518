enum EMimeType {
  MP4 = 'application/mp4',
  AUDIO = 'audio/mp4',
  VIDEO = 'video/mp4',
  TTML = 'text/ttml',
  WEBVTT = 'text/vtt',
  IMAGE = 'image/jpeg'
}

export default EMimeType;
