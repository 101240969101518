import Dispatcher from '@dispatcher/dispatcher';
import EEvent from '@dispatcher/enum/EEvent';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import IEssentialProperty from '@parser/manifest/interfaces/IEssentialProperty';
import IRepresentation from '@parser/manifest/interfaces/IRepresentation';
import ISegment from '@parser/manifest/interfaces/ISegment';

import IThumbnail from './interfaces/IThumbnail';

class ThumbnailManager {
  private _logger: ILogger;

  private _SCHEME_ID_URIS: Array<string> = [
    'http://dashif.org/thumbnail_tile',
    'http://dashif.org/guidelines/thumbnail_tile'
  ];

  constructor(loggerManager: LoggerManager, private _dispatcher: Dispatcher) {
    this._logger = loggerManager.registerLogger(ELogType.THUMBNAIL);
  }

  /**
   * convert sprites in array of thumbnails and emit the array
   * @param representations
   */
  public emitThumbnails(representations: Array<IRepresentation>): void {
    const thumbnails: Array<IThumbnail> = [];
    let index: number = 0;
    for (let i: number = 0; i < representations.length; i++) {
      const {segments, width, height, essentialProperties} = representations[i];

      const essentialProperty: IEssentialProperty = essentialProperties.filter(
        (ep: IEssentialProperty) => this._SCHEME_ID_URIS.indexOf(ep.schemeIdUri) >= 0
      )[0];

      if (essentialProperty) {
        const dimensions: Array<string> = essentialProperty.value.split('x');
        const horizontalTiles: number = +dimensions[0];
        const verticalTiles: number = +dimensions[1];
        const total: number = horizontalTiles * verticalTiles;
        const tileWidth: number = width / horizontalTiles;
        const tileHeight: number = height / verticalTiles;

        for (let i: number = 0; i < segments.length; i++) {
          let relativeTime: number = 0;
          const segment: ISegment = segments[i];
          const tileStart: number = segment.time - segment.duration;
          const tileDuration: number = segment.duration / total;
          let coordY: number = 0;
          for (let row: number = 0; row < verticalTiles; row++) {
            let coordX: number = 0;
            for (let column: number = 0; column < horizontalTiles; column++) {
              relativeTime = relativeTime + tileDuration;
              thumbnails.push({
                index,
                url: segment.url,
                width: tileWidth,
                height: tileHeight,
                duration: tileDuration,
                byteRange: segment.byteRange,
                x: coordX,
                y: coordY,
                time: tileStart + relativeTime
              });
              coordX = coordX + tileWidth;
              index++;
            }
            coordY = coordY + tileHeight;
          }
        }
      } else {
        for (let i: number = 0; i < segments.length; i++) {
          const {byteRange, duration, time, url} = segments[i];
          thumbnails.push({
            index,
            url,
            width,
            height,
            duration,
            byteRange,
            x: 0,
            y: 0,
            time
          });
          index++;
        }
      }
    }

    this._dispatcher.emit({
      name: EEvent.THUMBNAILS_UPDATE,
      thumbnails
    });
  }

  public reset(): void {
    this._dispatcher.emit({
      name: EEvent.THUMBNAILS_UPDATE,
      thumbnails: []
    });
  }

  public destroy(): void {
    this._logger.info('Destroying Thumbnail manager');
  }
}

export default ThumbnailManager;
