/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

import ConfigManager from '@config/configManager';
import Dispatcher from '@dispatcher/dispatcher';
import EEvent from '@dispatcher/enum/EEvent';

import ELogLevel from './enum/ELogLevel';
import ELogType from './enum/ELogType';
import ILogger from './interfaces/ILogger';

type noop = () => void;

class LoggerManager {
  constructor(private _configManager: ConfigManager, private _dispatcher: Dispatcher) {}

  private shouldLog(logLevel: ELogLevel, logType: ELogType): boolean {
    if (!this._configManager.logger.enabled) return false;
    if (this._configManager.logger.exclude.includes(logType)) return false;

    if (
      this._configManager.logger.include.length === 0 ||
      this._configManager.logger.include.includes(logType)
    ) {
      return logLevel >= this._configManager.logger.level;
    }

    return false;
  }

  private getPrefix(logType: ELogType): string {
    return `[${logType}]`;
  }

  private noop(): noop {
    return () => undefined;
  }

  private send<T>(consoleCallback: T, logLevel: ELogLevel, logType: ELogType): T | noop {
    if (this.shouldLog(logLevel, logType)) {
      if (this._configManager.logger.logThroughEvent) {
        return (...logs: any) => {
          this._dispatcher.emit({
            name: EEvent.TAPE_LOG,
            logs
          });
        };
      } else {
        return consoleCallback;
      }
    }

    return this.noop();
  }

  public registerLogger = (logType: ELogType): ILogger => {
    return {
      debug: this.send(console.debug.bind(self.console, this.getPrefix(logType)), ELogLevel.DEBUG, logType),
      log: this.send(console.log.bind(self.console, this.getPrefix(logType)), ELogLevel.LOG, logType),
      info: this.send(console.info.bind(self.console, this.getPrefix(logType)), ELogLevel.INFO, logType),
      warn: this.send(console.warn.bind(self.console, this.getPrefix(logType)), ELogLevel.WARN, logType),
      error: this.send(console.error.bind(self.console, this.getPrefix(logType)), ELogLevel.ERROR, logType)
    };
  };
}

export default LoggerManager;
