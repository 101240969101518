/* eslint-disable @typescript-eslint/ban-ts-comment */
import ConfigManager from '@config/configManager';
import Dispatcher from '@dispatcher/dispatcher';
import IDataSegment from '@downloader/segment/interfaces/IDataSegment';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import ManifestManager from '@manifest/manifestManager';
import EContentType from '@parser/manifest/enum/EContentType';
import IAccessibility from '@parser/manifest/interfaces/IAccessibility';
import IRepresentation from '@parser/manifest/interfaces/IRepresentation';
import CeaParser from '@parser/text/cea/parser';
import ICue from '@parser/text/interfaces/ICue';
import getStreamAndLanguages from '@utils/cea/getStreamAndLanguages';
import adjustCueTimings from '@utils/cue/adjustCueTimings';

import CueHandler from './cueHandler';

class CeaBuffer {
  private _logger: ILogger;
  private _parser: CeaParser;
  private _cueHandler: CueHandler;
  private _textTracks: Array<TextTrack> = [];
  private _streamAndLanguages: Array<[string, string]> = [];

  constructor(
    private _videoElement: HTMLVideoElement,
    private _manifestManager: ManifestManager,
    accessibility: IAccessibility,
    private _configManager: ConfigManager,
    loggerManager: LoggerManager,
    private _dispatcher: Dispatcher
  ) {
    this._logger = loggerManager.registerLogger(ELogType.BUFFER);
    this._parser = new CeaParser(loggerManager, this._dispatcher);
    this._cueHandler = new CueHandler(this._videoElement, this._logger, this._dispatcher);

    this._streamAndLanguages = getStreamAndLanguages(accessibility);
  }

  private updateTextTracksMode(lang: string | null): void {
    for (let i: number = 0; i < this._textTracks.length; i++) {
      const textTrack: TextTrack = this._textTracks[i];
      if (textTrack.language === lang) {
        textTrack.mode = 'hidden';
      } else {
        this.hideCues(textTrack);
        textTrack.mode = 'disabled';
      }
    }
  }

  private addCue(cue: ICue): void {
    const textTrack: TextTrack | null = this.getTrack(cue.lang);
    if (!textTrack) return;

    this._cueHandler.addCue(textTrack, cue);
  }

  private getTrack(lang: string): TextTrack | null {
    for (let i: number = 0; i < this._textTracks.length; i++) {
      const textTrack: TextTrack = this._textTracks[i];
      if (textTrack.language === lang) {
        return textTrack;
      }
    }

    return null;
  }

  private hideCues(textTrack: TextTrack): void {
    if (!textTrack?.cues) return;

    this._logger.debug(`Hide ${EContentType.TEXT} cues: ${textTrack.language}`);

    if (textTrack.activeCues) {
      for (let i: number = 0; i < textTrack.activeCues?.length; i++) {
        const cue: TextTrackCue = textTrack.activeCues[i];
        this._cueHandler.removeCue(textTrack, cue, false);
      }
    }
  }

  public clearBuffer(endTime: number = Infinity): void {
    for (let x: number = 0; x < this._textTracks.length; x++) {
      this._logger.debug(`Clear ${EContentType.TEXT} buffer: ${this._textTracks[x].language} to ${endTime}`);

      const textTrackCueList: TextTrackCueList | null = this._textTracks[x].cues;
      if (textTrackCueList) {
        const cueToRemove: Array<TextTrackCue> = [];
        for (let y: number = 0; y < textTrackCueList.length; y++) {
          const cue: TextTrackCue = textTrackCueList[y];
          if (cue.endTime <= endTime) {
            cueToRemove.push(cue);
          }
        }

        for (let y: number = 0; y < cueToRemove.length; y++) {
          this._cueHandler.removeCue(this._textTracks[x], cueToRemove[y]);
        }
      }
    }
    this._cueHandler.dispatchCuesChange();
  }

  public onRepresentationChange = (representation: IRepresentation | null): void => {
    let lang: string | null = null;

    if (representation !== null) {
      lang = representation.adaptation.lang;
    }
    this.updateTextTracksMode(lang);
  };

  public clear = (): void => {
    this.clearBuffer();
    this._parser.clear();
  };

  public feedBuffer = (dataSegment: IDataSegment): void => {
    const cues: Array<ICue> = this._parser.parse(dataSegment, this._streamAndLanguages);
    adjustCueTimings(cues, dataSegment, this._manifestManager);

    for (let i: number = 0; i < cues.length; i++) {
      this.addCue(cues[i]);
    }
  };

  public init(): void {
    this._logger.info('Add text track');
    for (let i: number = 0; i < this._streamAndLanguages.length; i++) {
      const [, lang] = this._streamAndLanguages[i];

      this._textTracks[i] = this._videoElement.addTextTrack('subtitles', 'TAPE Text Track', lang);
    }
    this.updateTextTracksMode(this._configManager.playback.preferredTextLanguage);
  }

  public destroy(): void {
    this._logger.info(`Destroying CEA Buffer`);
    this.clearBuffer();

    if (this._configManager.patch.removeTextTrack) {
      for (let i: number = 0; i < this._textTracks.length; i++) {
        // @ts-ignore
        this._videoElement.removeTrackById?.(this._textTracks[i].id);
      }
    }
    this._textTracks.length = 0;

    this._parser.destroy();
    this._cueHandler.destroy();
  }
}

export default CeaBuffer;
