import ConfigManager from '@config/configManager';
import Dispatcher from '@dispatcher/dispatcher';
import EEvent from '@dispatcher/enum/EEvent';
import ENativeEvent from '@dispatcher/enum/ENativeEvent';
import EErrorCode from '@error/enum/EErrorCode';
import EErrorSeverity from '@error/enum/EErrorSeverity';
import EErrorType from '@error/enum/EErrorType';
import IHttpError from '@error/interfaces/IHttpError';
import ILogger from '@logger/interfaces/ILogger';
import ERequestType from '@network/enum/ERequestType';
import IHttpResponse from '@network/interfaces/IHttpResponse';
import NetworkManager from '@network/networkManager';
import IContentProtection from '@parser/manifest/interfaces/IContentProtection';
import EPlayerState from '@state/enum/EPlayerState';
import StateManager from '@state/stateManager';
import stringToUint8 from '@utils/stringToUint8';
import uint8ToString from '@utils/uint8ToString';
import uintToHex from '@utils/uintToHex';

import IEme from '../interfaces/IEme';

// https://www.w3.org/TR/2017/REC-encrypted-media-20170918/
class Fairplay implements IEme {
  private _contentProtections: Array<IContentProtection> = [];
  private _pendingContentProtections: Array<IContentProtection> = [];
  private _mediaKeys: MediaKeys | null = null;
  private _mediaKeySessions: Map<
    MediaKeySession,
    [EventListenerOrEventListenerObject, EventListenerOrEventListenerObject]
  > = new Map();
  private _keysNeeded: Array<string> = [];
  private _keyIdKeyStatusMap: Map<string, string> = new Map();
  private _serverCertificate: Uint8Array | null = null;
  private _encryptedEvents: number = 0;

  constructor(
    private _videoElement: HTMLVideoElement,
    private _networkManager: NetworkManager,
    private _stateManager: StateManager,
    private _configManager: ConfigManager,
    private _logger: ILogger,
    private _dispatcher: Dispatcher,
    private _onEmeReady: () => void,
    private _onEmeKeyStatusesChanged: (keyStatuses: Map<string, string>) => void
  ) {
    this._logger.debug('Using EME_HLS');
  }

  private removeListeners(): void {
    this._videoElement.removeEventListener(ENativeEvent.ENCRYPTED, this.onEncrypted);
  }

  private onEncrypted = (e: MediaEncryptedEvent): void => {
    this._encryptedEvents++;
    if (this._encryptedEvents === this._contentProtections.length) {
      this._videoElement.removeEventListener(ENativeEvent.ENCRYPTED, this.onEncrypted);
    }
    const {initDataType, initData} = e;
    if (initDataType !== 'sinf' || !initData) return;
    this._logger.log(`Encrypted event: ${initDataType}`);
    const mediaKeys: MediaKeys | null = (e.target as HTMLVideoElement).mediaKeys;
    if (!mediaKeys) return;

    this.createSession(mediaKeys, initDataType, initData, this._contentProtections[0].keyId);
  };

  private onKeyStatusesChanged = (e: Event): void => {
    const mediaKeySession: MediaKeySession = e.target as MediaKeySession;
    this._logger.debug(
      `Media key statuses changed event: ${mediaKeySession.sessionId}, size ${mediaKeySession.keyStatuses.size}`
    );

    let hasStatusChanged: boolean = false;
    mediaKeySession.keyStatuses.forEach((status: MediaKeyStatus, keyStatusKeyId: BufferSource) => {
      // Some devices could have the entries reversed compared to the current EME spec.
      if (typeof keyStatusKeyId === 'string') {
        const tmp: string = keyStatusKeyId;
        keyStatusKeyId = status as unknown as BufferSource;
        status = tmp as MediaKeyStatus;
      }

      const keyId: Uint8Array = new Uint8Array(keyStatusKeyId as ArrayBuffer);
      const keyIdHex: string = uintToHex(keyId);

      this._logger.debug(`Key status for keyId ${keyIdHex} is ${status}`);
      const keyIndex: number = this._keysNeeded.findIndex((key: string) => key === keyIdHex);
      if (keyIndex === -1) {
        this._logger.debug(`Found unexpected media session keyId ${keyIdHex}`);

        return;
      }

      if (this._keyIdKeyStatusMap.get(keyIdHex) !== status) {
        this._keyIdKeyStatusMap.set(keyIdHex, status);
        hasStatusChanged = true;
      }
    });

    if (hasStatusChanged) {
      this._onEmeKeyStatusesChanged(this._keyIdKeyStatusMap);
    }
  };

  private onMessage = (e: Event, mediaKeySession: MediaKeySession, keyId: string): void => {
    const {message, messageType} = e as MediaKeyMessageEvent;
    this._logger.debug(`Media key message event: ${mediaKeySession.sessionId}`, messageType);

    const arrayMessage: Uint8Array = new Uint8Array(message);
    const msg: string = String.fromCharCode.apply(null, arrayMessage as unknown as Array<number>);
    const spc: string = self.btoa(msg).replace(/\+/g, '-').replace(/\//g, '_');
    const assetId: string = encodeURIComponent(keyId);
    const body: string = `spc=${spc}&assetId=${assetId}`;

    this._networkManager
      .request(
        this._configManager.eme.licenseServer,
        {type: ERequestType.LICENSE},
        {
          method: 'POST',
          body,
          headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }
      )
      .then(
        (r: IHttpResponse) => this.onLicenseHttpResponse(r, mediaKeySession),
        (_: IHttpError) => {}
      );
  };

  private onLicenseHttpResponse = (httpResponse: IHttpResponse, mediaKeySession: MediaKeySession): void => {
    const {data} = httpResponse;
    const responseText: string = uint8ToString(data as Uint8Array).trim();
    const {ckc} = JSON.parse(responseText);
    const raw: string = self.atob(ckc);

    const license: Uint8Array = stringToUint8(raw);

    mediaKeySession
      .update(license)
      .then(() => this._logger.debug(`Message/license updated successfully: ${mediaKeySession.sessionId}`))
      .catch((err: Error) => {
        const message: string = 'update() failed';
        this._logger.error(message, mediaKeySession.sessionId, err);
        this._dispatcher.emit({
          name: EEvent.TAPE_ERROR,
          type: EErrorType.EME,
          code: EErrorCode.MEDIA_KEY_SESSION_UPDATE,
          severity: EErrorSeverity.FATAL,
          message,
          nativeError: err
        });
      });
  };

  private onCertificateHttpResponse = (httpResponse: IHttpResponse): void => {
    this._serverCertificate = new Uint8Array(httpResponse.data as ArrayBuffer);

    this.requestMediaKeySystemAccess('sinf', this._serverCertificate);
  };

  private createSession(
    mediaKeys: MediaKeys,
    initDataType: string,
    initData: ArrayBuffer,
    keyId: string
  ): void {
    const mediaKeySession: MediaKeySession = mediaKeys.createSession();
    const onMessage: EventListenerOrEventListenerObject = (e: Event) =>
      this.onMessage(e, mediaKeySession, keyId);
    const onKeyStatusesChanged: EventListenerOrEventListenerObject = (e: Event) =>
      this.onKeyStatusesChanged(e);
    mediaKeySession.addEventListener('message', onMessage);
    mediaKeySession.addEventListener('keystatuseschange', onKeyStatusesChanged);
    this._mediaKeySessions.set(mediaKeySession, [onMessage, onKeyStatusesChanged]);
    this.generateRequest(mediaKeySession, initDataType, initData);
  }

  private generateRequest(
    mediaKeySession: MediaKeySession,
    initDataType: string,
    initData: ArrayBuffer
  ): void {
    this._logger.debug(`Generate '${initDataType}' request`, initData);

    mediaKeySession
      .generateRequest(initDataType, new Uint8Array(initData))
      .then(() => {
        this._logger.debug(`Media request generated successfully: ${mediaKeySession.sessionId}`);
      })
      .catch((err: Error) => {
        const message: string = 'Unable to create or initialize key session';
        this._logger.error(message, mediaKeySession.sessionId, err);
        this._dispatcher.emit({
          name: EEvent.TAPE_ERROR,
          type: EErrorType.EME,
          code: EErrorCode.INITIALIZE_KEY_SESSION,
          severity: EErrorSeverity.FATAL,
          message,
          nativeError: err
        });
      });
  }

  private processPendingContentProtections = (): void => {
    if (this._pendingContentProtections.length === 0) return;

    this._logger.debug('process pending content protections');
    this._pendingContentProtections.forEach((pendingContentProtection: IContentProtection) => {
      this.onContentProtectionEncountered(pendingContentProtection);
    });
  };

  private requestMediaKeySystemAccess(initDataType: string, serverCertificate: ArrayBuffer): void {
    const mediaKeySystemConfiguration: Array<MediaKeySystemConfiguration> = [
      {
        initDataTypes: [initDataType],
        videoCapabilities: [{contentType: 'video/mp4', robustness: ''}],
        distinctiveIdentifier: 'not-allowed',
        persistentState: 'not-allowed',
        sessionTypes: ['temporary']
      }
    ];
    navigator
      .requestMediaKeySystemAccess('com.apple.fps' as string, mediaKeySystemConfiguration)
      .then((keySystemAccess: MediaKeySystemAccess) =>
        this.createMediaKeys(keySystemAccess, serverCertificate).then(this.processPendingContentProtections)
      )
      .catch((err: Error) => {
        const message: string = 'Request media key system access failed';
        this._logger.error(message, err);
        this._dispatcher.emit({
          name: EEvent.TAPE_ERROR,
          type: EErrorType.EME,
          code: EErrorCode.REQUEST_MEDIA_KEY_ACCESS,
          severity: EErrorSeverity.FATAL,
          message,
          nativeError: err
        });
      });
  }

  private createMediaKeys(
    keySystemAccess: MediaKeySystemAccess,
    serverCertificate: ArrayBuffer
  ): Promise<MediaKeys> {
    this._logger.debug('Create media keys');

    return keySystemAccess
      .createMediaKeys()
      .then((createdMediaKeys: MediaKeys) => {
        if (this._stateManager.playerState === EPlayerState.STOPPED) return Promise.reject();

        this._logger.log('Set server certificate');
        createdMediaKeys.setServerCertificate(serverCertificate);

        this._logger.debug('Set media keys');
        this._videoElement.setMediaKeys(createdMediaKeys);

        this._mediaKeys = createdMediaKeys;
        this._onEmeReady();

        return createdMediaKeys;
      })
      .catch((err: Error) => {
        const message: string = 'Unable to create MediaKeys';
        this._logger.error(message, err);
        this._dispatcher.emit({
          name: EEvent.TAPE_ERROR,
          type: EErrorType.EME,
          code: EErrorCode.CREATE_MEDIA_KEYS,
          severity: EErrorSeverity.FATAL,
          message,
          nativeError: err
        });

        return Promise.reject();
      });
  }

  private requestServerCertificate(): void {
    if (this._configManager.eme.certificateServer) {
      this._logger.debug('Request server certificate');
      this._networkManager
        .request(this._configManager.eme.certificateServer, {type: ERequestType.CERTIFICATE})
        .then(this.onCertificateHttpResponse, (_: IHttpError) => {});
    } else {
      const message: string = 'Certificate url is not provided';
      this._logger.error(message);
      this._dispatcher.emit({
        name: EEvent.TAPE_ERROR,
        type: EErrorType.INTERNAL,
        code: EErrorCode.CERTIFICATE_ERROR,
        severity: EErrorSeverity.FATAL,
        message
      });

      return;
    }
  }

  public onContentProtectionEncountered(cp: IContentProtection): void {
    if (!this._mediaKeys) {
      this._logger.debug('Still waiting for media keys to be created');

      this._pendingContentProtections.push(cp);

      return;
    }

    this._logger.debug('onContentProtectionEncountered');
    this._contentProtections.push(cp);
    this._keysNeeded.push(cp.keyId);

    if (!cp.initData) return;
    this.createSession(this._mediaKeys, 'sinf', cp.initData.buffer, cp.keyId);
  }

  public onManifestParsed(cps: Array<IContentProtection>): void {
    if (cps.filter((cp: IContentProtection) => Boolean(cp.initData)).length === 0) {
      this._videoElement.addEventListener(ENativeEvent.ENCRYPTED, this.onEncrypted);
    }

    if (this._pendingContentProtections.length > 0 || cps.length === this._contentProtections.length) {
      this._logger.debug(
        `EME has been initialized for all ${this._configManager.eme.keySystem} content protections, skipping regular flow`
      );

      return;
    }

    this._logger.debug('onManifestParsed');
    for (let i: number = 0; i < cps.length; i++) {
      this.onContentProtectionEncountered(cps[i]);
    }
  }

  public init(): void {
    this._logger.debug('Init');

    this.requestServerCertificate();
  }

  public destroy(): void {
    this._logger.info('Destroying EME_HLS');
    this.removeListeners();

    this._contentProtections.length = 0;
    this._pendingContentProtections.length = 0;
    this._serverCertificate = null;
    this._mediaKeys = null;

    this._mediaKeySessions.forEach(
      (
        [onMessage, onKeyStatusesChanged]: [
          EventListenerOrEventListenerObject,
          EventListenerOrEventListenerObject
        ],
        mediaKeySession: MediaKeySession
      ) => {
        mediaKeySession.removeEventListener('message', onMessage);
        mediaKeySession.removeEventListener('keystatuseschange', onKeyStatusesChanged);

        if (!mediaKeySession.sessionId) return;

        mediaKeySession
          .close()
          .then(() => {
            this._logger.info(`Closed EME key request: ${mediaKeySession.sessionId}`);
          })
          .catch((e: Error) => {
            const message: string = 'Error closing EME key request';
            this._logger.warn(message, mediaKeySession.sessionId, e);
            this._dispatcher.emit({
              name: EEvent.TAPE_ERROR,
              type: EErrorType.EME,
              code: EErrorCode.CLOSE_MEDIA_KEY_SESSION,
              severity: EErrorSeverity.WARN,
              message,
              nativeError: e
            });
          });
      }
    );
  }
}

export default Fairplay;
