import ConfigManager from '@config/configManager';
import Dispatcher from '@dispatcher/dispatcher';
import EEvent from '@dispatcher/enum/EEvent';
import {RESTRICTED_EME_STATUSES} from '@eme/emeConstants';
import EMediaKeyStatus from '@eme/enum/EMediaKeyStatus';
import EErrorCode from '@error/enum/EErrorCode';
import EErrorSeverity from '@error/enum/EErrorSeverity';
import EErrorType from '@error/enum/EErrorType';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import IActiveRepresentationChange from '@manifest/interfaces/IActiveRepresentationChange';
import IAvailableRepresentations from '@manifest/interfaces/IAvailableRepresentations';
import IRepresentationChange from '@manifest/interfaces/IRepresentationChange';
import ManifestManager from '@manifest/manifestManager';
import NetworkManager from '@network/networkManager';
import EContentType from '@parser/manifest/enum/EContentType';
import IContentProtection from '@parser/manifest/interfaces/IContentProtection';
import ISeekableRange from '@presentation/interfaces/ISeekableRange';
import getCea from '@utils/cea/getCea';

import IBufferUpdate from './interfaces/IBufferUpdate';
import IGap from './interfaces/IGap';
import AVBuffer from './media/avBuffer';
import TextBuffer from './text/textBuffer';

class BufferManager {
  private _logger: ILogger;
  private _videoBuffer: AVBuffer | null = null;
  private _audioBuffer: AVBuffer | null = null;
  private _textBuffer: TextBuffer | null = null;
  private _gap: IGap | null = null;
  private _timeout: number = 0;
  private _emeReady: boolean = false;

  private _END_OF_STREAM_THRESHOLD: number = 0.1;

  constructor(
    private _videoElement: HTMLVideoElement,
    private _mediaSource: MediaSource,
    private _manifestManager: ManifestManager,
    private _networkManager: NetworkManager,
    private _configManager: ConfigManager,
    private _loggerManager: LoggerManager,
    private _dispatcher: Dispatcher,
    private _onBufferUpdate: (b: IBufferUpdate) => void,
    private _onActiveRepresentationChange: (ar: IActiveRepresentationChange) => void,
    private _onSeeking: (time: number) => void
  ) {
    this._logger = this._loggerManager.registerLogger(ELogType.BUFFER);
  }

  private get isLast(): boolean {
    let isLast: boolean = true;
    if (this._videoBuffer) {
      isLast = isLast && this._videoBuffer.isLast;
    }

    if (this._audioBuffer) {
      isLast = isLast && this._audioBuffer.isLast;
    }

    return isLast;
  }

  private get isUpdating(): boolean {
    let isUpdating: boolean = false;
    if (this._videoBuffer) {
      isUpdating = isUpdating || this._videoBuffer.updating;
    }

    if (this._audioBuffer) {
      isUpdating = isUpdating || this._audioBuffer.updating;
    }

    return isUpdating;
  }

  private onBufferUpdate = (bufferUpdate: IBufferUpdate): void => {
    const {contentType, ahead} = bufferUpdate;

    if (contentType === EContentType.TEXT) return;

    this._onBufferUpdate(bufferUpdate);

    // gap
    const seekableRange: ISeekableRange | null = this._manifestManager.getSeekableRange();
    if (!seekableRange) return;

    if (this._gap && this._gap.start - this._videoElement.currentTime <= 0.5) {
      const {contentType, start, size, jumpPosition} = this._gap;

      if (jumpPosition > seekableRange.end) {
        this._logger.warn(
          `jump position ${jumpPosition} is greater than the seekable range end ${seekableRange.end}, preventing the jump`
        );

        return;
      }

      const message: string = `Jumping ${contentType} gap`;
      this._logger.warn(
        message,
        `size: ${size.toFixed(2)}`,
        `seeking: ${start.toFixed(2)} -> ${jumpPosition.toFixed(2)}`
      );
      this._dispatcher.emit({
        name: EEvent.TAPE_ERROR,
        type: EErrorType.INTERNAL,
        code: EErrorCode.JUMP_OVER_GAP,
        severity: EErrorSeverity.WARN,
        message
      });
      this._gap = null;
      this._onSeeking(jumpPosition);
    } else {
      if (!this._manifestManager.isLive()) {
        // end of the stream
        const bufferEnd: number = this._videoElement.currentTime + ahead;
        if (
          this.isLast &&
          !this.isUpdating &&
          this._mediaSource.readyState === 'open' &&
          bufferEnd >= seekableRange.end - this._END_OF_STREAM_THRESHOLD
        ) {
          this._logger.info('Media Source end of stream');
          this._mediaSource.endOfStream();
        }
      }
    }
  };

  private onGapDetected = (gap: IGap): void => {
    if (!this._gap || gap.jumpPosition > this._gap.jumpPosition) {
      this._gap = {...gap};
      const {contentType, start, size, jumpPosition} = this._gap;
      this._logger.warn(
        `${contentType} gap detected`,
        `from ${start.toFixed(2)} to ${jumpPosition.toFixed(2)}`,
        `size ${size.toFixed(2)}`
      );
      this._dispatcher.emit({
        name: EEvent.GAP_DETECTED,
        ...gap
      });
    }
  };

  private seeking(contentType: Exclude<EContentType, EContentType.IMAGE>, currentTime: number): void {
    const buffer: AVBuffer | TextBuffer | null = this.getBuffer(contentType);
    if (!buffer) return;

    const bufferRange: [number, number] | null = buffer.getBufferRange(currentTime);
    if (!bufferRange) return;

    if (!bufferRange[0] && !bufferRange[1]) {
      this._logger.log(`Seeking without ${contentType} buffer`);
      buffer.reset();
      // The pts has shifted from the seek, invalidating captions currently
      // in the text buffer. Thus, clear and reset the cea buffer.
      if (contentType === EContentType.VIDEO) {
        this._videoBuffer?.clearCeaBuffer();
      }
    } else {
      this._logger.log(`Seeking within ${contentType} buffer`);
    }
  }

  private getBuffer = (
    contentType: Exclude<EContentType, EContentType.IMAGE>
  ): AVBuffer | TextBuffer | null => {
    switch (contentType) {
      case EContentType.VIDEO:
        return this._videoBuffer;
      case EContentType.AUDIO:
        return this._audioBuffer;
      case EContentType.TEXT:
        return this._textBuffer;
    }
  };

  /**
   * We are going to update the media source as soon as we know the duration.
   * @param duration
   * @returns
   */
  private updateMediaSource = (duration: number): void => {
    if (this._mediaSource.readyState === 'closed') return;
    if (!this.isUpdating) {
      // note: updating the media source duration also trigger source buffers updatestart and updateend events
      if (this._manifestManager.isLive()) {
        // Not all platforms support infinite durations, so set a finite duration
        // so we can append segments and so the user agent can seek.
        this._mediaSource.duration = Math.pow(2, 32);
      } else {
        this._mediaSource.duration = duration;
      }
    } else {
      this._logger.debug(`source buffer is updating, delaying Media Source duration update`);
      this._timeout = self.setTimeout(() => {
        this.updateMediaSource(duration);
      }, 100);
    }
  };

  public onEmeReady(): void {
    this._emeReady = true;
    this._videoBuffer?.onEmeReady();
    this._audioBuffer?.onEmeReady();
  }

  public onKeyStatusesChanged(keyStatusesMap: Map<string, string>): void {
    [EContentType.VIDEO, EContentType.AUDIO].forEach((contentType: string) => {
      const targetBuffer: AVBuffer | null = this.getBuffer(
        contentType as Exclude<EContentType, EContentType.IMAGE>
      ) as AVBuffer | null;
      if (!targetBuffer || !targetBuffer?.lastSegmentAppendedToBuffer) {
        return;
      }

      targetBuffer?.lastSegmentAppendedToBuffer?.representation.adaptation.contentProtections.some(
        (cp: IContentProtection) => {
          if (RESTRICTED_EME_STATUSES.includes(keyStatusesMap.get(cp.keyId) as EMediaKeyStatus)) {
            this._logger.warn(
              `Segments in ${contentType} buffer are not playable due to the media keys status changed to ${keyStatusesMap.get(
                cp.keyId
              )}... resetting`
            );
            targetBuffer?.reset();

            return true;
          }
        }
      );
    });
  }

  /**
   * Create Buffer class based on the content type received.
   * @param availableRepresentations
   * @returns
   */
  public onAvailableRepresentations = (availableRepresentations: IAvailableRepresentations): void => {
    const {representations, contentType} = availableRepresentations;
    switch (contentType) {
      case EContentType.AUDIO:
        if (!this._audioBuffer) {
          this._audioBuffer = new AVBuffer(
            this._videoElement,
            this._mediaSource,
            this._manifestManager,
            this._networkManager,
            this._configManager,
            this._loggerManager,
            this._dispatcher,
            this._emeReady,
            EContentType.AUDIO,
            this.onBufferUpdate,
            this.onGapDetected,
            this._onActiveRepresentationChange,
            representations
          );
        }
        break;
      case EContentType.VIDEO:
        if (!this._videoBuffer) {
          this._videoBuffer = new AVBuffer(
            this._videoElement,
            this._mediaSource,
            this._manifestManager,
            this._networkManager,
            this._configManager,
            this._loggerManager,
            this._dispatcher,
            this._emeReady,
            EContentType.VIDEO,
            this.onBufferUpdate,
            this.onGapDetected,
            this._onActiveRepresentationChange
          );
        }
        break;
      case EContentType.TEXT:
        if (!this._textBuffer) {
          this._textBuffer = new TextBuffer(
            this._videoElement,
            this._mediaSource,
            this._manifestManager,
            this._networkManager,
            this._configManager,
            this._loggerManager,
            this._dispatcher
          );
        }
        break;
      case EContentType.IMAGE:
        // do nothing
        break;
    }
  };

  public onRepresentationChange = (representationChange: IRepresentationChange): void => {
    const {representation: r, contentType} = representationChange;

    switch (contentType) {
      case EContentType.VIDEO:
        if (!r) return;
        this._videoBuffer?.onRepresentationChange(r);
        break;
      case EContentType.AUDIO:
        if (!r) return;
        this._audioBuffer?.onRepresentationChange(r);
        break;
      case EContentType.TEXT:
        this._videoBuffer?.onCeaRepresentationChange(r);

        if (r && getCea(r.adaptation.accessibilities)) {
          this._textBuffer?.onRepresentationChange(null);
        } else {
          this._textBuffer?.onRepresentationChange(r);
        }

        break;
      case EContentType.IMAGE:
        // do nothing
        break;
    }
  };

  public onSeekableRangeChange = (seekableRange: ISeekableRange): void => {
    if (this._timeout) {
      self.clearTimeout(this._timeout);
    }
    this.updateMediaSource(seekableRange.end);
  };

  public onSeeking = (): void => {
    const {currentTime} = this._videoElement;
    this.seeking(EContentType.VIDEO, currentTime);
    this.seeking(EContentType.AUDIO, currentTime);
    this.seeking(EContentType.TEXT, currentTime);
  };

  public destroy(): void {
    this._logger.info(`Destroying Buffer manager`);

    this._videoBuffer?.destroy();
    this._videoBuffer = null;
    this._audioBuffer?.destroy();
    this._audioBuffer = null;
    this._textBuffer?.destroy();
    this._textBuffer = null;
    self.clearTimeout(this._timeout);
  }
}

export default BufferManager;
