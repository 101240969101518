import createView from './createView';

const uint16ToString = (data: ArrayBuffer, littleEndian: boolean = true): string => {
  const length: number = Math.floor(data.byteLength / 2);
  const arr: Uint16Array = new Uint16Array(length);
  const dataView: DataView = createView(data, DataView) as DataView;
  for (let i: number = 0; i < length; i++) {
    arr[i] = dataView.getUint16(i * 2, littleEndian);
  }

  return String.fromCharCode.apply(null, arr as unknown as Array<number>);
};

export default uint16ToString;
