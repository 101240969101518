/* eslint-disable @typescript-eslint/ban-ts-comment */
import ConfigManager from '@config/configManager';
import Dispatcher from '@dispatcher/dispatcher';
import EEvent from '@dispatcher/enum/EEvent';
import EErrorCode from '@error/enum/EErrorCode';
import EErrorSeverity from '@error/enum/EErrorSeverity';
import EErrorType from '@error/enum/EErrorType';
import IHttpError from '@error/interfaces/IHttpError';
import ILogger from '@logger/interfaces/ILogger';
import ERequestType from '@network/enum/ERequestType';
import IHttpResponse from '@network/interfaces/IHttpResponse';
import NetworkManager from '@network/networkManager';
import IContentProtection from '@parser/manifest/interfaces/IContentProtection';

import IEme from '../interfaces/IEme';

// https://dvcs.w3.org/hg/html-media/rev/c996f5dc1e3c
class EME_2012 implements IEme {
  private _sessions: Map<string, ArrayBuffer> = new Map();
  private _initDataArray: Array<ArrayBuffer> = [];
  private _contentProtections: Array<IContentProtection> = [];
  private _keyNeeded: number = 0;
  private _needKeyEvents: number = 0;

  constructor(
    private _videoElement: HTMLVideoElement,
    private _networkManager: NetworkManager,
    private _configManager: ConfigManager,
    private _logger: ILogger,
    private _dispatcher: Dispatcher,
    private _onEmeReady: () => void
  ) {
    this._logger.debug('Using EME_2012');
    this.addListeners();
  }

  private addListeners(): void {
    // @ts-ignore: version specific
    this._videoElement.addEventListener('webkitkeyadded', this.onKeyAdded);
    // @ts-ignore: version specific
    this._videoElement.addEventListener('webkitkeyerror', this.onKeyError);
    // @ts-ignore: version specific
    this._videoElement.addEventListener('webkitkeymessage', this.onKeyMessage);
    // @ts-ignore: version specific
    this._videoElement.addEventListener('webkitneedkey', this.onNeedKey);
  }

  private removeListeners(): void {
    // @ts-ignore: version specific
    this._videoElement.removeEventListener('webkitkeyadded', this.onKeyAdded);
    // @ts-ignore: version specific
    this._videoElement.removeEventListener('webkitkeyerror', this.onKeyError);
    // @ts-ignore: version specific
    this._videoElement.removeEventListener('webkitkeymessage', this.onKeyMessage);
    // @ts-ignore: version specific
    this._videoElement.removeEventListener('webkitneedkey', this.onNeedKey);
  }

  private onNeedKey = (e: Event): void => {
    this._needKeyEvents++;
    if (this._needKeyEvents === this._contentProtections.length) {
      // @ts-ignore: version specific
      this._videoElement.removeEventListener('webkitneedkey', this.onNeedKey);
    }

    // @ts-ignore: version specific
    const {initData} = e;

    this._initDataArray.push(initData);

    this._logger.debug('Need key event');
    try {
      // @ts-ignore: version specific
      this._videoElement.webkitGenerateKeyRequest(this._configManager.eme.keySystem, initData);
    } catch (e) {
      const message: string = 'Error generating EME key request';
      this._logger.warn(message, e);
      this._dispatcher.emit({
        name: EEvent.TAPE_ERROR,
        type: EErrorType.EME,
        code: EErrorCode.CREATE_MEDIA_KEY_SESSION,
        severity: EErrorSeverity.WARN,
        message,
        nativeError: e
      });
    }
  };

  private onKeyAdded = (e: Event): void => {
    this._keyNeeded--;

    // @ts-ignore: version specific
    const {sessionId} = e;

    this._logger.debug('Key added event', sessionId);

    if (this._keyNeeded === 0) {
      // TODO: handle multi-keys
      // TODO: check if we can send this event before
      this._onEmeReady();
    }
  };

  private onKeyError = (e: Event): void => {
    // @ts-ignore: version specific
    const {errorCode, sessionId} = e;

    const message: string = `Media key error ${errorCode}`;
    this._logger.error(message, sessionId);
    this._dispatcher.emit({
      name: EEvent.TAPE_ERROR,
      type: EErrorType.EME,
      code: EErrorCode.MEDIA_KEY_ERROR,
      severity: EErrorSeverity.FATAL,
      message,
      nativeError: e
    });
  };

  private onKeyMessage = (e: MediaKeyMessageEvent): void => {
    // @ts-ignore: version specific
    const sessionId: string = e.sessionId;

    if (!e.message) {
      const message: string = 'Invalid key message';
      this._logger.error(message);
      this._dispatcher.emit({
        name: EEvent.TAPE_ERROR,
        type: EErrorType.EME,
        code: EErrorCode.INVALID_KEY_MESSAGE,
        severity: EErrorSeverity.FATAL,
        message,
        nativeError: e
      });
    }

    const initData: ArrayBuffer | undefined = this._initDataArray.shift();
    if (initData) {
      this._sessions.set(sessionId, initData);
    }

    // @ts-ignore: version specific
    this._logger.debug(`Media key message event: ${sessionId}`, e.keySystem);
    const body: ArrayBuffer = e.message;
    this._networkManager
      .request(
        this._configManager.eme.licenseServer,
        {type: ERequestType.LICENSE},
        {
          method: 'POST',
          body,
          headers: {'Content-Type': 'text/plain'}
        }
      )
      .then(
        (r: IHttpResponse) => this.onHttpResponse(r, sessionId),
        (_: IHttpError) => {}
      );
  };

  private onHttpResponse = (httpResponse: IHttpResponse, sessionId: string): void => {
    const {data} = httpResponse;
    const license: Uint8Array = new Uint8Array(data as ArrayBuffer);
    const initData: ArrayBuffer | undefined = this._sessions.get(sessionId);

    // TODO: do we need to pass initData to webkitAddKey?
    try {
      // @ts-ignore: version specific
      this._videoElement.webkitAddKey(this._configManager.eme.keySystem, license, initData, sessionId);
      this._logger.debug('Message/license loaded successfully');
    } catch (e) {
      const message: string = 'Unable to add key session';
      this._logger.warn(message, e);
      this._dispatcher.emit({
        name: EEvent.TAPE_ERROR,
        type: EErrorType.EME,
        code: EErrorCode.INITIALIZE_KEY_SESSION,
        severity: EErrorSeverity.FATAL,
        message,
        nativeError: e
      });
    }
  };

  private clearKeyRequest(): void {
    this._sessions.forEach((_initData: ArrayBuffer, sessionId: string) => {
      try {
        // @ts-ignore: version specific
        this._videoElement.webkitCancelKeyRequest(this._configManager.eme.keySystem, sessionId);
        this._logger.info('Canceled EME key request');
      } catch (e) {
        const message: string = 'Error canceling EME key request';
        this._logger.warn(message, e);
        this._dispatcher.emit({
          name: EEvent.TAPE_ERROR,
          type: EErrorType.EME,
          code: EErrorCode.CLOSE_MEDIA_KEY_SESSION,
          severity: EErrorSeverity.WARN,
          message,
          nativeError: e
        });
      }
    });
  }

  public init(): void {
    this._logger.debug('Init');
  }

  public onManifestParsed(cps: Array<IContentProtection>): void {
    this._logger.debug('onManifestParsed');
    this._keyNeeded = cps.length;
  }

  public destroy(): void {
    this._logger.info('Destroying EME_2012');
    this.removeListeners();

    this.clearKeyRequest();

    this._sessions.clear();
    this._initDataArray.length = 0;
    this._contentProtections.length = 0;
  }
}

export default EME_2012;
