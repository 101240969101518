enum ENativeEvent {
  CAN_PLAY_THROUGH = 'canplaythrough',
  ENCRYPTED = 'encrypted',
  ENDED = 'ended',
  ERROR = 'error',
  LOADED_DATA = 'loadeddata',
  LOADED_METADATA = 'loadedmetadata',
  PAUSE = 'pause',
  PLAY = 'play',
  PLAYING = 'playing',
  RATE_CHANGE = 'ratechange',
  SEEKING = 'seeking',
  SEEKED = 'seeked',
  TIME_UPDATE = 'timeupdate',
  VOLUME_CHANGE = 'volumechange',
  WAITING = 'waiting'
}

export default ENativeEvent;
