import IBufferConfig from '@config/interfaces/IBufferConfig';

const getSafeMarginBehind = (
  bufferConfig: Required<IBufferConfig>,
  averageSegmentDuration: number
): number => {
  return Math.max(bufferConfig.bufferBehind, 1.5 * averageSegmentDuration);
};

export default getSafeMarginBehind;
