import Dispatcher from '@dispatcher/dispatcher';
import EEvent from '@dispatcher/enum/EEvent';
import ILogger from '@logger/interfaces/ILogger';
import EContentType from '@parser/manifest/enum/EContentType';
import ICue from '@parser/text/interfaces/ICue';

class CueHandler {
  private _activeCuesMap: Map<string, ICue> = new Map();

  private _CUE_EXIT_TIMEOUT: number = 100;
  private _cuesExitTimeout: number | null = null;

  constructor(
    private _videoElement: HTMLVideoElement,
    private _logger: ILogger,
    private _dispatcher: Dispatcher
  ) {}

  public addCue(textTrack: TextTrack, cue: ICue): void {
    const {begin, end, rawText, id} = cue;

    const vttCue: VTTCue = new VTTCue(begin, end, rawText);
    vttCue.id = id;

    this._logger.debug(`Adding ${EContentType.TEXT} cue ${id} to text track`, {
      id,
      begin,
      end
    });
    textTrack.addCue(vttCue);

    const vttOnEnter = (_event: Event): void => {
      const missed: boolean = cue.end <= this._videoElement.currentTime;
      if (!missed) {
        if (this._cuesExitTimeout !== null) {
          self.clearTimeout(this._cuesExitTimeout);
        }
        this._logger.debug(`Cue ${id} enter`, cue);
        this._activeCuesMap.set(id, cue);
        this.dispatchCuesChange();
      } else {
        this._logger.warn(`Cue ${id} is missed`, cue);
      }
    };

    const vttOnExit = (event: Event | CustomEvent<{removeEventListeners: boolean}>): void => {
      if ('detail' in event && event.detail.removeEventListeners) {
        vttCue.removeEventListener('enter', vttOnEnter);
        vttCue.removeEventListener('exit', vttOnExit);
      }

      this._logger.debug(`Cue ${id} exit`, cue);
      this._activeCuesMap.delete(id);
      if (this._activeCuesMap.size === 0) {
        if (this._cuesExitTimeout !== null) {
          self.clearTimeout(this._cuesExitTimeout);
        }
        this._cuesExitTimeout = self.setTimeout(() => {
          this.dispatchCuesChange();
        }, this._CUE_EXIT_TIMEOUT);
      } else {
        this.dispatchCuesChange();
      }
    };

    vttCue.addEventListener('enter', vttOnEnter);
    vttCue.addEventListener('exit', vttOnExit);
  }

  /**
   * In order to remove cues, we are going to manually dispatch an "exit" Custom Event.
   * Then we remove the cue from the text track.
   */
  public removeCue(textTrack: TextTrack, cue: TextTrackCue, shouldRemoveTrack: boolean = true): void {
    this._logger.debug(`Remove cue ${cue.id}`);
    cue.dispatchEvent(
      new CustomEvent('exit', {
        detail: {
          removeEventListeners: true
        }
      })
    );
    if (shouldRemoveTrack) {
      textTrack.removeCue(cue);
    }
  }

  public dispatchCuesChange = (): void => {
    this._dispatcher.emit({
      name: EEvent.CUES_CHANGE,
      cues: Array.from(this._activeCuesMap).map(([_, cue]: [_: string, cue: ICue]) => cue)
    });
  };

  public destroy(): void {
    this._logger.info(`Destroying Cue Handler`);

    if (this._cuesExitTimeout !== null) {
      self.clearTimeout(this._cuesExitTimeout);
    }
  }
}

export default CueHandler;
