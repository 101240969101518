import IAlgorithm from '@abr/interfaces/IAlgorithm';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import IHttpResponse from '@network/interfaces/IHttpResponse';
import IHttpTimeout from '@network/interfaces/IHttpTimeout';

import IThroughput from '../interfaces/IThroughput';

class EWMAAlgorithm implements IAlgorithm {
  private _logger: ILogger;

  private _SAFE_BANDWIDTH_PERCENTAGE: number = 0.9;

  constructor(private _ewmaThrouhput: IThroughput, loggerManager: LoggerManager) {
    this._logger = loggerManager.registerLogger(ELogType.ABR);
    this._logger.info('Using EWMA');
  }

  public chooseBandwidth(bandwidths: Array<number>): number {
    const estimatedBandwidth: number = this._ewmaThrouhput.getEstimatedBandwidth();
    const targetEstimatedBandwidth: number = estimatedBandwidth * this._SAFE_BANDWIDTH_PERCENTAGE;

    let targetBandwidthIndex: number = 0;
    for (let i: number = 0; i < bandwidths.length; i++) {
      if (targetEstimatedBandwidth > bandwidths[i]) {
        targetBandwidthIndex = i;
      } else {
        break;
      }
    }

    return bandwidths[targetBandwidthIndex];
  }

  public onAverageSegmentDuration = (_averageSegmentDuration: number): void => {
    return;
  };

  public onBufferUpdate = (_bufferAhead: number): void => {
    return;
  };

  public onHttpResponse = (_httpResponse: IHttpResponse): void => {
    return;
  };

  public onHttpTimeout = (_httpTimeout: IHttpTimeout): void => {
    return;
  };

  public onRateChange(_playbackRate: number): void {
    return;
  }

  public onSeeking(): void {
    return;
  }

  public isTargetReached(): boolean {
    return this._ewmaThrouhput.isEstimatedBandwidthReliable();
  }

  public destroy(): void {
    this._logger.info('Destroying EWMA algorithm');
  }
}

export default EWMAAlgorithm;
