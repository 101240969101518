import ConfigManager from '@config/configManager';
import Dispatcher from '@dispatcher/dispatcher';
import ILogger from '@logger/interfaces/ILogger';
import NetworkManager from '@network/networkManager';
import StateManager from '@state/stateManager';

import BaseEme from '../baseEme';

// https://www.w3.org/TR/2017/REC-encrypted-media-20170918/
class Widevine extends BaseEme {
  private _EME_DEFAULT_WIDEVINE_ROBUSTNESSES: Array<string> = [
    'HW_SECURE_ALL',
    'HW_SECURE_DECODE',
    'HW_SECURE_CRYPTO',
    'SW_SECURE_DECODE',
    'SW_SECURE_CRYPTO'
  ];

  constructor(
    videoElement: HTMLVideoElement,
    networkManager: NetworkManager,
    stateManager: StateManager,
    configManager: ConfigManager,
    logger: ILogger,
    dispatcher: Dispatcher,
    onEmeReady: () => void,
    onEmeKeyStatusesChanged: (keyStatuses: Map<string, string>) => void
  ) {
    super(
      videoElement,
      networkManager,
      stateManager,
      configManager,
      logger,
      dispatcher,
      onEmeReady,
      onEmeKeyStatusesChanged
    );
    this._logger.debug('Using EME Widevine');
  }

  public override init(): void {
    this._logger.debug('Init');
    let videoRobustnesses: Array<string> = [...this._configManager.eme.videoRobustness];
    if (videoRobustnesses.length === 0) {
      videoRobustnesses = [...this._EME_DEFAULT_WIDEVINE_ROBUSTNESSES];
    }

    let audioRobustnesses: Array<string> = [...this._configManager.eme.audioRobustness];
    if (audioRobustnesses.length === 0) {
      audioRobustnesses = [...this._EME_DEFAULT_WIDEVINE_ROBUSTNESSES];
    }

    const videoCapabilities: Array<MediaKeySystemMediaCapability> = [];
    for (let i: number = 0; i < videoRobustnesses.length; i++) {
      for (let y: number = 0; y < this._VIDEO_MIME_CODEC.length; y++) {
        videoCapabilities.push({
          contentType: this._VIDEO_MIME_CODEC[y],
          robustness: videoRobustnesses[i]
        });
      }
    }

    const audioCapabilities: Array<MediaKeySystemMediaCapability> = [];
    for (let i: number = 0; i < audioRobustnesses.length; i++) {
      for (let y: number = 0; y < this._AUDIO_MIME_CODEC.length; y++) {
        audioCapabilities.push({
          contentType: this._AUDIO_MIME_CODEC[y],
          robustness: audioRobustnesses[i]
        });
      }
    }

    const mediaKeySystemConfiguration: Array<MediaKeySystemConfiguration> = [
      {
        initDataTypes: ['cenc'],
        videoCapabilities,
        audioCapabilities
      }
    ];

    super.init(mediaKeySystemConfiguration);
  }

  public destroy(): void {
    this._logger.info('Destroying EME Widevine');
    super.destroy();
  }
}

export default Widevine;
