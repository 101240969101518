const uint8ToString = (uint8: Uint8Array): string => {
  // If present, strip off the UTF-8 BOM.
  if (uint8[0] === 0xef && uint8[1] === 0xbb && uint8[2] === 0xbf) {
    uint8 = uint8.subarray(3);
  }

  let decoded: string = String.fromCharCode.apply(null, uint8 as unknown as Array<number>);

  decoded = decodeURIComponent(escape(decoded));

  return decoded;
};

export default uint8ToString;
