enum ParserState {
  INIT,
  TAG,
  TEXT,
  CDATA
}

type ContextResolver = (p: CustomParserContext) => void;

const openBracket: string = '<';
const closeBracket: string = '>';
const slashCC: number = '/'.charCodeAt(0);
const exclamationCC: number = '!'.charCodeAt(0);
const doubleQuote: string = '"';
const openCornerBracketCC: number = '['.charCodeAt(0);
const space: string = ' ';
const spaceCC: number = ' '.charCodeAt(0);
const lineFeedCC: number = 10;

function assertFail(test: boolean, message: string): void {
  if (!test) {
    throw new Error(`Assertion::fail: ${message}`);
  }
}

export function getNodeText(node: GenericXmlNode): string {
  if (!node.text) {
    return '';
  }

  return node.text[0];
}

export class StringSlice {
  private _str: string;
  private _copied: boolean = false;

  constructor(str: string, private _start: number, private _end: number) {
    this._str = str;
  }

  slice(): string {
    if (!this._copied) {
      this._str = this._str.substring(this._start, this._end);
      this._copied = true;
    }

    return this._str;
  }
}

type ParserContextOptions = {
  resolveOnExit?: boolean;
  allowMultiple?: boolean;
};

export interface ParserContext {
  parentContext: ParserContext | undefined;
  tagName: string;
  lazy?: boolean;
  childContext(tagName: string): ParserContext | undefined;
  setAttributes(attributes: Record<string, string | undefined>): void;
  appendText(text: string): void;
  appendCData(cdata: string): void;
  enter(innerXml?: StringSlice): void;
  exit(): void;
  destroy(): void;
}

export class CustomParserContext implements ParserContext {
  private _attr: Record<string, string> = {};
  private _text: Array<string> = [];
  private _cdata: Array<string> = [];
  private _childContexts: Map<string, CustomParserContext> = new Map();
  private _parentContext: CustomParserContext | undefined;
  private _exitCallback: (() => void) | undefined;
  private _isResolved = false;
  private _dataContext: unknown = null;

  constructor(
    private _tagName: string,
    private _resolver: ContextResolver,
    private _options: ParserContextOptions = {}
  ) {}

  private _resolve(): void {
    assertFail(
      this._options.allowMultiple || !this._isResolved,
      'Should only resolve once:tag:' + this._tagName
    );
    this._isResolved = true;
    this._resolver(this);
  }

  getAttr(name: string): string {
    return this._attr[name];
  }

  setAttributes(attributes: Record<string, string>): void {
    this._attr = attributes;
  }

  setParentContext(context: CustomParserContext): void {
    this._parentContext = context;
  }

  get parentContext(): CustomParserContext | undefined {
    return this._parentContext;
  }

  setDataContext(d: unknown): void {
    this._dataContext = d;
  }

  get dataContext(): unknown {
    return this._dataContext;
  }

  get tagName(): string {
    return this._tagName;
  }

  childContext(tagName: string): CustomParserContext | undefined {
    return this._childContexts.get(tagName);
  }

  addChildContext(
    tagName: string,
    resolver: (p: CustomParserContext) => void,
    options?: ParserContextOptions
  ): CustomParserContext {
    const context: CustomParserContext = new CustomParserContext(tagName, resolver, options);
    context.setParentContext(this);
    this._childContexts.set(tagName, context);

    return this;
  }

  setChildContext(p: CustomParserContext, dataContext?: unknown): CustomParserContext {
    p.setParentContext(this);
    p.setDataContext(dataContext);
    this._childContexts.set(p.tagName, p);

    return this;
  }

  appendText(text: string): void {
    this._text.push(text);
  }

  get text(): Array<string> {
    return this._text;
  }

  appendCData(cdata: string): void {
    this._cdata.push(cdata);
  }

  get cdata(): Array<string> {
    return this._cdata;
  }

  onExit(callback: () => void): void {
    this._exitCallback = callback;
  }

  enter(): void {
    if (this._options.resolveOnExit) {
      return;
    }
    this._resolve();
  }

  exit(): void {
    if (this._options.resolveOnExit) {
      this._resolve();
    }
    this._exitCallback?.();
    this._attr = {};
    this._cdata = [];
    this._text = [];
    this._isResolved = false;
  }

  destroy(): void {
    this._childContexts.forEach((c: CustomParserContext) => {
      c.destroy();
    });
    this._attr = {};
    this._cdata = [];
    this._text = [];
  }
}

export type GenericXmlNode = {
  tagName: string;
  attrs: Record<string, string>;
  text?: Array<string> | null;
  cdata?: Array<string> | null;
  children: Record<string, Array<GenericXmlNode> | undefined>;
  orderedChildren?: Array<GenericXmlNode | string> | null;
};

export type GenericParserContextOptions = {
  saveMultipleText?: boolean;
  needsOrderedChildren?: boolean;
  knownTags?: Array<{name: string; lazy?: boolean}>;
};

export class GenericParserContext implements ParserContext {
  public tagName: string;
  public lazy: boolean = false;
  private _dataContext: GenericXmlNode;
  private _dataContextStack: Array<GenericXmlNode> = [];
  private _knownTagIndex: Map<string, number> | null = null;

  constructor(private _rootDataContext: GenericXmlNode, private _options: GenericParserContextOptions = {}) {
    this.tagName = _rootDataContext.tagName;
    this._dataContext = _rootDataContext;
    this._dataContextStack.push(_rootDataContext);

    if (_options.needsOrderedChildren) {
      this._dataContext.orderedChildren = [];
    }

    if (_options.knownTags) {
      const len: number = _options.knownTags.length;
      this._knownTagIndex = new Map();
      for (let i: number = 0; i < len; i++) {
        this._knownTagIndex.set(_options.knownTags[i].name, i);
      }
    }
  }

  private _resolveInnerXml(innerXml: StringSlice): Record<string, Array<GenericXmlNode> | undefined> {
    const childrenNode: GenericXmlNode = {
      tagName: 'children',
      attrs: {},
      children: {}
    };

    new XmlParser(new GenericParserContext(childrenNode)).write(`<children>${innerXml.slice()}</children>`);

    return childrenNode.children;
  }

  get parentContext(): ParserContext | undefined {
    if (this._dataContextStack.length) {
      return this;
    }
  }

  childContext(tagName: string): ParserContext | undefined {
    const knownTagIndex: number | undefined = this._knownTagIndex
      ? this._knownTagIndex.get(tagName)
      : undefined;

    if (this._knownTagIndex && knownTagIndex === undefined) {
      return;
    }

    let newNode: GenericXmlNode;
    let isLazy: boolean = false;

    if (knownTagIndex! >= 0) {
      const knownTag: {name: string; lazy?: boolean} = this._options.knownTags![knownTagIndex!];
      isLazy = Boolean(knownTag.lazy);
      if (isLazy) {
        // We shouldn't define children here since we're going to define it later (enter) and it slightly faster to not re-define a property
        newNode = {
          tagName: knownTag.name,
          attrs: {}
        } as GenericXmlNode;
      } else {
        newNode = {
          tagName: knownTag.name,
          attrs: {},
          children: {}
        };
      }
    } else {
      newNode = {
        tagName: tagName,
        attrs: {},
        children: {}
      };
    }

    // Lazy nodes won't support ordered children
    if (!isLazy && this._options.needsOrderedChildren) {
      newNode.orderedChildren = [];
    }

    this.lazy = isLazy;
    this.tagName = newNode.tagName;

    const childrenOfType: Array<GenericXmlNode> | undefined = this._dataContext.children[newNode.tagName];
    if (childrenOfType) {
      childrenOfType.push(newNode);
    } else {
      this._dataContext.children[newNode.tagName] = [newNode];
    }

    if (this._dataContext.orderedChildren) {
      this._dataContext.orderedChildren.push(newNode);
    }

    this._dataContextStack.push(this._dataContext);
    this._dataContext = newNode;

    return this;
  }

  setAttributes(attributes: Record<string, string>): void {
    this._dataContext.attrs = attributes;
  }

  appendText(text: string): void {
    if (!this._dataContext.text) {
      this._dataContext.text = [];
    } else {
      if (!this._options.saveMultipleText) {
        return;
      }
    }
    this._dataContext.text.push(text);

    if (this._dataContext.orderedChildren) {
      this._dataContext.orderedChildren.push(text);
    }
  }

  appendCData(cdata: string): void {
    if (!this._dataContext.cdata) {
      this._dataContext.cdata = [];
    }
    this._dataContext.cdata.push(cdata);
  }

  enter(innerXml?: StringSlice): void {
    const node: GenericXmlNode = this._dataContext;

    if (this.lazy && innerXml) {
      Object.defineProperty(node, 'children', {
        enumerable: true,
        configurable: true,
        get: () => {
          const children: Record<string, Array<GenericXmlNode> | undefined> = this._resolveInnerXml(innerXml);

          Object.defineProperty(node, 'children', {
            enumerable: true,
            value: children
          });

          return children;
        }
      });
    }
  }

  exit(): void {
    const dataContext: GenericXmlNode | undefined = this._dataContextStack.pop();
    if (dataContext) {
      this._dataContext = dataContext;
      this.tagName = this._dataContext.tagName!;
    }
  }

  destroy(): void {
    function d(n: GenericXmlNode): void {
      n.attrs = {};
      n.cdata = null;
      n.text = null;
      n.tagName = '';

      if (Object.keys(n).length === 0) {
        return;
      }

      Object.keys(n).forEach((name: string) => n.children[name]?.forEach((node: GenericXmlNode) => d(node)));
      n.children = {};
      n.orderedChildren = null;
    }
    d(this._rootDataContext);
  }
}

export type Stats = {
  entered: number;
  exited: number;
  iterCount: number;
};

export type ParserOptions = {
  parseMultilineText?: boolean;
};

export class XmlParser {
  private _data = '';
  private _dataLength: number = 0;
  private _tagNamePos: number = 0;
  private _attrs: Record<string, string> = {};
  private _stats: Stats = {
    entered: 0,
    exited: 0,
    iterCount: 0
  };
  private _pos = 0;
  private _state: ParserState = ParserState.INIT;
  private _isResolvingRoot = true;
  private _exitedRootContext = false;
  private _rootContext: ParserContext;

  constructor(private _context: ParserContext, private _parserOptions: ParserOptions = {}) {
    this._rootContext = _context;
  }

  get stats(): Stats {
    return this._stats;
  }

  private _textAnalysis(): boolean {
    const openBracketPos: number = this._data.indexOf(openBracket, this._pos);

    if (openBracketPos >= 0) {
      if (
        openBracketPos > this._pos &&
        (this._data.charCodeAt(this._pos) !== lineFeedCC || this._parserOptions.parseMultilineText)
      ) {
        this._context.appendText(this._data.substring(this._pos, openBracketPos));
      }
      this._pos = openBracketPos + 1;
      // <![CDATA[ or <!--
      if (this._data.charCodeAt(this._pos) === exclamationCC) {
        if (this._data.charCodeAt(this._pos + 1) === openCornerBracketCC) {
          this._state = ParserState.CDATA;
        } else {
          const endCommentsPos: number = this._data.indexOf('-->', this._pos);
          if (!endCommentsPos) {
            // go back one char to resume searching for comments when we get more data
            this._pos--;

            return false;
          }
          // skip over the comment tag (stay in text analysis)
          this._pos = endCommentsPos + 3;
        }
      } else {
        this._tagNamePos = this._pos;
        this._state = ParserState.TAG;
      }
    } else {
      this._context.appendText(this._data.substring(this._pos, this._dataLength - 1));
      this._pos = this._dataLength;
    }

    return true;
  }

  private _cdataAnalysis(): boolean {
    const end: number = this._data.indexOf(']]>', this._pos);
    if (end === -1) {
      return false;
    }
    this._context.appendCData(this._data.substring(this._pos + 8, end));
    this._pos = end + 3;
    this._state = ParserState.TEXT;

    return true;
  }

  private _tagAnalysis(): boolean {
    const insideRoot: boolean = this._isResolvingRoot;
    let closeBracketPos: number = this._data.indexOf(closeBracket, this._pos);

    if (closeBracketPos === -1) {
      return false;
    }

    // if this is a close tag
    if (this._data.charCodeAt(this._tagNamePos) === slashCC) {
      this._exitContext();
      if (this._exitedRootContext) {
        return false;
      }
      this._pos = closeBracketPos + 1;
      this._state = ParserState.TEXT;

      return true;
    }

    let hasAttributes: boolean = false;
    let foundNextAttribute: boolean = false;

    do {
      const startQuotePos: number = this._data.indexOf(doubleQuote, this._pos);

      foundNextAttribute = startQuotePos >= 0 && startQuotePos < closeBracketPos;

      if (foundNextAttribute) {
        hasAttributes = true;

        const nameStartPos: number = this._data.lastIndexOf(space, startQuotePos - 2) + 1;
        const equalsPos: number = startQuotePos - 1;
        const endQuotePos: number = this._data.indexOf(doubleQuote, startQuotePos + 1);

        const name: string = this._data.substring(nameStartPos, equalsPos);
        const value: string = this._data.substring(startQuotePos + 1, endQuotePos);

        this._attrs[name] = value;

        this._pos = endQuotePos + 1;

        // Support close braket symbols inside attribute values
        if (closeBracketPos < this._pos) {
          closeBracketPos = this._data.indexOf(closeBracket, this._pos);
          if (closeBracketPos === -1) {
            return false;
          }
        }
      }
    } while (foundNextAttribute);

    const isSelfClosingTag: boolean = this._data.charCodeAt(closeBracketPos - 1) === slashCC;
    const nameStart: number = this._tagNamePos;
    const nameEnd: number = hasAttributes
      ? this._data.indexOf(space, this._tagNamePos)
      : isSelfClosingTag
      ? closeBracketPos - 1
      : closeBracketPos;
    const nameEndCC: number = this._data.charCodeAt(nameEnd - 1);
    const name: string =
      nameEndCC === lineFeedCC || nameEndCC == spaceCC
        ? this._data.substring(nameStart, nameEnd).trimEnd()
        : this._data.substring(nameStart, nameEnd);

    const childContext: ParserContext | undefined | false = !insideRoot && this._context.childContext(name!);

    if (insideRoot || childContext) {
      if (childContext) {
        if (childContext.lazy && !isSelfClosingTag) {
          const lazyTagEnd: string = `</${name}>`;
          const lazyTagEndPos: number = this._data.indexOf(lazyTagEnd, closeBracketPos + 1);
          if (lazyTagEndPos >= 0) {
            this._enterContext(
              this._attrs,
              childContext,
              new StringSlice(this._data, closeBracketPos + 1, lazyTagEndPos)
            );
            this._exitContext();
            this._pos = lazyTagEndPos + lazyTagEnd.length;

            return true;
          }

          return false;
        }
        this._enterContext(this._attrs, childContext);
      } else {
        this._isResolvingRoot = false;
        this._enterContext(this._attrs);
      }
      // self closing tag
      if (isSelfClosingTag) {
        this._exitContext();
      }
    } else {
      this._resetState();

      if (!isSelfClosingTag) {
        const unknownTagEnd: string = `</${name}>`;
        const unknownTagEndPos: number = this._data.indexOf(unknownTagEnd, closeBracketPos + 1);
        if (unknownTagEndPos >= 0) {
          this._pos = unknownTagEndPos + unknownTagEnd.length;

          return true;
        }

        return false;
      }
    }

    this._pos = closeBracketPos + 1;

    return true;
  }

  private _resetState(): void {
    this._state = ParserState.TEXT;
    this._attrs = {};
  }

  private _exitContext(): void {
    this._stats.exited++;
    this._context.exit();
    if (this._context.parentContext) {
      this._context = this._context.parentContext;
    } else {
      this._exitedRootContext = true;
    }
    this._resetState();
  }

  private _enterContext(
    attrs: Record<string, string>,
    context?: ParserContext,
    innerXml?: StringSlice
  ): void {
    this._stats.entered++;
    if (context) {
      this._context = context;
    }
    this._context.setAttributes(attrs);
    this._context.enter(innerXml);
    this._resetState();
  }

  write(newData: string): void {
    if (newData.length === 0) {
      return;
    }
    this._data += newData;

    const dataLength: number = (this._dataLength = this._data.length);

    while (this._pos < dataLength) {
      this.stats.iterCount++;

      if (this._state === ParserState.TAG) {
        if (!this._tagAnalysis()) break;
      } else if (this._state === ParserState.TEXT) {
        if (!this._textAnalysis()) break;
      } else if (this._state === ParserState.CDATA) {
        if (!this._cdataAnalysis()) break;
      } else if (this._state === ParserState.INIT) {
        const rootIndex: number = this._data.indexOf(`<${this._context.tagName}`);
        if (rootIndex < 0) {
          break;
        }
        this._pos = rootIndex + this._context.tagName.length + 1;
        this._tagNamePos = rootIndex + 1;
        this._state = ParserState.TAG;
      }
    }
  }

  destroy(): void {
    this._rootContext.destroy();
    this._attrs = {};
    this._data = '';
  }
}
