import ISegment from '@parser/manifest/interfaces/ISegment';
import IEmsg from '@utils/mp4/interfaces/IEmsg';

export default function getEventStartTimeFromEmsg(
  parsedEmsgBox: IEmsg,
  boxVersion: number,
  segmentRef: ISegment
): number {
  let presentationTime: number = 0;
  if (boxVersion === 0) {
    presentationTime =
      segmentRef.time - segmentRef.duration + parsedEmsgBox.presentationTimeDelta / parsedEmsgBox.timescale;
  } else {
    const timestampOffset: number =
      segmentRef.representation.adaptation.period.start - segmentRef.representation.presentationTimeOffset;
    presentationTime = parsedEmsgBox.presentationTimeDelta / parsedEmsgBox.timescale + timestampOffset;
  }

  return presentationTime;
}
