import IText from '@parser/text/interfaces/IText';

const buildRawText = (texts: Array<IText>): string => {
  return texts
    .map((t: IText) => t.text.trim())
    .join(' ')
    .replace(/[\n\r\t]|\s+/gm, ' ')
    .trim();
};

export default buildRawText;
