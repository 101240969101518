import ConfigManager from '@config/configManager';
import Dispatcher from '@dispatcher/dispatcher';
import EEvent from '@dispatcher/enum/EEvent';
import EErrorCode from '@error/enum/EErrorCode';
import EErrorSeverity from '@error/enum/EErrorSeverity';
import EErrorType from '@error/enum/EErrorType';
import IHttpError from '@error/interfaces/IHttpError';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import IRepresentationChange from '@manifest/interfaces/IRepresentationChange';
import ERequestType from '@network/enum/ERequestType';
import IHttpResponse from '@network/interfaces/IHttpResponse';
import NetworkManager from '@network/networkManager';
import EContentType from '@parser/manifest/enum/EContentType';
import HlsParser from '@parser/manifest/hls/parser';
import IManifest from '@parser/manifest/interfaces/IManifest';
import IRepresentation from '@parser/manifest/interfaces/IRepresentation';
import ProbeCapabilities from '@utils/capabilities/probe';
import isLiveManifest from '@utils/isLiveManifest';

import IDownloader from '../interfaces/IDownloader';

class HlsDownloader implements IDownloader {
  private _logger: ILogger;
  private _parser: HlsParser | null = null;
  private _fetchTimeoutMap: Map<EContentType, number> = new Map();
  private _activeRepresentationMap: Map<EContentType, string> = new Map();

  constructor(
    private _url: URL,
    private _networkManager: NetworkManager,
    private _configManager: ConfigManager,
    probeCapabilities: ProbeCapabilities,
    loggerManager: LoggerManager,
    private _dispatcher: Dispatcher,
    private _onManifestDownloaded: (m: IManifest) => void
  ) {
    this._logger = loggerManager.registerLogger(ELogType.DOWNLOADER);
    this._parser = new HlsParser(
      this._url,
      this._networkManager,
      _configManager,
      probeCapabilities,
      loggerManager,
      this._dispatcher
    );
  }

  private onHttpResponse = async (httpResponse: IHttpResponse, representationId?: string): Promise<void> => {
    const {data} = httpResponse;

    let manifest: IManifest | null | undefined = null;
    let representation: IRepresentation | undefined;
    const before: number = performance.now();
    try {
      if (representationId === undefined) {
        manifest = await this._parser?.parseManifest(httpResponse.data as string);
        this.fetchInitPlaylists();

        return;
      } else {
        if (typeof data === 'string') {
          manifest = await this._parser?.parsePlaylist(data as string, representationId);
          representation = this._parser?.representationMap.get(representationId)?.[0];
          if (!representation) return;
          const {
            adaptation: {contentType}
          } = representation;
          this._activeRepresentationMap.set(contentType, representationId);
        }
      }
    } catch (e) {
      const message: string = 'Error parsing manifest';
      this._logger.error(message, httpResponse.url, e);
      this._dispatcher.emit({
        name: EEvent.TAPE_ERROR,
        type: EErrorType.INTERNAL,
        message,
        code: EErrorCode.PARSER,
        severity: EErrorSeverity.FATAL
      });
    }

    if (manifest) {
      const timeMs: number = performance.now() - before;
      this._logger.log(`Manifest parsed in ${timeMs.toFixed(2)}ms`);
      this._logger.debug('Parsed manifest', manifest);

      if (isLiveManifest(manifest)) {
        const updatePeriodConfigOverride: number | null = this._configManager.manifest.updatePeriod;
        const timeout: number = updatePeriodConfigOverride
          ? updatePeriodConfigOverride
          : manifest.minimumUpdatePeriod * 1000;
        this._activeRepresentationMap.forEach((representationId: string, contentType: EContentType) => {
          self.clearTimeout(this._fetchTimeoutMap.get(contentType));
          this._fetchTimeoutMap.set(
            contentType,
            self.setTimeout(() => {
              if (!this._parser) return;
              this._fetchTimeoutMap.delete(contentType);
              this.fetchPlaylist(representationId);
            }, timeout)
          );
        });
      }
      this.emitManifestDownloadedEvent(manifest, timeMs);
      this._onManifestDownloaded(manifest);
    }
  };

  private emitManifestDownloadedEvent(manifest: IManifest, timeMs: number): void {
    this._dispatcher.emit({
      name: EEvent.MANIFEST_DOWNLOADED,
      url: manifest.url,
      type: manifest.type,
      timeMs
    });
  }

  private fetchInitPlaylists(): void {
    const playlistToDownload: Array<string> = []; // [video, audio]
    let minVideoBandwidth: number = Infinity;
    this._parser?.representationMap.forEach(
      (representationAndUri: [IRepresentation, string], representationId: string) => {
        const representation: IRepresentation = representationAndUri[0];
        const {
          adaptation: {contentType}
        } = representation;
        switch (contentType) {
          case EContentType.VIDEO:
            if (representation.bandwidth < minVideoBandwidth) {
              minVideoBandwidth = representation.bandwidth;
              playlistToDownload[0] = representationId;
            }
            break;
          case EContentType.AUDIO:
            if (playlistToDownload[1] === undefined) {
              playlistToDownload[1] = representationId;
            }
            break;
        }
      }
    );

    for (let i: number = 0; i < playlistToDownload.length; i++) {
      this.fetchPlaylist(playlistToDownload[i]);
    }
  }

  private fetchPlaylist(representationId: string): void {
    const representationAndUri: [IRepresentation, string] | undefined =
      this._parser?.representationMap.get(representationId);
    if (!representationAndUri) return;

    this._networkManager
      .request(
        representationAndUri[1],
        {type: ERequestType.MANIFEST, ref: representationId},
        {
          responseType: 'text'
        }
      )
      .then(
        (r: IHttpResponse) => this.onHttpResponse(r, representationId),
        (_: IHttpError) => {}
      );
  }

  public init(): void {
    this._networkManager
      .request(
        this._url.href,
        {type: ERequestType.MANIFEST},
        {
          responseType: 'text'
        }
      )
      .then(this.onHttpResponse, (_: IHttpError) => {});
  }

  public onAverageSegmentDuration(_averageSegmentDuration: number): void {
    return void 0;
  }

  /**
   * We have an empty representation when we need to fetch a new playlist
   */
  public onRepresentationEmpty(representation: IRepresentation): void {
    const {id} = representation;
    this.fetchPlaylist(id);
  }

  public onRepresentationChange = (representationChange: IRepresentationChange): void => {
    const {contentType, representation} = representationChange;
    if (!representation) {
      switch (contentType) {
        case EContentType.TEXT:
        case EContentType.IMAGE:
          self.clearTimeout(this._fetchTimeoutMap.get(contentType));
          this._activeRepresentationMap.delete(contentType);
          break;
      }
    }
  };

  public destroy(): void {
    this._logger.info('Destroying HLS downloader');

    this._parser?.destroy();
    this._parser = null;
    this._fetchTimeoutMap.forEach((timeout: number) => {
      self.clearTimeout(timeout);
    });
    this._fetchTimeoutMap.clear();
    this._activeRepresentationMap.clear();
  }
}

export default HlsDownloader;
