import CmcdManager from '@cmcd/cmcdManager';
import ConfigManager from '@config/configManager';
import Dispatcher from '@dispatcher/dispatcher';
import EEvent from '@dispatcher/enum/EEvent';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import ERequestType from '@network/enum/ERequestType';
import IHttpResponse from '@network/interfaces/IHttpResponse';
import IHttpTimeout from '@network/interfaces/IHttpTimeout';

import IThroughput from '../interfaces/IThroughput';
import EWMA from './ewma';

class EWMAThrougput implements IThroughput {
  private _logger: ILogger;
  private _cmcdManager: CmcdManager | null = null;
  private _fastThroughputEWMA: EWMA;
  private _slowThroughputEWMA: EWMA;

  private _FAST_THROUGHPUT_HALF_LIFE: number = 2;
  private _SLOW_THROUGHPUT_HALF_LIFE: number = 5;

  private _bytesSampled: number = 0;
  private _MIN_BYTES: number = 16e3; // 16kB
  private _MIN_TOTAL_BYTES: number = 128e3; // 128kB

  constructor(
    private _configManager: ConfigManager,
    loggerManager: LoggerManager,
    private _dispatcher: Dispatcher
  ) {
    this._logger = loggerManager.registerLogger(ELogType.ABR);

    this._fastThroughputEWMA = new EWMA(this._FAST_THROUGHPUT_HALF_LIFE, loggerManager);
    this._slowThroughputEWMA = new EWMA(this._SLOW_THROUGHPUT_HALF_LIFE, loggerManager);
  }

  private addThroughputSample(weight: number, bandwidth: number): void {
    this._fastThroughputEWMA.addSample(weight, bandwidth);
    this._slowThroughputEWMA.addSample(weight, bandwidth);
  }

  private processResponse(bytes: number, timeMs: number, requestType: ERequestType): void {
    if (requestType !== ERequestType.VIDEO_SEGMENT) {
      return;
    }

    if (this._bytesSampled < this._MIN_TOTAL_BYTES) {
      this._bytesSampled += bytes;
    }

    const bit: number = bytes * 8;
    const timeSec: number = timeMs / 1000;
    const bps: number = bit / timeSec;

    this.addThroughputSample(timeSec, bps);

    const estimatedBandwidth: number = this.getEstimatedBandwidth();

    this._cmcdManager?.onEstimatedBandwidth(estimatedBandwidth);
    this._dispatcher.emit({
      name: EEvent.ESTIMATED_BANDWIDTH,
      estimatedBandwidth
    });
  }

  set cmcdManager(cmcdManager: CmcdManager) {
    this._cmcdManager = cmcdManager;
  }

  public getEstimatedBandwidth(): number {
    if (!this.isEstimatedBandwidthReliable()) {
      return this._configManager.abr.defaultBandwidthEstimate;
    }

    return Math.min(this._fastThroughputEWMA.getEstimate(), this._slowThroughputEWMA.getEstimate());
  }

  public isEstimatedBandwidthReliable(): boolean {
    return this._bytesSampled >= this._MIN_TOTAL_BYTES;
  }

  public onHttpResponse = (httpResponse: IHttpResponse): void => {
    const {bytes, timeMs, request} = httpResponse;

    this.processResponse(bytes, timeMs, request.type);
  };

  public onHttpTimeout = (httpTimeout: IHttpTimeout): void => {
    const {loaded, timeout, request} = httpTimeout;

    this.processResponse(loaded, timeout, request.type);
  };

  public destroy(): void {
    this._logger.info('Destroying EWMA througput');

    this._fastThroughputEWMA.destroy();
    this._slowThroughputEWMA.destroy();
  }
}

export default EWMAThrougput;
