import Dispatcher from '@dispatcher/dispatcher';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import ManifestManager from '@manifest/manifestManager';
import EContentType from '@parser/manifest/enum/EContentType';
import IContentProtection from '@parser/manifest/interfaces/IContentProtection';
import createView from '@utils/createView';
import intToUint8 from '@utils/intToUint8';
import EBoxType from '@utils/mp4/enum/EBoxType';
import ESize from '@utils/mp4/enum/ESize';
import IElst from '@utils/mp4/interfaces/IElst';
import IMdhd from '@utils/mp4/interfaces/IMdhd';
import IParsedBox from '@utils/mp4/interfaces/IParsedBox';
import ISidx from '@utils/mp4/interfaces/ISidx';
import Mp4Parser from '@utils/mp4/parser';

class AVBufferPatch {
  private _logger: ILogger;
  private _timescaleMap: Map<EContentType, number> = new Map();

  constructor(
    private _manifestManager: ManifestManager,
    loggerManager: LoggerManager,
    private _dispatcher: Dispatcher,
    private _contentType: EContentType.AUDIO | EContentType.VIDEO
  ) {
    this._logger = loggerManager.registerLogger(ELogType.BUFFER);
  }

  public encryptClearContentInit(data: ArrayBuffer): ArrayBuffer {
    if (this._manifestManager.contentProtections.length === 0) return data;

    this._logger.debug(`Patch: encrypt clear ${this._contentType} content (init)`);

    let sourceBox: IParsedBox | undefined;
    let unencryptedBoxData: Uint8Array | undefined;
    const initSegment: Uint8Array = createView(data, Uint8Array) as Uint8Array;

    const unencryptedBox = (box: IParsedBox): void => {
      const {start, size} = box;
      unencryptedBoxData = new Uint8Array(initSegment.buffer.slice(start, start + size));
    };

    new Mp4Parser(this._dispatcher)
      .box('moov', Mp4Parser.children)
      .box('trak', Mp4Parser.children)
      .box('mdia', Mp4Parser.children)
      .box('minf', Mp4Parser.children)
      .box('stbl', Mp4Parser.children)
      .fullBox('stsd', Mp4Parser.sampleDescription)
      .box('avc1', unencryptedBox)
      .box('mp4a', unencryptedBox)
      .parse(data);

    if (!unencryptedBoxData) return data;

    const sinfBox: Uint8Array = Mp4Parser.createSinfBox();

    const psshBoxes: Array<Uint8Array> = [];
    let psshBoxesLength: number = 0;
    let defaultKid: string = '';
    this._manifestManager.contentProtections.forEach((cp: IContentProtection) => {
      const {initData, keyId} = cp;
      if (initData) {
        psshBoxes.push(initData);
        psshBoxesLength += initData.byteLength;
      }
      if (!defaultKid) {
        defaultKid = keyId;
      }
    });

    const convertBox = (box: IParsedBox, newType: EBoxType): void => {
      sourceBox = box;
      const {reader, start, size} = box;
      Mp4Parser.updateBoxSize(reader, start, size + sinfBox.byteLength);
      Mp4Parser.updateBoxType(reader, start, newType);
    };

    const traverse = (box: IParsedBox, callback: (box: IParsedBox) => void): void => {
      const {reader, start, size, name} = box;
      let value: number = size + sinfBox.byteLength;
      if (unencryptedBoxData) {
        value += unencryptedBoxData.byteLength;
      }
      if (name === 'moov') {
        value += psshBoxesLength;
      }
      if (unencryptedBoxData) {
        Mp4Parser.updateBoxSize(reader, start, value);
      }

      if (name === 'stsd') {
        // update entry_count
        const offset: number = reader.getPosition();
        reader.setUint32(offset, 2);
      }
      callback(box);
    };

    new Mp4Parser(this._dispatcher)
      .box('moov', (box: IParsedBox) => traverse(box, Mp4Parser.children))
      .box('trak', (box: IParsedBox) => traverse(box, Mp4Parser.children))
      .box('mdia', (box: IParsedBox) => traverse(box, Mp4Parser.children))
      .box('minf', (box: IParsedBox) => traverse(box, Mp4Parser.children))
      .box('stbl', (box: IParsedBox) => traverse(box, Mp4Parser.children))
      .fullBox('stsd', (box: IParsedBox) => traverse(box, Mp4Parser.sampleDescription))
      .box('avc1', (box: IParsedBox) => convertBox(box, EBoxType.ENCV))
      .box('mp4a', (box: IParsedBox) => convertBox(box, EBoxType.ENCA))
      .parse(data);

    if (!sourceBox || psshBoxes.length === 0) return data;

    Mp4Parser.updateSinfBoxType(sinfBox, sourceBox.type);
    Mp4Parser.updateSinfDefaultKid(sinfBox, defaultKid);

    const newData: Uint8Array = new Uint8Array(
      data.byteLength + sinfBox.byteLength + unencryptedBoxData.byteLength + psshBoxesLength
    );
    const cutPoint: number = sourceBox.start + sourceBox.size;

    const beforeData: Uint8Array = initSegment.subarray(0, cutPoint);
    const afterData: Uint8Array = initSegment.subarray(cutPoint);

    newData.set(beforeData);
    newData.set(sinfBox, cutPoint);
    newData.set(unencryptedBoxData, cutPoint + sinfBox.byteLength);
    newData.set(afterData, cutPoint + sinfBox.byteLength + unencryptedBoxData.byteLength);

    let insertPoint: number = newData.byteLength - psshBoxesLength;
    psshBoxes.forEach((psshBox: Uint8Array) => {
      newData.set(psshBox, insertPoint);
      insertPoint += psshBox.byteLength;
    });

    return newData;
  }

  public encryptClearContentMedia(data: ArrayBuffer): [ArrayBuffer, boolean] {
    if (this._manifestManager.contentProtections.length === 0) return [data, false];

    this._logger.debug(`Patch: encrypt clear ${this._contentType} content (media)`);

    let sourceBox: IParsedBox | undefined;
    const initSegment: Uint8Array = createView(data, Uint8Array) as Uint8Array;
    const sampleDescriptionIndex: number = 2;
    const sampleDescriptionIndexData: Uint8Array = intToUint8(sampleDescriptionIndex);

    const traverse = (box: IParsedBox, callback: (box: IParsedBox) => void): void => {
      const {reader, start, size} = box;
      Mp4Parser.updateBoxSize(reader, start, size + sampleDescriptionIndexData.byteLength);
      callback(box);
    };

    const parseTfhd = (box: IParsedBox): void => {
      sourceBox = box;
      const {reader, start, size} = box;
      Mp4Parser.updateBoxSize(reader, start, size + sampleDescriptionIndexData.byteLength);
      // update flags
      const offset: number = start + ESize.UINT32 * 2;
      const flags: number = reader.getUint32(offset);
      reader.setUint32(offset, flags + sampleDescriptionIndex);
      const end: number = box.start + box.size - reader.getPosition();
      reader.skip(end);
    };

    const parseTrun = (box: IParsedBox): void => {
      const {reader} = box;
      // update data_offset
      const offset: number = reader.getPosition() + ESize.UINT32;
      const dataOffset: number = reader.getUint32(offset);
      reader.setUint32(offset, dataOffset + sampleDescriptionIndexData.byteLength);
    };

    new Mp4Parser(this._dispatcher)
      .box('moof', (box: IParsedBox) => traverse(box, Mp4Parser.children))
      .box('traf', (box: IParsedBox) => traverse(box, Mp4Parser.children))
      .fullBox('tfhd', parseTfhd)
      .fullBox('trun', parseTrun)
      .parse(data);

    if (!sourceBox) return [data, false];

    const newData: Uint8Array = new Uint8Array(data.byteLength + sampleDescriptionIndexData.byteLength);
    // cutting before default_sample_flags
    const cutPoint: number = sourceBox.start + ESize.UINT32 * 4;

    const beforeData: Uint8Array = initSegment.subarray(0, cutPoint);
    const afterData: Uint8Array = initSegment.subarray(cutPoint);

    newData.set(beforeData);
    newData.set(sampleDescriptionIndexData, cutPoint);
    newData.set(afterData, cutPoint + 4);

    return [newData, true];
  }

  public encryptFirstClearInitSegment(data: ArrayBuffer): ArrayBuffer {
    if (this._manifestManager.contentProtections.length === 0) return data;

    this._logger.debug(`Patch: encrypt clear ${this._contentType} init segment`);

    let sourceBox: IParsedBox | undefined;
    const sinfBox: Uint8Array = Mp4Parser.createSinfBox();

    const convertBox = (box: IParsedBox, newType: EBoxType): void => {
      sourceBox = box;
      const {reader, start, size, name} = box;
      this._logger.debug(`Patch: converting ${name} box to ${EBoxType[newType]}`);
      Mp4Parser.updateBoxSize(reader, start, size + sinfBox.byteLength);
      Mp4Parser.updateBoxType(reader, start, newType);
    };

    const traverse = (box: IParsedBox, callback: (box: IParsedBox) => void): void => {
      const {reader, start, size} = box;
      Mp4Parser.updateBoxSize(reader, start, size + sinfBox.byteLength);
      callback(box);
    };

    new Mp4Parser(this._dispatcher)
      .box('moov', (box: IParsedBox) => traverse(box, Mp4Parser.children))
      .box('trak', (box: IParsedBox) => traverse(box, Mp4Parser.children))
      .box('mdia', (box: IParsedBox) => traverse(box, Mp4Parser.children))
      .box('minf', (box: IParsedBox) => traverse(box, Mp4Parser.children))
      .box('stbl', (box: IParsedBox) => traverse(box, Mp4Parser.children))
      .fullBox('stsd', (box: IParsedBox) => traverse(box, Mp4Parser.sampleDescription))
      .box('avc1', (box: IParsedBox) => convertBox(box, EBoxType.ENCV))
      .box('mp4a', (box: IParsedBox) => convertBox(box, EBoxType.ENCA))
      .parse(data);

    if (!sourceBox) return data;

    Mp4Parser.updateSinfBoxType(sinfBox, sourceBox.type);

    const initSegment: Uint8Array = createView(data, Uint8Array) as Uint8Array;
    const newData: Uint8Array = new Uint8Array(data.byteLength + sinfBox.byteLength);
    const cutPoint: number = sourceBox.start + sourceBox.size;

    const beforeData: Uint8Array = initSegment.subarray(0, cutPoint);
    const afterData: Uint8Array = initSegment.subarray(cutPoint);

    newData.set(beforeData);
    newData.set(sinfBox, cutPoint);
    newData.set(afterData, cutPoint + sinfBox.byteLength);

    return newData;
  }

  public normaliseTimescaleInit(data: ArrayBuffer): ArrayBuffer {
    this._logger.debug(`Patch: normalise timescale ${this._contentType} segment (init)`);

    const timescale: number = 100000;
    new Mp4Parser(this._dispatcher)
      .box('moov', Mp4Parser.children)
      .fullBox('mvhd', (box: IParsedBox) => {
        const {reader, version} = box;
        const payloadPosition: number = reader.getPosition();
        Mp4Parser.updateMvhdTimescale(reader, version, payloadPosition, timescale);
        const end: number = box.start + box.size - reader.getPosition();
        reader.skip(end);
      })
      .box('trak', Mp4Parser.children)
      .box('mdia', Mp4Parser.children)
      .fullBox('mdhd', (box: IParsedBox) => {
        const {reader, version} = box;
        const payloadPosition: number = reader.getPosition();
        const parsedMdhd: IMdhd = Mp4Parser.parseMdhd(box);
        this._timescaleMap.set(this._contentType, parsedMdhd.timescale);
        Mp4Parser.updateMdhdTimescale(reader, version, payloadPosition, timescale);
        const end: number = box.start + box.size - reader.getPosition();
        reader.skip(end);
      })
      .parse(data);

    const originalTimescale: number | undefined = this._timescaleMap.get(this._contentType);
    if (!originalTimescale) return data;

    new Mp4Parser(this._dispatcher)
      .box('moov', Mp4Parser.children)
      .box('mvex', Mp4Parser.children)
      .fullBox('mehd', (box: IParsedBox) => {
        const {reader, version} = box;
        const payloadPosition: number = reader.getPosition();
        Mp4Parser.updateMehdTimescale(reader, version, payloadPosition, originalTimescale, timescale);
        const end: number = box.start + box.size - reader.getPosition();
        reader.skip(end);
      })
      .fullBox('trex', (box: IParsedBox) => {
        const {reader} = box;
        const payloadPosition: number = reader.getPosition();
        Mp4Parser.updateTrexTimescale(reader, payloadPosition, originalTimescale, timescale);
        const end: number = box.start + box.size - reader.getPosition();
        reader.skip(end);
      })
      .parse(data);

    return data;
  }

  public normaliseTimescaleMedia(data: ArrayBuffer): ArrayBuffer {
    this._logger.debug(`Patch: normalise timescale ${this._contentType} segment (media)`);

    const timescale: number = 100000;
    let originalTimescale: number | undefined = this._timescaleMap.get(this._contentType);

    new Mp4Parser(this._dispatcher)
      .fullBox('sidx', (box: IParsedBox) => {
        const {reader, version} = box;
        const payloadPosition: number = reader.getPosition();
        const parsedSidxBox: ISidx = Mp4Parser.parseSidx(box);
        originalTimescale = parsedSidxBox.timescale;
        Mp4Parser.updateSidxTimescale(reader, version, payloadPosition, timescale);
        const end: number = box.start + box.size - reader.getPosition();
        reader.skip(end);
      })
      .box('moof', Mp4Parser.children)
      .box('traf', Mp4Parser.children)
      .fullBox('tfdt', (box: IParsedBox) => {
        if (!originalTimescale) return;
        const {reader, version} = box;
        const payloadPosition: number = reader.getPosition();
        Mp4Parser.updateTfdtTimescale(reader, version, payloadPosition, originalTimescale, timescale);
        const end: number = box.start + box.size - reader.getPosition();
        reader.skip(end);
      })
      .fullBox('tfhd', (box: IParsedBox) => {
        if (!originalTimescale) return;
        const {reader, flags} = box;
        const payloadPosition: number = reader.getPosition();
        Mp4Parser.updateTfhdTimescale(reader, flags, payloadPosition, originalTimescale, timescale);
        const end: number = box.start + box.size - reader.getPosition();
        reader.skip(end);
      })
      .fullBox('trun', (box: IParsedBox) => {
        if (!originalTimescale) return;
        const {reader, version, flags} = box;
        const payloadPosition: number = reader.getPosition();
        Mp4Parser.updateTrunTimescale(reader, version, flags, payloadPosition, originalTimescale, timescale);
        const end: number = box.start + box.size - reader.getPosition();
        reader.skip(end);
      })
      .parse(data);

    return data;
  }

  public removeGapFromFirstInitSegment(data: ArrayBuffer): ArrayBuffer {
    new Mp4Parser(this._dispatcher)
      .box('moov', Mp4Parser.children)
      .box('trak', Mp4Parser.children)
      .box('edts', Mp4Parser.children)
      .fullBox('elst', (box: IParsedBox) => {
        const {reader, version} = box;
        const payloadPosition: number = reader.getPosition();
        const parsedElstBox: IElst = Mp4Parser.parseElst(box);
        if (parsedElstBox.mediaTime !== 0) {
          this._logger.debug(
            `Patch: ${this._contentType} media time is ${parsedElstBox.mediaTime}, adjusting to 0`
          );
          Mp4Parser.updateElstMediaTime(reader, version, payloadPosition, 0);
        } else {
          this._logger.debug(`Patch: ${this._contentType} media time is already 0`);
        }
      })
      .parse(data);

    return data;
  }

  public destroy(): void {
    this._logger.info(`Destroying ${this._contentType} buffer patch`);

    this._timescaleMap.clear();
  }
}

export default AVBufferPatch;
