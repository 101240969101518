enum EPlayerState {
  BUFFERING = 'BUFFERING',
  ENDED = 'ENDED',
  LOADING = 'LOADING',
  PAUSED = 'PAUSED',
  PLAYING = 'PLAYING',
  SEEKING = 'SEEKING',
  STOPPED = 'STOPPED',
  UNKNOWN = 'UNKNOWN'
}

export default EPlayerState;
