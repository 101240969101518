import Dispatcher from '@dispatcher/dispatcher';
import EEvent from '@dispatcher/enum/EEvent';
import EErrorCode from '@error/enum/EErrorCode';
import EErrorSeverity from '@error/enum/EErrorSeverity';
import EErrorType from '@error/enum/EErrorType';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';

import ETextCodecs from './enum/ETextCodecs';
import IParser from './interfaces/IParser';
import TtmlParser from './ttml/parser';
import WebVTTParser from './webvtt/parser';

class TextParser {
  private _logger: ILogger;
  private _parser: IParser | null = null;

  constructor(codecs: string, loggerManager: LoggerManager, private _dispatcher: Dispatcher) {
    this._logger = loggerManager.registerLogger(ELogType.PARSER);

    if (codecs === ETextCodecs.STPP || codecs === ETextCodecs.STPP_TTML) {
      this._parser = new TtmlParser(loggerManager, this._dispatcher);
    } else if (codecs === ETextCodecs.WEBVTT) {
      this._parser = new WebVTTParser(loggerManager, this._dispatcher);
    } else {
      const message: string = 'Text track not supported';
      this._logger.error(message);
      this._dispatcher.emit({
        name: EEvent.TAPE_ERROR,
        type: EErrorType.INTERNAL,
        code: EErrorCode.TEXT_TRACK_NOT_SUPPORTED,
        severity: EErrorSeverity.FATAL,
        message
      });
    }
  }

  get parser(): IParser | null {
    return this._parser;
  }

  public destroy(): void {
    this._logger.info('Destroying text Parser');

    this._parser?.destroy();
    this._parser = null;
  }
}

export default TextParser;
