import EContentType from '@parser/manifest/enum/EContentType';
import EMimeType from '@parser/manifest/enum/EMimeType';

const guessMimeType = (extension: string, contentType: EContentType): EMimeType => {
  switch (contentType) {
    case EContentType.AUDIO:
      return EMimeType.AUDIO;
    case EContentType.VIDEO:
      return EMimeType.VIDEO;
    case EContentType.TEXT:
      if (extension.includes('vtt')) return EMimeType.WEBVTT;
      if (extension === 'ttml') return EMimeType.TTML;

      return EMimeType.MP4;
    case EContentType.IMAGE:
      return EMimeType.IMAGE;
  }
};

export default guessMimeType;
