import Dispatcher from '@dispatcher/dispatcher';
import EEvent from '@dispatcher/enum/EEvent';
import ENativeEvent from '@dispatcher/enum/ENativeEvent';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';

import EPlayerState from './enum/EPlayerState';

/**
 * This class listen for native events from the video element
 * and convert those into specific player states
 */
class StateManager {
  private _logger: ILogger;
  private _playerState: EPlayerState = EPlayerState.UNKNOWN;

  constructor(
    private _videoElement: HTMLVideoElement,
    loggerManager: LoggerManager,
    private _dispatcher: Dispatcher
  ) {
    this._logger = loggerManager.registerLogger(ELogType.STATE);
    this.addListeners();
    this.setPlayerState(EPlayerState.LOADING);
  }

  get playerState(): EPlayerState {
    return this._playerState;
  }

  private addListeners(): void {
    this._videoElement.addEventListener(ENativeEvent.CAN_PLAY_THROUGH, this.canPlayThrough);
    this._videoElement.addEventListener(ENativeEvent.PLAYING, this.onPlaying);
    this._videoElement.addEventListener(ENativeEvent.ENDED, this.onEnded);
    this._videoElement.addEventListener(ENativeEvent.PAUSE, this.onPause);
    this._videoElement.addEventListener(ENativeEvent.PLAY, this.onPlay);
    this._videoElement.addEventListener(ENativeEvent.SEEKING, this.onSeeking);
    this._videoElement.addEventListener(ENativeEvent.SEEKED, this.onSeeked);
    this._videoElement.addEventListener(ENativeEvent.WAITING, this.onWaiting);
  }

  private removeListeners(): void {
    this._videoElement.removeEventListener(ENativeEvent.CAN_PLAY_THROUGH, this.canPlayThrough);
    this._videoElement.removeEventListener(ENativeEvent.PLAYING, this.onPlaying);
    this._videoElement.removeEventListener(ENativeEvent.ENDED, this.onEnded);
    this._videoElement.removeEventListener(ENativeEvent.PAUSE, this.onPause);
    this._videoElement.removeEventListener(ENativeEvent.PLAY, this.onPlay);
    this._videoElement.removeEventListener(ENativeEvent.SEEKING, this.onSeeking);
    this._videoElement.removeEventListener(ENativeEvent.SEEKED, this.onSeeked);
    this._videoElement.removeEventListener(ENativeEvent.WAITING, this.onWaiting);
  }

  private canPlayThrough = (_e: Event): void => {
    const {paused} = this._videoElement;
    if (paused) {
      this.setPlayerState(EPlayerState.PAUSED);
    }
  };

  private onPlaying = (_e: Event): void => {
    this.setPlayerState(EPlayerState.PLAYING);
  };

  private onEnded = (_e: Event): void => {
    this.setPlayerState(EPlayerState.ENDED);
  };

  private onPause = (_e: Event): void => {
    const {ended} = this._videoElement;
    if (!ended) {
      this.setPlayerState(EPlayerState.PAUSED);
    }
  };

  private onPlay = (_e: Event): void => {
    this.setPlayerState(EPlayerState.PLAYING);
  };

  private onSeeking = (_e: Event): void => {
    if (this.playerState === EPlayerState.LOADING) return;
    this.setPlayerState(EPlayerState.SEEKING);
  };

  private onSeeked = (_e: Event): void => {
    const {paused, seeking} = this._videoElement;
    if (this.playerState === EPlayerState.LOADING || seeking) return;
    if (paused) {
      this.setPlayerState(EPlayerState.PAUSED);
    } else {
      this.setPlayerState(EPlayerState.PLAYING);
    }
  };

  private onWaiting = (_e: Event): void => {
    this.setPlayerState(EPlayerState.BUFFERING);
  };

  private setPlayerState(playerState: EPlayerState): void {
    if (this.playerState === playerState && playerState !== EPlayerState.SEEKING) return;
    this._logger.log(`Player state changed from to ${this.playerState} to ${playerState}`);
    this._playerState = playerState;

    const {currentTime} = this._videoElement;
    this._dispatcher.emit({
      name: EEvent.PLAYER_STATE_CHANGE,
      playerState,
      currentTime
    });
  }

  public destroy(): void {
    this._logger.info('Destroying State manager');
    this.removeListeners();

    this.setPlayerState(EPlayerState.STOPPED);
  }
}

export default StateManager;
