enum EEvent {
  AVAILABLE_REPRESENTATIONS = 'AVAILABLE_REPRESENTATIONS',
  BUFFER_UPDATE = 'BUFFER_UPDATE',
  CDN_CHANGE = 'CDN_CHANGE',
  CUES_CHANGE = 'CUES_CHANGE',
  ESTIMATED_BANDWIDTH = 'ESTIMATED_BANDWIDTH',
  GAP_DETECTED = 'GAP_DETECTED',
  HTTP_REQUEST = 'HTTP_REQUEST',
  INBAND_STREAM = 'INBAND_STREAM',
  OUTBAND_STREAM = 'OUTBAND_STREAM',
  MANIFEST_DOWNLOADED = 'MANIFEST_DOWNLOADED',
  MANIFEST_READY = 'MANIFEST_READY',
  ACTIVE_REPRESENTATION_CHANGE = 'ACTIVE_REPRESENTATION_CHANGE',
  PLAYER_STATE_CHANGE = 'PLAYER_STATE_CHANGE',
  RATE_CHANGE = 'RATE_CHANGE',
  REPRESENTATION_CHANGE = 'REPRESENTATION_CHANGE',
  REPRESENTATION_EMPTY = 'REPRESENTATION_EMPTY',
  SEEKABLE_RANGE_CHANGE = 'SEEKABLE_RANGE_CHANGE',
  TAPE_ERROR = 'TAPE_ERROR',
  TAPE_LOG = 'TAPE_LOG',
  THUMBNAILS_UPDATE = 'THUMBNAILS_UPDATE',
  TIME_UPDATE = 'TIME_UPDATE',
  VOLUME_CHANGE = 'VOLUME_CHANGE'
}

export default EEvent;
