import ConfigManager from '@config/configManager';
import Dispatcher from '@dispatcher/dispatcher';
import EEvent from '@dispatcher/enum/EEvent';
import EErrorCode from '@error/enum/EErrorCode';
import EErrorSeverity from '@error/enum/EErrorSeverity';
import EErrorType from '@error/enum/EErrorType';
import IHttpError from '@error/interfaces/IHttpError';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import ERequestType from '@network/enum/ERequestType';
import IHttpResponse from '@network/interfaces/IHttpResponse';
import INetworkOptions from '@network/interfaces/INetworkOptions';
import NetworkManager from '@network/networkManager';
import ProbeCapabilities from '@utils/capabilities/probe';
import getAudioChannels from '@utils/getAudioChannels';
import getBaseUrl from '@utils/getBaseUrl';
import getFileExtension from '@utils/getFileExtension';
import getTimeFromDate from '@utils/getTimeFromDate';
import guessCodecs from '@utils/guessCodecs';
import guessMimeType from '@utils/guessMimeType';
import isAbsoluteUrl from '@utils/isAbsoluteUrl';
import IMdhd from '@utils/mp4/interfaces/IMdhd';
import IParsedBox from '@utils/mp4/interfaces/IParsedBox';
import ITfdt from '@utils/mp4/interfaces/ITfdt';
import Mp4Parser from '@utils/mp4/parser';
import round from '@utils/round';

import EAudioChannelSchemeUri from '../enum/EAudioChannelSchemeUri';
import ECeaSchemeUri from '../enum/ECeaSchemeUri';
import EContentType from '../enum/EContentType';
import EKeySystem from '../enum/EKeySystem';
import EManifestType from '../enum/EManifestType';
import EMimeType from '../enum/EMimeType';
import EPlayable from '../enum/EPlayable';
import EProtocol from '../enum/EProtocol';
import IAccessibility from '../interfaces/IAccessibility';
import IAdaptationSet from '../interfaces/IAdaptationSet';
import IAudioChannelConfiguration from '../interfaces/IAudioChannelConfiguration';
import IContentProtection from '../interfaces/IContentProtection';
import IEssentialProperty from '../interfaces/IEssentialProperty';
import IManifest from '../interfaces/IManifest';
import IOutbandEventStream from '../interfaces/IOutbandEventStream';
import IRepresentation from '../interfaces/IRepresentation';
import IRole from '../interfaces/IRole';
import ISegment from '../interfaces/ISegment';
import EKeyFormat from './enum/EKeyFormat';
import EMediaType from './enum/EMediaType';
import EPlaylistType from './enum/EPlaylistType';
import ETagName from './enum/ETagName';
import IRefPlaylist from './interfaces/IRefPlaylist';
import ITag from './interfaces/ITag';

type IVideoDataRepresentation = {
  id: string;
  contentType: EContentType;
  contentProtection: IContentProtection | null;
  mimeType: EMimeType;
  videoCodec: string;
  frameRate: number;
  uri: string;
  width: number;
  height: number;
  bandwidth: number;
};

class HlsParser {
  private _logger: ILogger;
  private _manifest: IManifest;
  // keeps in memory a start time reference for Audio and a start time reference for Video separately
  private _refPlaylists: [IRefPlaylist, IRefPlaylist] = [{}, {}]; // [video, audio]
  private _parsedPlaylists: [boolean, boolean] = [false, false]; // [video, audio]
  private _representationMap: Map<string, [IRepresentation, string]> = new Map();
  private _maxPublishTime: number = 0;

  private _CODECS_MAP: Record<Exclude<EContentType, EContentType.IMAGE>, Array<string>> = {
    [EContentType.VIDEO]: ['avc', 'hev', 'hvc', 'vp0', 'av01'],
    [EContentType.AUDIO]: ['vorbis', 'opus', 'flac', 'mp4a', 'aec', 'ec-3'],
    [EContentType.TEXT]: ['vtt', 'wvtt', 'stpp']
  };
  private _KEY_SYSTEM_MAP: Record<EKeyFormat, EKeySystem> = {
    [EKeyFormat.WIDEVINE]: EKeySystem.WIDEVINE,
    [EKeyFormat.PLAYREADY]: EKeySystem.PLAYREADY,
    [EKeyFormat.FAIRPLAY]: EKeySystem.FAIRPLAY
  };

  constructor(
    private _url: URL,
    private _networkManager: NetworkManager,
    private _configManager: ConfigManager,
    private _probeCapabilities: ProbeCapabilities,
    loggerManager: LoggerManager,
    private _dispatcher: Dispatcher
  ) {
    this._logger = loggerManager.registerLogger(ELogType.PARSER);
    // TODO: handle merge logic in manifest manager!
    this._manifest = {
      url: this._url.href,
      type: EManifestType.DYNAMIC,
      protocol: EProtocol.HLS,
      availabilityStartTime: 0,
      producerReferenceStartTime: 0,
      maxSegmentDuration: 0,
      mediaPresentationDuration: 0,
      minBufferTime: 0,
      minimumUpdatePeriod: 0,
      publishTime: 0,
      timeShiftBufferDepth: 0,
      suggestedPresentationDelay: 0,
      patchLocation: null,
      periods: []
    };

    this._manifest.periods[0] = {
      manifest: this._manifest,
      id: `period_0`,
      start: 0,
      duration: Infinity,
      eventStream: null,
      video: [],
      audio: [],
      text: [],
      image: []
    };
  }

  public get representationMap(): Map<string, [IRepresentation, string]> {
    return this._representationMap;
  }

  private buildBaseAdaptationSet(
    id: string,
    contentType: EContentType,
    mimeType: EMimeType,
    bandwidth: number = 0,
    width: number = 0,
    height: number = 0,
    frameRate: number = 0,
    label: string = '',
    lang: string = '',
    codecs: string = '',
    roles: Array<IRole> = [],
    audioChannelConfigurations: Array<IAudioChannelConfiguration>
  ): IAdaptationSet {
    const adaptation: IAdaptationSet = {
      period: this._manifest.periods[0],
      id: `a_${id}`,
      playable: new Map(),
      contentType,
      mimeType,
      label,
      lang: lang.toLowerCase(),
      maxWidth: width,
      maxHeight: height,
      minBandwidth: bandwidth,
      maxBandwidth: bandwidth,
      accessibilities: [],
      roles,
      inbandEventStreams: [],
      contentProtections: [],
      representations: []
    };

    adaptation.representations = [
      this.buildBaseRepresentation(
        adaptation,
        id,
        bandwidth,
        width,
        height,
        frameRate,
        codecs,
        audioChannelConfigurations
      )
    ];

    return adaptation;
  }

  private buildBaseRepresentation(
    adaptation: IAdaptationSet,
    id: string,
    bandwidth: number,
    width: number,
    height: number,
    frameRate: number,
    codecs: string,
    audioChannelConfigurations: Array<IAudioChannelConfiguration> = []
  ): IRepresentation {
    return {
      adaptation,
      id,
      bandwidth,
      codecs,
      width,
      height,
      frameRate,
      presentationTimeOffset: 0,
      timescale: 0,
      essentialProperties: [],
      audioChannelConfigurations,
      segments: [],
      buildSegments: null
    };
  }

  private buildAccessibilities(closedCaptions: Array<[string, string]>): Array<IAccessibility> {
    if (closedCaptions.length <= 0) return [];
    let schemeIdUri: string = '';
    const values: Array<string> = [];

    for (let i: number = 0; i < closedCaptions.length; i++) {
      const closedCaption: [string, string] = closedCaptions[i];
      const [instreamId, language] = closedCaption;
      if (instreamId.indexOf('SERVICE') >= 0) {
        schemeIdUri = ECeaSchemeUri.CEA708;
        const channel: string = instreamId.replace('SERVICE', '');
        values.push(`${channel}=lang:${language}`);
      } else if (instreamId.indexOf('CC') >= 0) {
        schemeIdUri = ECeaSchemeUri.CEA608;
        values.push(`${instreamId}=${language}`);
      }
    }
    const value: string = values.join(';');
    const accessibility: IAccessibility = {
      schemeIdUri,
      value
    };

    return [accessibility];
  }

  private buildContentProtection(keyFormat: EKeyFormat, uri: string, iv: string): IContentProtection | null {
    const keyId: string = this.getKeyId(uri);
    const keySystem: EKeySystem | undefined = this._KEY_SYSTEM_MAP[keyFormat];
    const schemeIdUri: string = '';

    if (!keySystem) return null;

    let initData: Uint8Array | null = null;
    if (iv) {
      initData = this.getInitData(iv);
    }

    return {
      initData,
      keyId,
      keySystem,
      schemeIdUri
    };
  }

  private buildEssentialProperties(layout: string): Array<IEssentialProperty> {
    const essentialProperty: IEssentialProperty = {
      schemeIdUri: 'http://dashif.org/thumbnail_tile',
      value: layout
    };

    return [essentialProperty];
  }

  private buildSegment(
    representation: IRepresentation,
    id: number,
    url: string,
    duration: number,
    time: number,
    byteRange: [number, number] | null
  ): ISegment {
    return {
      representation,
      id,
      url,
      duration,
      time,
      byteRange
    };
  }

  private canUpdateManifest(): boolean {
    return this._refPlaylists[0].startTime !== undefined && this._refPlaylists[1].startTime !== undefined;
  }

  private getAudioChannelConfiguration(
    attributes: Record<string, string>
  ): IAudioChannelConfiguration | null {
    const channels: string = this.getAttribute(attributes, 'CHANNELS');

    if (!channels) {
      return null;
    }
    const tokens: Array<string> = channels.split('/');
    const value: string = tokens[0];
    const schemeIdUri: EAudioChannelSchemeUri = EAudioChannelSchemeUri.URN_DTS;

    return {
      schemeIdUri,
      value,
      channels: getAudioChannels(schemeIdUri, value)
    };
  }

  private getByteRange(value: string): [number, number] {
    const tokens: Array<string> = value.split('@');
    const byteLength: number = +tokens[0];
    const start: number = +tokens[1];
    const end: number = start + byteLength - 1;

    return [start, end];
  }

  private getCodecs(attribute: string): [string, string] {
    const codecs: Array<string> = attribute.split(',');
    let videoCodec: string = '';
    let audioCodec: string = '';
    for (let i: number = 0; i < codecs.length; i++) {
      const codec: string = codecs[i];
      if (!videoCodec) {
        for (let x: number = 0; x < this._CODECS_MAP[EContentType.VIDEO].length; x++) {
          if (codec.indexOf(this._CODECS_MAP[EContentType.VIDEO][x]) === 0) {
            videoCodec = codec;
            break;
          }
        }
      }
      if (!audioCodec) {
        for (let x: number = 0; x < this._CODECS_MAP[EContentType.AUDIO].length; x++) {
          if (codec.indexOf(this._CODECS_MAP[EContentType.AUDIO][x]) === 0) {
            audioCodec = codec;
            break;
          }
        }
      }
      if (videoCodec && audioCodec) break;
    }

    return [videoCodec, audioCodec];
  }

  private getFrameRate(frameRateAttr: string): number {
    let frameRate: number;
    const tokens: Array<string> = frameRateAttr.split('/');
    if (tokens[1] === undefined) {
      frameRate = +tokens[0];
    } else {
      frameRate = +tokens[0] / +tokens[1];
      frameRate = round(frameRate, 3);
    }

    return frameRate;
  }

  private getKeyId(uri: string): string {
    const tokens: Array<string> = uri.split('//');

    return tokens[1] ?? '';
  }

  private getInitData(iv: string): Uint8Array {
    let stringValue: string = iv.slice(2);
    stringValue = (stringValue.length % 2 ? '0' : '') + stringValue;

    const value: Uint8Array = new Uint8Array(stringValue.length / 2);
    for (let i: number = 0; i < stringValue.length / 2; i++) {
      value[i] = parseInt(stringValue.slice(i * 2, i * 2 + 2), 16);
    }

    return value;
  }

  private getRefPlaylist(representation: IRepresentation): IRefPlaylist | null {
    if (!this.isAudioOrVideo(representation)) return null;
    let refPlaylist: IRefPlaylist | null = null;
    switch (representation.adaptation.contentType) {
      case EContentType.VIDEO:
        refPlaylist = this._refPlaylists[0];
        break;
      case EContentType.AUDIO:
        refPlaylist = this._refPlaylists[1];
        break;
    }

    return refPlaylist;
  }

  private getTextRole(attributes: Record<string, string>): IRole {
    const forced: string = this.getAttribute(attributes, 'FORCED');
    let value: string = 'subtitle';
    if (forced === 'YES') {
      value = 'forced-subtitle';
    }

    return {
      schemeIdUri: '',
      value
    };
  }

  private getUri(url: string, baseUrl: string): string {
    if (isAbsoluteUrl(url)) {
      return url;
    } else {
      return `${baseUrl}${url}`;
    }
  }

  private getAttribute(attributes: Record<string, string>, attributeName: string): string {
    const value: string = attributes[attributeName] || '';

    return value.replace(/"/g, '');
  }

  private getMimeType(uri: string, contentType: EContentType): EMimeType {
    const extension: string = getFileExtension(uri);

    return guessMimeType(extension, contentType);
  }

  private getWidthHeight(attribute: string): {width: number; height: number} {
    const tokens: Array<string> = attribute.split('x');
    if (tokens.length === 2) {
      return {width: +tokens[0], height: +tokens[1]};
    }

    return {width: 0, height: 0};
  }

  private isAudioOrVideo(representation: IRepresentation): boolean {
    return [EContentType.VIDEO, EContentType.AUDIO].includes(representation.adaptation.contentType);
  }

  private mapMediaToContent(mediaType: EMediaType.AUDIO | EMediaType.SUBTITLES): EContentType {
    switch (mediaType) {
      case EMediaType.AUDIO:
        return EContentType.AUDIO;
      case EMediaType.SUBTITLES:
        return EContentType.TEXT;
    }
  }

  private mapPlaylistTypeToManifest(playlistType: EPlaylistType): EManifestType {
    switch (playlistType) {
      case EPlaylistType.VOD:
        return EManifestType.STATIC;
      case EPlaylistType.EVENT:
        return EManifestType.DYNAMIC;
    }
  }

  private pushBaseImageAdaptationSet(): IAdaptationSet {
    const imageAdaptation: IAdaptationSet = {
      period: this._manifest.periods[0],
      id: 'a_0',
      playable: new Map(),
      contentType: EContentType.IMAGE,
      mimeType: '' as EMimeType,
      label: '',
      lang: '',
      maxWidth: 0,
      maxHeight: 0,
      minBandwidth: Infinity,
      maxBandwidth: 0,
      accessibilities: [],
      roles: [],
      inbandEventStreams: [],
      contentProtections: [],
      representations: []
    };

    this._manifest.periods[0].image.push(imageAdaptation);

    return imageAdaptation;
  }

  private pushBaseVideoAdaptationSet(): IAdaptationSet {
    const videoAdaptation: IAdaptationSet = {
      period: this._manifest.periods[0],
      id: 'a_0',
      playable: new Map(),
      contentType: EContentType.VIDEO,
      mimeType: EMimeType.VIDEO,
      label: '',
      lang: '',
      maxWidth: 0,
      maxHeight: 0,
      minBandwidth: Infinity,
      maxBandwidth: 0,
      accessibilities: [],
      roles: [],
      inbandEventStreams: [],
      contentProtections: [],
      representations: []
    };

    this._manifest.periods[0].video.push(videoAdaptation);

    return videoAdaptation;
  }

  private removeRepresentation(representation: IRepresentation): void {
    const adaptations: Array<IAdaptationSet> =
      this._manifest.periods[0][representation.adaptation.contentType];
    const adaptationIndex: number = adaptations.findIndex(
      (a: IAdaptationSet) => a.id === representation.adaptation.id
    );
    if (adaptationIndex === -1) return;

    const representationIndex: number = adaptations[adaptationIndex].representations.findIndex(
      (r: IRepresentation) => r.id === representation.id
    );
    if (representationIndex === -1) return;

    adaptations[adaptationIndex].representations.splice(representationIndex, 1);
    if (adaptations[adaptationIndex].representations.length <= 0) {
      this._logger.debug(
        `Adaptation "${adaptations[adaptationIndex].id}" is not playable due to media capabilities`
      );
      adaptations[adaptationIndex].playable.set(EPlayable.MEDIA_CAPABILITIES, false);
    }
  }

  private requestAdditionalSegments(representation: IRepresentation): Promise<IManifest | null> {
    const refPlaylist: IRefPlaylist | null = this.getRefPlaylist(representation);
    if (!refPlaylist) return Promise.reject();

    if (refPlaylist.startTime !== undefined) return Promise.reject();

    const initSegment: ISegment = representation.segments[0];
    const mediaSegment: ISegment = representation.segments[1];

    if (initSegment.id !== 0) {
      const message: string = `Can't request additional segment: ${representation.adaptation.contentType} representation ${representation.id} doesn't have an init segment`;
      this._logger.warn(message);
      this._dispatcher.emit({
        name: EEvent.TAPE_ERROR,
        type: EErrorType.INTERNAL,
        code: EErrorCode.PARSER,
        severity: EErrorSeverity.WARN,
        message
      });

      return Promise.reject();
    }

    return Promise.all([
      this.requestAdditionalSegment(initSegment),
      this.requestAdditionalSegment(mediaSegment)
    ]).then((r: [IManifest | null, IManifest | null]) => {
      if (r[0]) {
        return r[0];
      } else if (r[1]) {
        return r[1];
      }

      return null;
    });
  }

  private requestAdditionalSegment(segment: ISegment): Promise<IManifest | null> {
    const networkOptions: INetworkOptions = {};
    if (segment.byteRange) {
      networkOptions.byteRange = segment.byteRange;
    }

    return this._networkManager.request(segment.url, {type: ERequestType.MANIFEST}, networkOptions).then(
      (r: IHttpResponse) => {
        return this.parseSegment(r.data as ArrayBuffer, segment.representation.id, segment.id);
      },
      (_: IHttpError) => null
    );
  }

  // sort representations by bitrate (highest to lowest)
  private sortVideoRepresentations = (
    vD1: IVideoDataRepresentation,
    vD2: IVideoDataRepresentation
  ): number => {
    return vD2.bandwidth - vD1.bandwidth;
  };

  private updateRefPlaylists(representation: IRepresentation, mediaSequence: number): void {
    const refPlaylist: IRefPlaylist | null = this.getRefPlaylist(representation);
    if (!refPlaylist) return;

    const durations: Array<number> = [];
    for (let i: number = 0; i < representation.segments.length; i++) {
      const segment: ISegment = representation.segments[i];
      if (segment.id !== 0) {
        durations.push(segment.duration);
      }
    }

    if (refPlaylist.sequence === undefined || refPlaylist.durations === undefined) {
      refPlaylist.sequence = mediaSequence;
      refPlaylist.durations = durations;
    } else {
      const prevStartTime: number | undefined = refPlaylist.startTime;
      const prevSequence: number = refPlaylist.sequence;
      const prevDurations: Array<number> = [...refPlaylist.durations];

      if (!prevStartTime) return;

      refPlaylist.sequence = mediaSequence;
      refPlaylist.durations = durations;

      const delta: number = mediaSequence - prevSequence;

      if (delta === 0) return;

      if (delta > prevDurations.length) {
        this._logger.debug(
          `Need to refetch additional segments for ${representation.adaptation.contentType} representation ${representation.id}`
        );
        refPlaylist.timescale = undefined;
        refPlaylist.baseMediaDecodeTime = undefined;
        refPlaylist.startTime = undefined;
      } else {
        let time: number = 0;
        for (let x: number = 0; x < delta; x++) {
          const duration: number = prevDurations[x];
          time += duration;
        }
        const currentStartTime: number = prevStartTime + time;
        this._logger.debug(
          `Updating start time from ${prevStartTime} to ${currentStartTime} for ${representation.adaptation.contentType} representation ${representation.id}, delta ${delta}`
        );
        refPlaylist.startTime = currentStartTime;
      }
    }
  }

  private updateOutbandEventStream(datarangeTags: Array<ITag>): void {
    if (datarangeTags.length === 0) return;

    const outbandEventStream: IOutbandEventStream = {
      schemeIdUri: '',
      events: []
    };

    for (let i: number = 0; i < datarangeTags.length; i++) {
      const datarangeTag: ITag = datarangeTags[i];
      const attributes: Record<string, string> = datarangeTag.attributes as Record<string, string>;
      const idAttr: string = this.getAttribute(attributes, 'ID');
      const durationAttr: string =
        this.getAttribute(attributes, 'DURATION') || this.getAttribute(attributes, 'PLANNED-DURATION');
      const eventDuration: number = +durationAttr;
      const startDateAttr: string = this.getAttribute(attributes, 'START-DATE');
      const presentationTime: number = getTimeFromDate(startDateAttr);

      outbandEventStream.events.push({
        id: idAttr,
        eventDuration,
        presentationTime,
        value: JSON.stringify(attributes)
      });
    }

    this._manifest.periods[0].eventStream = outbandEventStream;
  }

  private parseTag(line: string): ITag | null {
    const [firstToken, ...rest]: Array<string> = line.split(':');
    if (firstToken.indexOf('#EXT') < 0 && firstToken.indexOf('#USP') < 0) return null;

    const name: ETagName = firstToken.substring(1) as ETagName;
    const value: string | null = rest.join(':') ?? null;
    let attributes: Record<string, string> | null = null;
    if (value !== null) {
      const attributeRegex: RegExp = /(?:[^,=]+)(?:=((?:"[^"]+"|[^,"]+)))/g;
      const attributeMatches: RegExpMatchArray | null = value.match(attributeRegex);

      if (attributeMatches) {
        attributes = {};

        for (let i: number = 0; i < attributeMatches.length; i++) {
          const attributeMatch: string = attributeMatches[i];
          const splitRegex: RegExp = /([^=]+?)=(.+)/;
          const splitMatches: RegExpMatchArray | null = attributeMatch.match(splitRegex);
          if (splitMatches) {
            const [, attributeName, attributeValue] = splitMatches;
            attributes[attributeName] = attributeValue;
          }
        }
      }
    }

    return {name, value, attributes};
  }

  private resetSegments(contentType: EContentType): void {
    this._representationMap.forEach((representationAndUri: [IRepresentation, string]) => {
      const representation: IRepresentation = representationAndUri[0];
      if (representation.adaptation.contentType === contentType) {
        representation.segments.length = 0;
      }
    });
  }

  private updateManifest(): IManifest {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const videoRefStartTime: number | undefined = this._refPlaylists[0].startTime!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const audioRefStartTime: number | undefined = this._refPlaylists[1].startTime!;

    if (this._manifest.type === EManifestType.DYNAMIC) {
      const minStartTime: number =
        videoRefStartTime < audioRefStartTime ? videoRefStartTime : audioRefStartTime;
      this._manifest.availabilityStartTime = this._maxPublishTime - minStartTime;
    }

    this._representationMap.forEach((representationAndUri: [IRepresentation, string]) => {
      const representation: IRepresentation = representationAndUri[0];
      let startTime: number;
      switch (representation.adaptation.contentType) {
        case EContentType.VIDEO:
        case EContentType.IMAGE:
          {
            startTime = videoRefStartTime;
          }
          break;
        case EContentType.AUDIO:
        case EContentType.TEXT:
          {
            startTime = audioRefStartTime;
          }
          break;
      }

      let relativeTime: number = 0;
      for (let i: number = 0; i < representation.segments.length; i++) {
        const segment: ISegment = representation.segments[i];
        if (segment.id === 0) continue;
        relativeTime = relativeTime + segment.duration;
        segment.time = relativeTime + startTime;
      }
    });

    return this._manifest;
  }

  public parseSegment(data: ArrayBuffer, representationId: string, segmentId: number): IManifest | null {
    const representationAndUri: [IRepresentation, string] | undefined =
      this._representationMap.get(representationId);
    if (!representationAndUri) return null;
    const refPlaylist: IRefPlaylist | null = this.getRefPlaylist(representationAndUri[0]);
    if (!refPlaylist) return null;

    switch (segmentId) {
      case 0:
        new Mp4Parser(this._dispatcher)
          .box('moov', Mp4Parser.children)
          .box('trak', Mp4Parser.children)
          .box('mdia', Mp4Parser.children)
          .fullBox('mdhd', (box: IParsedBox) => {
            const parsedMdhdBox: IMdhd = Mp4Parser.parseMdhd(box);
            refPlaylist.timescale = parsedMdhdBox.timescale;
          })
          .parse(data);
        break;
      case 1:
        new Mp4Parser(this._dispatcher)
          .box('moof', Mp4Parser.children)
          .box('traf', Mp4Parser.children)
          .fullBox('tfdt', (box: IParsedBox) => {
            const parsedTfdtBox: ITfdt = Mp4Parser.parseTfdt(box);
            refPlaylist.baseMediaDecodeTime = parsedTfdtBox.baseMediaDecodeTime;
          })
          .parse(data);
        break;
    }

    const {timescale, baseMediaDecodeTime} = refPlaylist;
    if (timescale !== undefined && baseMediaDecodeTime !== undefined) {
      refPlaylist.startTime = baseMediaDecodeTime / timescale;

      if (this.canUpdateManifest()) {
        return this.updateManifest();
      }
    }

    return null;
  }

  public async parsePlaylist(data: string, representationId: string): Promise<IManifest | null> {
    const lines: Array<string> = data.split('\n');
    const representationAndUri: [IRepresentation, string] | undefined =
      this._representationMap.get(representationId);
    if (!representationAndUri) return null;

    const [representation, uri] = representationAndUri;
    const url: URL = new URL(uri);
    const baseUrl: string = getBaseUrl(url.origin, url.pathname);

    let segmentId: number = 1;
    let totalDuration: number = 0;
    let mediaSequence: number = 0;
    let mimeType: EMimeType | null = null;
    const datarangeTags: Array<ITag> = [];

    if (this._manifest.type === EManifestType.DYNAMIC) {
      this.resetSegments(representation.adaptation.contentType);
    }

    for (let i: number = 1; i < lines.length; i++) {
      const line: string = lines[i];
      const tag: ITag | null = this.parseTag(line);
      if (!tag) continue;

      switch (tag.name) {
        case ETagName.DATERANGE:
          {
            datarangeTags.push(tag);
          }
          break;
        case ETagName.DISCONTINUITY:
          {
            if (representation.segments.length > 0) {
              segmentId = 1;
            }
          }
          break;
        case ETagName.ENDLIST:
          {
            this._manifest.type = EManifestType.STATIC;
          }
          break;
        case ETagName.INF:
          {
            const value: string = tag.value as string;
            const attributes: Record<string, string> | null = tag.attributes;

            const duration: number = +value.split(',')[0];

            if (!representation.frameRate && attributes) {
              const frameRateAttr: string = this.getAttribute(attributes, 'frame-rate');
              representation.frameRate = this.getFrameRate(frameRateAttr);
            }

            let byteRange: [number, number] | null = null;

            i++; // move to the following line
            const nextTag: ITag | null = this.parseTag(lines[i]);
            if (nextTag && nextTag.name === ETagName.BYTERANGE) {
              const nextValue: string = nextTag.value as string;
              byteRange = this.getByteRange(nextValue);
              i++; // move to the following line
            } else if (nextTag && nextTag.name === ETagName.TILES) {
              const nextAttributes: Record<string, string> = nextTag.attributes as Record<string, string>;
              const layoutAttr: string = this.getAttribute(nextAttributes, 'LAYOUT');
              const {width: x, height: y} = this.getWidthHeight(layoutAttr);
              const resolutionsAttr: string = this.getAttribute(nextAttributes, 'RESOLUTION');
              const {width, height} = this.getWidthHeight(resolutionsAttr);

              representation.width = width * x;
              representation.height = height * y;
              representation.essentialProperties = this.buildEssentialProperties(layoutAttr);
              i++; // move to the following line
            }

            const uri: string = this.getUri(lines[i], baseUrl);

            if (!mimeType) {
              mimeType = this.getMimeType(uri, representation.adaptation.contentType);
            }

            totalDuration += duration;

            const segment: ISegment = this.buildSegment(
              representation,
              segmentId,
              uri,
              duration,
              totalDuration,
              byteRange
            );
            representation.segments.push(segment);

            segmentId++;
          }
          break;
        case ETagName.KEY:
          {
            const attributes: Record<string, string> = tag.attributes as Record<string, string>;

            const method: string = this.getAttribute(attributes, 'METHOD');
            if (method !== 'NONE') {
              const uri: string = this.getAttribute(attributes, 'URI');
              const keyFormat: EKeyFormat = this.getAttribute(attributes, 'KEYFORMAT') as EKeyFormat;

              const iv: string = this.getAttribute(attributes, 'IV');

              const contentProtection: IContentProtection | null = this.buildContentProtection(
                keyFormat,
                uri,
                iv
              );
              if (contentProtection) {
                representation.adaptation.contentProtections.push(contentProtection);
              }
            }
          }
          break;
        case ETagName.MAP:
          {
            const attributes: Record<string, string> = tag.attributes as Record<string, string>;

            const uriAttr: string = this.getAttribute(attributes, 'URI');
            const uri: string = this.getUri(uriAttr, baseUrl);

            const byterangeAttr: string = this.getAttribute(attributes, 'BYTERANGE');
            let byteRange: [number, number] | null = null;
            if (byterangeAttr) {
              byteRange = this.getByteRange(byterangeAttr);
            }

            if (!mimeType) {
              mimeType = this.getMimeType(uri, representation.adaptation.contentType);
            }

            const segment: ISegment = this.buildSegment(representation, 0, uri, 0, totalDuration, byteRange);
            representation.segments.push(segment);
          }
          break;
        case ETagName.MEDIA_SEQUENCE:
          {
            const value: string = tag.value as string;
            mediaSequence = +value;
          }
          break;
        case ETagName.PLAYLIST_TYPE:
          {
            const value: string = tag.value as string;
            this._manifest.type = this.mapPlaylistTypeToManifest(value as EPlaylistType);
          }
          break;
        case ETagName.PROGRAM_DATE_TIME:
          {
            const value: string = tag.value as string;

            const programDateTime: number = getTimeFromDate(value);

            if (this.isAudioOrVideo(representation)) {
              if (programDateTime > this._maxPublishTime) {
                this._maxPublishTime = programDateTime;
              }
            }
          }
          break;
        case ETagName.TARGET_DURATION:
          {
            const value: string = tag.value as string;

            if (this.isAudioOrVideo(representation)) {
              const maxSegmentDuration: number = +value;
              if (+value > this._manifest.maxSegmentDuration) {
                this._manifest.maxSegmentDuration = maxSegmentDuration;
                this._manifest.minBufferTime = maxSegmentDuration;
                if (this._manifest.type === EManifestType.DYNAMIC) {
                  this._manifest.minimumUpdatePeriod = maxSegmentDuration * 0.7;
                }
              }
            }
          }
          break;
        case ETagName.USP_MEDIA:
          {
            const attributes: Record<string, string> = tag.attributes as Record<string, string>;

            const bandwidthAttr: string = this.getAttribute(attributes, 'BANDWIDTH');
            const bandwidth: number = +bandwidthAttr;

            const codecs: string = this.getAttribute(attributes, 'CODECS').toLowerCase();

            representation.bandwidth = bandwidth;
            representation.codecs = codecs;
          }
          break;
      }
    }

    if (!representation.codecs) {
      representation.codecs = guessCodecs(representation.segments[0].url);
    }

    if (mimeType && mimeType !== representation.adaptation.mimeType) {
      (representation.adaptation as IAdaptationSet).mimeType = mimeType;

      const cp: IContentProtection | null =
        representation.adaptation.contentProtections.filter(
          (cp: IContentProtection) => cp.keySystem === this._configManager.eme.keySystem
        )[0] ?? null;
      const channels: number =
        representation.audioChannelConfigurations.filter(
          (ac: IAudioChannelConfiguration) => ac.channels !== 0
        )[0]?.channels ?? 0;
      const isSupported: boolean = await this._probeCapabilities.isSupported(
        representation.adaptation.contentType,
        cp,
        representation.adaptation.mimeType,
        representation.codecs,
        representation.width,
        representation.height,
        representation.frameRate,
        representation.bandwidth,
        channels
      );
      if (!isSupported) {
        this.removeRepresentation(representation);

        this._logger.debug(
          `Unsupported ${representation.adaptation.contentType} representation: mimeType=${representation.adaptation.mimeType} codecs=${representation.codecs} bandwidth=${representation.bandwidth} width=${representation.width} height=${representation.height} keySystem=${cp?.keySystem}`
        );
      }
    }

    if (this.isAudioOrVideo(representation)) {
      if (
        !this._manifest.mediaPresentationDuration ||
        totalDuration < this._manifest.mediaPresentationDuration
      ) {
        this._manifest.mediaPresentationDuration = totalDuration;
        if (this._manifest.type === EManifestType.DYNAMIC) {
          this._manifest.timeShiftBufferDepth = totalDuration;
        }
      }

      if (this._manifest.type === EManifestType.STATIC) {
        if (totalDuration < this._manifest.periods[0].duration) {
          this._manifest.periods[0].duration = totalDuration;
        }
      }

      if (this._manifest.type === EManifestType.DYNAMIC) {
        const publishTime: number = this._maxPublishTime + this._manifest.mediaPresentationDuration;
        if (publishTime > this._manifest.publishTime) {
          this._manifest.publishTime = publishTime;
        }
      }
    }

    switch (representation.adaptation.contentType) {
      case EContentType.VIDEO:
        this._parsedPlaylists[0] = true;
        break;
      case EContentType.AUDIO:
        this._parsedPlaylists[1] = true;
        break;
    }

    switch (this._manifest.type) {
      case EManifestType.STATIC:
        if (!this._parsedPlaylists[0] || !this._parsedPlaylists[1]) return null;

        return this._manifest;
      case EManifestType.DYNAMIC:
        this.updateOutbandEventStream(datarangeTags);
        this.updateRefPlaylists(representation, mediaSequence);
        if (this.canUpdateManifest()) {
          return this.updateManifest();
        } else {
          const manifest: IManifest | null = await this.requestAdditionalSegments(representation);

          return manifest;
        }
    }
  }

  public async parseManifest(data: string): Promise<IManifest> {
    const {origin, pathname} = this._url;
    const manifestBaseUrl: string = getBaseUrl(origin, pathname);
    const videoAdaptation: IAdaptationSet = this.pushBaseVideoAdaptationSet();

    let playlistId: number = 0;
    let cp: IContentProtection | null = null;
    const audioCodecs: Map<string, string> = new Map();
    const mediaTags: Array<ITag> = [];
    const videoDataRepresentations: Array<IVideoDataRepresentation> = [];
    const lines: Array<string> = data.split('\n');
    for (let i: number = 1; i < lines.length; i++) {
      const representationId: string = playlistId.toString();
      const line: string = lines[i];
      const tag: ITag | null = this.parseTag(line);
      if (!tag) continue;

      switch (tag.name) {
        case ETagName.MEDIA:
          {
            mediaTags.push(tag);
          }
          break;
        case ETagName.SESSION_KEY:
          {
            const values: Record<string, string> = tag.attributes as Record<string, string>;

            const method: string = this.getAttribute(values, 'METHOD');
            if (method !== 'NONE') {
              const uri: string = this.getAttribute(values, 'URI');
              const keyFormat: EKeyFormat = this.getAttribute(values, 'KEYFORMAT') as EKeyFormat;

              const iv: string = this.getAttribute(values, 'IV');

              const contentProtection: IContentProtection | null = this.buildContentProtection(
                keyFormat,
                uri,
                iv
              );

              if (contentProtection?.keySystem === this._configManager.eme.keySystem) {
                cp = contentProtection;
              }
            }
          }
          break;
        case ETagName.IMAGE_STREAM_INF:
        case ETagName.I_FRAME_STREAM_INF:
          {
            let imageAdaptation: IAdaptationSet | undefined = this._manifest.periods?.[0].image[0];
            if (!imageAdaptation) {
              imageAdaptation = this.pushBaseImageAdaptationSet();
            }

            const attributes: Record<string, string> = tag.attributes as Record<string, string>;

            const bandwidthAttr: string = this.getAttribute(attributes, 'BANDWIDTH');
            const bandwidth: number = +bandwidthAttr;

            const resolutionsAttr: string = this.getAttribute(attributes, 'RESOLUTION');
            const {width, height} = this.getWidthHeight(resolutionsAttr);

            const uriAttr: string = this.getAttribute(attributes, 'URI');
            const uri: string = this.getUri(uriAttr, manifestBaseUrl);

            if (bandwidth > imageAdaptation.maxBandwidth) {
              imageAdaptation.maxBandwidth = bandwidth;
            }
            if (bandwidth < imageAdaptation.minBandwidth) {
              imageAdaptation.minBandwidth = bandwidth;
            }
            if (height > imageAdaptation.maxHeight) {
              imageAdaptation.maxHeight = height;
            }
            if (width > imageAdaptation.maxWidth) {
              imageAdaptation.maxWidth = width;
            }

            const representation: IRepresentation = this.buildBaseRepresentation(
              imageAdaptation,
              representationId,
              bandwidth,
              width,
              height,
              0,
              ''
            );
            imageAdaptation.representations.push(representation);
            this._representationMap.set(representationId, [representation, uri]);
          }
          break;
        case ETagName.STREAM_INF:
          {
            const attributes: Record<string, string> = tag.attributes as Record<string, string>;

            const bandwidthAttr: string = this.getAttribute(attributes, 'BANDWIDTH');
            const bandwidth: number = +bandwidthAttr;

            const codecsAttr: string = this.getAttribute(attributes, 'CODECS').toLowerCase();
            const codecs: Array<string> = this.getCodecs(codecsAttr);

            if (codecs.length === 1) continue;
            const [videoCodec, audioCodec] = codecs;

            const resolutionsAttr: string = this.getAttribute(attributes, 'RESOLUTION');
            const {width, height} = this.getWidthHeight(resolutionsAttr);

            const frameRateAttr: string = this.getAttribute(attributes, 'FRAME-RATE');
            const frameRate: number = this.getFrameRate(frameRateAttr);

            const audioIdAttr: string = this.getAttribute(attributes, 'AUDIO');
            audioCodecs.set(audioIdAttr, audioCodec);

            const uri: string = this.getUri(lines[i + 1], manifestBaseUrl);
            i++;

            if (bandwidth > videoAdaptation.maxBandwidth) {
              videoAdaptation.maxBandwidth = bandwidth;
            }
            if (bandwidth < videoAdaptation.minBandwidth) {
              videoAdaptation.minBandwidth = bandwidth;
            }
            if (height > videoAdaptation.maxHeight) {
              videoAdaptation.maxHeight = height;
            }
            if (width > videoAdaptation.maxWidth) {
              videoAdaptation.maxWidth = width;
            }

            const contentType: EContentType = EContentType.VIDEO;
            const mimeType: EMimeType = EMimeType.VIDEO;

            videoDataRepresentations.push({
              id: representationId,
              contentType,
              contentProtection: cp,
              mimeType,
              videoCodec,
              frameRate,
              uri,
              width,
              height,
              bandwidth
            });
          }
          break;
      }
      playlistId++;
    }

    // sort and process video
    videoDataRepresentations.sort(this.sortVideoRepresentations);
    for (let i: number = 0; i < videoDataRepresentations.length; i++) {
      const {
        contentType,
        contentProtection,
        mimeType,
        videoCodec,
        width,
        height,
        frameRate,
        bandwidth,
        uri,
        id
      } = videoDataRepresentations[i];
      // eslint-disable-next-line no-await-in-loop
      const isSupported: boolean = await this._probeCapabilities.isSupported(
        contentType,
        contentProtection,
        mimeType,
        videoCodec,
        width,
        height,
        frameRate,
        bandwidth,
        0
      );
      if (!isSupported) {
        this._logger.debug(
          `Unsupported ${videoAdaptation.contentType} representation: mimeType=${videoAdaptation.mimeType} codecs=${videoCodec} bandwidth=${bandwidth} width=${width} height=${height}`
        );

        continue;
      }

      const representation: IRepresentation = this.buildBaseRepresentation(
        videoAdaptation,
        id,
        bandwidth,
        width,
        height,
        frameRate,
        videoCodec
      );
      videoAdaptation.representations.push(representation);
      this._representationMap.set(id, [representation, uri]);
    }

    const closedCaptions: Array<[string, string]> = [];
    // process sub/audio tags after video tags
    for (let i: number = 0; i < mediaTags.length; i++) {
      const mediaTag: ITag = mediaTags[i];
      const attributes: Record<string, string> = mediaTag.attributes as Record<string, string>;

      const nameAttr: string = this.getAttribute(attributes, 'NAME');
      const typeAttr: EMediaType = this.getAttribute(attributes, 'TYPE') as EMediaType;
      const languageAttr: string = this.getAttribute(attributes, 'LANGUAGE').toLowerCase();

      if (typeAttr === EMediaType.CLOSED_CAPTIONS) {
        const instreamIdAttr: string = this.getAttribute(attributes, 'INSTREAM-ID');
        closedCaptions.push([instreamIdAttr, languageAttr]);
        continue;
      }

      const contentType: EContentType = this.mapMediaToContent(typeAttr);
      let mimeType: EMimeType = '' as EMimeType;
      if (contentType === EContentType.AUDIO) {
        mimeType = EMimeType.AUDIO;
      }

      const uriAttr: string = this.getAttribute(attributes, 'URI');
      const uri: string = this.getUri(uriAttr, manifestBaseUrl);

      let codecs: string = '';
      const audioChannelConfigurations: Array<IAudioChannelConfiguration> = [];
      if (contentType === EContentType.AUDIO) {
        const groupIdAttr: string = this.getAttribute(attributes, 'GROUP-ID');
        codecs = audioCodecs.get(groupIdAttr) ?? '';

        const audioChannelConfiguration: IAudioChannelConfiguration | null =
          this.getAudioChannelConfiguration(attributes);
        if (audioChannelConfiguration) {
          audioChannelConfigurations.push(audioChannelConfiguration);
        }
      }

      const roles: Array<IRole> = [];
      if (contentType === EContentType.TEXT) {
        const role: IRole = this.getTextRole(attributes);
        roles.push(role);
      }

      const id: string = playlistId.toString();

      if (contentType === EContentType.AUDIO) {
        // eslint-disable-next-line no-await-in-loop
        const isSupported: boolean = await this._probeCapabilities.isSupported(
          contentType,
          cp,
          mimeType,
          codecs,
          0,
          0,
          0,
          0,
          0
        );
        if (!isSupported) {
          this._logger.debug(
            `Unsupported ${contentType} representation: mimeType=${mimeType} codecs=${codecs}`
          );

          continue;
        }
      }

      const adaptation: IAdaptationSet = this.buildBaseAdaptationSet(
        id,
        contentType,
        mimeType,
        0,
        0,
        0,
        0,
        nameAttr,
        languageAttr,
        codecs,
        roles,
        audioChannelConfigurations
      );
      this._manifest.periods?.[0][contentType].push(adaptation);
      this._representationMap.set(id, [adaptation.representations[0], uri]);

      playlistId++;
    }

    if (closedCaptions.length > 0) {
      const accessibilities: Array<IAccessibility> = this.buildAccessibilities(closedCaptions);
      videoAdaptation.accessibilities = accessibilities;
    }

    return this._manifest;
  }

  public reset(): void {
    this._maxPublishTime = 0;
  }

  public destroy(): void {
    this._logger.info('Destroying HLS parser');

    this._representationMap.clear();
  }
}

export default HlsParser;
