enum ELogType {
  ABR = 'ABR',
  BUFFER = 'BUFFER',
  CDN = 'CDN',
  CMCD = 'CMCD',
  CONFIG = 'CONFIG',
  DOWNLOADER = 'DOWNLOADER',
  EME = 'EME',
  MANIFEST = 'MANIFEST',
  NETWORK = 'NETWORK',
  MSE = 'MSE',
  PARSER = 'PARSER',
  PRESENTATION = 'PRESENTATION',
  PROBE = 'PROBE',
  STATE = 'STATE',
  TIME = 'TIME',
  THUMBNAIL = 'THUMBNAIL'
}

export default ELogType;
