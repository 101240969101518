import ConfigManager from '@config/configManager';
import Dispatcher from '@dispatcher/dispatcher';
import ILogger from '@logger/interfaces/ILogger';
import NetworkManager from '@network/networkManager';
import StateManager from '@state/stateManager';
import base64ToUint8 from '@utils/base64ToUint8';
import getUuidFromKeyId from '@utils/getUuidFromKeyId';
import uint16ToString from '@utils/uint16ToString';
import {GenericParserContext, GenericXmlNode, getNodeText, XmlParser} from '@utils/xml-parser-ts/parser';

import BaseEme from '../baseEme';

// https://www.w3.org/TR/2017/REC-encrypted-media-20170918/
class Playready extends BaseEme {
  constructor(
    videoElement: HTMLVideoElement,
    networkManager: NetworkManager,
    stateManager: StateManager,
    configManager: ConfigManager,
    logger: ILogger,
    dispatcher: Dispatcher,
    onEmeReady: () => void,
    onEmeKeyStatusesChanged: (keyStatuses: Map<string, string>) => void
  ) {
    super(
      videoElement,
      networkManager,
      stateManager,
      configManager,
      logger,
      dispatcher,
      onEmeReady,
      onEmeKeyStatusesChanged
    );
    this._logger.debug('Using EME Playready');
  }

  private unwrapPlayReadyRequest(data: ArrayBuffer): Uint8Array | null {
    const xml: string = uint16ToString(data);
    if (!xml.includes('PlayReadyKeyMessage')) {
      this._logger.debug('PlayReady request is already unwrapped');

      return null;
    }

    this._logger.debug('Unwrapping PlayReady request');

    const challengeNode: GenericXmlNode = {
      tagName: 'challenge',
      attrs: {},
      children: {}
    };

    new XmlParser(new GenericParserContext(challengeNode)).write(xml);

    if (!challengeNode || typeof challengeNode === 'string') return null;

    const challenge: string = getNodeText(challengeNode);
    if (!challenge) return null;

    const body: Uint8Array = base64ToUint8(challenge);

    return body;
  }

  protected override patchKeyId(keyId: Uint8Array): Uint8Array {
    if (this._configManager.patch.convertLittleEndianKeyId) {
      keyId = getUuidFromKeyId(keyId);
    }

    return keyId;
  }

  protected override getLicenseRequestBody(message: ArrayBuffer): ArrayBuffer {
    return this.unwrapPlayReadyRequest(message) ?? message;
  }

  public override init(): void {
    this._logger.debug('Init');

    const videoCapabilities: Array<MediaKeySystemMediaCapability> = this._VIDEO_MIME_CODEC.map(
      (mimeCodec: string) => ({
        contentType: mimeCodec
      })
    );

    const audioCapabilities: Array<MediaKeySystemMediaCapability> = this._AUDIO_MIME_CODEC.map(
      (mimeCodec: string) => ({
        contentType: mimeCodec
      })
    );

    const mediaKeySystemConfiguration: Array<MediaKeySystemConfiguration> = [
      {
        initDataTypes: ['cenc'],
        videoCapabilities,
        audioCapabilities
      }
    ];

    super.init(mediaKeySystemConfiguration);
  }

  public destroy(): void {
    this._logger.info('Destroying EME Playready');
    super.destroy();
  }
}

export default Playready;
