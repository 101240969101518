import createView from './createView';

const uintToHex = (data: BufferSource | DataView | ArrayBuffer | Uint8Array): string => {
  const arr: Uint8Array = createView(data, Uint8Array) as Uint8Array;
  let hex: string = '';
  for (let i: number = 0; i < arr.length; i++) {
    let str: string = arr[i].toString(16);
    if (str.length === 1) {
      str = '0' + str;
    }
    hex += str;
  }

  return hex;
};

export default uintToHex;
