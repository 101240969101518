import ConfigManager from '@config/configManager';
import Dispatcher from '@dispatcher/dispatcher';
import EEvent from '@dispatcher/enum/EEvent';
import IDataSegment from '@downloader/segment/interfaces/IDataSegment';
import SegmentDownloaderManager from '@downloader/segment/segmentDownloaderManager';
import EErrorCode from '@error/enum/EErrorCode';
import EErrorSeverity from '@error/enum/EErrorSeverity';
import EErrorType from '@error/enum/EErrorType';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import IActiveRepresentationChange from '@manifest/interfaces/IActiveRepresentationChange';
import ManifestManager from '@manifest/manifestManager';
import NetworkManager from '@network/networkManager';
import EContentType from '@parser/manifest/enum/EContentType';
import EMimeType from '@parser/manifest/enum/EMimeType';
import IAccessibility from '@parser/manifest/interfaces/IAccessibility';
import IInbandEventStream from '@parser/manifest/interfaces/IInbandEventStream';
import IRepresentation from '@parser/manifest/interfaces/IRepresentation';
import ISegment from '@parser/manifest/interfaces/ISegment';
import getCea from '@utils/cea/getCea';
import getCodecsProfile from '@utils/getCodecsProfile';
import getMimeCodec from '@utils/getMimeCodec';
import getSafeMarginBehind from '@utils/getSafeMarginBehind';
import getTimeRange from '@utils/getTimeRange';
import getEventStartTimeFromEmsg from '@utils/mp4/getEventStartTimeFromEmsg';
import IEmsg from '@utils/mp4/interfaces/IEmsg';
import IParsedBox from '@utils/mp4/interfaces/IParsedBox';
import ITfhd from '@utils/mp4/interfaces/ITfhd';
import ITrun from '@utils/mp4/interfaces/ITrun';
import Mp4Parser from '@utils/mp4/parser';

import IBuffer from '../interfaces/IBuffer';
import IBufferUpdate from '../interfaces/IBufferUpdate';
import IGap from '../interfaces/IGap';
import CeaBuffer from '../text/ceaBuffer';
import AVBufferPatch from './avBufferPatch';

class AVBuffer implements IBuffer {
  private _logger: ILogger;
  private _avBufferPatch: AVBufferPatch;
  private _segmentDownloaderManager: SegmentDownloaderManager;
  private _ceaBuffer: CeaBuffer | null = null;
  private _mimeType: EMimeType | null = null;
  private _codecsProfile: string | null = null;
  private _sourceBuffer: SourceBuffer | null = null;
  private _shouldRemoveBuffer: boolean = false;

  private _lastSegmentAppendedToBuffer: ISegment | null = null;
  private _mediaSegmentsInBuffer: Array<ISegment> = [];
  private _currentSegmentInBuffer: ISegment | null = null;
  private _isLastSegmentInBuffer: boolean = false;
  private _appendTimeStart: number = 0;
  private _appendInProgress: boolean = false;
  private _prftBoxParsed: boolean = false;

  private _GAP_THRESHOLD: number = 0.1;
  private _MIN_BUFFER_AHEAD: number = 10;

  private _APPEND_WINDOW_END_FUDGE: number = 0.01;
  private _APPEND_WINDOW_START_FUDGE: number = 0.1;

  private _tickInterval: number | null = null;
  private _TICK_INTERVAL: number = 500;

  constructor(
    private _videoElement: HTMLVideoElement,
    private _mediaSource: MediaSource,
    private _manifestManager: ManifestManager,
    private _networkManager: NetworkManager,
    private _configManager: ConfigManager,
    private _loggerManager: LoggerManager,
    private _dispatcher: Dispatcher,
    private _emeReady: boolean,
    private _contentType: EContentType.AUDIO | EContentType.VIDEO,
    private _onBufferUpdate: (b: IBufferUpdate) => void,
    private _onGapDetected: (g: IGap) => void,
    private _onActiveRepresentationChange: (ar: IActiveRepresentationChange) => void,
    representations?: Array<IRepresentation>
  ) {
    this._logger = this._loggerManager.registerLogger(ELogType.BUFFER);
    this._avBufferPatch = new AVBufferPatch(
      this._manifestManager,
      this._loggerManager,
      this._dispatcher,
      this._contentType
    );

    this._segmentDownloaderManager = new SegmentDownloaderManager(
      this._videoElement,
      this._mediaSource,
      this._manifestManager,
      this._networkManager,
      this._configManager,
      this._loggerManager,
      this._contentType,
      this.feedBuffer,
      representations
    );
  }

  public get isLast(): boolean {
    return this._isLastSegmentInBuffer;
  }

  public get lastSegmentAppendedToBuffer(): ISegment | null {
    return this._lastSegmentAppendedToBuffer;
  }

  public get updating(): boolean {
    return Boolean(this._sourceBuffer?.updating);
  }

  private runBufferOperations = (): void => {
    if (!this._appendInProgress) {
      this.detectMediaQualityChange();
      this.feedBuffer();
      this.emitBufferUpdate(this._lastSegmentAppendedToBuffer);
      this._segmentDownloaderManager.onTimeUpdate();
      this._segmentDownloaderManager.downloadSegments();
    }
  };

  private onSourceBufferAbort = (): void => {
    this._logger.debug(
      `Source buffer ${this._appendInProgress ? 'append' : 'remove'} ${this._contentType}) aborted`
    );
    this._appendInProgress = false;
  };

  private onSourceBufferUpdateEnd = (): void => {
    if (this._appendInProgress) {
      this._logger.debug(
        `AppendBuffer (${this._contentType}) took: ${(performance.now() - this._appendTimeStart).toFixed(
          2
        )}ms`
      );
      this._appendTimeStart = 0;
      this._appendInProgress = false;
      this.detectGap();
      this.runBufferOperations();
    }
  };

  private onSourceBufferUpdateStart = (): void => {
    if (!this._appendTimeStart && this._appendInProgress) {
      this._appendTimeStart = performance.now();
    }
  };

  private canFeedBuffer(currentTime: number, segmentId: number, segmentEndTime: number): boolean {
    if (!this._emeReady) return false;

    if (segmentId === 0) {
      // always feed the buffer if it's an init segment
      return true;
    }

    if (this._mediaSegmentsInBuffer.find((s: ISegment) => s.time === segmentEndTime)) {
      // always feed the buffer if it's a replacement segment
      return true;
    }

    const timeRange: [number, number] | null = this.getBufferRange();
    if (!timeRange) return false;

    const endTime: number = timeRange[1];
    const bufferAhead: number = endTime - currentTime;

    return (
      bufferAhead <
      Math.max(
        this._configManager.buffer.bufferAhead,
        this._manifestManager.averageSegmentDuration,
        this._MIN_BUFFER_AHEAD
      )
    );
  }

  private detectGap(): void {
    const {currentTime, readyState, seeking} = this._videoElement;
    if (
      !this._sourceBuffer ||
      this._sourceBuffer.updating ||
      readyState === this._videoElement.HAVE_NOTHING ||
      this.isUnbufferedSeek(seeking)
    )
      return;

    const ranges: Array<[number, number]> = [];
    const buffered: TimeRanges = this._sourceBuffer.buffered;
    for (let i: number = 0; i < buffered.length; i++) {
      const start: number = buffered.start(i);
      const end: number = buffered.end(i);
      if (start <= currentTime && currentTime <= end) {
        ranges.push([start, end]);
      } else if (start >= currentTime) {
        ranges.push([start, end]);
        break;
      }
    }

    if (ranges.length <= 0) return;

    let startPosition: number;
    let jumpPosition: number;
    if (ranges.length === 1) {
      startPosition = currentTime;
      jumpPosition = ranges[0][0];
      if (startPosition >= jumpPosition) return;
    } else {
      startPosition = ranges[0][1];
      jumpPosition = ranges[1][0];
    }

    const gapSize: number = jumpPosition - startPosition;
    if (gapSize >= this._GAP_THRESHOLD) {
      const gap: IGap = {
        contentType: this._contentType,
        start: startPosition,
        size: gapSize,
        jumpPosition
      };

      if (ranges.length === 1) {
        this._logger.warn(`current position ${currentTime} is outside the ${this._contentType} buffer range`);
      }

      this._onGapDetected(gap);
    }
  }

  private getBufferAhead(bufferEnd: number, currentTime: number): number {
    const bufferAhead: number = bufferEnd - currentTime;

    return bufferAhead < 0 ? 0 : bufferAhead;
  }

  private getBufferBehind(bufferStart: number, currentTime: number): number {
    const bufferBehind: number = currentTime - bufferStart;

    return bufferBehind < 0 ? 0 : bufferBehind;
  }

  private handleAccessibility(accessibilities: Array<IAccessibility>): void {
    if (this._contentType === EContentType.VIDEO) {
      const ceaAccessibility: IAccessibility | null = getCea(accessibilities);
      if (ceaAccessibility && !this._ceaBuffer) {
        this._ceaBuffer = new CeaBuffer(
          this._videoElement,
          this._manifestManager,
          ceaAccessibility,
          this._configManager,
          this._loggerManager,
          this._dispatcher
        );
        this._ceaBuffer.init();
      }
    }
  }

  private isUnbufferedSeek(seeking: boolean): boolean {
    if (!seeking) return false;

    // this handle the scenario when we seek inside a gap
    return !this._lastSegmentAppendedToBuffer;
  }

  private emitBufferUpdate(lastSegmentAppendedToBuffer: ISegment | null): void {
    if (!this._sourceBuffer) return;
    // we don't want emit a buffer update after feeding the init segment
    if (lastSegmentAppendedToBuffer?.id === 0) return;

    const {currentTime} = this._videoElement;
    const bufferRange: [number, number] | null = this.getBufferRange(currentTime);
    if (!bufferRange) return;

    const [start, end]: [number, number] = bufferRange;

    let behind: number = 0;
    let ahead: number = 0;

    const endPosition: number = Math.max(end, lastSegmentAppendedToBuffer?.time || 0);
    if (endPosition !== 0) {
      behind = this.getBufferBehind(start, currentTime);
      ahead = this.getBufferAhead(endPosition, currentTime);
    }

    this._segmentDownloaderManager.bufferEndPosition = endPosition;
    const bufferUpdate: IBufferUpdate = {
      contentType: this._contentType,
      buffered: this._sourceBuffer.buffered,
      behind,
      ahead
    };
    this._dispatcher.emit({
      name: EEvent.BUFFER_UPDATE,
      ...bufferUpdate
    });
    this._onBufferUpdate(bufferUpdate);
  }

  private emitInbandStreamEvent(
    segmentRef: ISegment,
    data: ArrayBuffer,
    inbandEventStreams: Array<IInbandEventStream>
  ): void {
    const schemeIdUris: Array<string> = inbandEventStreams.map((i: IInbandEventStream) => i.schemeIdUri);
    new Mp4Parser(this._dispatcher)
      .fullBox('emsg', (box: IParsedBox) => {
        const parsedEmsgBox: IEmsg = Mp4Parser.parseEmsg(box);
        const presentationTime: number = getEventStartTimeFromEmsg(parsedEmsgBox, box.version, segmentRef);

        if (schemeIdUris.includes(parsedEmsgBox.schemeIdUri)) {
          this._dispatcher.emit({
            name: EEvent.INBAND_STREAM,
            id: parsedEmsgBox.id,
            eventDuration: parsedEmsgBox.eventDuration,
            schemeIdUri: parsedEmsgBox.schemeIdUri,
            value: parsedEmsgBox.value,
            messageData: parsedEmsgBox.messageData,
            presentationTime
          });
        }
      })
      .parse(data);
  }

  private parseProducerRefTime(data: ArrayBuffer, timescale: number): void {
    new Mp4Parser(this._dispatcher)
      .fullBox('prft', (box: IParsedBox) => {
        const {wallClockTimeSecs, mediaTime} = Mp4Parser.parsePrft(box);
        const producerReferenceStartTime: number = wallClockTimeSecs - mediaTime / timescale;

        this._logger.info(`using producerReferenceStartTime from MP4 segment: ${producerReferenceStartTime}`);
        this._manifestManager.setProducerReferenceStartTime(producerReferenceStartTime);
        this._prftBoxParsed = true;
      })
      .parse(data);
  }

  private patchAudioWindowAppendEnd(
    data: ArrayBuffer,
    segment: ISegment,
    currentAppendWindowEnd: number
  ): number {
    let appendWindowEnd: number = currentAppendWindowEnd;
    let defaultSampleDuration: number | null = 0;
    let trunSampleDuration: number | null = 0;
    new Mp4Parser(this._dispatcher)
      .box('moof', Mp4Parser.children)
      .box('traf', Mp4Parser.children)
      .fullBox('trun', (box: IParsedBox) => {
        const parsedTrun: ITrun = Mp4Parser.parseTrun(box);
        trunSampleDuration = parsedTrun.sampleData[parsedTrun.sampleData.length - 1].sampleDuration;
      })
      .fullBox('tfhd', (box: IParsedBox) => {
        const parsedTfhdBox: ITfhd = Mp4Parser.parseTfhd(box);
        defaultSampleDuration = parsedTfhdBox.defaultSampleDuration;
      })
      .parse(data);

    const sampleDuration: number | null = trunSampleDuration || defaultSampleDuration;
    if (sampleDuration) {
      // We ensure we have full audio frame in the appendWindowEnd to avoid frame splicing
      // audio access units (frames) for 48000 sampling rate (same as timescale) containing 1024 "samples" (1 / 48000) * 1024.
      const timescale: number = segment.representation.timescale;
      const audioFrameDuration: number = Number(((1 / timescale) * sampleDuration).toPrecision(2));
      this._logger.debug(`Patch: add ${audioFrameDuration} to ${this._contentType} appendWindowEnd`);
      appendWindowEnd += audioFrameDuration;
    }

    return appendWindowEnd;
  }

  private clearBuffer(startTime?: number, endTime?: number): boolean {
    const buffered: TimeRanges | undefined = !this._sourceBuffer ? undefined : this._sourceBuffer.buffered;
    if (!buffered || buffered.length === 0) return false;

    if (this._mediaSource.readyState === 'open' && this._appendInProgress) {
      this._logger.debug(`Aborting append for ${this._contentType} buffer`);
      this._sourceBuffer?.abort();
      this._appendInProgress = false;
    }

    const start: number = startTime ?? 0;
    let end: number | undefined = endTime;
    if (end === undefined) {
      end = buffered.end(buffered.length - 1);
    }

    if (start >= end || start < 0) {
      this._logger.warn(`Unexpected buffer remove range: [${start}, ${end}]`);

      return false;
    }

    if (Math.abs(end - start) < 1) return false;

    this._logger.debug(`Clear ${this._contentType} buffer from ${start.toFixed(2)}s to ${end.toFixed(2)}s`);

    this.removeBufferBehindMediaQuality(end);
    this._ceaBuffer?.clearBuffer(end);
    this._sourceBuffer?.remove(start, end);

    return true;
  }

  private detectMediaQualityChange(): void {
    const currentTime: number = this._videoElement.currentTime;
    for (let i: number = 0; i < this._mediaSegmentsInBuffer.length; i++) {
      const current: ISegment = this._mediaSegmentsInBuffer[i];
      if (current.time - current.duration <= currentTime && current.time > currentTime) {
        if (
          !this._currentSegmentInBuffer ||
          this._currentSegmentInBuffer.representation.id !== this._mediaSegmentsInBuffer[i].representation.id
        ) {
          this._currentSegmentInBuffer = this._mediaSegmentsInBuffer[i];
          const currentRepresentation: IRepresentation = this._currentSegmentInBuffer.representation;
          this._logger.debug(`active ${this._contentType} representation changed to:`, currentRepresentation);
          this._onActiveRepresentationChange({
            contentType: this._contentType,
            representation: currentRepresentation
          });
          this._dispatcher.emit({
            name: EEvent.ACTIVE_REPRESENTATION_CHANGE,
            contentType: this._contentType,
            representation: currentRepresentation
          });

          return;
        }
      }
    }
  }

  private feedBuffer = (): void => {
    if (this._sourceBuffer && !this._sourceBuffer.updating) {
      const {currentTime} = this._videoElement;
      const readyDataSegment: IDataSegment | null = this._segmentDownloaderManager.getReadyDataSegment();

      if (readyDataSegment) {
        const {
          representation: {
            bandwidth,
            adaptation: {
              period: {start: periodStart, id: periodId, duration: periodDuration},
              mimeType,
              contentProtections,
              inbandEventStreams,
              label
            },
            codecs,
            timescale
          },
          id,
          time
        } = readyDataSegment;
        if (this.canFeedBuffer(currentTime, id, time)) {
          this._shouldRemoveBuffer = true;
          const {data, ...s} = readyDataSegment;
          this._sourceBuffer.timestampOffset =
            periodStart - this._manifestManager.getPeriodPresentationTimeOffset(periodId);

          let appendWindowEnd: number = periodDuration
            ? Number((periodStart + periodDuration + this._APPEND_WINDOW_END_FUDGE).toFixed(9)) // Add fudge
            : Infinity;
          const appendWindowStart: number = Number(
            Math.max(0, periodStart - this._APPEND_WINDOW_START_FUDGE).toFixed(9)
          ); // Subtract fudge

          if (
            this._configManager.patch.avoidAudioFrameSplicing &&
            this._contentType === EContentType.AUDIO &&
            this._manifestManager.isLastSegment(s)
          ) {
            appendWindowEnd = this.patchAudioWindowAppendEnd(data as ArrayBuffer, s, appendWindowEnd);
          }

          if (
            this._sourceBuffer.appendWindowStart !== appendWindowStart ||
            this._sourceBuffer.appendWindowEnd !== appendWindowEnd
          ) {
            // Append window start must always be lower than or equal to window end
            this._sourceBuffer.appendWindowStart = 0;
            this._sourceBuffer.appendWindowEnd = appendWindowEnd;
            this._sourceBuffer.appendWindowStart = appendWindowStart;
          }

          if (typeof this._sourceBuffer.changeType === 'function') {
            const codecsProfile: string = getCodecsProfile(codecs);
            if (this._mimeType !== mimeType || this._codecsProfile !== codecsProfile) {
              try {
                const mimeCodec: string = getMimeCodec(mimeType, codecs);
                this._logger.debug(
                  `Changing codec to '${mimeCodec}'. Prev mimeType was "${this._mimeType}" and codecs profile was "${this._codecsProfile}"`
                );
                this._sourceBuffer.changeType(mimeCodec);
                this._mimeType = mimeType;
                this._codecsProfile = codecsProfile;
              } catch (e) {
                this._logger.warn(`Could not call 'changeType' on ${this._contentType} SourceBuffer:`, e);
              }
            }
          }

          let buffer: ArrayBuffer = data as ArrayBuffer;
          if (this._configManager.patch.removeGapFromFirstInitSegment) {
            if (s.id === 0) {
              buffer = this._avBufferPatch.removeGapFromFirstInitSegment(buffer);
            }
          }
          if (this._configManager.patch.encryptFirstClearInitSegment) {
            if (s.id === 0 && contentProtections.length === 0) {
              buffer = this._avBufferPatch.encryptFirstClearInitSegment(buffer);
            }
          }
          if (this._configManager.patch.encryptClearContent) {
            if (contentProtections.length === 0) {
              if (s.id === 0) {
                buffer = this._avBufferPatch.encryptClearContentInit(buffer);
              } else {
                const [newBuffer, applied] = this._avBufferPatch.encryptClearContentMedia(buffer);
                if (applied) {
                  buffer = newBuffer;
                }
              }
            }
          }

          if (this._configManager.patch.normaliseTimescale) {
            if (s.id === 0) {
              buffer = this._avBufferPatch.normaliseTimescaleInit(buffer);
            } else {
              buffer = this._avBufferPatch.normaliseTimescaleMedia(buffer);
            }
          }

          if (
            this._manifestManager.isLive() &&
            this._contentType === EContentType.VIDEO &&
            timescale &&
            s.id !== 0 && // prft boxes only exist in media segments
            !this._prftBoxParsed
          ) {
            this.parseProducerRefTime(buffer, timescale);
          }

          this._ceaBuffer?.feedBuffer(readyDataSegment);

          this._logger.debug(
            `Feeding ${this._contentType} buffer ${label ? `${label}:` : ''}${
              s.id
            } (${periodId}:${bandwidth}) range: [${s.time - s.duration} - ${s.time}]`
          );

          try {
            this._appendTimeStart = performance.now();
            this._sourceBuffer.appendBuffer(buffer);
            this._appendInProgress = true;

            this._lastSegmentAppendedToBuffer = Object.assign({}, s);
            this._segmentDownloaderManager.lastSegmentInBuffer = this._lastSegmentAppendedToBuffer;
            if (s.id !== 0) {
              const i: number = this._mediaSegmentsInBuffer.findIndex(
                ({time}: {time: number}) => time === s.time
              );
              if (i < 0) {
                this._mediaSegmentsInBuffer.push(s);
              } else {
                this._mediaSegmentsInBuffer[i] = s;
              }
            }
          } catch (e) {
            this.handleAppendBufferError(e as Error);

            return;
          }

          this._segmentDownloaderManager.deleteReadyDataSegment();

          this._isLastSegmentInBuffer = this._manifestManager.isLastSegment(s);
          if (inbandEventStreams.length > 0) {
            this.emitInbandStreamEvent(s, buffer, inbandEventStreams);
          }
        } else if (this._shouldRemoveBuffer) {
          const removed: boolean = this.removeBufferBehind(currentTime);
          this._shouldRemoveBuffer = !removed;
        }
      } else if (this._shouldRemoveBuffer) {
        const removed: boolean = this.removeBufferBehind(currentTime);
        this._shouldRemoveBuffer = !removed;
      }
    }
  };

  /**
   * When `QuotaExceededError` error occurs we shorten the buffer ahead by 5 seconds
   * @param e
   */
  private handleAppendBufferError(e: Error): void {
    if ((e as Error)?.name === 'QuotaExceededError') {
      const {bufferAhead} = this._configManager.buffer;
      if (bufferAhead <= this._MIN_BUFFER_AHEAD) {
        const message: string = 'QuotaExceededError after reaching the min buffer ahead value';
        this._logger.error(message, this._MIN_BUFFER_AHEAD);
        this._dispatcher.emit({
          name: EEvent.TAPE_ERROR,
          type: EErrorType.MSE,
          code: EErrorCode.QUOTA_EXCEEDED_ERROR,
          severity: EErrorSeverity.FATAL,
          nativeError: e,
          message
        });
      }

      let newBufferAhead: number = bufferAhead - 5;
      if (newBufferAhead < this._MIN_BUFFER_AHEAD) {
        newBufferAhead = this._MIN_BUFFER_AHEAD;
      }
      this._logger.warn(
        `QuotaExceededError: ${this._contentType}, updating buffer ahead from ${bufferAhead} to ${newBufferAhead}`
      );
      this._configManager.update({buffer: {bufferAhead: newBufferAhead}});

      const removed: boolean = this.removeBufferBehind(this._videoElement.currentTime);
      this._shouldRemoveBuffer = !removed;
    }
  }

  private init(mimeType: EMimeType, codecs: string): void {
    this._mimeType = mimeType;
    this._codecsProfile = getCodecsProfile(codecs);
    const mimeCodecs: string = getMimeCodec(mimeType, codecs);
    this._logger.info(`Add ${this._contentType} source buffer: '${mimeCodecs}'`);
    this._sourceBuffer = this._mediaSource.addSourceBuffer(mimeCodecs);
    this._sourceBuffer.mode = 'segments';
    this._sourceBuffer.addEventListener('updateend', this.onSourceBufferUpdateEnd);
    this._sourceBuffer.addEventListener('updatestart', this.onSourceBufferUpdateStart);
    this._sourceBuffer.addEventListener('abort', this.onSourceBufferAbort);

    this._segmentDownloaderManager.init(this._manifestManager.getStartingPosition());
    this._tickInterval = self.setInterval(this.runBufferOperations, this._TICK_INTERVAL);
  }

  private removeBufferBehind(currentTime: number): boolean {
    if (!this._sourceBuffer) return false;
    const {buffered, updating} = this._sourceBuffer;
    // if it's updating we can remove the buffer behind later, we don't want to call abort() here
    if (buffered.length === 0 || updating) return false;
    const startTime: number = buffered.start(0);
    const bufferToKeep: number = getSafeMarginBehind(
      this._configManager.buffer,
      this._manifestManager.averageSegmentDuration
    );
    const endTime: number = currentTime - bufferToKeep;

    if (currentTime - startTime >= bufferToKeep) {
      return this.clearBuffer(startTime, endTime);
    }

    return false;
  }

  private removeBufferBehindMediaQuality(endTime: number): void {
    let i: number = this._mediaSegmentsInBuffer.length;
    while (i--) {
      if (this._mediaSegmentsInBuffer[i].time < endTime) {
        this._mediaSegmentsInBuffer.splice(i, 1);
      }
    }
  }

  public getBufferRange(time?: number): [number, number] | null {
    if (!this._sourceBuffer) return null;

    return getTimeRange(this._sourceBuffer.buffered, time);
  }

  public onCeaRepresentationChange(representation: IRepresentation | null): void {
    this._ceaBuffer?.onRepresentationChange(representation);
  }

  public onRepresentationChange(representation: IRepresentation): void {
    this._segmentDownloaderManager.onRepresentationChange(representation);
    if (!this._sourceBuffer) {
      const mimeType: EMimeType = representation.adaptation.mimeType;
      const codecs: string = representation.codecs;
      this.init(mimeType, codecs);
    } else {
      this.reset();
    }
    this.handleAccessibility(representation.adaptation.accessibilities);
  }

  public onEmeReady = (): void => {
    this._emeReady = true;
    this.feedBuffer();
  };

  public clearCeaBuffer(): void {
    this._ceaBuffer?.clear();
  }

  public reset(): void {
    this._lastSegmentAppendedToBuffer = null;
    this._segmentDownloaderManager.reset();
    this.clearBuffer();
    this._mediaSegmentsInBuffer.length = 0;
    this._currentSegmentInBuffer = null;
  }

  public destroy(): void {
    this._logger.info(`Destroying ${this._contentType} buffer`);

    if (this._sourceBuffer && !this._videoElement.error) {
      this.clearBuffer();
      this._sourceBuffer.removeEventListener('updateend', this.onSourceBufferUpdateEnd);
      this._sourceBuffer.removeEventListener('updatestart', this.onSourceBufferUpdateStart);
      this._sourceBuffer.removeEventListener('abort', this.onSourceBufferAbort);
      this._mediaSource.removeSourceBuffer(this._sourceBuffer);
    }
    this._sourceBuffer = null;

    this._segmentDownloaderManager.destroy();
    this._avBufferPatch.destroy();

    this._ceaBuffer?.destroy();
    this._ceaBuffer = null;

    this._mediaSegmentsInBuffer.length = 0;
    this._currentSegmentInBuffer = null;

    if (this._tickInterval !== null) {
      self.clearInterval(this._tickInterval);
      this._tickInterval = null;
    }
  }
}

export default AVBuffer;
