import AbrManager from '@abr/abrManager';
import BufferManager from '@buffer/bufferManager';
import IBufferUpdate from '@buffer/interfaces/IBufferUpdate';
import CdnManager from '@cdn/cdnManager';
import CmcdManager from '@cmcd/cmcdManager';
import ConfigManager from '@config/configManager';
import ITapeConfig from '@config/interfaces/ITapeConfig';
import Dispatcher from '@dispatcher/dispatcher';
import EEvent from '@dispatcher/enum/EEvent';
import ENativeEvent from '@dispatcher/enum/ENativeEvent';
import ICustomEvents from '@dispatcher/interfaces/ICustomEvents';
import EmeManager from '@eme/emeManager';
import EErrorCode from '@error/enum/EErrorCode';
import EErrorSeverity from '@error/enum/EErrorSeverity';
import EErrorType from '@error/enum/EErrorType';
import ELogType from '@logger/enum/ELogType';
import ILogger from '@logger/interfaces/ILogger';
import LoggerManager from '@logger/loggerManager';
import IAbrRepresentationChange from '@manifest/interfaces/IAbrRepresentationChange';
import IActiveRepresentationChange from '@manifest/interfaces/IActiveRepresentationChange';
import IAudioDedupFilter from '@manifest/interfaces/IAudioDedupFilter';
import IAvailableRepresentations from '@manifest/interfaces/IAvailableRepresentations';
import IRepresentationChange from '@manifest/interfaces/IRepresentationChange';
import ManifestManager from '@manifest/manifestManager';
import IRequestFilter from '@network/interfaces/IRequestFilter';
import IResponseFilter from '@network/interfaces/IResponseFilter';
import NetworkManager from '@network/networkManager';
import IContentProtection from '@parser/manifest/interfaces/IContentProtection';
import ELiveEdgeStatus from '@presentation/enum/ELiveEdgeStatus';
import ISeekableRange from '@presentation/interfaces/ISeekableRange';
import EPlayerState from '@state/enum/EPlayerState';
import StateManager from '@state/stateManager';
import removeSrcAttribute from '@utils/removeSrcAttribute';
import version from '@version/version';

class MseManager {
  private _logger: ILogger;
  private _videoElement: HTMLVideoElement;
  private _dispatcher: Dispatcher;
  private _loggerManager: LoggerManager;
  private _configManager: ConfigManager;
  private _networkManager: NetworkManager;
  private _stateManager: StateManager | null = null;
  private _mediaSource: MediaSource | null = null;
  private _emeManager: EmeManager | null = null;
  private _abrManager: AbrManager | null = null;
  private _manifestManager: ManifestManager | null = null;
  private _bufferManager: BufferManager | null = null;
  private _cdnManager: CdnManager | null = null;
  private _cmcdManager: CmcdManager | null = null;

  private _audioDedupFilter: IAudioDedupFilter | null = null;

  constructor(videoElement: HTMLVideoElement, config?: ITapeConfig) {
    if (typeof Proxy !== 'undefined') {
      this._videoElement = new Proxy(videoElement, this.getVideoProxyHandler());
    } else {
      this._videoElement = videoElement;
    }

    this._dispatcher = new Dispatcher(this._videoElement);
    this._configManager = new ConfigManager(config);
    this._loggerManager = new LoggerManager(this._configManager, this._dispatcher);
    this._logger = this._loggerManager.registerLogger(ELogType.MSE);

    this._videoElement.controls = false;
    this._videoElement.autoplay = this._configManager.playback.autoplay;

    this._logger.info(`Tape v${version}`);
    this._logger.info('Tape config:', this._configManager.config);
    this._logger.info('Creating MSE Manager');

    this.addListeners();

    this._mediaSource = new MediaSource();
    this._mediaSource!.addEventListener('sourceopen', this.onSourceOpen);
    this._mediaSource!.addEventListener('sourceended', this.onSourceEnded);

    this._abrManager = new AbrManager(
      this._mediaSource!,
      this._configManager,
      this._loggerManager,
      this._dispatcher
    );

    this._networkManager = new NetworkManager(
      this._abrManager,
      this._configManager,
      this._loggerManager,
      this._dispatcher
    );
  }

  private addListeners(): void {
    this._videoElement.addEventListener(ENativeEvent.TIME_UPDATE, this.onTimeUpdate);
    this._videoElement.addEventListener(ENativeEvent.ERROR, this.onError);
    this._videoElement.addEventListener(ENativeEvent.RATE_CHANGE, this.onRateChange);
    this._videoElement.addEventListener(ENativeEvent.VOLUME_CHANGE, this.onVolumeChange);
    this._videoElement.addEventListener(ENativeEvent.LOADED_DATA, this.onLoadedData);
    this._videoElement.addEventListener(ENativeEvent.LOADED_METADATA, this.onLoadedMetadata);
    this._videoElement.addEventListener(ENativeEvent.CAN_PLAY_THROUGH, this.onCanPlayThrough);
  }

  private removeListeners(): void {
    this._videoElement.removeEventListener(ENativeEvent.TIME_UPDATE, this.onTimeUpdate);
    this._videoElement.removeEventListener(ENativeEvent.ERROR, this.onError);
    this._videoElement.removeEventListener(ENativeEvent.RATE_CHANGE, this.onRateChange);
    this._videoElement.removeEventListener(ENativeEvent.VOLUME_CHANGE, this.onVolumeChange);
    this._videoElement.removeEventListener(ENativeEvent.LOADED_DATA, this.onLoadedData);
    this._videoElement.removeEventListener(ENativeEvent.LOADED_METADATA, this.onLoadedMetadata);
    this._videoElement.removeEventListener(ENativeEvent.CAN_PLAY_THROUGH, this.onCanPlayThrough);

    this._mediaSource?.removeEventListener('sourceopen', this.onSourceOpen);
    this._mediaSource?.removeEventListener('sourceended', this.onSourceEnded);
  }

  private onBufferUpdate = (bufferUpdate: IBufferUpdate): void => {
    this._abrManager?.onBufferUpdate(bufferUpdate);
    this._cmcdManager?.onBufferUpdate(bufferUpdate);
  };

  private onEmeReady = (): void => {
    this._bufferManager?.onEmeReady();
  };

  private onEmeKeyStatusesChanged = (keyStatuses: Map<string, string>): void => {
    this._manifestManager?.onKeyStatusesChanged(keyStatuses);
    this._bufferManager?.onKeyStatusesChanged(keyStatuses);
  };

  private onError = (e: Event): void => {
    const videoElement: HTMLVideoElement = e.target as HTMLVideoElement;
    const mediaError: MediaError | null = videoElement.error;
    if (mediaError) {
      let code: EErrorCode = EErrorCode.MEDIA_ERROR_UNKNOWN;
      let message: string = 'The HTMLMediaElement errored due to an unknown reason';

      switch (mediaError.code) {
        case mediaError.MEDIA_ERR_ABORTED:
          message = "The fetching of the associated resource was aborted by the user's request";
          code = EErrorCode.MEDIA_ERROR_ABORTED;
          break;
        case mediaError.MEDIA_ERR_NETWORK:
          message = 'A network error occurred which prevented the media from being successfully fetched';
          code = EErrorCode.MEDIA_ERROR_NETWORK;
          break;
        case mediaError.MEDIA_ERR_DECODE:
          message = 'An error occurred while trying to decode the media resource';
          code = EErrorCode.MEDIA_ERROR_DECODE;
          break;
        case mediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
          message = 'The media resource has been found to be unsuitable';
          code = EErrorCode.MEDIA_ERROR_SRC_NOT_SUPPORTED;
          break;
      }

      message = mediaError.message ?? message;
      this._logger.error(message);
      this._dispatcher.emit({
        type: EErrorType.MSE,
        name: EEvent.TAPE_ERROR,
        severity: EErrorSeverity.FATAL,
        code,
        nativeError: mediaError,
        message
      });
    }
  };

  private onLoadedData = (_e: Event): void => {
    this._videoElement.removeEventListener(ENativeEvent.LOADED_DATA, this.onLoadedData);
    // In case there are any native text track, we are going to hide them.
    for (let i: number = 0; i < this._videoElement.textTracks.length; i++) {
      const textTrack: TextTrack = this._videoElement.textTracks[i];
      if (textTrack.mode === 'showing') {
        this._logger.debug(`Hide embedded text track: ${textTrack.label}`);
        textTrack.mode = 'hidden';
      }
    }
  };

  private onLoadedMetadata = (e: Event): void => {
    this._videoElement.removeEventListener(ENativeEvent.LOADED_METADATA, this.onLoadedMetadata);
    this.setStartingPosition();
    this.onVolumeChange(e, true);
  };

  private onCanPlayThrough = (_e: Event): void => {
    this._videoElement.removeEventListener(ENativeEvent.CAN_PLAY_THROUGH, this.onCanPlayThrough);
  };

  private onManifestUpdate = (isFirstUpdate: boolean): void => {
    if (isFirstUpdate) {
      this.init();
    }

    this._cmcdManager?.onManifestUpdate();
  };

  private onRateChange = (e: Event): void => {
    const {playbackRate} = e.target as HTMLVideoElement;
    this._logger.info(`Playback rate changed to ${playbackRate}`);
    this._dispatcher.emit({
      name: EEvent.RATE_CHANGE,
      playbackRate
    });

    this._abrManager?.onRateChange(playbackRate);
    this._cmcdManager?.onRateChange(playbackRate);
  };

  private onSeeking = (time: number): void => {
    this._logger.info(`Seeking to ${time}s`);
    this._videoElement.currentTime = time;
    this._abrManager?.onSeeking();
    this._bufferManager?.onSeeking();
  };

  private onAvailableRepresentations = (availableRepresentations: IAvailableRepresentations): void => {
    this._bufferManager?.onAvailableRepresentations(availableRepresentations);
  };

  private onRepresentationChange = (
    representationChange: IRepresentationChange | IAbrRepresentationChange
  ): void => {
    if ('switchInfo' in representationChange) return;
    this._bufferManager?.onRepresentationChange(representationChange);
  };

  private onActiveRepresentationChange = (activeRepresentationChange: IActiveRepresentationChange): void => {
    this._cmcdManager?.onActiveRepresentationChange(activeRepresentationChange);
  };

  private onSeekableRangeChange = (): ((seekableRangeChange: ISeekableRange) => void) => {
    let called: boolean = false;

    return (seekableRangeChange: ISeekableRange): void => {
      if (!called) {
        this.setStartingPosition();
        this._bufferManager?.onSeekableRangeChange(seekableRangeChange);
        called = true;
      }
    };
  };

  private onContentProtectionEncountered = (cp: IContentProtection): void => {
    this._emeManager?.onContentProtectionEncountered(cp);
  };

  private onSourceOpen = (): void => {
    this._mediaSource?.removeEventListener('sourceopen', this.onSourceOpen);
    URL.revokeObjectURL(this._videoElement.src);
    this._logger.info('Media Source open');
  };

  private onSourceEnded = (): void => {
    this._logger.info('Media Source ended');
  };

  private onTimeUpdate = (e: Event): void => {
    const {paused, seeking, currentTime} = e.target as HTMLVideoElement;
    if (paused || seeking) return;

    this._dispatcher.emit({
      name: EEvent.TIME_UPDATE,
      currentTime
    });

    this._manifestManager?.onTimeUpdate(currentTime);
    this._cmcdManager?.onTimeUpdate(currentTime);

    if (!this._manifestManager?.isLive()) return;

    const isOutside: boolean = this.seekIfOutsideOfSeekableRange(currentTime);
    if (!isOutside) {
      // if we are far from the live edge we try to catch up
      const liveEdgeStatus: ELiveEdgeStatus | null = this._manifestManager?.getLiveEdgeStatus();
      switch (liveEdgeStatus) {
        case ELiveEdgeStatus.AT:
          if (this._videoElement.playbackRate !== 1) this.playbackRate(1);
          break;
        case ELiveEdgeStatus.AWAY:
          if (this._videoElement.playbackRate === 1) this.playbackRate(1.1);
          break;
      }
    }
  };

  private onVolumeChange = (e: Event, forceEmit?: boolean): void => {
    const {muted, volume} = e.target as HTMLVideoElement;
    if (!forceEmit) {
      this._logger.info(`Volume changed to ${volume}, muted: ${muted}`);
    }
    this._dispatcher.emit({
      name: EEvent.VOLUME_CHANGE,
      muted,
      volume
    });
  };

  private attemptToPlay(): void {
    try {
      this._videoElement.play();
    } catch (e) {
      this._logger.error('Failed to play', e);
    }
  }

  private getVideoProxyHandler = (): ProxyHandler<HTMLVideoElement> => {
    let startingPosition: number = 0;

    return {
      get: (target: HTMLVideoElement, prop: keyof HTMLVideoElement): unknown => {
        const value: unknown = Reflect.get(target, prop);
        if (typeof value === 'function') {
          return value.bind(target);
        }

        if (prop === 'currentTime') {
          if (target.readyState === target.HAVE_NOTHING) {
            return startingPosition;
          }
        }

        return value;
      },
      set: (target: HTMLVideoElement, prop: keyof HTMLVideoElement, value: unknown): boolean => {
        if (prop === 'currentTime') {
          if (target.readyState === target.HAVE_NOTHING) {
            this._logger.debug(`storing ${value} as 'currentTime' in the video element proxy handler`);
            startingPosition = value as number;

            return true;
          }
        }

        return Reflect.set(target, prop, value);
      }
    };
  };

  private init(): void {
    if (!this._manifestManager || !this._stateManager) return;

    if (this._manifestManager.contentProtections.length > 0) {
      this._emeManager?.onManifestParsed(this._manifestManager.contentProtections);
    } else {
      this.onEmeReady();
    }
  }

  private setStartingPosition = (position?: number): void => {
    const startingPosition: number | undefined = position ?? this._manifestManager?.getStartingPosition();
    if (startingPosition) {
      this._logger.debug(`Set starting position: ${startingPosition}`);
      this._videoElement.currentTime = startingPosition;
      this._dispatcher.emit({
        name: EEvent.TIME_UPDATE,
        currentTime: startingPosition
      });
    }
  };

  private seekIfOutsideOfSeekableRange(currentTime: number): boolean {
    if (!this._manifestManager) return false;

    const seekableRange: ISeekableRange | null = this._manifestManager?.getSeekableRange();
    if (!seekableRange) return false;

    const {start} = seekableRange;
    const isOutsideSeekableRange: boolean = currentTime < start;

    if (isOutsideSeekableRange && this._stateManager?.playerState !== EPlayerState.ENDED) {
      const message: string = 'Playing outside the seekable range';
      this._logger.warn(message, currentTime);
      this._dispatcher.emit({
        name: EEvent.TAPE_ERROR,
        type: EErrorType.INTERNAL,
        message,
        code: EErrorCode.OUTSIDE_SEEKABLE_RANGE,
        severity: EErrorSeverity.WARN
      });
      const dvrWindowStart: number = seekableRange.start + (seekableRange.presentationDelay ?? 0);
      this.onSeeking(dvrWindowStart);
    }

    return isOutsideSeekableRange;
  }

  public addEventListener<K extends keyof ICustomEvents>(
    eventName: K,
    callback: (event: ICustomEvents[K]) => void
  ): void {
    this._dispatcher.on(eventName, callback);
  }

  public removeEventListener<K extends keyof ICustomEvents>(
    eventName: K,
    callback: (event: ICustomEvents[K]) => void
  ): void {
    this._dispatcher.off(eventName, callback);
  }

  public load(url: string): void {
    if (this._stateManager) {
      this._logger.error('Tape is already loaded');

      return;
    }

    if (typeof MediaSource === 'undefined') {
      const message: string = 'The Media Source Extensions API is not supported';
      this._logger.error(message);
      this._dispatcher.emit({
        name: EEvent.TAPE_ERROR,
        type: EErrorType.INTERNAL,
        message,
        code: EErrorCode.MEDIA_SOURCE_EXTENSIONS_NOT_SUPPORTED,
        severity: EErrorSeverity.FATAL
      });

      return;
    }

    this._logger.info('Loading');

    this._stateManager = new StateManager(this._videoElement, this._loggerManager, this._dispatcher);

    this._videoElement.src = URL.createObjectURL(this._mediaSource!);

    this._emeManager = new EmeManager(
      this._videoElement,
      this._networkManager,
      this._stateManager,
      this._configManager,
      this._loggerManager,
      this._dispatcher,
      this.onEmeReady,
      this.onEmeKeyStatusesChanged
    );

    const seekableRangeChange: (seekableRangeChange: ISeekableRange) => void = this.onSeekableRangeChange();
    this._manifestManager = new ManifestManager(
      url,
      this._videoElement,
      this._mediaSource!,
      this._abrManager!,
      this._networkManager,
      this._configManager,
      this._loggerManager,
      this._dispatcher,
      this._audioDedupFilter,
      this.onManifestUpdate,
      this.onAvailableRepresentations,
      this.onRepresentationChange,
      this.setStartingPosition,
      seekableRangeChange,
      this.onContentProtectionEncountered
    );

    this._bufferManager = new BufferManager(
      this._videoElement,
      this._mediaSource!,
      this._manifestManager,
      this._networkManager,
      this._configManager,
      this._loggerManager,
      this._dispatcher,
      this.onBufferUpdate,
      this.onActiveRepresentationChange,
      this.onSeeking
    );

    if (this._configManager.cdn.enabled) {
      this._cdnManager = new CdnManager(url, this._configManager, this._loggerManager, this._dispatcher);
      this._networkManager.cdnManager = this._cdnManager;
    }

    if (this._configManager.cmcd.enabled) {
      this._cmcdManager = new CmcdManager(
        this._manifestManager,
        this._stateManager,
        this._configManager,
        this._loggerManager
      );
      this._networkManager.cmcdManager = this._cmcdManager;
      this._abrManager!.cmcdManager = this._cmcdManager;
    }

    this._manifestManager.init();
    this._emeManager.init();
  }

  public mute(): void {
    this._logger.info('Mute');
    this._videoElement.muted = true;
  }

  public unmute(): void {
    this._logger.info('Unmute');
    this._videoElement.muted = false;
  }

  public volume(volume: number): void {
    let targetVolume: number = volume;
    if (volume < 0) {
      targetVolume = 0;
    } else if (volume > 1) {
      targetVolume = 1;
    }

    this._logger.info(`Set volume to ${targetVolume}`);
    this._videoElement.volume = targetVolume;
  }

  public pause(): void {
    this._logger.info('Pause');
    this._videoElement.pause();
  }

  public play(): void {
    if (!this._manifestManager) return;
    const {currentTime} = this._videoElement;
    this._logger.info('Play');

    if (this._manifestManager.isLive()) {
      this.seekIfOutsideOfSeekableRange(currentTime);
    } else if (this._stateManager?.playerState === EPlayerState.ENDED) {
      this.onSeeking(0);
    }
    this.attemptToPlay();
  }

  public playbackRate(rate: number): void {
    let targetRate: number = rate;
    const {playbackRate} = this._videoElement;
    if (rate === playbackRate) return;
    try {
      this._videoElement.playbackRate = targetRate;
    } catch (e) {
      targetRate = 1;
      this._videoElement.playbackRate = targetRate;
    }
    this._logger.info(`Set playback rate to ${targetRate}`);
  }

  public registerAudioDedupFilter(audioDedupFilter: IAudioDedupFilter): void {
    this._audioDedupFilter = audioDedupFilter;
  }

  public registerRequestFilter(requestFilter: IRequestFilter): void {
    this._networkManager.registerRequestFilter(requestFilter);
  }

  public registerResponseFilter(responseFilter: IResponseFilter): void {
    this._networkManager.registerResponseFilter(responseFilter);
  }

  public selectVideoTrack(bandwidth: number | null): void {
    let value: number | 'auto' = 'auto';
    if (bandwidth !== null) {
      value = bandwidth;
    }
    this._manifestManager?.selectVideoTrack(value);
  }

  public selectAudioTrack(id: string): void {
    this._manifestManager?.selectAudioTrack(id);
  }

  public selectTextTrack(id: string | null): void {
    let value: Omit<string, 'off'> | 'off' = 'off';
    if (id !== null) {
      value = id;
    }
    this._manifestManager?.selectTextTrack(value);
  }

  public selectImageTrack(id: string | null): void {
    let value: Omit<string, 'off'> | 'off' = 'off';
    if (id !== null) {
      value = id;
    }
    this._manifestManager?.selectImageTrack(value);
  }

  public seek(relativeTime: number): void {
    const {currentTime} = this._videoElement;
    this.seekTo(currentTime + relativeTime);
  }

  public seekTo(absoluteTime: number): void {
    if (!this._manifestManager) return;

    let targetTime: number = absoluteTime;
    const seekableRange: ISeekableRange | null = this._manifestManager.getSeekableRange();
    if (!seekableRange) {
      this._logger.warn(`Can't seek to ${absoluteTime}, manifest manager is not ready yet`);

      return;
    }

    if (this._configManager.manifest.isMultiview) {
      this._logger.warn(`Seeking is not currently supported with multiview enabled`);

      return;
    }

    const {start, end} = seekableRange;
    if (absoluteTime < start) {
      targetTime = start;
    } else if (absoluteTime >= end) {
      if (this._manifestManager?.isLive()) {
        targetTime = end;
      } else {
        const seekToEndOffset: number = 0.5;
        this._logger.warn(
          `Applying ${seekToEndOffset}s offset to the seek position ${end}s to gracefully end the playout`
        );
        targetTime = end - seekToEndOffset;
      }
    }

    if (this._stateManager?.playerState === EPlayerState.ENDED && targetTime === end) {
      return;
    }

    this.onSeeking(targetTime);
  }

  public setAbrLimits({minBandwidth, maxBandwidth}: {minBandwidth: number; maxBandwidth: number}): void {
    this._configManager.update({
      abr: {
        minBandwidth,
        maxBandwidth
      }
    });
  }

  public destroy(): Promise<void> {
    return new Promise((resolve: () => void) => {
      this._logger.info('Destroying MSE manager');
      this.removeListeners();

      this._networkManager.destroy();
      this._stateManager?.destroy();
      this._stateManager = null;
      this._abrManager?.destroy();
      this._abrManager = null;
      this._manifestManager?.destroy();
      this._manifestManager = null;
      this._mediaSource = null;
      this._bufferManager?.destroy();
      this._bufferManager = null;
      this._emeManager?.destroy();
      this._emeManager = null;
      this._cdnManager?.destroy();
      this._cdnManager = null;
      this._cmcdManager?.destroy();
      this._cmcdManager = null;

      this._audioDedupFilter = null;

      removeSrcAttribute(this._videoElement);
      if (this._videoElement.networkState !== 0 /* NETWORK_EMPTY */) {
        this._videoElement.addEventListener('emptied', () => resolve(), {once: true});
      } else {
        resolve();
      }
      this._videoElement.load();
    });
  }
}

export default MseManager;
