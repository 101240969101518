import ETextCodecs from '@parser/text/enum/ETextCodecs';

import getFileExtension from './getFileExtension';

const guessCodecs = (url: string): string => {
  const extension: string = getFileExtension(url);
  switch (extension) {
    case 'vtt':
    case 'webvtt':
      return ETextCodecs.WEBVTT;
  }

  return '';
};

export default guessCodecs;
