enum EAnchorId {
  UPPER_LEFT = 0,
  UPPER_CENTER = 1,
  UPPER_RIGHT = 2,
  MIDDLE_LEFT = 3,
  MIDDLE_CENTER = 4,
  MIDDLE_RIGHT = 5,
  LOWER_LEFT = 6,
  LOWER_CENTER = 7,
  LOWER_RIGHT = 8
}

export default EAnchorId;
