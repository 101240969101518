import EAudioChannelSchemeUri from '@parser/manifest/enum/EAudioChannelSchemeUri';

const getAudioChannels = (schemeIdUri: EAudioChannelSchemeUri, value: string): number => {
  let channels: number = 0;

  switch (schemeIdUri) {
    case EAudioChannelSchemeUri.URN_MPEG:
      // A space-separated list of speaker positions, so the number of
      // channels is the length of this list.
      channels = value.trim().split(/ +/).length;
      break;
    case EAudioChannelSchemeUri.URN_MPEG_23003:
    case EAudioChannelSchemeUri.URN_DTS: {
      // As far as we can tell, this is a number of channels.
      channels = +value;
      break;
    }
    case EAudioChannelSchemeUri.TAG_DOLBY:
    case EAudioChannelSchemeUri.URN_DOLBY: {
      // A hex-encoded 16-bit integer, in which each bit represents a
      // channel.
      let hexValue: number = parseInt(value, 16);
      if (!hexValue) {
        // 0 or NaN
        break;
      }
      // Count the 1-bits in hexValue.
      let numBits: number = 0;
      while (hexValue) {
        if (hexValue & 1) {
          ++numBits;
        }
        hexValue >>= 1;
      }

      channels = numBits;
      break;
    }
    // Defined by https://dashif.org/identifiers/audio_source_metadata/ and clause 8.2, in ISO/IEC 23001-8.
    case EAudioChannelSchemeUri.URN_MPEG_MPEGB: {
      const noValue: number = 0;
      const channelCountMapping: Array<number> = [
        noValue,
        1,
        2,
        3,
        4,
        5,
        6,
        8,
        2,
        3 /* 0--9 */,
        4,
        7,
        8,
        24,
        8,
        12,
        10,
        12,
        14,
        12 /* 10--19 */,
        14 /* 20 */
      ];
      const intValue: number = parseInt(value, 10);
      if (!intValue) {
        // 0 or NaN
        break;
      }
      if (intValue > noValue && intValue < channelCountMapping.length) {
        channels = channelCountMapping[intValue];
        break;
      }
    }
  }

  return channels;
};

export default getAudioChannels;
