enum ETagName {
  BYTERANGE = 'EXT-X-BYTERANGE',
  DATERANGE = 'EXT-X-DATERANGE',
  DISCONTINUITY = 'EXT-X-DISCONTINUITY',
  ENDLIST = 'EXT-X-ENDLIST',
  IMAGE_STREAM_INF = 'EXT-X-IMAGE-STREAM-INF',
  INDEPENDENT_SEGMENTS = 'EXT-X-INDEPENDENT-SEGMENTS',
  I_FRAME_STREAM_INF = 'EXT-X-I-FRAME-STREAM-INF',
  INF = 'EXTINF',
  KEY = 'EXT-X-KEY',
  MAP = 'EXT-X-MAP',
  MEDIA = 'EXT-X-MEDIA',
  MEDIA_SEQUENCE = 'EXT-X-MEDIA-SEQUENCE',
  PLAYLIST_TYPE = 'EXT-X-PLAYLIST-TYPE',
  PROGRAM_DATE_TIME = 'EXT-X-PROGRAM-DATE-TIME',
  SESSION_KEY = 'EXT-X-SESSION-KEY',
  STREAM_INF = 'EXT-X-STREAM-INF',
  TARGET_DURATION = 'EXT-X-TARGETDURATION',
  TILES = 'EXT-X-TILES',
  USP_MEDIA = 'USP-X-MEDIA'
}

export default ETagName;
